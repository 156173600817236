import React, { useEffect } from 'react'

import GridItem from "./../Grid/GridItem.js";
import GridContainer from "./../Grid/GridContainer.js";
import Card from "./../Card/Card";
import CardHeader from "./../Card/CardHeader.js";
import CardIcon from "./../Card/CardIcon.js";
import CardFooter from "./../Card/CardFooter.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "./../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import ReceiptIcon from '@material-ui/icons/Receipt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AuthService from '../../../../AuthService.js';
import { useState } from 'react';
import SkelectonCard from '../../../Skeleton/SkelectonCard.js';
import utils from '../../../../utils/index.js';

const useStyles = makeStyles(styles);

export default function CardQuick({periodo, tipoPedido}) {
    let filter = (periodo.dataInicial ? '/' + periodo.dataInicial + '/' + periodo.dataFinal: '') + '/' + tipoPedido

    return (

        <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
                <CardGenerico
                    color="info"
                    url={`/dashboard/cardquick/QUANTIDADE_PEDIDO${filter}`}
                    icon={<ReceiptIcon />}
                    maskTypeValue="int"
                />
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
                <CardGenerico
                    color="success"
                    url={`/dashboard/cardquick/VALOR_TOTAL_VENDA${filter}`}
                    icon={<AttachMoneyIcon />}
                    maskTypeValue="decimal"
                />
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
                <CardGenerico
                    color="primary"
                    url={`/dashboard/cardquick/TICKET_MEDIO_VENDA${filter}`}
                    icon={<TrendingFlatIcon />} 
                    maskTypeValue="decimal"/>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>

                <CardGenerico
                    color="rose"
                    url={`/dashboard/cardquick/QUANTIDADE_CUPOM_DESCONTO${filter}`}
                    icon={<LoyaltyIcon />} 
                    maskTypeValue="percent"/>
            </GridItem>
        </GridContainer >
    )
}

export function CardGenerico({ color, icon, url, maskTypeValue}) {
    let [informacao, setinformacao] = useState(0);
    let [loading, setloading] = useState(true);
    const classes = useStyles();

    function getInfo() {
        let service = new AuthService();
        service
            .get(url)
            .then(resp => {
                setinformacao(resp)
                setloading(false)
            })
            .catch(err => {
                setloading(false)
                console.log(err)
            })

    }

    useEffect(() => {
        getInfo();
    }, [])

    useEffect(() => {
        getInfo();
    }, [url])


    return (
        <Card>
            <CardHeader color={color} stats icon>
                <CardIcon color={color}>
                    {icon}
                </CardIcon>
                {loading ? (<SkelectonCard className={classes.cardCategory} />) :
                    (<div>
                        <p className={classes.cardCategory}>
                            {informacao.descricao}
                        </p>
                        <h3 className={classes.cardTitle}>
                                {maskTypeValue === "decimal"? (utils.formatNumber(informacao.valor)) : 
                                (maskTypeValue === "percent"? ('% ' + utils.formataMonetario(informacao.valor)) :  
                                (maskTypeValue === "double"?  utils.formataMonetario(informacao.valor) :
                                (maskTypeValue === "data"? utils.formatarData(informacao.valor) :
                            (informacao.valor? Math.round(informacao.valor): 0))))} 
                        </h3>
                    </div>
                    )
                }
            </CardHeader>
            <CardFooter >
            </CardFooter>
        </Card>
    )
}
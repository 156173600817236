import ReactPixel from 'react-facebook-pixel';

const getConfig = () => {

    let ID = process.env.REACT_APP_PIXEL_FACEBOOK_ID ?? 2538871009718963;
    let OPTIONS = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
    };

    return {
        id: ID,
        options: OPTIONS
    }
}


export const init = () => {
    let config = getConfig();
    console.log("PIXEL FACEBOOK INICIADO", config.id);
    ReactPixel.init(config.id, config.options);
}

export const trackPageView = () => {
    console.log("TRACKING PAGE VIEW");
    ReactPixel.pageView(); // For tracking page view
}

export const trackEvent = (event, data) => {
    ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
}

export const trackSingle = (event, data) => {
    let config = getConfig();
    ReactPixel.trackSingle(config.id, event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
}

export const trackCustom = (event, data) => {
    ReactPixel.trackCustom(event, data); // For tracking custom events
}

export const trackSingleCustom = (event, data) => {
    let config = getConfig();
    ReactPixel.trackSingleCustom(config.id, event, data); // For tracking custom events
}
import React from 'react';
import CsvDownload from 'react-json-to-csv'
import { IconButton, TableBody, TableRow, Box, TableContainer, Table, TableCell, Grid, TableHead } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import utils from '../../../../utils';
import { Alert, AlertTitle } from '@material-ui/lab';
import { dateFormat } from '../../../../utils/DateUtils';

export default function ReportSaleCustomer({ orderList, filename }) {

    let data = orderList?.map(({ hash, name, phone, quantity, value, quantityTakeWay, quantityDelivery }) => 
    ({ hash, name, phone, quantity, value: utils.formataMonetario(value), 
        quantityTakeWay: parseInt(quantityTakeWay), 
        quantityDelivery: parseInt(quantityDelivery)
    
    }))

    return (
        <Box pt={2}>
            <Grid container direction='column' spacing={2}>
                <Grid item >
                    <CsvDownload
                        disabled={!orderList || (orderList.length <= 0)}
                        filename={filename}
                        data={data}
                    >
                        Exportar
                    </CsvDownload>
                </Grid>

                <Grid item>
                    {orderList && orderList.length > 0 ? (
                        <TableContainer>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Nome
                                        </TableCell>
                                        <TableCell>
                                            Telefone
                                        </TableCell>
                                        <TableCell align='right'>
                                            Pedidos
                                        </TableCell>
                                        <TableCell align='right'>
                                            Total
                                        </TableCell>
                                        <TableCell align='right'>
                                            Qtde Retirar
                                        </TableCell>
                                        <TableCell align='right'>
                                            Qtde Entrega
                                        </TableCell>
                                        <TableCell align='right'>
                                            Ult. Pedido
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {orderList?.map((e, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {e.name}
                                            </TableCell>
                                            <TableCell>
                                                {e.phone}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {utils.formatNumber(e.quantity, 'decimal')}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {utils.formatNumber(e.value)}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {parseInt(e.quantityTakeWay)}
                                            </TableCell>
                                            <TableCell align='right'>
                                                {parseInt(e.quantityDelivery)}
                                            </TableCell>
                                            {/* <TableCell>
                                                <IconButton color='primary'>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableCell> */}
                                            <TableCell align='right'>
                                                {dateFormat(e.dataUltimoPedido)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>)
                        :
                       
                            'Nenhum pedido encontratado'
                       
                    }
                </Grid>
            </Grid>
        </Box>
    );
}


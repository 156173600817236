import React from "react";

import AuthService from "../../AuthService";
import MenuPrincipal from "./MenuPrincipal";

export default function MenuPrincipalLogado() {

    const auth = new AuthService();

    return (auth.loggedIn() ? <MenuPrincipal/> : null)
}
import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box, Button, Grid } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    Button: {
        fontSize: "100%",
        color: "#253257",
        "&:hover": {
            color: "#F2C26A",
            background: "transparent"
        }
    },
    appBarSpacer: theme.mixins.toolbar
}));

export default function BarraTitulo(props) {
    const title = props.title ? props.title : ""
    const subTitle = props.subTitle ? props.subTitle : ""
    const component = props.component ? props.component : false

    return (
        <>
            <Grid container direction="row" justifyContent="space-between">
                <Grid item xs={10}>
                    <Box pt={1} pb={2}>
                        <Typography color="primary" variant="h4" component="h2" style={{ fontFamily: "Bebas Neue", fontSize: "1.9em" }}>
                            {title}
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle1" gutterBottom>
                            {subTitle}
                        </Typography>
                    </Box>
                </Grid>

                {component && (
                    <Grid xs={2} item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CompRelatorios />
                    </div>
                </Grid>
                )}                
            </Grid>
        </>
    )
}

const CompRelatorios = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        //setOpen(!open)
    };

    const handleClose = () => {
        setAnchorEl(null);
      };

    return (
        <>
            <Button 
                aria-controls={'menu-list-grow'}
                aria-haspopup="true"
                variant="outlined"
                onClick={handleClick} 
                className={classes.Button}>
                Relatórios
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                onClose={ handleClose }
            >
                <NavLink to="/CustomerSalesByType">
                    <MenuItem className={classes.Button} >Vendas por cliente</MenuItem>
                </NavLink>
            </Menu>
        </>
    );
}
import React from 'react';
import Routes from './routes';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#253257' },
    secondary: { main: '#F2C26A' }
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      }
    },
  } 
});

const App = () => (
  <div className="App" >
    <ThemeProvider theme={theme} >
      <SnackbarProvider maxSnack={3} >
        <Routes />
      </SnackbarProvider>
    </ThemeProvider>
  </div>
);

export default App;



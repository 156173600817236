import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Box, IconButton } from '@material-ui/core';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { converterURLImagemProduto } from "../../../utils/index"

const useStyles = makeStyles({
  root: {
    // maxWidth: 445,

  },
  media: {
    height: 160,
    objectFit: 'contain'
  },
});

export default function ImagemCard({ imagem, handleDelete, handleOrdemAdicionar, handleOrdemSubtrair }) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>

      <CardMedia
        className={classes.media}
        image={converterURLImagemProduto(imagem)}
        title={`Public ID ${imagem.publicId}`}
        component="img"
      />
      <CardContent >

        <Box display="block" textAlign="center">
          <Typography variant="body2" color="textSecondary" component="p">
            (ordem {imagem.ordem})
          </Typography>
        </Box>
      </CardContent>

      <CardActions disableSpacing>

        <Box display="flex" style={{ width: '100%' }}>

          <Box>
            <IconButton onClick={() => handleOrdemSubtrair(imagem)}>
              <ChevronLeftIcon />
            </IconButton>
          </Box>

          <Box flexGrow={1} textAlign="center">
            <IconButton onClick={() => handleDelete(imagem)}>
              <DeleteIcon />
            </IconButton>
          </Box>

          <Box>
            <IconButton onClick={() => handleOrdemAdicionar(imagem)}>
              <ChevronRightIcon />
            </IconButton>
          </Box>

        </Box>

      </CardActions>
    </Card>
  );
}
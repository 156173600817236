import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { TextField, Paper, Box, Button, Divider, IconButton } from "@material-ui/core";
import AuthService from "../../AuthService";
import Template from "../Generico/Template";
import BarraTitulo from "../Generico/BarraTitulo";
import AddIcon from "@material-ui/icons/Add";
import { withSnackbar } from "notistack";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';


const styles = theme => ({
    table: {
        minWidth: 600,
      },
});

class Chamado extends Component {
    constructor(props) {
        super(props);
        let service = new AuthService();
        let empresa = service.getProfile().empresa
        this.state = {
            chamados: [],
            chInput: {
                descricao: "",
                ativo: true,
            },
            chamarGarcom: empresa.chamarGarcom,
            open: false,
            rowsPerPage: 10,
            page: 0,
        };

        this.handleClose = this.handleClose.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSaveAll = this.handleSaveAll.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    }
    
    novoChamado(){
        let { chInput } = this.state;
        chInput = {
            descricao: '',
            ativo: true,
            pausado: true,
        }

        this.setState({ chInput });
    }

    componentDidMount() {
        this.handleListChamados();
    }

    handleClose() {
        this.setState({ open: false })
    };

    handleClickOpenNew = () => {
        this.setState({ open: true })
        this.novoChamado()
};

    handleClickOpenEdit = (chamado) => {
        this.setState({ open: true })

        let chamado2 = JSON.parse(JSON.stringify(chamado))

        this.setState({ chInput: chamado2 })
    };

    handleChange = (event) => {
        console.log('CHANGE', event);
        
        const { chInput } = this.state;

        let name = event.target.name;
        let value = event.target.value;

        chInput[name] = value;

        this.setState({ chInput })

        console.log('CHANGE', chInput);
    }

    handleListChamados() {

        let service = new AuthService();
        service
            .get("/tipoChamado/list")
            .then(retorno => {
                this.setState({ chamados: retorno })
            })
            .catch(err => {
                console.error(err);
                console.log(`Falha ao buscar chamados. Detalhes: ${err.message}`, { variant: 'error' });
            });
    }

    handleDelete(chamado, index) {
        let { chamados } = this.state;
        let service = new AuthService();

        service
          .delete(`/tipoChamado/${chamado.id}`)
          .then(resp => {
            this.setState({ page: 0 })
            chamados.splice(index, 1)

            this.setState({ chamados })

            this.props.enqueueSnackbar("Chamado excluído com sucesso", {
                variant: "success"
            });
          }).catch(err => {
            this.props.enqueueSnackbar(`Erro ao excluir o chamado. Detalhes: ${err.message}` , {
                variant: "error"
              });
          })
      }

    handleSaveAll() {
        let { chInput, chamados } = this.state;
        
        if (chInput.descricao === ""){
            this.props.enqueueSnackbar("Preencha o campo descrição.", {
                variant: "Error"
              });
              return;
        }

        let service = new AuthService();

        service
            .post("/tipoChamado", chInput)
            .then(resp => {
                let pos = chamados.map(function(ch) { return ch.id; }).indexOf(chInput.id);

                console.log('SAVE POSIÇÃO', pos)
                
                if (pos >= 0) {
                    chamados.splice(pos, 1)    
                }

                chamados.push(resp)

                this.setState({ chamados })
                this.setState({open: false})
   
                this.props.enqueueSnackbar("Salvo com sucesso.", {
                    variant: "success"
                  });


            })
            .catch(err => {
                this.props.enqueueSnackbar('Erro ao cadastrar um novo chamado.' + err.message, {
                    variant: "error"
                  });
            });
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };

    handleClickStopAndPause(event, chamado) {
        console.log("Pausar chamado clicado" + chamado)
        event.preventDefault();
        event.stopPropagation();

        let service = new AuthService();
        service
            .post(`/tipoChamado/pausar/${chamado.id}/${!chamado.pausado}`)
            .then(retorno => {
                chamado.pausado = retorno.pausado;
                this.setState({ chamado: chamado })
                console.log(`chamado  ${chamado.pausado ? 'liberado com sucesso' : 'chamado pausado'}`, { variant: 'info' });
            }).catch(err => {
                console.error("Falha ao pausar/startar chamado", err)
            })
        return false;
    }

    alterarConfiguracao(flagChamarGarcom){

        let service = new AuthService();

        let empresa = service.getProfile().empresa

        let empresaChange = { id: empresa.id,  chamarGarcom: flagChamarGarcom}

        service
            .put("/empresa/changeOnly", empresaChange)
            .then(retorno => {
                this.setState({ chamarGarcom: retorno.chamarGarcom })
                let profile = service.getProfile()
                profile.empresa = retorno;
                localStorage.setItem("_profile", JSON.stringify(profile))
            })
            .catch(err => {
                console.error(err);
                console.log(`Falha ao buscar alterar configuração do chamado. Detalhes: ${err.message}`, { variant: 'error' });
            });
    }


    render() {
        const { classes } = this.props;
        const { chamados, page, rowsPerPage, chInput, open } = this.state;
        
        return (
                <Template documentTitle="Cadastro de chamados">
                    <BarraTitulo title="Cadastro de chamados" subTitle="Configure os chamados"/>
                        <Paper>  
                            <Box p={3}>

                                <Button color="primary"
                                    variant="contained"
                                    startIcon={<AddIcon />}
                                    size="medium"
                                    onClick={this.handleClickOpenNew}
                                    >
                                    Chamado
                                </Button>
                                
                                <Box p={2} display="inline">
                                    <FormControlLabel
                                        control={
                                        <Switch
                                            checked={this.state.chamarGarcom}
                                            name="chamarGarcom"
                                            color="primary"
                                            onChange={() => this.alterarConfiguracao(!this.state.chamarGarcom) }
                                        />
                                        }
                                        label="Ativar chamado do garçom"
                                    />
                                </Box>
                            </Box>

                            <Divider />

                            <Box p={3}>
                                <TableContainer>
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="left">
                                                    Descrição
                                                </TableCell>
                                                <TableCell align="right">
                                                    Visível
                                                </TableCell>
                                                <TableCell align="right">
                                                    {/* Opções */}
                                                </TableCell>

                                            </TableRow>
                                        </TableHead>


                                        <TableBody>
                                            {chamados.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((chamado, index) => {
                                                return (
                                                    <TableRow key={chamado.id}>
                                                        <TableCell align="left">
                                                            {chamado.descricao}
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={chamado.pausado}
                                                                        name=""
                                                                        color="primary"
                                                                        onChange={(event) => this.handleClickStopAndPause(event, chamado) }
                                                                    />
                                                                }
                                                            //    label={chamado.pausado? "Esconder?": "Mostrar?"}
                                                            />

                                                        </TableCell>
                                                        
                                                        <TableCell scope="medium" align="right" xs={2}>
                                                            
                                                                <Button
                                                                    onClick={event => { this.handleClickOpenEdit(chamado) }}
                                                                    startIcon={<EditIcon />}>
                                                                    Editar
                                                                    </Button>

                                                                <IconButton
                                                                    aria-label="delete"
                                                                    variant="text"
                                                                    onClick={event => { this.handleDelete(chamado, index) }} >
                                                                    <DeleteIcon />
                                                                </IconButton>

                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })}
                                        </TableBody>

                                    </Table>

                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25]}
                                        component="div"
                                        count={chamados.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={this.handleChangePage}
                                        onChangeRowsPerPage={this.handleChangeRowsPerPage} /> 
                                </TableContainer> 
                           </Box>



                        </Paper>
                
                
                        <Dialog 
                            open={open} 
                            onClose={this.handleClose} 
                            aria-labelledby="max-width-dialog-title" 
                            fullWidth>
                            
                            <DialogTitle id="max-width-dialog-title">Chamado</DialogTitle>
                            <DialogContent>
                                
                                <Box p={2}>
                                    <Grid container spacing={2}  >
                                        <Grid item xs={12} md={12} >
                                            <TextField
                                                required
                                                onChange={this.handleChange}
                                                value={chInput.descricao}
                                                variant="outlined"
                                                label="Descrição"
                                                name="descricao"
                                                fullWidth
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DialogContent>

                            <DialogActions>
                                <Button 
                                    color="primary"
                                    variant="text"
                                    onClick={this.handleClose} 
                                    color="primary" 
                                    startIcon={<CancelIcon/>}>
                                    Cancelar
                                </Button>
                            
                                <Button
                                    aria-expanded={true}
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.handleSaveAll}
                                    startIcon={<SaveIcon />}>
                                    Salvar
                                    </Button>
                            </DialogActions>
                        </Dialog>  
                
                </Template>
        )
    }
}

export default withSnackbar(withStyles(styles)(Chamado));
import React from 'react'
import { Paper, Grid, TextField, Button, Box } from '@material-ui/core';
import AuthService from "../../AuthService";
import { useSnackbar } from 'notistack';
import MenuItem from '@material-ui/core/MenuItem';

export default function ConfigurarTimezone(){
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [empresa, setEmpresa] = React.useState(null);
    const [values, setValues] = React.useState({
        timezone: ''
      });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };

    const handleSalvar = () => {
        let empresaToChange = {id: empresa.id, timezone: values.timezone}
        service.put('/empresa/changeOnly', empresaToChange)
        .then(empresaRetorno => {
            setEmpresa(empresaRetorno)
            setValues({  timezone: empresaRetorno.timezone });
            service.atualizarEmpresaProfile(empresaRetorno)
            enqueueSnackbar("Timezone atualizado sucesso", { variant: 'success' })
        }).catch(err =>{
            console.error(err)
            enqueueSnackbar("Falha ao salvar timezone", { variant: 'error' })
        })
    };
    
    React.useEffect(() => {
        service.get('/login/empresaLogada')
        .then(empresa => {
            setEmpresa(empresa)
            setValues({  timezone: empresa.timezone });
        }).catch(err =>{
            console.error(err)
        })
    }, []);
    


    return(
        <Paper>
            <Box p={3}>

            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={10}>

                <TextField
                    id="timezone-select-label"
                    variant="outlined"
                    select
                    name="timezone"
                    label="Selecione o timezone (fuso horário) adequado a sua região"
                    value={values.timezone}
                    onChange={handleChange}
                    fullWidth
                >
                <MenuItem value={'-05:00'}>Acre (ACT) -05:00</MenuItem>
                <MenuItem value={'-04:00'}>Amazonas (AMT) -04:00</MenuItem>
                <MenuItem value={'-03:00'}><strong>Brasília (BRT) -03:00 (timezone padrão)</strong></MenuItem>
                <MenuItem value={'-02:00'}>Fernando de Noronha (FNT) -02:00</MenuItem>
              </TextField>

                </Grid>

                <Grid item xs={12} md={2}>
                    <Button fullWidth 
                        color="secondary"
                        onClick={handleSalvar}
                        variant="contained">Salvar</Button>
                </Grid>
            </Grid>
            </Box>
        </Paper> 
    )
}
import DateFnsUtils from '@date-io/date-fns';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { dateFormat } from '../../utils/DateUtils';
const moment = require('moment')

const loadPeriodOptions = () => [
    { key: 0, label: 'Próximos 30 dias', startDate: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(30, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 1, label: 'Próximos 15 dias', startDate: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(15, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 2, label: 'Próximos 7 dias', startDate: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(7, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 3, label: 'Próximos 3 dias', startDate: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(3, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 4, label: 'Amanhã', startDate: `${moment().add(1, 'days').subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(2, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 5, default: true, label: 'Hoje', startDate: `${moment().subtract(6, 'hours').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(6, 'hours').add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 6, label: 'Ontem', startDate: `${moment().subtract(1, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 7, label: 'Últimos 2 dias', startDate: `${moment().subtract(2, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 8, label: 'Últimos 3 dias', startDate: `${moment().subtract(3, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 9, label: 'Últimos 7 dias', startDate: `${moment().subtract(7, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 10, label: 'Últimos 15 dias', startDate: `${moment().subtract(15, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 11, label: 'Últimos 30 dias', startDate: `${moment().subtract(30, 'days').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` },
    { key: 12, label: 'Mês anterior', startDate: `${moment().startOf('month').subtract(1, 'month').format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().subtract(1, 'month').endOf('month').format('yyyy-MM-DD')} 00:00:00` },
    { key: 13, label: 'Personalizado', startDate: `${moment().format('yyyy-MM-DD')} 00:00:00`, endDate: `${moment().format('yyyy-MM-DD')} 23:59:59` },
]

function CustomDateFilter({ onChange }) {
    const periodOptions = loadPeriodOptions()
    const [openFilterCustom, setOpenFilterCustom] = useState(false)

    const [defaultPeriod, setdefaultPeriod] = React.useState(periodOptions.filter(elem => elem.default)[0])
    const [selectedDate, setSelectedDate] = React.useState({ startDate: defaultPeriod.startDate, endDate: defaultPeriod.endDate });

    const handleChangeDate = (date, name) => {
        const dateFormatted = `${dateFormat(date, { pattern: "yyyy-MM-DD" })} 06:00:00`

        setSelectedDate({ ...selectedDate, [name]: dateFormatted })
    };

    const handleChangeSelect = (event) => {
        const keySelected = event.target.value

        //localiza na lista de periodos o elemento selecionado pela chave
        const filterSelected = periodOptions.filter(elem => elem.key === keySelected)[0]

        if (filterSelected) {
            const { key, startDate, endDate } = filterSelected

            if (key === 13) {
                setOpenFilterCustom(true)
            } else {
                setOpenFilterCustom(false)
            }

            setSelectedDate({ startDate, endDate })
            setdefaultPeriod(filterSelected)
        }
    };

    useEffect(() => {
        if (selectedDate) {
            onChange(selectedDate.startDate, selectedDate.endDate)
        }
    }, [selectedDate])

    return (
        <div>
            <FormControl variant="filled" fullWidth size="small">
                <InputLabel>Filtrar por período</InputLabel>
                <Select
                    id="periodSelect"
                    value={defaultPeriod.key}
                    onChange={handleChangeSelect}
                >
                    {
                        periodOptions.map(option => (
                            <MenuItem key={option.key} value={option.key}>{option.label}</MenuItem>
                        ))
                    }

                </Select>

                {openFilterCustom &&
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="startDate"
                                    name="startDate"
                                    size='small'
                                    label="Período inicial"
                                    value={selectedDate.startDate}
                                    onChange={(date) => handleChangeDate(date, 'startDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período inicial',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    size='small'
                                    id="endDate"
                                    name="endDate"
                                    label="Período final"
                                    value={selectedDate.endDate}
                                    onChange={(date) => handleChangeDate(date, 'endDate')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período final',
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                }
            </FormControl>
        </div>
    );
}

export default CustomDateFilter;
import React from 'react';
import { makeStyles, Box, Paper, Typography} from '@material-ui/core';
 

const useStyles = makeStyles((theme) => ({
     root: {
        minWidth: theme.spacing(8),
     },
     caption: {
        opacity: "0.5",
     },
     value: {
        fontWeight: "500",
     },
}));


export default function InfoCard({caption, value, valueColor, ...props}) {
    const classes = useStyles();
   
    return (
       <Paper elevation={0} className={classes.root}>
        <Box pl={2} pt={1} pr={2} pb={1}>
            <Typography variant="caption" display="block" className={classes.caption} >{caption}</Typography>
            <Box color={valueColor}>
               <Typography variant={props.variant || "h4"} display="block" className={classes.value}>{value}</Typography>
            </Box>
        </Box>
       </Paper>
    )
}

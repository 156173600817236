import moment from "moment";

export function getCurrentDate(separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}`
}



export const dateFormat = (date, options) => {

    if (!date)
        return date

    let { pattern } = { pattern: "DD MMM HH:mm", ...options }
    let formated = moment(date).format(pattern)

    return formated
}
import { Box, Button, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask';
import Utils from '../../../utils';

const TextMaskCnpj = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder="xx.xxx.xxx/xxxx-xx"
        />
    );
}

export default function DadosEmpresa(props) {
    const [errorEmpresa, setErrorEmpresa] = React.useState({ nome: null, cpfCnpj: null });

    const validaStep = () => {

        var error = false;
        var erros = { nome: null, cpfCnpj: null };

        var cnpjDigits = Utils.onlyDigits(props.empresa.cpfCnpj);

        if (props.empresa.nome.length < 4) {
            erros.nome = "O nome deve conter no minímo 4 caracteres";
            error = true;
        }

        if (!props.empresa.nome) {
            erros.nome = "O nome não pode estar em branco";
            error = true;
        }

        if (cnpjDigits.length >= 0 && cnpjDigits.length < 14) {
            erros.cnpj = "O cnpj deve ter 14 digitos";
            error = true;
        }

        setErrorEmpresa(erros);

        if (error) {
            return;
        }

        props.handleChangeStep("+")

    }

    return (
        <Paper>
            <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <Typography align="center" variant="h5" style={{ margin: "10px 0" }}>
                    E sobre seu negócio...
                </Typography>

                <TextField
                    label="Nome do estabelecimento (obrigatório)"
                    error={!!errorEmpresa.nome}
                    helperText={errorEmpresa.nome}
                    style={{ margin: '10px' }}
                    value={props.empresa.nome}
                    name="nome"
                    variant="outlined"
                    fullWidth
                    onChange={event => props.handleChangeEmpresa(event)} />

                <TextField
                    label="CNPJ (obrigatório)"
                    error={!!errorEmpresa.cnpj}
                    helperText={errorEmpresa.cnpj}
                    style={{ margin: "10px" }}
                    name="cpfCnpj"
                    defaultValue={props.empresa.cpfCnpj}
                    fullWidth
                    inputProps={{ maxLength: 30 }}
                    onChange={event => props.handleChangeEmpresa(event)}
                    variant="outlined"
                    type="tel"
                    InputProps={{
                        inputComponent: TextMaskCnpj
                    }}
                />

                <TextField
                    label="Representante"
                    style={{ margin: '10px' }}
                    value={props.empresa.representante}
                    name="representante"
                    variant="outlined"
                    fullWidth
                    onChange={event => props.handleChangeEmpresa(event)} />

                <Box style={{ marginTop: "20px", width: "100%" }} display="flex" justifyContent="space-between">
                    <Button
                        onClick={() => props.handleChangeStep("-")}
                        variant="text">
                        Retornar
                    </Button>

                    <Button
                        onClick={validaStep}
                        variant="contained"
                        color="primary">
                        Próximo
                    </Button>
                </Box>

            </Box>
        </Paper>
    )
}

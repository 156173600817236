import React, { Component } from 'react'
import { Dialog, Paper, Grid, Box, Button, TextField,  DialogTitle, DialogContent, DialogActions, Chip, Divider } from '@material-ui/core';
import AuthService from '../../AuthService';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import BarraTitulo from '../Generico/BarraTitulo';
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';

export class permissaoMunicipio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            estados: [],
            municipios: [],
            municipio: null,
            municipiosPermitidos: [],
            carregando: false,
        }
    }

    componentDidMount() {

        this.handleListCidadesPermissao();

    }

    handleConfig = () => {
        this.setState({ open: !this.state.open })
    }

    handleChangeMunicipio = (event) => {
        this.carregarMunicipios(event.target.value);

    }

    carregarMunicipios(value) {
        let { carregando } = this.state

        if (carregando == false) {
            let service = new AuthService();
            service
                .get(`/municipio/list?first=0&max=9999&q=${value}`)
                .then(retorno => {
                    console.log(retorno);
                    this.setState({ municipios: retorno })
                    this.setState({ carregando: true })

                    setTimeout(() => {
                        this.setState({ carregando: false })
                    }, 2540);

                })
        }
    }

    handleListCidadesPermissao() {

        let service = new AuthService();
        service
            .get("/permissaoMunicipio/listOrCreate?tipo=station")
            .then(retorno => {
                this.setState({ municipiosPermitidos: retorno })

            })
            .catch(err => {
                console.error(err);
                console.log(`Falha ao buscar municipios`, err.message);
            });
    }

    handleSave = () => {
        let service = new AuthService();
        let { municipio, municipiosPermitidos } = this.state;

        let permissaoMunicipio = {
            municipio: municipio
        }

        service
            .post("/permissaoMunicipio", permissaoMunicipio)
            .then(resp => {
                municipiosPermitidos.push(resp);
                this.setState({ municipiosPermitidos });
                console.log(municipiosPermitidos);

                this.setState({ municipio })
                this.setState({ open: false })

            })
            .catch(err => {
                console.log("erro", err);

            })
    }

    handleClose = () => {
        let { open } = this.state;
        this.setState({ open: !open })
    }

    handleDelete = (municipioObj) => {
        let { municipiosPermitidos } = this.state;
        let service = new AuthService();

        service
            .delete(`/permissaoMunicipio/${municipioObj.id}`)
            .then(resp => {
                let index = municipiosPermitidos.findIndex(el => el.id === municipioObj.id);

                if (index != -1) {
                    municipiosPermitidos.splice(index, 1)
                }

                this.setState({ municipiosPermitidos })
            })
    }

    handleSelectMunicipio = (e, value) => {
        console.log(value);


        this.setState({ municipio: value })
    }

    render() {
        let { municipio, municipios } = this.state;
        let municipioEmpresa = JSON.parse(localStorage.getItem("_profile")).empresa.municipio;

        return (
            <React.Fragment>
                <BarraTitulo
                    title="Municípios atendidos"
                    subTitle="Adicione os municípios onde realiza entregas" />

                <Paper>
                    <Box p={3}>
                        <Button variant="contained" color="primary" onClick={this.handleConfig} size='medium' startIcon={<AddIcon />}>
                            Municípios
                        </Button>
                    </Box>
                    <Divider />

                    <Box p={3} >
                        <Grid container spacing={2} >
                            {this.state.municipiosPermitidos.map((municipioObj, index) => (
                                <Grid item key={index}>
                                    <Chip
                                        size="medium"
                                        label={municipioObj.municipio.nome}
                                        onDelete={municipioEmpresa.id === municipioObj.municipio.id ? null : e => this.handleDelete(municipioObj)}>
                                    </Chip>
                                </Grid>

                            ))}
                        </Grid>
                    </Box>

                </Paper>

                <Dialog maxWidth="md" fullWidth open={this.state.open} onClose={this.handleConfig}>
                    <DialogTitle style={{ justifyContent: "center", display: "flex" }} id="max-width-dialog-title">Cadastro de municipios para entrega</DialogTitle>

                    <DialogContent>
                        <Box p={3}>
                            <Grid item xs={12}>

                                <Autocomplete
                                    options={municipios}
                                    getOptionLabel={(option) => option.nomeComEstado}
                                    getOptionSelected={(option, value) => {
                                        return option.id === value.id;
                                    }}
                                    value={municipio}
                                    selectOnFocus
                                    onChange={(e, value) => this.handleSelectMunicipio(e, value)}
                                    renderInput={(params) =>
                                        <TextField
                                            onChange={e => this.handleChangeMunicipio(e)}
                                            disabled
                                            label="Municipio" variant="outlined"
                                            fullWidth required
                                            {...params}>
                                        </TextField>
                                    }
                                />

                            </Grid>
                        </Box>

                    </DialogContent>

                    <DialogActions>

                        <Button
                            color="primary"
                            variant="text"
                            onClick={this.handleClose}
                            startIcon={<CancelIcon />}>
                            Cancelar
                            </Button>

                        <Button
                            aria-expanded={true}
                            variant="contained"
                            color="secondary"
                            onClick={this.handleSave}
                            startIcon={<SaveIcon />}>
                            Salvar
                                </Button>

                    </DialogActions>
                </Dialog>


            </React.Fragment >
        )
    }
}

export default permissaoMunicipio

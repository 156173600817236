import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Divider, TextField, Box, Paper, IconButton, Checkbox, Typography } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit'
import Utils from '../../utils';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Chip from '@material-ui/core/Chip';
import utils from "../../utils";

const styles = theme => ({

    table: {
        minWidth: 600,
    },

});

class CupomDesconto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cdInput: {
                cupom: '',
                tipo: '',
                valor: 0.00,
                inicio: null,
                termino: null,
                pedidoValorMinimo: 0.00,
                ignorarProdutosPromocao: true,
                ativo: true,
                tipoCupom: 'DELIVERY',
            },
            cupomList: [],
            cupomValido: true,
            open: false,
            rowsPerPage: 10,
            count: 0,
            page: 0,
            permitirRetirarLoja: false
        };

        this.handleClose = this.handleClose.bind(this)
        this.validaCupom = this.validaCupom.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.buscarCupomValido = this.buscarCupomValido.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
        this.novoCupomDesconto = this.novoCupomDesconto.bind(this)
        this.handleClickOpenEdit = this.handleClickOpenEdit.bind(this)
    }

    novoCupomDesconto() {
        let { cdInput } = this.state;

        cdInput = {
            cupom: '',
            tipo: 'PERCENTUAL_DA_CONTA',
            valor: 0.00,
            inicio: new Date(),
            termino: new Date(),
            pedidoValorMinimo: 0.00,
            ignorarProdutosPromocao: true,
            ativo: true,
            tipoCupom: 'DELIVERY',
        }

        this.setState({ cdInput });
    }

    componentDidMount = () => {
        const service = new AuthService();

        service
            .get(`/cupomDesconto/cupomValido?valido=${this.state.cupomValido}`)
            .then(resp => {
                this.setState({ cupomList: resp })
            })
            .catch(err => {
                this.props.enqueueSnackbar("Erro ao listar os cupons de desconto. ", {
                    variant: "error"
                });

                console.log(err)
            })
    }

    handleChange = (event) => {
        const { cdInput } = this.state;

        let name = event.target.name;
        let value = event.target.value;

        if (event.target.type === 'checkbox') {
            value = event.target.checked;
        }

        if (event.target.type === 'text') {
            value = event.target.value.toUpperCase();
        }

        cdInput[name] = value;

        if (event.target.name === 'tipo') {

            if (event.target.value === 'ENTREGA_GRATIS') {
                cdInput['valor'] = 0
                cdInput['ignorarProdutosPromocao'] = false
            } else if (event.target.value === 'VALOR_FIXO') {
                cdInput['ignorarProdutosPromocao'] = false
            }
        }

        this.setState({ cdInput });
    }

    handleSaveAll = () => {
        let { cupomList, cdInput } = this.state;

        console.log('SALVAR', cdInput)

        let service = new AuthService();
        service
            .post("/cupomDesconto", cdInput)
            .then(resp => {
                let pos = cupomList.map(function (t) { return t.id; }).indexOf(cdInput.id);

                console.log('SAVE POSIÇÃO', resp)

                if (pos >= 0) {
                    cupomList.splice(pos, 1)
                }

                cupomList.push(resp)
                cupomList.sort((cupomA, cupomB) => cupomA.id - cupomB.id)

                this.setState({ cupomList })
                this.setState({ open: false })

                this.props.enqueueSnackbar("Cupom criado com sucesso.", {
                    variant: "success"
                });

            })
            .catch(err => {
                console.log(err);

            })
    }

    handleClickOpenNew = () => {
        this.setState({ open: true })
        this.novoCupomDesconto()
    };

    handleClickOpenEdit = (cupom) => {
        this.setState({ open: true })

        let cupom2 = JSON.parse(JSON.stringify(cupom))

        this.setState({ cdInput: cupom2 })
    };

    handleDelete = (cupom, index) => {
        let { cupomList } = this.state;

        let service = new AuthService();

        service
            .delete(`/cupomDesconto/${cupom.id}`)
            .then(resp => {
                this.setState({ page: 0 })
                cupomList.splice(index, 1)

                cupomList.sort((cupomA, cupomB) => cupomA.id - cupomB.id)

                this.setState({ cupomList })

                this.props.enqueueSnackbar("Cupom excluído com sucesso", {
                    variant: "success"
                });
            })
            .catch(err => {
                this.props.enqueueSnackbar("Não foi possível excluir o cupom desconto.", {
                    variant: "error"
                });
            })
    }

    validaCupom = () => {
        let { cupomList, cdInput } = this.state;
        let salvar = true;

        console.log("cdInput: ", cdInput)

        if (cdInput.inicio == null) {
            this.props.enqueueSnackbar("Informe a data de inicio.", {
                variant: "error"
            });

            salvar = false;
        }

        if (salvar) {
            this.handleSaveAll()
        }


        if (cdInput.termino == null) {
            this.props.enqueueSnackbar("Informe a data de termino.", {
                variant: "error"
            });

            salvar = false;
        }

        if (cdInput.cupom.match(/\W/) !== null) {

            //console.log("CARACTERES ESPECIAIS");
            //console.log(cdInput.cupom.match(/\W/));

            this.props.enqueueSnackbar("Não é permitido caracteres especiais e espaços no cupom.", {
                variant: "error"
            });

            salvar = false;
        }

        if (cdInput.tipo === "PERCENTUAL_DA_CONTA") {
            if (cdInput.valor > 100) {
                this.props.enqueueSnackbar("Não é permitido criar cupom com desconto maior que 100%", {
                    variant: "error"
                });

                salvar = false;
            }
        }

        if (cdInput.cupom === null || cdInput.cupom === "") {
            this.props.enqueueSnackbar("Não é permitido criar cupom de desconto sem nome", {
                variant: "error"
            });
            salvar = false;
        }

        cupomList.map(cd => {

            if ((cd.cupom === cdInput.cupom) && (cd.id !== cdInput.id)) {
                this.props.enqueueSnackbar("Cupom de desconto já cadastrado.", {
                    variant: "error"
                });
                salvar = false;
            }

        })

        if (salvar) {
            this.handleSaveAll();
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };

    handleClose() {
        this.setState({ open: false })
    };

    buscarCupomValido() {
        let { cupomValido } = this.state;
        let service = new AuthService();

        service
            .get(`/cupomDesconto/cupomValido?valido=${!cupomValido}`)
            .then(retorno => {
                this.setState({ cupomList: retorno })
                this.setState({ cupomValido: !cupomValido })
            })
            .catch(err => {
                console.error(err);
                console.log(`Falha ao buscar os cupons validos. Detalhes: ${err.message}`, { variant: 'error' });
            });
    }

    onCopy = () => {
        this.props.enqueueSnackbar('Cupom copiado.', { variant: 'success' })
    };

    render() {
        const { classes } = this.props;
        const { cdInput, cupomList, page, rowsPerPage, open, cupomValido } = this.state;

        let cupomDescontoTipoList = [
            { label: "PERCENTUAL DA CONTA", value: "PERCENTUAL_DA_CONTA" },
            { label: "ENTREGA GRÁTIS", value: "ENTREGA_GRATIS" },
            { label: 'VALOR FIXO', value: "VALOR_FIXO" }]

        if (cdInput.tipoCupom === 'LOCAL') {
            cupomDescontoTipoList = [
                { label: "PERCENTUAL DA CONTA", value: "PERCENTUAL_DA_CONTA" },
                { label: 'VALOR FIXO', value: "VALOR_FIXO" }
            ]
        }

        return (
            <React.Fragment>
                <BarraTitulo
                    title="Cupom de desconto"
                    subTitle="Cadastre os cupons de desconto" />

                <Paper>
                    <Box p={3} >
                        <Button color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            size="medium"
                            onClick={this.handleClickOpenNew}>
                            Novo cupom
                        </Button>
                        <Box p={2} display="inline">
                            <FormControl component="fieldset" >
                                <RadioGroup row name="filtroValido" value={cupomValido} onChange={() => this.buscarCupomValido()}>
                                    <FormControlLabel value={true} control={<Radio />} label="Válidos" />
                                    <FormControlLabel value={false} control={<Radio />} label="Todos" />
                                </RadioGroup>
                            </FormControl>
                        </Box>

                    </Box>

                    <Divider />

                    <Box p={2}>
                        <TableContainer>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">
                                            Cupom
                                        </TableCell>

                                        <TableCell align="center">
                                            Tipo / Valor
                                        </TableCell>

                                        <TableCell align="center">
                                            Início
                                        </TableCell>

                                        <TableCell align="center">
                                            Término
                                        </TableCell>

                                        <TableCell align="center">
                                            Pedido mínimo
                                        </TableCell>

                                        <TableCell align="center">
                                            Ignorar promoção
                                        </TableCell>

                                        <TableCell align="center">
                                            Válido para
                                        </TableCell>

                                    </TableRow>
                                </TableHead>


                                <TableBody>
                                    {cupomList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cupom, index) => {
                                        return (
                                            <TableRow key={cupom.id}>
                                                <TableCell align="left">
                                                    <CopyToClipboard
                                                        fullWidth
                                                        text={cupom.cupom}
                                                        variant="outlined"
                                                        onCopy={this.onCopy}>
                                                        <Button /* startIcon={<CopyIcon/>} */ color="primary">{cupom.cupom}</Button>
                                                    </CopyToClipboard>
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Chip
                                                        variant="outlined"
                                                        size="medium"
                                                        label={cupom.tipo === 'VALOR_FIXO' ? Utils.formatNumber(cupom.valor) : cupom.tipo === 'PERCENTUAL_DA_CONTA' ? `% ${utils.formataMonetario(cupom.valor)}` : cupom.tipo === 'ENTREGA_GRATIS' ? 'Entrega grátis' : ''}
                                                        color="primary"
                                                    />
                                                </TableCell>

                                                <TableCell align="center">
                                                    {Utils.formatarDataHora(cupom.inicio)}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {Utils.formatarDataHora(cupom.termino)}
                                                </TableCell>

                                                <TableCell align="center">
                                                    {Utils.formatNumber(cupom.pedidoValorMinimo)}
                                                </TableCell>

                                                <TableCell align="center">
                                                    <Checkbox
                                                        disabled
                                                        checked={cupom.ignorarProdutosPromocao} />
                                                </TableCell>

                                                <TableCell align="center">
                                                    {cupom.tipoCupom === 'DELIVERY' ? 'Delivery' : 'Local'}
                                                </TableCell>

                                                <TableCell scope="medium" align="right" xs={2}>

                                                    <Button
                                                        onClick={event => { this.handleClickOpenEdit(cupom) }}
                                                        startIcon={<EditIcon />}>
                                                        Editar
                                                    </Button>

                                                    <IconButton
                                                        variant="text"
                                                        onClick={event => { this.handleDelete(cupom, index) }} >
                                                        <DeleteIcon />
                                                    </IconButton>

                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>

                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={cupomList.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                        </TableContainer>
                    </Box>
                </Paper>

                <Dialog open={open} onClose={this.handleClose}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth>

                    <DialogContent>
                        <DialogTitle style={{ textAlign: 'center' }} id="max-width-dialog-title">Cupom de desconto</DialogTitle>
                        <Box p={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12} >
                                    <TextField
                                        onChange={this.handleChange}
                                        value={cdInput.cupom}
                                        variant="outlined"
                                        label="Cupom"
                                        placeholder="Ex: QRPEDIR10"
                                        name="cupom"
                                        required
                                        fullWidth
                                        style={{ marginBottom: '5px' }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <FormControl required component="fieldset">
                                        <FormLabel component="legend" style={{ color: 'black' }}>Válido para:</FormLabel>
                                        <RadioGroup row name="tipoCupom" value={cdInput.tipoCupom} onChange={this.handleChange}>
                                            <FormControlLabel
                                                style={{ marginTop: '-10px' }}
                                                value="DELIVERY"
                                                control={<Radio />}
                                                label="Delivery" />

                                            <FormControlLabel
                                                style={{ marginTop: '-10px' }}
                                                value="LOCAL"
                                                control={<Radio />}
                                                label="Local" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="outlined-select-currency"
                                        select
                                        name="tipo"
                                        label="Tipo"
                                        required
                                        onChange={this.handleChange}
                                        value={cdInput.tipo}
                                        variant="outlined"
                                    >
                                        {cupomDescontoTipoList.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        value={cdInput.valor}
                                        disabled={cdInput.tipo === 'ENTREGA_GRATIS'}
                                        name="valor"
                                        fullWidth
                                        label={cdInput.tipo === 'PERCENTUAL_DA_CONTA' ? '% Desconto' : cdInput.tipo === 'VALOR_FIXO' ? Utils.optionsCurrency().currencySymbol + 'Desconto' : 'Entrega grátis'}
                                    />
                                </Grid>

                                <Grid item xs={6} md={3}>
                                    <TextField
                                        type="number"
                                        variant="outlined"
                                        onChange={this.handleChange}
                                        value={cdInput.pedidoValorMinimo}
                                        name="pedidoValorMinimo"
                                        fullWidth
                                        label={Utils.optionsCurrency().currencySymbol + 'Ped. Mínimo'}
                                    />
                                </Grid>

                                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                                    <Grid item xs={12} md={6}>
                                        <KeyboardDateTimePicker
                                            margin="normal"
                                            ampm={false}
                                            inputVariant="outlined"
                                            name="inicio"
                                            label="Inicar em"
                                            format="yyyy/MM/dd HH:mm"
                                            onError={console.log}
                                            value={cdInput.inicio || ''}
                                            onChange={date => {
                                                this.handleChange({ "target": { "name": "inicio", "value": date } })
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            inputVariant="outlined"
                                            fullWidth
                                            margin="normal"
                                            name="termino"
                                            label="Termina em"
                                            format="yyyy/MM/dd HH:mm"
                                            value={cdInput.termino || ''}
                                            onError={console.log}
                                            onChange={date => {
                                                this.handleChange({ "target": { "name": "termino", "value": date } })
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }} />
                                    </Grid>
                                </MuiPickersUtilsProvider>

                                <Grid item xs={12} md={12}  >
                                    <FormControlLabel
                                        control={<Checkbox
                                            name="ignorarProdutosPromocao"
                                            checked={cdInput.ignorarProdutosPromocao}
                                            onChange={this.handleChange} />}
                                        label="Ignorar produtos em promoção"
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            color="primary"
                            variant="text"
                            onClick={this.handleClose}
                            startIcon={<CancelIcon />}>
                            Cancelar
                        </Button>

                        <Button
                            aria-expanded={true}
                            variant="contained"
                            color="secondary"
                            onClick={this.validaCupom}
                            startIcon={<SaveIcon />}>
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        );
    }
}


export default withSnackbar(withStyles(styles)(CupomDesconto));

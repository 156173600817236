import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Container } from '@material-ui/core';
import MenuPrincipalLogado from "../../components/Menu/MenuPrincipalLogado";

const useStyles = makeStyles((theme) => ({
    toolbar: theme.mixins.toolbar,
  
    content: {
      flexGrow: 1,
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(4),
  
      [theme.breakpoints.up('md')]: {
            marginLeft: "70px",
      },
    },

  }));
  

const MainLayout = props => {
    const classes = useStyles();
    const { children } = props;

    return (
        <div>   
            <MenuPrincipalLogado/> 
            <main className={classes.content}>
                <div className={classes.toolbar}>
                    <Container maxWidth="lg">
                        {children}
                    </Container>
                </div>
            </main>
        </div>    
    );
  };
  
  MainLayout.propTypes = {
    children: PropTypes.node
  };
  
  export default MainLayout;
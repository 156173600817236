import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Divider, TextField, Box, Paper, IconButton } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit'
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ForList from './../Skeleton/ForList'
import Utils from '../../utils'

const styles = theme => ({

    table: {
        minWidth: 600,
    },


});

class Delivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taxaInput: {
                kmFinal: 0,
                valor: 0,
            },
            taxas: [],
            open: false,
            rowsPerPage: 10,
            count: 0,
            page: 0,
            skeleton: false
        };

        this.handleClose = this.handleClose.bind(this)
        this.validaTaxas = this.validaTaxas.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this)
    }

    novaTaxa() {

        let { taxaInput } = this.state;
        taxaInput = {
            kmFinal: 0,
            valor: 0,
        }

        this.setState({ taxaInput });
    }


    componentDidMount = () => {
        const service = new AuthService();

        this.setState({skeleton: true})

        service
            .get("/configuracaoTaxa/list")
            .then(resp => {
                this.setState({skeleton: false})
                this.setState({ taxas: resp })
            })
            .catch(err => {
                this.props.enqueueSnackbar("Erro ao salvar a taxa de entrega.", {
                    variant: "error"
                });
            })

    }

    handleChange = (event) => {
        const { taxaInput } = this.state;

        let name = event.target.name;
        let value = event.target.value;

        taxaInput[name] = value;

        this.setState({ taxaInput })
    }

    handleSaveAll = () => {
        let { taxas, taxaInput } = this.state;

        console.log('SALVAR', taxaInput)

        let service = new AuthService();
        service
            .post("/configuracaoTaxa", taxaInput)
            .then(resp => {
                let pos = taxas.map(function (t) { return t.id; }).indexOf(taxaInput.id);
                console.log("pos ", pos)

                if (pos >= 0) {
                    taxas.splice(pos, 1)
                }

                taxas.push(resp)

                this.setState({ taxas })
                this.setState({ open: false })

                this.props.enqueueSnackbar("Taxas salvas com sucesso.", {
                    variant: "success"
                });

            })
            .catch(err => {
                console.log(err);

            })
    }

    handleClickOpenNew = () => {
        this.setState({ open: true })

        this.novaTaxa()

        console.log("abrir", this.state.open)
    };

    handleClickOpenEdit = (taxa) => {
        this.setState({ open: true })

        let taxa2 = JSON.parse(JSON.stringify(taxa))

        this.setState({ taxaInput: taxa2 })

        console.log("abrir", this.state.open, taxa)
    };

    handleDelete = (taxa, index) => {
        let { taxas } = this.state;

        let service = new AuthService();

        service
            .delete(`/configuracaoTaxa/${taxa.id}`)
            .then(resp => {
                this.setState({ page: 0 })
                taxas.splice(index, 1)

                this.setState({ taxas })

                this.props.enqueueSnackbar("Taxa excluída com sucesso", {
                    variant: "success"
                });
            })
            .catch(err => {
                this.props.enqueueSnackbar("Não foi possível excluir o taxa de entrega.", {
                    variant: "error"
                });
            })
    }

    validaTaxas = () => {
        let { taxas, taxaInput } = this.state;
        let salvar = true;

        console.log('VALIDAR', taxas)

        taxas.map(taxa => {
            console.log(taxa.kmFinal == taxaInput.kmFinal, taxa.kmFinal, taxaInput.idkmFinal)

            if ((taxa.kmFinal == taxaInput.kmFinal) && (taxaInput.id !== taxa.id)) {
                this.props.enqueueSnackbar("Taxa já cadastrada.", {
                    variant: "error"
                });
                salvar = false;
            }
        })

        if (salvar) {
            this.handleSaveAll();
        }
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
        this.setState({ page: 0 })
    };

    handleClose() {
        this.setState({ open: false })
    };

    render() {
        const { classes } = this.props;
        const { taxaInput, taxas, page, rowsPerPage, open, skeleton } = this.state;

        return (
            <React.Fragment>
                <BarraTitulo
                    title="Taxas por quilometragem"
                    subTitle="Configure as taxa de entrega conforme a distância" />

                    <Box p={2} >
                        <Button color="primary"
                            variant="contained"
                            startIcon={<AddIcon />}
                            size="medium"
                            onClick={this.handleClickOpenNew}>
                            Nova taxa
                        </Button>
                    </Box>

                <Divider />

                <Box p={3}>
                    <TableContainer>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                        Distância
                                    </TableCell>
                                    <TableCell align="right">
                                        Valor da taxa
                                        </TableCell>
                                    <TableCell align="right">
                                        {/* Opções */}
                                    </TableCell>

                                </TableRow>
                            </TableHead>

                            {skeleton ? (
                                <TableBody>
                                    <TableRow >
                                        <TableCell colSpan={3}>
                                            <ForList rows={rowsPerPage} height={50} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : null}

                            <TableBody>
                                {taxas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((taxa, index) => {
                                    return (
                                        <TableRow key={taxa.id}>
                                            <TableCell align="left">
                                                Até {taxa.kmFinal} km
                                                </TableCell>
                                            <TableCell align="right">
                                                 {Utils.formatNumber(taxa.valor)}
                                            </TableCell>

                                            <TableCell scope="medium" align="right" xs={2}>

                                                <Button
                                                    onClick={event => { this.handleClickOpenEdit(taxa) }}
                                                    startIcon={<EditIcon />}>
                                                    Editar
                                                            </Button>

                                                <IconButton
                                                    variant="text"
                                                    onClick={event => { this.handleDelete(taxa, index) }} >
                                                    <DeleteIcon />
                                                </IconButton>

                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>

                        </Table>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={taxas.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage} />
                    </TableContainer>
                </Box>

                <Dialog open={open} onClose={this.handleClose}
                    aria-labelledby="max-width-dialog-title"
                    fullWidth
                // maxWidth='sm'
                >
                    <DialogTitle id="max-width-dialog-title">Taxa de entrega</DialogTitle>
                    <DialogContent>

                        <Box p={2}>
                            <Grid container spacing={2}  >
                                <Grid item xs={12} md={6} >
                                    <TextField
                                        onChange={this.handleChange}
                                        value={taxaInput.kmFinal}
                                        variant="outlined"
                                        label="Até a distância (em km)"
                                        name="kmFinal"
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}  >
                                    <TextField
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={this.handleChange}
                                        value={taxaInput.valor}
                                        label="Cobrar o valor da taxa"
                                        variant="outlined"
                                        // currencySymbol={optionsCurrency.currencySymbol}
                                        // decimalPlaces={optionsCurrency.decimalPlaces}
                                        // outputFormat="string"
                                        name="valor"
                                        // decimalCharacter="."
                                        // digitGroupSeparator=""
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>

                    <DialogActions>
                        {/* <Grid container spacing={2} alignItems="flex-start"> */}
                        {/* <Grid item xs={12} md={6}> */}
                        <Button
                            color="primary"
                            variant="text"
                            onClick={this.handleClose}
                            startIcon={<CancelIcon />}>
                            Cancelar
                                    </Button>

                        <Button
                            aria-expanded={true}
                            variant="contained"
                            color="secondary"
                            onClick={this.validaTaxas}
                            startIcon={<SaveIcon />}>
                            Salvar
                                        </Button>
                        {/* </Grid> */}
                        {/* </Grid> */}
                    </DialogActions>
                </Dialog>
            </React.Fragment>

        );
    }
}


export default withSnackbar(withStyles(styles)(Delivery));

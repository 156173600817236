import { Box, Button, Checkbox, Link, TextField, Typography, Paper, Grid } from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask';
import AuthService from '../../../AuthService';
import Utils from "../../../utils/index"
import { useSnackbar } from 'notistack';


const TextMaskPhone = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder="(xx) x xxxx-xxxx"
        />
    );
}

export default function DadosPessoa(props) {
    const [errorUsuario, setErrorUsuario] = React.useState({ nome: null, email: null, telefone: null });
    const [permissaoDados, setPermissaoDados] = React.useState(true);
    const { enqueueSnackbar } = useSnackbar();

    function usuarioLogado() {
        let service = new AuthService();
        return service.getProfile()
    }

    async function validaStep() {

        var error = false;
        var erros = { nome: null, email: null, telefone: null };

        var telefoneDigits = Utils.onlyDigits(props.pessoa.telefone);

        if (!props.pessoa.nome) {
            erros.nome = "O nome não pode estar em branco";
            error = true;
        }

        /*if(!!usuarioLogado()){
            error = true; 
            enqueueSnackbar('Não é possível criar uma empresa com o usuário logado. Realize o logoff ou abra está pagina em uma guia anônima', { variant: 'error' });   
        }*/

        if (!props.pessoa.email) {
            erros.email = "O E-mail não pode estar em branco";
            error = true;
        } else {
            if (props.pessoa.email.match(/\@/) == null) {
                erros.email = "E-mail inválido";
                error = true;
            } else if (await validaEmail()) {
                erros.email = "esse E-mail ja foi utilizado!";
                error = true;
            }
        }

        if (!telefoneDigits || telefoneDigits.length > 11 || telefoneDigits.length < 10) {
            erros.telefone = "Precisa ter pelo menos 10 dígitos (10 para telefone fixo e 11 para celular)";
            error = true;
        }

        if(!permissaoDados){
            enqueueSnackbar('É necessário concordar com a Política de Privacidade', { variant: 'error' });
            error = true
        }

        setErrorUsuario(erros);

        if (error) {
            return;
        } else {

            if (permissaoDados && !Utils.isDev) {
                enviaDadosLeads(props.pessoa);
                console.log("ENVIANDO DADOS");
            }

            props.handleChangeStep("+")


        }

    }

    const enviaDadosLeads = (pessoa) => {

        const API_KEY = process.env.REACT_APP_RD_TOKEN

        const conversionData = {
            event_type: 'CONVERSION',
            event_family: 'CDP',
            payload: {
                conversion_identifier: 'iniciado_conversao_cadastro_empresa_qrpedir',
                email: pessoa.email,
                name: pessoa.nome,
                personal_phone: pessoa.telefone,
                tags: ['qrpedir', 'cadastro-empresa-cliente']
            }
        }

        const fetchOptions = {
            method: 'POST',
            body: JSON.stringify(conversionData),
            headers: {
                accept: 'application/json',
                "Content-Type": "application/json"
            }
        };

        fetch(`https://api.rd.services/platform/conversions?api_key=${API_KEY}`, fetchOptions)
            .then(result => {
                console.log("RETORNO ENVIO RD STATION", result)
            }).catch(err => {
                console.error("ERROR ENVIO RD STATION", err)
            })

    }

    const validaEmail = () => {
        return new Promise(resolve => {
            let service = new AuthService()

            service
                .get(`/usuario/validateEmail/${props.pessoa.email}`)
                .then(resp => {
                    if (resp.value === "USUARIO_EXISTENTE") {
                        resolve(true);
                    } else if (resp.value === "USUARIO_INEXISTENTE") {
                        resolve(false);
                        /* enviaDadosLeads(props.pessoa) */
                    }
                }).catch(err => {
                    resolve(false);
                })
        });
    }

    const handleChangePermissao = (event) => {
        setPermissaoDados(event.target.checked);
    }

    return (
        <Paper>
            <Box p={4} display="flex" flexDirection="column"
                justifyContent="space-between" alignItems="center">


                <Grid container spacing={2} direction='row' alignItems="center">
                    <Grid item xs={12}>
                        <Typography align="center" variant="h5" >
                            Conte-nos sobre você
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Nome completo (obrigatório)"
                            error={errorUsuario.nome != null}
                            helperText={errorUsuario.nome}
                            value={props.pessoa.nome}
                            name="nome"
                            variant="outlined"
                            fullWidth
                            onChange={event => props.handleChangePessoa(event)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="E-mail (obrigatório)"
                            error={errorUsuario.email != null}
                            helperText={errorUsuario.email}
                            value={props.pessoa.email}
                            name="email"
                            variant="outlined"
                            fullWidth
                            onChange={event => props.handleChangePessoa(event)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Telefone  (obrigatório)"
                            error={errorUsuario.telefone != null}
                            helperText={errorUsuario.telefone}
                            name="telefone"
                            defaultValue={props.pessoa.telefone}
                            fullWidth
                            onChange={event => props.handleChangePessoa(event)}
                            variant="outlined"
                            type="tel"
                            InputProps={{
                                inputComponent: TextMaskPhone
                            }}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <Checkbox
                            checked={permissaoDados}
                            onChange={e => handleChangePermissao(e)}
                            color="primary"
                        />
                    </Grid>

                    <Grid item xs={10}>
                        <Typography variant='caption' color="textSecondary">
                            Ao informar meus dados, eu concordo com a <a target="_blank" href="https://qrpedir.com/wp-content/uploads/2020/12/Política-de-Privacidade.pdf">Política de Privacidade</a>.
                            </Typography>
                    </Grid>

                    <Grid item xs={6} >
                        <Link style={{ cursor: "pointer" }} onClick={() => { window.open('/login', '_parent') }}
                        >voltar a página inicial</Link>
                    </Grid>

                    <Grid item xs={6} >
                        <Button
                            fullWidth
                            onClick={validaStep}
                            variant="contained"
                            color="primary">
                            Próximo
                           </Button>
                    </Grid>
                </Grid>
            </Box>
        </Paper >
    )
}

import React from "react";
 
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(4),
      right: theme.spacing(10),
    },
  }));
  
  export default function CustomSpeedDialog(props) {

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [hidden] = React.useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
      
    return (
        <SpeedDial
          ariaLabel="Ações"
          className={classes.speedDial}
          hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
        {props.children}
            
        </SpeedDial>
    );
  }
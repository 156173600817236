import React from "react";
import { Button, Box, Paper } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import utils from '../../utils'

export default function ConfiguracaoValorMinimo() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [valorMinimo, setValorMinimo] = React.useState("00");
    const [empresa, setEmpresa] = React.useState(null);
    const optionsCurrency = utils.optionsCurrency()

    const handleChange = (event, value) => {
        console.log(value);
        setValorMinimo(value);
    }

    const handleSave = () => {

        let string = `{ "id": ${empresa.id}, "valorMinimo": ${valorMinimo} }`
        let json = JSON.parse(string)

        service
            .put(`/empresa/changeOnly`, json)
            .then(retorno => {
                let profile = JSON.parse(localStorage.getItem("_profile"));
                profile.empresa = retorno;
                localStorage.setItem("_profile", JSON.stringify(profile));

                setEmpresa(retorno);
                enqueueSnackbar('Pedido mínimo alterado com sucesso.', { variant: 'success' })
            })
            .catch(err => {
                enqueueSnackbar('Erro ao alterar valor do pedido mínimo', { variant: 'error' })
            })
    }

    React.useEffect(() => {
        service.get('/login/empresaLogada')
            .then(empresa => {
                setEmpresa(empresa)
                setValorMinimo(empresa.valorMinimo);
                console.log(valorMinimo);

            }).catch(err => {
                console.error(err)
            })
    }, []);

    return (
        <React.Fragment>
            <BarraTitulo
                title="Valor mínimo para entrega" />

            <Paper>
                <Box p={3}>
                    <Grid container spacing={2} alignItems="center">

                        <Grid item xs={12} sm={10}>
                            <CurrencyTextField
                                label="Valor mínimo do pedido (para entrega em casa)"
                                variant="outlined"
                                currencySymbol={optionsCurrency.currencySymbol}
                                decimalPlaces={optionsCurrency.decimalPlaces}
                                outputFormat="string"
                                name="valorMinimo"
                                size="small"
                                fullWidth
                                value={valorMinimo}
                                decimalCharacter=","
                                digitGroupSeparator=""
                                onChange={(event, value) => handleChange(event, value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={2}>
                            <Button fullWidth
                                color="secondary"
                                onClick={handleSave}
                                variant="contained">Salvar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
        </React.Fragment>
    );
}
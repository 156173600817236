import React from "react";
import {Paper } from "@material-ui/core";
import BarraTitulo from "./BarraTitulo";
import Template from "./Template";

 

export default function TemplateBase(props) {
    
    return (
        <Template {...props} >
            <BarraTitulo {...props} />

            <Paper>
                {props.children}
            </Paper>

        </Template>
    )
}
import React, { Component } from "react";
import { Card, Box, Dialog, ListItem, ListItemText, DialogContent, Chip, DialogActions, Button, IconButton, Menu, MenuItem, Fab, List, LinearProgress, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { Typography, Grid, Divider } from "@material-ui/core";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Paper from '@material-ui/core/Paper';
import Util from '../../../utils'
import AuthService from "../../../AuthService";
import PrintIcon from '@material-ui/icons/Print';
import Utils from '../../../utils';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Detalhes from './compDetalhesPedido';
import { withSnackbar } from "notistack";
import ForBoards from "../../Skeleton/ForBoards"

class CompPedidosMesa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            pedidos: [],
            identificadoresAll: [],
            identificadoresList: [],
            identificador: null,
            pedidosPendentes: [],
            buscando: false,
            buscandoIdentificacores: false,
            radio: "TODOS"
        }
        this.handleMarcarComoImpresso = this.props.handleMarcarComoImpresso;
        this.handlePrintOut = this.props.handlePrintOut;
        this.handleCancel = this.props.handleCancel;
        this.buscaPedidosPendentes = this.buscaPedidosPendentes.bind()
    }

    handleCloseAll = (pedidos) => {
        let pedidosBody = [];
        if (!window.confirm('Deseja fechar o(s) pedido(s) ?'))
            return;
        let service = new AuthService();
        pedidos.map(pedido => (
            pedido.status !== "PAGO" ? pedidosBody.push({ id: pedido.id }) : null
        ))
        service
            .post(`/pedido/marcarComoPago`, pedidosBody)
            .then(retorno => {

                let { pedidosPendentes } = this.state;

                let pedidosPendentesSobraram = []

                pedidosPendentes.map(identificadorID => {

                    const pedidoEncontrado = retorno.find(pedidoPago => {
                        return pedidoPago.identificador.id === identificadorID
                    })

                    if (!pedidoEncontrado) {
                        pedidosPendentesSobraram.push(identificadorID)
                    }

                })

                this.setState({ pedidosPendentes: pedidosPendentesSobraram }, () => this.handleChangeListagem());
                this.setState({ pedidos: retorno });

            }).catch(err => {

                console.error(err)
            })
    }

    componentDidMount() {

        this.buscaPedidosPendentes();
        this.carregarIdentificadores();
    }

    buscaPedidosPendentes = (callback = null) => {

        this.setState({ buscandoIdentificacores: true })

        let service = new AuthService();

        service
            .get("/pedido/abertos")
            .then(resp => {
                let { pedidosPendentes } = this.state;

                pedidosPendentes = [];

                resp.map(item => {
                    pedidosPendentes.push(item.identificador.id)
                })

                this.setState({ pedidosPendentes })

                this.setState({ buscandoIdentificacores: false })

                if(callback) callback();
            })
            .catch(err => {
                console.log(err);
                this.setState({ buscandoIdentificacores: false })
            })
    }

    buscaPedidos(identificador) {
        let service = new AuthService();
        this.setState({ buscando: true, identificador: identificador })

        service
            .get(`/pedido/identificador/${identificador.id}/PENDENTE`)
            .then(resp => {
                this.setState({
                    pedidos: resp,
                    identificador: identificador
                })
                this.setState({ buscando: false })
                this.handleToggle();
            })
            .catch(err => {
                this.setState({ buscando: false })
                console.log("ERRO ao buscar pedidos");

            })
    }

    validaPedido(identificador) {
        let { pedidosPendentes } = this.state;

        pedidosPendentes.forEach(pedido => {
            if (pedido.identificador.id == identificador.id) {
                console.log("TRUE");
                // let value = true
            }
            console.log("FALSE");
        })

    }

    carregarIdentificadores() {

        this.setState({ buscandoIdentificacores: true })
        let service = new AuthService();
        service
            .get("/identificador/list")
            .then(retorno => {
                this.setState({ identificadoresAll: retorno, identificadoresList: retorno });
                this.setState({ buscandoIdentificacores: false })
            })
            .catch(err => {
                this.setState({ buscandoIdentificacores: false })
                console.error(err)
            });
    }

    handleToggle = () => {
        this.setState({
            open: !this.state.open
        });
    };

    handleSnackBar(text, err, variant) {
        this.props.enqueueSnackbar(`${text}, ${err}`, { variant: variant })
    }

    handleRadioPesquisa = (e) => {
        this.setState({ radio: e.target.value }, () => this.handleChangeListagem())
    }

    handleChangeListagem = () => {
        const { radio, identificadoresAll, pedidosPendentes } = this.state;

        if (radio == "TODOS") {
            this.setState({ identificadoresList: identificadoresAll })
        } else {
            var identificadores = [];
            identificadoresAll.map(identificador => {
                if (pedidosPendentes.includes(identificador.id)) identificadores.push(identificador);
            })
            this.setState({ identificadoresList: identificadores })
        }
    }

    handleVerificarPedidos = () => {
        this.buscaPedidosPendentes(this.handleChangeListagem);
        
    }

    render() {
        let { identificadoresList, pedidosPendentes, buscandoIdentificacores } = this.state;
        let { buscando } = this.state;

        return (
            <Card style={{ width: "100%" }}>
                <Box pt={2} pl={5} pb={2} pr={5} display="flex" justifyContent="space-between">
                    <RadioGroup row value={this.state.radio} onChange={e => this.handleRadioPesquisa(e)}>
                        <FormControlLabel value="TODOS" control={<Radio />} label="Todas as mesas" />
                        <FormControlLabel value="PENDENTES" control={<Radio />} label="Com pedidos pendentes" />
                    </RadioGroup>
                    <Button variant="contained" color="secondary" onClick={this.handleVerificarPedidos}>
                        Buscar Pedidos
                    </Button>
                </Box>

                {buscandoIdentificacores ? <ForBoards /> : null}


                <ListaPedidos
                    handleChangeListagem={this.handleChangeListagem}
                    handleCloseAll={this.handleCloseAll}
                    pedidosPendentes={pedidosPendentes}
                    handlePrintOut={this.handlePrintOut}
                    handleMarcarComoImpresso={this.handleMarcarComoImpresso}
                    handleCancel={this.handleCancel}
                    open={this.state.open}
                    handleToggle={this.handleToggle}
                    pedidos={this.state.pedidos}
                    identificador={this.state.identificador} />

                <Box p={4}>
                    <GridList cellHeight='auto' cols={0} spacing={1}
                        style={{
                            width: '100%',
                            height: "80%",
                            maxHeight: "100%"
                        }}>
                        {identificadoresList.map(identificador => (

                            <GridListTile key={identificador.id} cols={1}>

                                <Box display="flex" justifyContent="center" m={1} p={1} >
                                    <Button onClick={() => { if (pedidosPendentes.includes(identificador.id)) this.buscaPedidos(identificador) }} disabled={!identificador.ativo} >
                                        <Paper style={{ opacity: (identificador.ativo ? '1' : '0.3'), height: "165px", backgroundColor: (pedidosPendentes.includes(identificador.id) ? "#F2C26A" : "") }}>
                                            <Box p={4} textAlign="center" minWidth="150px" >
                                                <Typography color="textSecondary" style={{ fontFamily: "Bebas Neue", fontWeight: "600" }}>
                                                    {Util.labelTipoIdentificador(identificador.tipo)}
                                                </Typography>
                                                <Typography variant="h4" component="h2" >
                                                    {identificador.numero}
                                                    {this.state.identificador ?
                                                        this.state.identificador.id === identificador.id ? (
                                                            buscando ? (
                                                                <LinearProgress color="primary" />
                                                            ) : null) : null : null}
                                                </Typography>

                                            </Box>

                                        </Paper>
                                    </Button>
                                </Box>

                            </GridListTile>

                        ))}
                    </GridList>
                </Box>
            </Card >
        )
    }
}

class ListaPedidos extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            openInfo: false,
            pedido: null
        }

        this.handleMarcarComoImpresso = this.props.handleMarcarComoImpresso;
        this.handlePrintOut = this.props.handlePrintOut;
        this.handleCancel = this.props.handleCancel;

    }

    calculaTotal(pedidos) {
        let total = 0;
        pedidos.map(pedido => (
            total += Utils.getTotalPedido(pedido)
        ))

        return total;
    }

    handleClose(pedido) {
        if (!window.confirm('Deseja fechar o pedido ?'))
            return;
        let service = new AuthService();
        let pedidosBody = [{ id: pedido.id }]
        service
            .post(`/pedido/marcarComoPago`, pedidosBody)
            .then(retorno => {

                pedido.status = retorno[0].status
                //atualiza state
                let { pedidos } = this.state
                this.setState({ pedidos })


                let index = this.props.pedidosPendentes.indexOf(pedido.identificador.id)
                this.props.pedidosPendentes.splice(index, 1)
                console.log(this.props.pedidosPendentes)
                this.props.handleChangeListagem();
            }).catch(err => {

                console.error(err)
            })
    }

    handleOpenInfo = (event, pedido = null) => {
        let { openInfo } = this.state;
        if(pedido === "backdropClick") {
            pedido = null
        }
        console.log("pedido", pedido)
        this.setState({ openInfo: !openInfo, pedido })
    }

    render() {
        let { open, pedidos, identificador } = this.props;
        const { pedido } = this.state

        return (
            <Dialog
                open={open}
                onClose={this.props.handleToggle}
                style={{ height: "105vh", width: "100%" }}
                maxWidth="xl"
            >
                <ListItem>
                    <ListItemText
                        primary={
                            <React.Fragment>
                                <Typography style={{ fontSize: "30px", fontWeight: "700", fontFamily: "Bebas Neue", marginLeft: "1vw", }} color="textPrimary">
                                    Pedidos da {identificador ? (
                                        Util.labelTipoIdentificador(identificador.tipo)
                                        + " " + identificador.numero
                                    ) : " ... "}
                                </Typography>
                            </React.Fragment>
                        }
                    />

                </ListItem>
                <Divider style={{ margin: "10px", padding: "-10px" }} />
                <DialogContent style={{ width: "100%" }}>
                    <Grid>
                        {
                            pedidos.map(pedido => (
                                <List key={pedido.id}>
                                    <ListItem >
                                        <ListItemText className="ListCenter">

                                            <Chip
                                                style={{ marginRight: "10px" }}
                                                spacing={2}
                                                label={pedido.statusImpressao === "IMPRESSO" ? " Impresso " : " pendente "}
                                                icon={<PrintIcon fontSize="small" />}
                                                title={Utils.formatarDataHora(pedido.dataHoraImpressao, pedido.empresa.timezone)}
                                                size="small"
                                                color={pedido.statusImpressao === "IMPRESSO" ? "default" : "primary"}>
                                            </Chip>

                                            <Chip
                                                style={{ marginRight: "40px" }}
                                                label={pedido.statusIntegracao === "INTEGRADO" ? "Integrado" : "Integração"}
                                                icon={pedido.statusIntegracao === "INTEGRADO" ? <DoneAllIcon fontSize="small" /> : <HourglassEmptyIcon fontSize="small" />}
                                                title={pedido.statusIntegracao === "INTEGRADO" ? "Pedido integrado" : "Aguardando integração"}
                                                size="small"
                                                color={pedido.statusIntegracao === "INTEGRADO" ? "default" : "primary"}>
                                            </Chip>

                                            {!pedido.ativo ? (
                                                <>
                                                    <Chip label="cancelado"
                                                        style={{ marginRight: "40px" }}
                                                        title={pedido.motivoCancelamento}
                                                        size="small">
                                                    </Chip>
                                                </>
                                            ) : null}

                                        </ListItemText>
                                        <ListItemText
                                            style={{ marginRight: "40px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textPrimary">{pedido.identificador.numero}</Typography>
                                                </React.Fragment>
                                            }
                                            secondary="MESA"
                                        />

                                        <ListItemText
                                            style={{ marginRight: "40px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textPrimary">{pedido.identificadorSecundario}</Typography>
                                                </React.Fragment>
                                            }
                                            secondary="Comanda"
                                        />

                                        <ListItemText
                                            style={{ marginRight: "40px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textPrimary">{pedido.nomeCliente}</Typography>
                                                </React.Fragment>
                                            }
                                            secondary="NOME"
                                        />

                                        <ListItemText
                                            style={{ marginRight: "40px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textPrimary">{Utils.formatarDataHora(pedido.dataPedido, pedido.empresa.timezone)}</Typography>
                                                </React.Fragment>
                                            }
                                            secondary="DATA/HORA"
                                        />

                                        <ListItemText
                                            style={{ marginRight: "40px" }}
                                            primary={
                                                <React.Fragment>
                                                    <Typography color="textPrimary"> {Utils.formatNumber(Utils.getTotalPedido(pedido), 'decimal')}</Typography>
                                                </React.Fragment>
                                            }
                                            secondary="VALOR"
                                        />

                                        {pedido.status === "PAGO" ?
                                            <Fab variant="extended" size="small" disabled  >
                                                &nbsp; Conta fechada &nbsp;
                                        </Fab>
                                            :
                                            <Fab variant="extended" size="small" color="secondary" onClick={() => this.handleClose(pedido)}>
                                                &nbsp;&nbsp; Fechar conta &nbsp;
                                        </Fab>
                                        }

                                        <MenuDetalhe itensPed={[
                                            { nome: "Cancelar pedido", ativo: (pedido.ativo), call: () => this.handleCancel(pedido) },
                                            { nome: "Liberar nova impressão", ativo: (pedido.statusImpressao !== 'PENDENTE'), call: () => this.handlePrintOut(pedido) },
                                            { nome: "Marcar como impresso", ativo: (pedido.statusImpressao === 'PENDENTE'), call: () => this.handleMarcarComoImpresso(pedido) },
                                            { nome: "Detalhes do Pedido", ativo: (pedido.ativo), call: (event) => this.handleOpenInfo(event, pedido) }
                                        ]}>
                                        </MenuDetalhe>

                                    </ListItem>
                                    <Divider />
                                </List>
                            ))}
                    </Grid>
                </DialogContent>

                <Divider />
                <DialogActions>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                        <strong style={{ marginLeft: "10px" }}>
                            Total: {Utils.formatNumber(this.calculaTotal(pedidos))}
                        </strong>

                        <Fab
                            variant="extended"
                            size="small"
                            color="secondary"
                            style={{ margin: "10px" }}
                            onClick={() => this.props.handleCloseAll(pedidos)}
                        >
                            &nbsp;&nbsp; Fechar todas as contas &nbsp;
                    </Fab>
                    </div>
                </DialogActions>
                {pedido ?
                    <Detalhes key={pedido?.id} pedido={pedido} open={this.state.openInfo} handleOpenInfo={this.handleOpenInfo} /> 
                : null}
            </Dialog>
        )
    }
}

function MenuDetalhe(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const elementos = props.itens ? props.itens.filter(elem => elem.visible !== false) : []
    return (
        <React.Fragment>
            <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreHorizIcon fontSize="small" />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {elementos ? (
                    elementos.map((item, i) => (

                        <MenuItem key={i} onClick={() => {
                            handleClose();
                            item.call()
                        }
                        }>
                            {item.nome}
                        </MenuItem>

                    ))) : null
                }
                {props.itensPed ? (props.itensPed.map((item, i) => (
                    (item.ativo ? (

                        <MenuItem key={i}
                            onClick={() => {
                                handleClose();
                                item.call()
                            }
                            }>
                            {item.nome}
                        </MenuItem>

                    ) : null)

                )
                )) : null}

            </Menu>
        </React.Fragment>
    )

}

export default withSnackbar(CompPedidosMesa);
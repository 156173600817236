import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  }
}));


export default function ForBoards(props) {

  const classes = useStyles();
  let totalIntens = props.totalIntens || 10
  const _array = Array.from(Array(totalIntens).keys())

  return (
    <div>
      <Grid container className={classes.root} spacing={2} >
        <Grid item xs={12}>
          <Grid container justify="center" spacing={1}>
            {_array.map(elem => (
              <Grid key={elem} item>
                <Skeleton variant="text" width='60vw' height='50vh' />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
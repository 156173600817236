import React from "react";
import AuthService from "../../AuthService";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Box, makeStyles, Button, TextField } from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {useHistory } from "react-router-dom";
import TimerIcon from '@material-ui/icons/Timer';
import SportsMotorsportsIcon from '@material-ui/icons/SportsMotorsports';
import {ReactComponent as QrCodeIcon} from '../../imagens/qr_code.svg';
import { Fragment } from "react";

const RADIUS_DOT = 5;

const useStyles = makeStyles((theme) => ({
  paperBackground: {
      backgroundColor: '#ffffff',
      borderRadius: '50px',
      maxHeight: '30px',
  },
  active: {
    fill: '#66bb6a'
  },
  inactive: {
    fill: '#666666'
  },
  circle: {
    borderRadius: RADIUS_DOT,
    height: RADIUS_DOT * 2,
    width: RADIUS_DOT * 2,
    padding: 0,
    display: 'block'
  },

}));

const ColoredCircle = ({ styles }) => {
  const classes = useStyles();

  return styles ? (
    <Fragment>
      <span className={classes.circle} style={styles} />
    </Fragment>
  ) : null;
};

export default function StatusLoja() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [statusLoja, setStatusLoja] = React.useState(localStorage.getItem("_statusAberturaDelivery"));
  const [configuracaoAberturaDelivery, setConfiguracaoAberturaDelivery] = React.useState(localStorage.getItem("_configuracaoAberturaDelivery"));
  const [configuracaoAberturaComanda, setConfiguracaoAberturaComanda] = React.useState(localStorage.getItem("_configuracaoAberturaComanda"));
  const [statusLojaComanda, setStatusLojaComanda] = React.useState(localStorage.getItem("_statusAberturaComanda"));
  const history = useHistory();
  const classes = useStyles()

  const setStatusRetorno = (retorno) => {
    setStatusLoja(retorno[0].statusAbertura)
    setConfiguracaoAberturaDelivery(retorno[0].configuracaoAbertura)
    localStorage.setItem("_statusAberturaDelivery", retorno[0].statusAbertura)
    localStorage.setItem("_configuracaoAbertura", retorno[0].configuracaoAbertura)
    setStatusLojaComanda(retorno[1].statusAbertura)
    setConfiguracaoAberturaComanda(retorno[1].configuracaoAbertura)
    localStorage.setItem("_statusAberturaComanda", retorno[1].statusAbertura)
    localStorage.setItem("_configuracaoAbertura", retorno[1].configuracaoAbertura)
  }

  React.useEffect(() => {
    let service = new AuthService()
    service.get("/empresa/statusLoja?tipo=AMBOS")
      .then(resp => {
        setStatusRetorno(resp)
      }).catch(err => {
        console.error(err)
      })
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (value,tipo) => {

    let service = new AuthService()

    let status = {
      configuracaoAbertura: value,
      tipo: tipo
    }
    service.post("/empresa/configuracaoAbertura", status)
      .then(resp => {
        setStatusRetorno(resp)
      }).catch(err => {
        console.error(err)
      })

  };

  return (
    <Box paddingRight={1}>

      <Box>
        <Button onClick={handleClick} variant='contained' className={classes.paperBackground}>
          <ColoredCircle styles={ statusLoja === "ABERTO" ? {backgroundColor: '#66bb6a'} : {backgroundColor: '#C30010'}}/>
          <SportsMotorsportsIcon />
          <Divider orientation="vertical" flexItem style={{marginLeft: '5px', marginRight: '5px'}}/>
          <ColoredCircle styles={ statusLojaComanda === "ABERTO" ? {backgroundColor: '#66bb6a'} : {backgroundColor: '#C30010'}} />
          <QrCodeIcon />
        </Button>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Box style={{display: "flex", flexDirection: "column", flexWrap: "nowrap"}}>
          <Box paddingLeft={1} style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center"}}>
              <ColoredCircle styles={ statusLoja === "ABERTO" ? {backgroundColor: '#66bb6a'} : {backgroundColor: '#C30010'}}/>
              <ListItemText style={{marginLeft: '5px'}} primary={statusLoja === "ABERTO" ? "Delivery aberto" : "Delivery Fechado"}/>
          </Box>
          <Box padding={1} style={{display: "flex", flexDirection: "row", flexWrap: "nowrap", alignItems: "center"}}>
              <ColoredCircle styles={ statusLojaComanda === "ABERTO" ? {backgroundColor: '#66bb6a'} : {backgroundColor: '#C30010'}} />
              <ListItemText style={{marginLeft: '5px'}} primary={statusLojaComanda === "ABERTO" ? 'Comanda / Mesa aberto' : 'Comanda / Mesa Fechado'}/>
          </Box>
        </Box>

        <Divider />

        <Box style={{display: "grid", flexDirection: "column", flexWrap: "nowrap", marginBottom: '10px'}}>
          <SelectOneMenu handleItemClick={handleItemClick} selected={configuracaoAberturaDelivery} label={"Delivery"} tipo={"DELIVERY"}/>
          <SelectOneMenu handleItemClick={handleItemClick} selected={configuracaoAberturaComanda} label={"Comanda/Mesa"} tipo={"COMANDA"}/>
        </Box>

        <Divider />

        <MenuItem onClick={() => { history.push("/HorarioAbertura"); }} >
          <ListItemIcon>
            <AccessTimeIcon />
          </ListItemIcon>
          <ListItemText primary="Configurar horários" />
        </MenuItem>

        <MenuItem onClick={() => { history.push("/Delivery#mostrarTempoEntrega"); }}>
          <ListItemIcon>
            <TimerIcon />
          </ListItemIcon>
            <ListItemText primary="Configurar tempo de entrega" />
        </MenuItem>

      </Menu>
    </Box>
  );
}

export function SelectOneMenu({handleItemClick, selected, label, tipo}){
  
  return  (
    <>
      <TextField
          style={{marginTop: '15px', marginLeft: '10px', marginRight: '10px'}}
          variant="filled"
          select
          name="statusImpressao"
          id="statusImpressao"
          value={selected}
          size="small"
          label={label}>
          <MenuItem key={"SEMPRE_FECHADO"} value={"SEMPRE_FECHADO"} onClick={event => handleItemClick("SEMPRE_FECHADO",tipo)}>
            <ListItemText primary="Loja sempre fechada" />
          </MenuItem>
          <MenuItem key={"SEMPRE_ABERTO"} value={"SEMPRE_ABERTO"} onClick={event => handleItemClick("SEMPRE_ABERTO",tipo)}>
            <ListItemText primary="Loja sempre aberta" />
          </MenuItem>
          <MenuItem key={"AUTOMATICO"} value={"AUTOMATICO"} onClick={event => handleItemClick("AUTOMATICO",tipo)}>
            <ListItemText primary="Automático" secondary="Abrir com o monitor de impressão" />
          </MenuItem>
      </TextField>
    </>
  )
}
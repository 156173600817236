import React from 'react'
import { Box, Grid } from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star';
import { CardGenerico } from '../../Dashboard/components/CardQuick';
import GridItem from '../../Dashboard/components/Grid/GridItem';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';

export default function Infos(props) {

    return (
        <Box p={1} m={4}>
            <Grid container spacing={4}>
                <GridItem xs={12} sm={6} md={3}>
                    <CardGenerico
                        color="success"
                        url={`/avaliacaoEmpresa/informacoes/TOTAL_AVALIACOES`}
                        icon={<CheckOutlinedIcon />}
                        maskTypeValue="int"
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={3}>
                    <CardGenerico
                        color="yellow"
                        url={`/avaliacaoEmpresa/informacoes/MEDIA`}
                        icon={<StarIcon />}
                        maskTypeValue="double"
                    />
                </GridItem>
            </Grid>
        </Box>
    )
}
import React, { Component } from "react";
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import { Grid, Paper } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Util from '../../utils'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TituloPrincipal from '../Generico/TituloPrincipal'
import Template from '../Generico/Template'
import RefreshIcon from '@material-ui/icons/Refresh';


import {
  MenuItem
} from "@material-ui/core";

const styles = theme => ({
  card: {
    marginTop: "20px"
  },
  title: {
    marginTop: "10%",
    fontSize: "240%",
    color: "#253257",
    fontFamily: "Bebas Neue",
    textTransform: "uppercase",
    fontWeight: "700"
  },
  subTitle: {
    marginBottom: "30px"
  },
  gridList: {
    width: 500,
    height: 450,
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
  },
});

class Usuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: {
        nome: '',
        token: '',
        username: '',
        email: '',
        password: '',
        ativo: true,
        grupoPermissao: { id: '' },
        empresa: { id: '' }
      },
      gruposPermissao: [],
      empresas: []
    };

    this.carregarUsuario = this.carregarUsuario.bind(this)
    this.carregarSelects = this.carregarSelects.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.salvarUsuario = this.salvarUsuario.bind(this)
    this.textarea = React.createRef();
  }

  handleChange = event => {
    let { usuario } = this.state
    console.log('name', event.target.name)
    console.log('value', event.target.value)

    Util.setValue(usuario, event.target.name, event.target.value)
    console.log('usuario', usuario)

    this.setState({ usuario })
  };

  componentDidMount() {

    let service = new AuthService();

    const { id } = this.props.match.params
    let idUsuario = id || service.getProfile().id

    console.log("ID", id)
    this.carregarUsuario(idUsuario)
    this.carregarSelects()
  }

  carregarUsuario(idUsuario) {
    let service = new AuthService();

    if (!service.getProfile().admin) {
      this.props.enqueueSnackbar(`Seu usuário não tem permissão para editar outros usuários`, { variant: 'error' })
      return
    }

    service
      .get(`/usuario/${idUsuario}`)
      .then(retorno => {
        retorno.passwordOriginal = retorno.password
        retorno.empresaOriginal = retorno.empresa
        this.setState({ usuario: retorno });
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar(`Falha ao buscar usuario com ID ${idUsuario}`, { variant: 'error' })
      });

  }

  copyCodeToClipboard = () => {
    const el = this.textArea;
    el.current.select()
    document.execCommand("copy");
  }

  carregarSelects() {
    let service = new AuthService();
    service
      .get("/grupoPermissao/list")
      .then(retorno => {
        this.setState({ gruposPermissao: retorno });
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao buscar grupo de permissões', { variant: 'error' })
      });

    service
      .get("/empresa/list")
      .then(retorno => {
        this.setState({ empresas: retorno });
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao buscar empresas', { variant: 'error' })
      });
  }

  salvarUsuario() {

    let { usuario } = this.state

    if (!usuario.id) {
      this.props.enqueueSnackbar('Nenhum usuário para ser salvo', { variant: 'error' })
      return;
    }

    if (!usuario.empresa) {
      this.props.enqueueSnackbar('Nenhuma empresa selecionada', { variant: 'error' })
      return;
    }

    /* SE PASS MUDOU, PASSA O MD5 NELE */
    if (usuario.passwordOriginal !== usuario.password) {
      const md5 = require('js-md5');
      usuario.password = md5(usuario.password)
    }

    let mudouEmpresaLogada = (usuario.empresaOriginal.id !== usuario.empresa.id)

    let service = new AuthService();
    console.log('USUARIO A SER SALVO', usuario)
    service
      .post("/usuario", usuario)
      .then(retorno => {
        this.setState({ usuario: retorno });
        this.props.enqueueSnackbar('Usuário salvo com sucesso', { variant: 'success' })

        if (mudouEmpresaLogada) {
          let profile = JSON.parse(localStorage.getItem("_profile"))
          service
            .get(`/empresa/${usuario.empresa.id}`)
            .then(retorno => {
              profile.empresa = retorno;
              localStorage.setItem("_profile", JSON.stringify(profile))
              window.location.reload();
            })
        }
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao buscar grupo de permissões', { variant: 'error' })
      });

  }

  handleAtualizarToken = () => {
    const { usuario } = this.state
    let service = new AuthService()

    service.post("/usuario/atualizarToken", usuario)
    .then(resp => {
      usuario.token = resp.token
      this.setState({ usuario })
    })
    .catch(err => {
      console.error(err)
    })
  }

  render() {
    
    const { usuario, gruposPermissao, empresas } = this.state

    return (
      <Template documentTitle="Dados do usuário">
        <TituloPrincipal title="Dados do usuário" subTitle="Alterar os dados do usuário" />

        <Paper>
        <React.Fragment>

              <Box p={4} >

                <form noValidate autoComplete="off">

                  <Grid container spacing={3} alignItems="center">

                    <Grid item xs={12}>
                      <TextField id="nome" label="Nome do usuário" variant="outlined"
                        name="nome" fullWidth value={usuario.nome} onChange={this.handleChange} />
                    </Grid>

                    <Grid item xs={12} >
                      <TextField id="username"
                        InputProps={{
                          readOnly: true,
                        }}
                        label="Login" variant="outlined" name="username" fullWidth value={usuario.username}
                        helperText="Não é possível alterar o login" />
                    </Grid>

                    <Grid item xs={12} >
                      <TextField id="email" label="E-mail" type="email" onChange={this.handleChange}
                        variant="outlined" name="email" fullWidth value={usuario.email} />
                    </Grid>

                    <Grid item xs={12} >
                      <TextField id="password" label="Senha" type="password" onChange={this.handleChange}
                        variant="outlined" name="password" fullWidth value={usuario.password} />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField id="grupoPermissao" label="Grupo de permissão" variant="outlined" name="grupoPermissao.id"
                        fullWidth value={(usuario.grupoPermissao ? usuario.grupoPermissao.id : '')}
                        select
                        onChange={this.handleChange} >
                        <MenuItem value={'-1'}>
                          nenhum grupo
                      </MenuItem>
                        {gruposPermissao.map(grupo => (
                          <MenuItem key={grupo.id} value={grupo.id}>
                            {grupo.descricao}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      {usuario.admin ? (
                        <Autocomplete
                          options={empresas}
                          getOptionLabel={(option) => option ? option.nome : ""}
                          getOptionSelected={(option, value) => {
                            return option.id === value.id;
                          }}
                          selectOnFocus
                          value={usuario.empresa}
                          disabled={!usuario.admin}
                          onChange={(event, newValue) => {
                            console.log('SELECIONADO', newValue)
                            usuario.empresa = newValue ? newValue : null
                            this.setState({ usuario })
                          }}

                          renderInput={(params) =>
                            <TextField
                              disabled
                              label="Empresa logada" variant="outlined"
                              fullWidth required
                              {...params}>
                            </TextField>
                          }
                        />
                      ) : null}

                    </Grid>

                    <Grid item xs={12}>
                      <TextField label="Token do Usuario" variant="outlined"
                        fullWidth
                        ref={(textarea) => this.textArea = textarea}
                        value={usuario.token}
                        disabled={!usuario.admin}
                      >

                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display="flex" flexDirection="row">
                      <Button variant="contained" color="primary" onClick={this.salvarUsuario}>
                        Salvar
                    </Button>
                    <Box pl={2}>
                      <Button
                        variant='contained'
                        color="secondary"
                        startIcon={<RefreshIcon />}
                        onClick={() => this.handleAtualizarToken()}
                      >Atualizar token</Button>
                    </Box>
                    </Box>
                    </Grid>

                  </Grid>

                </form>


              </Box>

        </React.Fragment>
        </Paper>
      </Template>
    );
  }

}

export default withStyles(styles)(withSnackbar(Usuario));

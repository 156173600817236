import React from "react";
import Template from "../Generico/Template"
import { withSnackbar } from "notistack";
import TituloPrincipal from "../Generico/TituloPrincipal"
import { Box } from "@material-ui/core";
import ConfigurarTema from "./configurarTema"
import OutrasConfiguracoes from "./outrasConfiguracoes"
 

function Configuracoes()  {
     
        return (
            <Template documentTitle="Configurações gerais">
                <TituloPrincipal title="Configurações gerais" subTitle="Configure as opções globais do sistema"/>

                <Box mt={5} >  
                    <ConfigurarTema/>
                </Box>

                <Box mt={5} >  
                    <OutrasConfiguracoes/>
                </Box>
                
            </Template>
        );
}


export default withSnackbar(Configuracoes);

import React, { Component } from "react";
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import { Typography, Grid, Divider, TablePagination, Checkbox } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CustomSpeedDialog from "../../components/CustomSpeedDialog";
import Util from '../../utils'
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Mesa from "../../imagens/iconeMesa.svg";
import Sequencial from "../../imagens/iconeSequencial.svg";
import Comanda from "../../imagens/iconeComanda.svg";
import DeliveryIcon from "../../imagens/iconeDelivery.svg";
import ForBoards from "../Skeleton/ForBoards"
import TemplateBase from '../Generico/TemplateBase';

import {
  Menu,
  MenuItem
} from "@material-ui/core";
import ConfigQr from "./components/compConfigQr";

const styles = theme => ({
  card: {
    marginTop: "20px"
  },
  title: {
    marginTop: "10%",
    fontSize: "240%",
    color: "#253257",
    fontFamily: "Bebas Neue",
    textTransform: "uppercase",
    fontWeight: "700"
  },
  subTitle: {
    marginBottom: "30px"
  },
  gridList: {
    width: 500,
    height: 450,
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'absolute',
  },
});

class Identificadores extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identificadores: [],
      openDialogGerarMesas: false,
      filtrarPor: 'TODOS',
      ativo: true,
      buscando: false,
      open: false,
      identificadorEdicao: null,
      page: 0,
      rowsPerPage: 20,
      count: 0,
      tipo: ''
    };

    this.handleOpenDialogGerarMesas = this.handleOpenDialogGerarMesas.bind(this)
    this.handleCloseDialogGerarMesas = this.handleCloseDialogGerarMesas.bind(this)
    this.inativarIdentificador = this.inativarIdentificador.bind(this)
    this.ativarIdentificador = this.ativarIdentificador.bind(this)
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.setIdentificadorEdicao = this.setIdentificadorEdicao.bind(this)
    this.alterarIdentificador = this.alterarIdentificador.bind(this)
  }

  componentDidMount() {
    this.handleFiltrarPor()
  }

  handleOpenDialogGerarMesas() {
    this.setState({ openDialogGerarMesas: true })
  }

  handleCloseDialogGerarMesas() {
    this.setState({ openDialogGerarMesas: false })
  }

  setIdentificadorEdicao(identificadorEdicao) {
    this.setState({ identificadorEdicao })
  }

  inativarIdentificador(identificador) {
    let service = new AuthService();
    service
      .delete(`/identificador/${identificador.id}`)
      .then(retorno => {
        let { identificadores } = this.state

        //let identificadoresFiltrados = identificadores.filter(elem => {
        //  return elem.id != retorno.id;
        //})
        identificador.ativo = retorno.ativo
        this.setState({ identificadores: identificadores });

        this.props.enqueueSnackbar(`${retorno.tipo} ${retorno.numero} inativado com sucesso`, { variant: 'success' })
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao inativar identificador', { variant: 'error' })
      });
  }

  ativarIdentificador(identificador) {
    let service = new AuthService();
    identificador.ativo = true
    service
      .put(`/identificador`, identificador)
      .then(retorno => {
        let { identificadores } = this.state
        identificador.ativo = retorno.ativo
        //let identificadoresFiltrados = identificadores.filter(elem => {
        //  return elem.id != retorno.id;
        //})
        this.setState({ identificadores: identificadores });

        this.props.enqueueSnackbar(`${retorno.tipo} ${retorno.numero} ativado com sucesso`, { variant: 'success' })
      })
      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao inativar identificador', { variant: 'error' })
      });
  }

  handleChangeSwitch = event => {
    let value = event.target.checked
    this.setState({ ativo: value }, () => {
      this.handleFiltrarPor();
    });

    //let filtrarPor = this.state.filtrarPor
    //this.filtrarIdentificadores(filtrarPor, value)
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  alterarIdentificador = (identificador, onSuccess) => {

    
    //identificador.uuid = identificador.uuid.toLowerCase();
    let service = new AuthService();

    service
      .put(`/identificador`, identificador)
      .then(resp => {
        let { identificadores } = this.state
        identificador.tipo = resp.tipo
        this.setState({ identificadores: identificadores });

        this.props.enqueueSnackbar('Identificador alterado com sucesso.', { variant: 'success' })
        if (onSuccess) {
          onSuccess()
        }
      }).catch(err => {

        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao alterar identificador.', { variant: 'error' })
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao alterar identificador. Erro: ' + elem.errorMessage, { variant: 'error' })
          });
        }


      })
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.handleFiltrarPor()
    })
  };


  handleChangeRowsPerPage = (event) => {
    let { rowsPerPage } = this.state
    rowsPerPage = event.target.value

    this.setState({ rowsPerPage }, () => {
      this.handleFiltrarPor()
    })
  };

  handleChangeTipo = (event) => {
    let tipo = event.target.value

    this.setState({ filtrarPor: tipo }, () => {
      this.handleFiltrarPor()
    })
  };

  handleFiltrarPor = () => {
    let service = new AuthService();
    let { ativo, rowsPerPage, page, filtrarPor } = this.state;

    let filtro = ""
    if (filtrarPor !== "TODOS") {
      filtro = `findBy=tipo:${filtrarPor}&`
    }
    this.setState({ buscando: true })

    service
      .request(`/identificador/list?${filtro}first=${rowsPerPage * page}&max=${rowsPerPage}&ativo=${ativo}`)
      .then(response => {

        let count = response.headers.get('totalCount')
        this.setState({ count: parseInt(count) })

        response.json()

          .then(retorno => {
            this.setState({ identificadores: retorno, buscando: false })
          }

          )
      })

      .catch(err => {
        console.error(err)
        this.props.enqueueSnackbar('Falha ao buscar mesas', { variant: 'error' })
        this.setState({ buscando: false })
      });
  }

  render() {
    document.title = 'QR Pedir - Identificadores'
    const { classes } = this.props;
    const { identificadores, ativo, buscando, open, identificadorEdicao, rowsPerPage, page, filtrarPor } = this.state;

    return (
      <TemplateBase title="Mesas e QRCodes" subTitle="Aqui você pode ver suas mesas, gerar novas ou inativá-las">

        <ConfigQr
          open={open}
          handleToggle={this.handleToggle} />

        <Box p={4} >
          <Grid container spacing={1} alignItems="center">

            <Grid item sm={12}>

              <FormControl component="fieldset" >
                <RadioGroup row onChange={(e) => this.handleChangeTipo(e)} name="tipo" value={filtrarPor} >
                  <FormControlLabel value="TODOS" control={<Radio />} label="Todos" />
                  <FormControlLabel value="MESA" control={<Radio />} label="Mesas" />
                  <FormControlLabel value="COMANDA" control={<Radio />} label="Comandas" />
                  <FormControlLabel value="SEQUENCIAL" control={<Radio />} label="Sequencial" />
                  <FormControlLabel value="DELIVERY" control={<Radio />} label="Delivery" />
                </RadioGroup>
              </FormControl>

              <FormControlLabel
                control={
                  <Switch checked={ativo} value="ativo" onChange={e => this.handleChangeSwitch(e)} />
                }
                label={ativo ? 'Ativos' : 'Inativos'}
              />

            </Grid>

          </Grid>
        </Box>

        <Divider className={classes.divider} />

        <CustomSpeedDialog >
          <SpeedDialAction
            key="GerarMesas"
            icon={<PlayArrowIcon />}
            tooltipTitle={'Gerar'}
            tooltipOpen
            onClick={this.handleOpenDialogGerarMesas}
          />

          <SpeedDialAction
            key="BaixarQRCode"
            icon={<GetAppIcon />}
            tooltipTitle={'Baixar'}
            tooltipOpen
            onClick={this.handleToggle}
          />

        </CustomSpeedDialog>

        <Grid container justify="flex-end">

          <FormDialogGerarMesas
            handleRefresh={this.handleFiltrarPor}
            isOpen={this.state.openDialogGerarMesas}
            open={this.handleOpenDialogGerarMesas}
            close={this.handleCloseDialogGerarMesas} />

        </Grid>

        <Box p={4}>

          <GridList cellHeight='auto' className={classes.gridList} cols={0} spacing={1}
            style={{
              width: '100%',
              maxHeight: 320,
              height: "80%",
              maxHeight: "100%",
              overflow: "hidden"
            }}>

            {buscando ? (
              <ForBoards totalIntens={rowsPerPage} width={150} />
            ) : (
              identificadores.map(identificador => (

                <GridListTile key={identificador.id} cols={1}>

                  <Box display="flex" justifyContent="center" m={1} p={0} >
                    <Paper style={{ opacity: (identificador.ativo ? '1' : '0.3') }}>
                      <Box p={4} textAlign="center" minWidth="150px" >
                        <Typography color="textSecondary" style={{ fontFamily: "Bebas Neue", fontWeight: "600" }}>

                          {identificador.tipo === 'MESA' ? <img src={Mesa} /> : null}
                          {identificador.tipo === 'COMANDA' ? <img src={Comanda} /> : null}
                          {identificador.tipo === 'SEQUENCIAL' ? <img src={Sequencial} /> : null}
                          {identificador.tipo === 'DELIVERY' ? <img src={DeliveryIcon} /> : null}

                          <br />

                          {
                            Util.labelTipoIdentificador(identificador.tipo)
                          }
                        </Typography>
                        <Typography variant="h4" component="h2" title={identificador.uuid}>
                          {identificador.numero}
                        </Typography>

                        <MenuDetalhe itens={[
                          { nome: "Inativar", call: () => this.inativarIdentificador(identificador), visible: identificador.ativo },
                          { nome: "Ativar", call: () => this.ativarIdentificador(identificador), visible: !identificador.ativo },
                          { nome: "Editar identificador", call: () => this.setIdentificadorEdicao(identificador), visible: identificador.ativo }
                        ]}>
                        </MenuDetalhe>
                      </Box>
                    </Paper>
                  </Box>

                </GridListTile>

              ))
            )}
          </GridList>

          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={this.state.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage} />
        </Box>

        <FormDialogAlterarTipo
          identificador={identificadorEdicao}
          handleClose={() => this.setIdentificadorEdicao(null)}
          alterarIdentificador={this.alterarIdentificador} />


      </TemplateBase>
    );
  }

}

export default withStyles(styles)(withSnackbar(Identificadores));

function FormDialogAlterarTipo(props) {

  const [identificador, setIdentificador] = React.useState(null);
  const [tipo, setTipo] = React.useState("");
  const [uuid, setUuid] = React.useState("");
  const [viewOnly, setViewOnly] = React.useState(false);

  React.useEffect(() => {
    setIdentificador(props.identificador);
    console.log('ALTEROU PROPS')
    setTipo(props.identificador ? props.identificador.tipo : "")
    setUuid(props.identificador ? props.identificador.uuid : "")
    setViewOnly(props.identificador ? props.identificador.viewOnly : false)
  }, [props.identificador]);


  const handleClose = () => {
    props.handleClose()
  };

  const handleChangeTipo = event => {
    setTipo(event.target.value)
  };

  const handleChangeUUID = event => {
    var uuid = event.target.value;
    if (uuid.match(/\ /)) {
      uuid = uuid.replace(/\ /, '-');
    }
    uuid = uuid.toLowerCase();
    setUuid(uuid);
  };


  const handleSave = () => {
    identificador.tipo = tipo
    identificador.uuid = uuid
    identificador.viewOnly = viewOnly
    console.log("handleSave", identificador)
    props.alterarIdentificador(identificador, () => {
      handleClose()
    })
  };


  return (
    <div>

      <Dialog open={identificador !== null} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Editar identificador</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Editar identificador
      </DialogContentText>
          <TextField
            autoFocus
            select
            id="tipo"
            value={tipo}
            label="Tipo do identificador"
            fullWidth
            name="tipo"
            variant="outlined"
            onChange={handleChangeTipo}
          >
            <MenuItem key={"COMANDA"} value={"COMANDA"}>Comanda</MenuItem>
            <MenuItem key={"MESA"} value={"MESA"}>Mesa</MenuItem>
            <MenuItem key={"SEQUENCIAL"} value={"SEQUENCIAL"}>Sequencial</MenuItem>
            <MenuItem key={"DELIVERY"} value={"DELIVERY"}>Delivery</MenuItem>
          </TextField>

          {tipo === "DELIVERY" ? (
            <React.Fragment>
              <TextField
                id="uuid"
                value={uuid}
                label="Link de acesso"
                fullWidth
                name="uuid"
                onChange={handleChangeUUID}
                margin="normal"
                variant="outlined"
                required
              />

              <TextField
                value={`${process.env.REACT_APP_APP_QRPEDIR_URL}checkout/${uuid}`}
                label="Link para compartilhar (copie e compartilhe com seus clientes)"
                fullWidth
                name="link"
                margin="normal"
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />


            </React.Fragment>
          ) : null}

        <Box display="inline"> 
          <FormControlLabel
            control={
              <Checkbox
                checked={viewOnly}
                onChange={()=>(setViewOnly(!viewOnly))}
                name="Somente visualização"
                color="secondary"
              />
            }
            label="Somente visualização"
          />
        </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
      </Button>
          <Button onClick={handleSave} variant="contained" color="primary">
            Salvar
      </Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}

function FormDialogGerarMesas(props) {

  const [numInicial, setNumInicial] = React.useState("");
  const [numFinal, setNumFinal] = React.useState("");
  const [tipo, setTipo] = React.useState("");
  const [viewOnly, setViewOnly] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    props.open()
  };

  const handleClose = () => {
    props.close()
  };

  const handleGerar = () => {
    let service = new AuthService();
    service
      .post(`/identificador/gerar/${numInicial}/${numFinal}/${tipo}/${viewOnly}`)
      .then(retorno => {
        console.log("RETORNO", retorno)
        enqueueSnackbar(`Foram gerados ${retorno.length} identificadores`, { variant: 'success' })
        if (props.handleRefresh) {
          props.handleRefresh()
          handleClose()
        }
      })
      .catch(err => {
        console.error(err)
        if (err.response === undefined) {
          console.log(err);
          enqueueSnackbar('Falha ao gerar identificadores', { variant: 'error' })
        } else {
          err.response.json().then(elem => {
            console.log(elem);
            enqueueSnackbar(`Falha ao gerar identificadores - ${elem.errorMessage}`, { variant: 'error' })
          });
        }

      });
  };

  return (
     <Dialog open={props.isOpen} onClose={handleClose} maxWidth="sm" aria-labelledby="form-dialog-title" style={{ height: "70vh", minHeight: "490px" }}>
        <DialogTitle id="form-dialog-title" style={{ fontFamily: "Bebas Neue", textTransform: "uppercase" }}>Gerar mesas ou comandas</DialogTitle>
        <DialogContent>

          <DialogContentText>
            Informe o número inicial, final e tipo (MESA ou COMANDA)
          </DialogContentText>

          <Grid container spacing={2}>
            <Grid item xs={6} >
              <TextField
                autoFocus
                margin="dense"
                id="numInicial"
                label="Número inicial da mesa. Ex: 1"
                type="number"
                required
                value={numInicial}
                onChange={(e) => setNumInicial(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={6} >
              <TextField
                margin="dense"
                id="numFinal"
                required
                label="Número final da mesa. Ex: 20"
                type="number"
                onChange={(e) => setNumFinal(e.target.value)}
                value={numFinal}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} >
              <TextField
                id="tipo"
                fullWidth
                select
                value={tipo}
                name="tipo"
                label="Tipo"
                margin="normal"
                variant="outlined"
                onChange={(e) => setTipo(e.target.value)}
              >
                <MenuItem value="MESA">Tipo MESA</MenuItem>
                <MenuItem value="COMANDA">Tipo COMANDA</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewOnly}
                    onChange={()=>(setViewOnly(!viewOnly))}
                    name="Somente visualização"
                    color="secondary"
                  />
                }
                label="Somente visualização"
              />
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleGerar} variant="contained" color="primary">
            Gerar
          </Button>
        </DialogActions>
      </Dialog>
  );
}


function MenuDetalhe(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const elementos = props.itens ? props.itens.filter(elem => elem.visible !== false) : []
  return (
    <React.Fragment>
      <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {elementos.map((item, i) => (

          <MenuItem key={i} onClick={() => {
            handleClose();
            item.call()
          }
          }>
            {item.nome}
          </MenuItem>

        )
        )}


      </Menu>
    </React.Fragment>
  )

}

import React, { Component } from 'react'
import { Paper, Box, Grid, TextField, Button } from '@material-ui/core'
import BarraTitulo from '../Generico/BarraTitulo'
import AuthService from '../../AuthService'
import { withSnackbar } from 'notistack'

export class MensagemDelivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            empresa: {}
        }
    }

    componentDidMount() {
        this.getEmpresa();
    }

    getEmpresa = () => {
        let service = new AuthService();
        let id = JSON.parse(localStorage.getItem("_profile")).empresa.id;
        service
            .get(`/empresa/${id}`)
            .then(empresa => {
                this.setState({ empresa: empresa })
            })
            .catch(err => {
                console.log("falha ao buscar empresa: " + err);
            })
    }

    handleChange = (e) => {
        let { empresa } = this.state;
        empresa.descricaoDelivery = e.target.value;
        this.setState({ empresa })
    }

    handleSave = () => {
        let { empresa } = this.state;

        let service = new AuthService();

        let jsonObj = {
            id: empresa.id,
            descricaoDelivery: empresa.descricaoDelivery.replace("script>", "")
        }

        service
            .put("/empresa/changeOnly", jsonObj)
            .then(resp => {
                this.props.enqueueSnackbar('Mensagem salva com sucesso', { variant: 'success' })
            })
            .catch(err => {
                this.props.enqueueSnackbar(`Erro ao salvar a mensagem: ${err}`, { variant: 'error' })
            })
    }

    render() {
        let { empresa } = this.state;

        return (
            <React.Fragment>
                <BarraTitulo
                    title="Mensagem do app" />

                <Paper>
                    <Box p={3}>
                        <Grid container spacing={2} alignItems="center">

                            <Grid item xs={12} sm={10}>
                                <TextField
                                    variant="outlined"
                                    label="Mensagem"
                                    placeholder="Mensagem mostrada para pedidos do delivery na tela principal do cardápio"
                                    onChange={e => this.handleChange(e, empresa)}
                                    value={empresa.descricaoDelivery || ''}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                                <Button fullWidth
                                    color="secondary"
                                    onClick={this.handleSave}
                                    variant="contained">Salvar</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </React.Fragment>
        )
    }
}

export default withSnackbar(MensagemDelivery);

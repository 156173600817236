import React from "react";
import { Button, Box, Paper, Typography } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';
import Radio from '@material-ui/core/Radio';
import CheckIcon from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import VisibilityIcon from '@material-ui/icons/Visibility';

const primaryColors = [
    '#d32f2f', '#c2185b', '#303f9f',
    '#0288d1', '#2e7d32', '#424242',
    '#9e9e9e', '#ef9a40'
]

const secondaryColors = [
    '#404040', '#ff5252', '#ff4081',
    '#0d47a1', '#388e3c',
    '#f57c00', '#757575'
]

const styles = (theme) => ({
    radio: {
        padding: 0,
    },
    radioIcon: {
        width: 48,
        height: 48,
    },
    radioIconSelected: {
        width: 48,
        height: 48,
        border: '1px solid white',
        color: theme.palette.common.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

function ConfigurarTema(props) {
    const { classes } = props;
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [empresa, setEmpresa] = React.useState(null);
    const [identificadores, setIdentificadores] = React.useState(null);

    const [values, setValues] = React.useState({
        primary: '',
        secondary: '',
        background: ''
    });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };


    const handleSalvar = () => {
        console.log("Salvar tema", values)
        if (!values.primary || !values.secondary /* || !values.background */) {
            enqueueSnackbar("É necessário configurar as 2 cores", { variant: 'error' })
            return;
        }
        let empresaToChange = { id: empresa.id, tema: JSON.stringify(values) }
        service.put('/empresa/changeOnly', empresaToChange)
            .then(empresaRetorno => {
                setEmpresa(empresaRetorno)
                enqueueSnackbar("Tema atualizado com sucesso", { variant: 'success' })
            }).catch(err => {
                console.error(err)
                enqueueSnackbar("Falha ao salvar tema", { variant: 'error' })
            })
    };

    const handleConfigurarTemaPadrao = () => {

        let empresaToChange = { id: empresa.id, tema: null }
        service.put('/empresa/changeOnly', empresaToChange)
            .then(empresaRetorno => {
                setEmpresa(empresaRetorno)
                setValues({
                    primary: '',
                    secondary: '',
                })
                enqueueSnackbar("Tema padrão configurado", { variant: 'success' })
            }).catch(err => {
                console.error(err)
                enqueueSnackbar("Falha ao mudar tema para padrão", { variant: 'error' })
            })
    };

    const abrirLinkEmpresa = () => {
        if (!identificadores) {
            enqueueSnackbar("Não foi encontrado um link para sua loja. Abra pelo QRCODE", { variant: 'warning' })
            return;
        }
        const identificado = identificadores[0]
        window.open(`http://${identificado.uuid}.qrpedir.com`);
    }

    React.useEffect(() => {
        service.get('/login/empresaLogada')
            .then(empresa => {
                setEmpresa(empresa)
                let temaStr = empresa.tema
                if (temaStr) {
                    let tema = JSON.parse(temaStr)
                    setValues(tema);
                }
            }).catch(err => {
                console.error(err)
            })

        service.get('/identificador/tipo/DELIVERY')
            .then(resp => {
                setIdentificadores(resp);
            }).catch(err => {
                console.error(err)
            })
    }, []);

    return (
        <React.Fragment>
            <BarraTitulo
                title="Configurar tema" />

            <Paper>
                <Box p={3}>

                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={6} >
                            <Typography display="block" color="primary" variant="h5">Cor primária</Typography>
                            <Box pt={1}>
                                {primaryColors.map((backgroundColor) => {
                                    //const backgroundColor = colors[hue][900];
                                    //console.log("bg", backgroundColor)
                                    return (
                                        <Radio
                                            key={`primary-${backgroundColor}`}
                                            className={classes.radio}
                                            color="default"
                                            name="primary"
                                            checked={values.primary === backgroundColor}
                                            value={backgroundColor}
                                            onChange={handleChange}
                                            icon={<div className={classes.radioIcon} style={{ backgroundColor }} />}
                                            checkedIcon={
                                                <div className={classes.radioIconSelected} style={{ backgroundColor }}>
                                                    <CheckIcon style={{ fontSize: 30 }} />
                                                </div>
                                            }
                                        />)
                                })}
                            </Box>
                        </Grid>

                        <Grid item xs={6} >

                            <Typography display="block" color="primary" variant="h5">Cor secundária</Typography>
                            <Box pt={1}>
                                {secondaryColors.map((backgroundColor) => {
                                    return (
                                        <Radio
                                            key={`secondary-${backgroundColor}`}
                                            className={classes.radio}
                                            color="default"
                                            name="secondary"
                                            checked={values.secondary === backgroundColor}
                                            value={backgroundColor}
                                            onChange={handleChange}
                                            icon={<div className={classes.radioIcon} style={{ backgroundColor }} />}
                                            checkedIcon={
                                                <div className={classes.radioIconSelected} style={{ backgroundColor }}>
                                                    <CheckIcon style={{ fontSize: 30 }} />
                                                </div>
                                            }
                                        />)
                                })}
                            </Box>
                        </Grid>

                        {/*
                    <Grid item xs={4} >

                    <Typography display="block" color="primary" variant="h5">Fundo</Typography>
                     <Box pt={1}>
                    {backgroundColors.map((backgroundColor) => {
                        return (
                        <Radio
                            key={`background-${backgroundColor}`}
                            className={classes.radio}
                            color="default"
                            name="background"
                            checked={values.background === backgroundColor}
                            value={backgroundColor}
                            onChange={handleChange}
                            icon={<div className={classes.radioIcon} style={{ backgroundColor }} />}
                            checkedIcon={
                                <div  className={classes.radioIconSelected}  style={{ backgroundColor }}>
                                <CheckIcon style={{ fontSize: 30 }} />
                                </div>
                            }
                        /> )
                    })}
                    </Box> 
                    </Grid>
                    */}

                        <Grid item xs={12} >

                            <Grid container spacing={2} >
                                <Grid item>
                                    <Button
                                        color="primary"
                                        onClick={handleSalvar}
                                        variant="contained">Salvar tema</Button>
                                </Grid>

                                <Grid item>
                                    <Button startIcon={
                                        <SettingsBackupRestoreIcon />
                                    } onClick={handleConfigurarTemaPadrao} >Restaurar tema padrão</Button>
                                </Grid>

                                <Grid item>
                                    <Button startIcon={<VisibilityIcon />} onClick={abrirLinkEmpresa} >Ver como ficou</Button>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>



                </Box>
            </Paper>
        </React.Fragment>
    );
}

export default withStyles(styles)(ConfigurarTema);
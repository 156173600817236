import { Box, Button, Container, Typography } from '@material-ui/core'
import React from 'react'
import Fundo from "../../imagens/fundo-qrpedir-min.png"
import Logo from "../../imagens/LogoVersao2Azul.svg";

export default function TempoExpiradoTeste(props) {
    const { tipo } = props.location.state;

    const styles = {
        fundo: {
            height: "100vh",
            background: `url(${Fundo}) repeat`
        }
    }

    function handleClickLinkPlano() {
        window.open("https://app.galaxpay.com.br/landing1868454118/planoqrpedir", '_blank');
    }

    return (
        <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Container fixed style={{ display: "flex", justifyContent: "center" }}>
                <img alt='logo' src={Logo} style={{ width: "30%" }} />

                <Box style={{ marginLeft: "30px", display: "flex", justifyContent: "space-between", flexDirection: "column", }}>
                    <Typography align={"center"} style={{ fontSize: "150%" }}>
                        Agora que você já experimentou e já sabe o quanto o nosso cardápio digital <br />
                        pode ajudar no dia a dia no seu estabelecimento, que tal fecharmos a nossa <br />
                        parceria e começarmos a trabalhar juntos, rumo ao seu sucesso, <br /> HOJE MESMO?
                    </Typography>

                    <Typography align={"center"} style={{ fontSize: "150%" }} >
                        Faça parte do novo! Transforme o atendimento do seu estabelecimento!
                    </Typography>

                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={() => window.open("https://qrpedir.com/suporte", "_blank")} style={{ width: "170px", margin: "10px auto" }} variant="text">
                            Falar com um consultor
                        </Button>

                        {tipo === "TEMPORARIA" ? (
                            <Button onClick={() => window.location.replace("/TempoExtra")} style={{ width: "170px", margin: "10px auto" }} variant="contained">
                                Mais 3 dias de teste
                            </Button>
                        ) : null}

                        <Button onClick={handleClickLinkPlano} style={{ width: "170px", margin: "10px auto" }} variant="contained" color="primary">
                            Ativar Plano
                        </Button>
                    </Box>
                </Box>

            </Container>
        </Box>
    )
}

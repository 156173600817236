import React from "react";
import { Button, TextField, Box, Paper, FormControlLabel, Switch, FormGroup } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';


import Utils from '../../utils';

import MenuItem from '@material-ui/core/MenuItem';

 function OutrasConfiguracoes(props) {
    
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar(); 
    const [empresa, setEmpresa] = React.useState({
        tipoIdentificadorPrincipal: '',
        tipoIdentificadorSecundario: '',
        tempoValidadeQrcodeMinutos: '',
        numeroSequencial: 0,
        modoConfirmacao: ""
    });

    const getSequencial = (empresa) => {
      service
        .get(`/empresa/getSequencial/${empresa.id}`)
        .then(resp => {
          console.log(resp);
          setEmpresa({ ...empresa, numeroSequencial: resp.sequence })
        }).catch(err => {
          console.error(err)
        })
    }

    const handleChange = (event) => {
        setEmpresa({ ...empresa, [event.target.name]: event.target.value });
    }

    const handleSalvarSequence = () => {
      if(!empresa.numeroSequencial || empresa.numeroSequencial == ""){
        empresa.numeroSequencial = 0
      }
      service
      .get(`/empresa/setSequencial/${empresa.id}/${empresa.numeroSequencial}`)
      .then(resp => {
          setEmpresa({...empresa, numeroSequencial: resp.sequence})
      }).catch(err => {
        console.error(err)
      })
    }

    const handleSalvar = () => { 
        handleSalvarSequence();
        service
        .post("/empresa", empresa)
        .then(retorno => {
            enqueueSnackbar('Configurações atualizas com sucesso', { variant: 'success' })
            let profile = service.getProfile()
            profile.empresa = retorno;
            service.setProfile(profile)
        })
        .catch(err => {
          //em caso de erro é impresso o erro no console.
          if (err.response === undefined) {
            console.log(err);
            enqueueSnackbar('Erro ao salvar configurações', { variant: 'error' })
          } else {
            err.response.json().then(elem => {
              console.log(elem);
                enqueueSnackbar(`Falha ao salvar: ${elem.errorMessage}`, { variant: 'error' })
            });
          }
        });
    }

    const handleCheckTelefoneMesa = (event) => {
      let string = `{ "id": ${empresa.id}, "telefoneMesa": ${event.target.checked} }`
      let json = JSON.parse(string)
  
      service
        .put(`/empresa/changeOnly`, json)
        .then(resp => {
          setEmpresa(resp);
        })
        .catch(err => {
          console.error(err);
        })
    }

    const handleValidarIdentificador = () => {

     if(empresa.tipoIdentificadorPrincipal === empresa.tipoIdentificadorSecundario){
      enqueueSnackbar('Os identificadores principal e secundário não podem ser iguais', { variant: 'error' })
     } else{
      handleSalvar()
     } 
     

    }

    React.useEffect(() => {
        service.get('/login/empresaLogada')
        .then(empresa => {
            setEmpresa(empresa)
            getSequencial(empresa);
        }).catch(err =>{
            console.error(err)
        })
    }, []);

  return (
    <React.Fragment>
        <BarraTitulo   
            title="Configurações qrpedir na mesa" subTitle="Configurações dos pedidos internos e sequênciais"/>

            <Paper>  
                <Box p={3}>

                <Grid container spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="flex-start">

                  <Grid item xs={12} sm={6}>
                    <TextField
                      id="tipoIdentificadorPrincipal"
                      fullWidth
                      select
                      value={empresa.tipoIdentificadorPrincipal}
                      name="tipoIdentificadorPrincipal"
                      label="Tipo do identificador principal"
                      helperText="Como o cliente irá iniciar a leitura pelo app"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                    >
                      <MenuItem value="MESA">Iniciar pela MESA</MenuItem>
                      <MenuItem value="COMANDA">Iniciar pela COMANDA</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <TextField
                      id="tipoIdentificadorSecundario"
                      fullWidth
                      name="tipoIdentificadorSecundario"
                      select
                      value={empresa.tipoIdentificadorSecundario}
                      label="Tipo do identificador secundário"
                      helperText="Solicitado antes de finalizar o pedido no app"
                      margin="normal"
                      variant="outlined"
                      onChange={handleChange}
                    >
                      <MenuItem value="MESA" disabled={empresa.tipoIdentificadorPrincipal === 'MESA'} >Solicitar MESA</MenuItem>
                      <MenuItem value="COMANDA" disabled={empresa.tipoIdentificadorPrincipal === 'COMANDA'}>Solicitar COMANDA</MenuItem>
                      <MenuItem value="NAO_SE_APLICA">Não solicitar nada</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Validade do QRCode (minutos)"}
                      name="tempoValidadeQrcodeMinutos"
                      value={empresa.tempoValidadeQrcodeMinutos}
                      onChange={handleChange}
                      helperText="Após esse tempo, o cliente precisará ler o qrcode novamente"
                      type="number" />
                  </Grid>


                  <Grid item xs={12} sm={6} >
                    <TextField
                      fullWidth
                      variant="outlined"
                      label={"Última notificação (monitor de impressão)"}
                      value={Utils.formatarDataHora(empresa.ultimoPing, empresa.timezone) || ''}
                      disabled={true}
                    />

                  </Grid>


                  <Grid item xs={12} sm={6} >
                    <TextField
                        fullWidth
                      variant="outlined"
                      label={"Sequencial atual"}
                      name="numeroSequencial"
                      value={empresa.numeroSequencial || ""}
                      onChange={handleChange}
                      helperText="Numero a ser utilizado no próximo pedido"
                      type="number" />
 
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <TextField
                      id="modoConfirmacao"
                      fullWidth
                      name="modoConfirmacao"
                      select
                      label="Modo de confirmação do pedido"
                      variant="outlined"
                      value={empresa.modoConfirmacao}
                      onChange={handleChange}
                    >
                      <MenuItem value="CONFIRMAR_AO_IMPRIMIR">Confirmar automáticamente ao imprimir</MenuItem>
                      <MenuItem value="NAO_CONFIRMAR_AO_IMPRIMIR">Não confirmar automáticamente (ao imprimir)</MenuItem>
                    </TextField>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                  <FormGroup>
                    <FormControlLabel
                      name="telefoneMesa"
                      control={
                        <Switch
                          checked={empresa.telefoneMesa ? empresa.telefoneMesa : false}
                          color="primary"
                          onChange={event => handleCheckTelefoneMesa(event)}
                        />
                      }
                      label={"Solicitar telefone para pedidos realizados na mesa?"}
                    />
                  </FormGroup>
                  </Grid>

             
                  <Grid item xs={12} sm={6}>
                    {empresa.chamarGarcom ? (
                    <TextField
                        id="localImpressaoChamado"
                        fullWidth
                        name="localImpressaoChamado"
                        select
                        label="Impressora para os chamados do garçom"
                        variant="outlined"
                        value={empresa.localImpressaoChamado || ""}
                        onChange={handleChange}
                      >
                        <MenuItem value="CAIXA">Caixa</MenuItem>
                        <MenuItem value="COZINHA">Cozinha</MenuItem>
                        <MenuItem value="BAR">Bar</MenuItem>
                    </TextField>
                    ): null}
                  </Grid>
              

                  <Grid item xs={12} >
                        <Button
                            onClick={handleValidarIdentificador}
                            color="primary"
                            variant="contained">Salvar</Button>
                    </Grid>
                  </Grid>
                    
                </Box>
            </Paper>
    </React.Fragment>
  );
}

export default  OutrasConfiguracoes;
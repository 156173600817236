import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";


export default function TituloPrincipal(props) {
    const title = props.title ? props.title : ""
    const subTitle = props.subTitle ? props.subTitle : ""

    return (
        <Box p={2}>

            <Typography
                display="block"
                align="center"
                style={{ fontFamily: "Bebas Neue", fontSize: "35px", fontWeight: "600" }}
                color="primary">
                {title}
            </Typography>
            
            <Typography display="block" align="center" color="textSecondary">
                {subTitle}
            </Typography>


        </Box>
    )
}
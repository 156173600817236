import React, { useEffect } from 'react'

import GridItem from "./../Grid/GridItem.js";
import GridContainer from "./../Grid/GridContainer.js";
import Card from "./../Card/Card";
import CardHeader from "./../Card/CardHeader.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./../../assets/jss/material-dashboard-react/views/dashboardStyle.js";
import AuthService from '../../../../AuthService.js';
import { useState } from 'react';
import CardBody from '../Card/CardBody.js';
import Table from "../Table/Table.js";
import { Box } from '@material-ui/core';

const useStyles = makeStyles(styles);

export default function ListDashboard({ periodo, tipoPedido }) {

    const filter = (periodo.dataInicial ? '/' + periodo.dataInicial + '/' + periodo.dataFinal : '') + '/' + tipoPedido

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <CardGenerico
                    title="Cupom de desconto utilizados"
                    color="rose"
                    url={`/dashboard/list/CUPOM_QUANTIDADE${filter}`} />
            </GridItem>
        </GridContainer >
    )
}

function CardGenerico({ color, title, url }) {
    let [informacao, setinformacao] = useState(0);
    let [loading, setloading] = useState(true);
    const classes = useStyles();

    function getInfo() {
        let service = new AuthService();
        service
            .get(url)
            .then(resp => {
                setinformacao(resp)
                setloading(false)
            })
            .catch(err => {
                setloading(false)
                console.log(err)
            })
    }

    useEffect(() => {
        getInfo();
    }, [])

    useEffect(() => {
        getInfo();
    }, [url])

    return (
        <Card>

            <CardHeader color={color}>
                <Box display='flex' justifyContent='center'>
                    <h3 className={classes.cardTitleWhite}>{title}</h3>
                </Box>
            </CardHeader>
            <CardBody>
                <Table
                    tableHeaderColor="gray"
                    tableHead={informacao.head}
                    tableData={informacao.rows}
                />
            </CardBody>
        </Card>
    )
}
import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import { DialogContent, Dialog, DialogActions, Button, Grid, Divider, withStyles, LinearProgress } from "@material-ui/core";
import { withSnackbar } from "notistack";
import AuthService from "../../../AuthService";

const styles = theme => ({

    titleForm: {
        fontSize: "200%",
        textAlign: "center",
        fontFamily: "Bebas Neue",
        textTransform: "uppercase"
    },

    subtitleForm: {
        fontSize: "95%",
        textAlign: "center",
        fontFamily: "Bebas Neue",
        textTransform: "uppercase"
    },

    divider: {
        marginTop: "18px"
    },

    congigs: {
        marginTop: "4vh"
    },
    progess: {
        width: '24.5vw',
        marginRight: "11vw",
        padding: "1px",
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },

});

class ConfigQr extends Component {
    constructor(props) {
        super(props);
        this.state = {
            configuracoes: {
                size: 500,
                margin: 1
            },
            baixando: false
        }
    }

    validaTamanho() {
        let validacao = false;
        if (!this.state.configuracoes.size || this.state.configuracoes.size > 3000 || this.state.configuracoes.size < 57) {
            validacao = true
        } else {
            validacao = false
        }
        return validacao;
    }

    validaMargin() {
        let validacao = false;
        if (!this.state.configuracoes.margin || this.state.configuracoes.margin < 0 || this.state.configuracoes.margin > 5) {
            validacao = true
        } else {
            validacao = false
        }
        return validacao;
    }




    handleChange(e) {

        let { configuracoes } = this.state;
        configuracoes[e.target.name] = e.target.value
        this.setState({ configuracoes });

    }

    donwloadQRCodes = () => {
        let size = parseInt(this.state.configuracoes.size);
        let margin = parseInt(this.state.configuracoes.margin);
        this.setState({ baixando: true })

        let service = new AuthService();
        service
            .download(`/identificador/donwloadQRCodesPerso/${size}/${margin}`)
            .then(retorno => {
                console.log("RETORNO", retorno)

                var url = window.URL.createObjectURL(retorno);
                var a = document.createElement('a');
                a.href = url;
                a.download = "qrcode-mesas.zip";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();
                this.setState({ baixando: false })
            })
            .catch(err => {
                
                this.setState({ baixando: false })
                if (err.response === undefined) {
                    this.props.enqueueSnackbar("Falha ao tentar gerar e baixar QRcode's", {
                        variant: "error"
                    });
                } else {
                    err.response.json().then(elem => {
                        this.props.enqueueSnackbar(
                            "Falha ao gerar e baixar QRcode's" + elem.errorMessage,
                            { variant: "error" }
                        );
                    });
                }
            });
    }

    render() {
        const { open } = this.props;
        let { baixando } = this.state;
        const { classes } = this.props;

        return (
            <Dialog
                style={{ height: "60vh" }}
                open={open}
                onClose={this.props.handleToggle}>

                <DialogContent>

                    <Grid className={classes.titleForm}>
                        <strong>
                            Configurações de QRcode
                        </strong>
                    </Grid>

                    <Grid >
                        <Divider className={classes.divider} />
                        {  baixando ? (
                            <LinearProgress color="primary"/>
                          ) : null }
                    </Grid>

                    <Grid container spacing={2} className={classes.congigs}>
                        <Grid item xs={6}>
                            <TextField
                                error={this.validaTamanho()}
                                style={{ width: "100%" }}
                                variant="outlined"
                                label={"Tamanho"}
                                name="size"
                                type="number"
                                defaultValue="500"
                                required
                                helperText={this.validaTamanho() ? "MÍNIMO de tamanho:57px / MÁXIMO de tamanho: 3000px" : "Tamanho em pixels (1Cm = 28px)"}
                                onChange={event => this.handleChange(event)}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                error={this.validaMargin()}
                                style={{ width: "100%", marginLeft: "15px" }}
                                variant="outlined"
                                label={"Margem"}
                                name="margin"
                                type="number"
                                defaultValue="1"
                                required
                                helperText={this.validaMargin() ? "Preencha com um valor de 0 a 5" : "MÍNIMO: 0 / MÁXIMO: 5"}
                                onChange={event => this.handleChange(event)}
                            />
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions >

                    <Button
                        variant="contained"
                        color="secondary"
                        style={{ fontFamily: "Bebas Neue", textTransform: "uppercase", margin: "0 30px 2vh 0" }}
                        size="large"
                        onClick={this.props.handleToggle}
                        disabled={baixando}
                    >
                        Cancelar
                    </Button>

                    <Button
                        // disabled={this.validaTamanho() || this.validaMargin()} - estava duplicado
                        variant="contained"
                        color="primary"
                        style={{ fontFamily: "Bebas Neue", textTransform: "uppercase", margin: "0 30px 2vh 0" }}
                        size="large"
                        disabled={baixando}
                        onClick={this.donwloadQRCodes}
                    >
                        {baixando ? 'Aguarde...' : 'Baixar'}
                      
                    </Button>


                </DialogActions>
            </Dialog>

        )
    }
}

export default withSnackbar(withStyles(styles)(ConfigQr));
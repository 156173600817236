import React, { Component } from 'react'
import { Container, Typography, Link, Button, Box } from '@material-ui/core'
import Fundo from "../../imagens/fundo-qrpedir-min.png"
import Robo from "../../imagens/roboError.svg";

/*
Tela para representar que a empresa está inativa, somente é mostrada para usuários que não são adm
*/
export class TelaInativo extends Component {

    handleReturn() {
        window.location.href = process.env.REACT_APP_STATION_URL;
    }

    render() {
        const styles = {
            fundo: {
                height: "100vh",
                background: `url(${Fundo}) repeat`
            }
        }
        return (
            <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Container fixed style={{ display: "flex", justifyContent: "center" }}>
                    <img src={Robo} style={{ width: "30%" }} />

                    <Box style={{ marginLeft: "30px", display: "flex", justifyContent: "space-between", flexDirection: "column", }}>
                        <Typography align={"center"} style={{ fontSize: "150%", marginTop: "10px" }}>
                            Espera aí, parece que seu sistema foi Inativado! <br />
                            Entre em contato com nosso suporte para tirar suas <br /> duvidas.
                        </Typography>
                        <Box style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                            <Typography align={"center"} style={{ fontSize: "100%" }}>
                                Central de atendimento: <a href={"https://qrpedir.com/suporte/"}>qrpedir.com/suporte/</a> <br />
                                Telefone: (46) 3199-0986.
                            </Typography>
                            <Button onClick={this.handleReturn} style={{ width: "170px", margin: "10px auto" }} variant="contained" color="primary">
                                Voltar ao inicio
                            </Button>
                        </Box>
                    </Box>

                </Container>
            </Box>
        )
    }
}

export default TelaInativo;

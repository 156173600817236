import React from "react";
import { Button, TextField, Box, Paper, InputAdornment } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';

export default function ConfigurarEndereco() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [identificadores, setIdentificadores] = React.useState([]);

    const handleChange = (event, identificador) => {
        let index = identificadores.findIndex(el => el.id === identificador.id);
        identificadores[index].uuid = event.target.value;
        console.log(identificadores[index]);
    }

    const handleSubmit = (identificador) => {

        let string = `{ "id": ${identificador.id}, "uuid": "${identificador.uuid}" }`
        console.log(string);
        
        let json = JSON.parse(string)

        service
        .put("/identificador/changeOnly", json)
        .then(resp => {
            console.log("deu certo");
            enqueueSnackbar("Sucesso ao salvar informações.", { variant: 'success' })
        })
        .catch(err => {
            if (err.response === undefined) {
                enqueueSnackbar("Erro ao salvar informações.", { variant: 'error' })
            }else {
                enqueueSnackbar("Já existe um endereço cadastrado com esse valor: "+ identificador.uuid, { variant: 'error' })
            }

        })
        
        
    }

    React.useEffect(() => {
        service.get('/identificador/tipo/DELIVERY')
            .then(resp => {

                setIdentificadores(resp);

            }).catch(err => {
                console.error(err)
            })
    }, []);

    return (
        <React.Fragment>
            <BarraTitulo
                title="configurar endereço do cardápio digital" />

            <Paper>
                <Box p={3}>
                    <Grid container spacing={2} alignItems="center"  >
                        {identificadores.map(identificador => (
                            <React.Fragment key={identificador.id} >
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        variant="outlined"
                                        label="Endereço digital"
                                        name="uuid"
                                        onChange={e => handleChange(e, identificador)}
                                        defaultValue={identificador.uuid}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="start">.qrpedir.com</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                    <Button fullWidth
                                        color="secondary"
                                        onClick={() => handleSubmit(identificador)}
                                        variant="contained">Salvar</Button>
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>


                </Box>

            </Paper>

        </React.Fragment>
    );
}

import React, { Component } from 'react'
import TemplateBase from "../Generico/TemplateBase";
import { Box } from "@material-ui/core";
import CardQuick from './components/CardQuick/index.js';
import Chart from './components/Chart';
import "./assets/css/material-dashboard-react.css";
import Divider from '@material-ui/core/Divider';
import ListDashboard from './components/List/Index';
import moment from 'moment';
import DashboardFilter from './components/DashboardFilter';

export class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            periodo: {
                dataInicial: `${moment().subtract(6, 'hours').format('yyyy-MM-DD')} 06:00:00`,
                dataFinal: `${moment().subtract(6, 'hours').add(1, 'days').format('yyyy-MM-DD')} 06:00:00`
            },
            tipoPedido: 'TODOS',
            tipoEntrega: 'TODOS'
        }

        this.setPeriodo = this.setPeriodo.bind(this);
    }

    setPeriodo(periodo) {
        this.setState(periodo)
    }

    render() {
        return (
            <TemplateBase title="Dashboard" subTitle="Acompanhe o resultado das suas vendas." component={true}>
                <Box p={2}>
                    <DashboardFilter setPeriodo={this.setPeriodo} />

                    <Box p={2}>
                        <Divider variant="middle" />
                    </Box>
                    
                    <CardQuick periodo={this.state.periodo} tipoPedido={this.state.tipoPedido}/>
                    <Chart periodo={this.state.periodo} tipoPedido={this.state.tipoPedido}/>
                    <ListDashboard periodo={this.state.periodo} tipoPedido={this.state.tipoPedido}/>
                    
                </Box>
            </TemplateBase>
        )
    }
}


export default (Dashboard);
import React, { useEffect } from 'react'

import GridItem from "./../Grid/GridItem.js";
import GridContainer from "./../Grid/GridContainer.js";
import Card from "./../Card/Card";

import AuthService from '../../../../AuthService.js';
import { useState } from 'react';
import ForBoards from '../../../Skeleton/ForBoards.js';

import {
  Chart,
  BarSeries,
  ArgumentAxis,
  ValueAxis,
  Title,
  LineSeries,
  Tooltip,
  Legend,
  PieSeries
} from '@devexpress/dx-react-chart-material-ui';

import {
  symbol,
  symbolCircle,
} from 'd3-shape';

import { EventTracker, ScatterSeries, Stack } from '@devexpress/dx-react-chart'
import { withStyles } from '@material-ui/core/styles';
import utils from '../../../../utils/index.js';

export default function ChartReceitas({ periodo, tipoPedido }) {

  const filter = (periodo.dataInicial ? '/' + periodo.dataInicial + '/' + periodo.dataFinal : '') + '/' + tipoPedido

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CardGenerico
          nameChart="receitaAcumulada"
          title="Receitas acumuladas do mês atual comparadas com o mês anterior"
          url={`/dashboard/chart/ACUMULADO_DIA${filter}`} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <CardGenerico
          nameChart="numVendas"
          title="Vendas por dia"
          url={`/dashboard/chart/QUANTIDADE_PEDIDO_DIA${filter}`} />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <CardGenerico
          nameChart="receitaPorDiaDaSemana"
          title="Vendas por dia da semana"
          url={`/dashboard/chart/VALOR_TOTAL_PEDIDO_DIA_DA_SEMANA${filter}`} />
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <CardGenerico
          nameChart="receitaTipoPedido"
          title="Vendas por tipo de pedido"
          url={`/dashboard/chart/VALOR_TOTAL_TIPO_PEDIDO${filter}`} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <CardGenerico
          nameChart="valorItensMaisVendidos"
          title="Ranking dos 15 de itens mais vendidos (valor)"
          url={`/dashboard/chart/VALOR_ITENS_MAIS_VENDIDOS${filter}`} />
      </GridItem>
      <GridItem xs={12} sm={12} md={12}>
        <CardGenerico
          nameChart="quantItensMaisVendidos"
          title="Ranking dos 15 itens mais vendidos (quantidade)"
          url={`/dashboard/chart/QUANTIDADE_ITENS_MAIS_VENDIDOS${filter}`} />
      </GridItem>
    </GridContainer>
  )
}

function CardGenerico({ url, title, nameChart }) {
  let [data, setData] = useState(null);
  let [loading, setloading] = useState(true);

  function getInfo() {
    let service = new AuthService();
    service
      .get(url)
      .then(resp => {
        setData(resp)
        //setloading(false)
      })
      .catch(err => {
        //setloading(false)
        setData([])
        console.error(err)
      })
      .finally(() => {
        setloading(false);
      })
  }

  useEffect(() => {
    getInfo();
  }, [url])

  const Point = (type, styles) => (props) => {
    const {
      arg, val, color,
    } = props;
    return (
      <path
        fill={color}
        transform={`translate(${arg} ${val})`}
        d={symbol().size([10 ** 2]).type(type)()}
        style={styles}
      />
    );
  };

  const CirclePoint = Point(symbolCircle, {
    strokeWidth: '1px'
  });

  const LineWithCirclePoint = props => (
    <React.Fragment>
      <LineSeries.Path {...props} />
      <ScatterSeries.Path {...props} pointComponent={CirclePoint} />
    </React.Fragment>
  );

  const legendStyles = {
    root: {
      display: 'flex',
      margin: 'auto',
      flexDirection: 'row'
    },
  };

  const legendRootBase = ({ classes, ...restProps }) => (
    <Legend.Root {...restProps} className={classes.root} />
  );

  const legendLabelBase = ({ classes, ...restProps }) => (
    <Legend.Label className={classes.label} {...restProps} />
  );

  const Root = withStyles(legendStyles, { name: 'LegendRoot' })(legendRootBase);
  const legendLabelStyles = () => ({
    label: {
      whiteSpace: 'nowrap',
    },
  });

  const Legenda = withStyles(legendLabelStyles, { name: 'LegendLabel' })(legendLabelBase);

  const Label = symbol => (props) => {
    const { text } = props;
    return (
      <ValueAxis.Label
        {...props}
        text={symbol + text}
      />
    );
  };

  const TooltipContent = (props) => {
    const { targetItem, text } = props;
    return (
      <div>
        <div>
          <Tooltip.Content
            text={targetItem.series}
          />
        </div>
        <div>
          {targetItem.series === 'Quantidade vendida' || targetItem.series === 'Quantidade' ?
            (
              <Tooltip.Content
                text={text}
              />
            ) : targetItem.series === 'Valor vendido' || targetItem.series === 'Mês anterior'
              || targetItem.series === 'Mês atual' || targetItem.series === 'Total de venda do produto'
              || targetItem.series === 'Valor' ?
              (
                <Tooltip.Content
                  text={utils.formatNumber(parseFloat(text), 'decimal')}
                />
              ) : targetItem.series === 'Porcentagem' ?
                (
                  <Tooltip.Content
                    text={utils.formatNumber(parseFloat(text).toFixed(2), 'percent')}
                  />
                ) : null
          }
        </div>
      </div>
    );
  };

  const Grid = props => (
    <ValueAxis.Grid {...props} strokeDasharray="0 5" />
  );

  const labelMonetaria = Label(utils.optionsCurrency().currencySymbol)

  const AxisLabel = (props) => {
    const { text } = props;
    return (
      <ArgumentAxis.Label
        style={{ maxWidth: "60px" }}
        {...props}
        text={text}
      />
    )
  }

  var valueMesAnterior
  var valueMesAtual

  if (data) {
    data.series.map(
      (lista) => {
        valueMesAnterior = lista.valueMesAnterior
        valueMesAtual = lista.valueMesAtual
      })

    var chartData = []
    for (var i = 0; i < 31; i++) {
      chartData.push({
        labels: data.labels[i],
        valueMesAnterior: valueMesAnterior[i],
        valueMesAtual: valueMesAtual[i],
        pieChartValues: (valueMesAtual[i] / 100), //divisão para gerar porcentagem no gráfico de formato de pizza
      })
    }
  }

  return (

    <Card>
      {loading ? (<ForBoards totalIntens={1} />) : (
        <React.Fragment>
          {nameChart !== 'quantItensMaisVendidos' && nameChart !== 'valorItensMaisVendidos' ?
            <Chart
              data={chartData}
              height='450'
            >

              {nameChart === "numVendas" ? [

                <BarSeries
                  name="Valor vendido"
                  key="valueMesAtual"
                  valueField="valueMesAtual"
                  argumentField="labels"
                  color='#2F3559'
                />,
                <LineSeries
                  name='Quantidade'
                  key="valueMesAnterior"
                  valueField="valueMesAnterior"
                  argumentField="labels"
                  seriesComponent={LineWithCirclePoint}
                  color='#F2C26A'
                />,

                <ArgumentAxis />,
                <ValueAxis gridComponent={Grid} labelComponent={labelMonetaria} />,

                <Legend position="bottom" rootComponent={Root} labelComponent={Legenda} />,

              ] : nameChart === "receitaAcumulada" ? [

                <LineSeries
                  name="Mês anterior"
                  key="valueMesAnterior"
                  valueField="valueMesAnterior"
                  argumentField="labels"
                  seriesComponent={LineWithCirclePoint}
                  color='#2F3559'
                />,

                <LineSeries
                  name='Mês atual'
                  key="valueMesAtual"
                  valueField="valueMesAtual"
                  argumentField="labels"
                  seriesComponent={LineWithCirclePoint}
                  color='#F2C26A'
                />,

                <ArgumentAxis />,
                <ValueAxis gridComponent={Grid} labelComponent={labelMonetaria} />,

                <Legend position="bottom" rootComponent={Root} labelComponent={Legenda} />,

              ] : nameChart === "receitaTipoPedido" ? [

                <PieSeries
                  name="Porcentagem"
                  key="pieChartValues"
                  valueField="pieChartValues"
                  argumentField="labels"
                // pointComponent={customizePoint}
                />,

                <Legend />,

              ] : nameChart === "receitaPorDiaDaSemana" ? [

                <BarSeries
                  name="Valor"
                  key="valueMesAtual"
                  valueField="valueMesAtual"
                  argumentField="labels"
                  color='#2F3559'
                />,

                <LineSeries
                  name='Quantidade'
                  key="valueMesAnterior"
                  valueField="valueMesAnterior"
                  argumentField="labels"
                  seriesComponent={LineWithCirclePoint}
                  color='#F2C26A'
                />,

                <ArgumentAxis />,
                <ValueAxis gridComponent={Grid} labelComponent={labelMonetaria} />,

                <Legend position="bottom" rootComponent={Root} labelComponent={Legenda} />

              ] : null}

              <EventTracker />
              <Tooltip
                contentComponent={TooltipContent}
              />

              <Title text={title} />

            </Chart>
            :
            <React.Fragment>
              <Chart
                data={chartData}
                height='450'
                rotated>

                {nameChart === "valorItensMaisVendidos" ? [

                  <BarSeries
                    name="Valor vendido"
                    key="valueMesAtual"
                    valueField="valueMesAtual"
                    argumentField="labels"
                    color='#2F3559'
                  />,

                  <ArgumentAxis labelComponent={AxisLabel} />,
                  <ValueAxis gridComponent={Grid} labelComponent={labelMonetaria} />,

                  <Stack />,

                  <Legend position="bottom" rootComponent={Root} labelComponent={Legenda} />,

                ] : nameChart === "quantItensMaisVendidos" ? [

                  <BarSeries
                    name="Quantidade vendida"
                    key="valueMesAtual"
                    valueField="valueMesAtual"
                    argumentField="labels"
                    color='#F2C26A'
                  />,

                  <ArgumentAxis labelComponent={AxisLabel} />,
                  <ValueAxis gridComponent={Grid} />,

                  <Stack />,

                  <Legend position="bottom" rootComponent={Root} labelComponent={Legenda} />,

                ] : null}

                <EventTracker />
                <Tooltip
                  contentComponent={TooltipContent}
                />

                <Title text={title} />

              </Chart>
            </React.Fragment>
          }
        </React.Fragment>
      )}
    </Card>

  )
}
import React, { Component } from "react";
import AuthService from "../../../AuthService";
import withStyles from "@material-ui/styles/withStyles";
import { withSnackbar } from "notistack";
import MenuItem from '@material-ui/core/MenuItem';
import Utils from '../../../utils';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Avatar from "@material-ui/core/Avatar";
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import DividerP from './Divider'
import {
  Grid,
  Button,
  TextField,
  Box,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  Radio,
  RadioGroup,
  DialogActions,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import S3 from 'react-aws-s3';
import { createUUID } from "../../../utils";

const styles = theme => ({
  div: {
    flexGrow: 1
  },
  avatarLogo: {
    width: "110px",
    height: "110px",
    cursor: 'pointer',
    "&:hover": {
      opacity: "0.5"
    }
  },
  alterarLogo: {
    width: "180px",
    height: "180px",
    cursor: 'pointer',
    "&:hover": {
      opacity: "0.5"
    }
  },
  iconesLogo: {
    width: '90px',
    height: '90px',
    opacity: '0.7'
  },
  removerLogo: {
    fontSize: '18px',
  },
  boxBotao: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: '50px 0px 25px',
  },
});

class formEmpresa extends Component {
  constructor(props) {
    super(props);
    //Cada elemento de input que você terá em seu component
    //formulário assumirá o valor de state como seu valor.
    this.state = {
      empresa: {
        nome: '',
        razaoSocial: '',
        cpfCnpj: '',
        representante: '',
        cep: '',
        endereco: '',
        numero: '',
        bairro: '',
        complemento: '',
        estado: { id: '' },
        municipio: { id: '' },
        tipoIdentificadorPrincipal: '',
        tipoIdentificadorSecundario: '',
        tempoValidadeQrcodeMinutos: 30,
        chamarGarcom: false,
        urlFacebook: "",
        urlInstagram: "",
        foneWhatsapp: "",
        ativo: true,
        formatoLogo: 'square',
        tipoConta: ''
      },
      estados: [],
      municipios: [],
      planos: [],
      open: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleCheckedMostraLoja = this.handleCheckedMostraLoja.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleList = this.handleList.bind(this);
    this.carregarPlanos = this.carregarPlanos.bind(this)
    this.usuarioLogado = this.usuarioLogado.bind(this)
    this.uploadFileServer = this.uploadFileServer.bind(this)
    this.converteData = this.converteData.bind(this)
    this.handleChangeEstado = this.handleChangeEstado.bind(this)
    this.handleChangeMunicipio = this.handleChangeMunicipio.bind(this)
    this.carregarMunicipios = this.carregarMunicipios.bind(this)
    this.carregarEstados = this.carregarEstados.bind(this)
  }

  async componentDidMount() {
    this.carregarEstados()
    this.carregarPlanos()
    this.handleList()
  }

  usuarioLogado() {
    let service = new AuthService();
    return service.getProfile()
  }

  handleList() {
    let service = new AuthService();
    service
      .get("/login/usuarioLogado")
      .then(retorno => {

        let data = this.converteData(retorno.empresa.dataBloqueio)
        retorno.empresa.dataBloqueio = data

        retorno.empresa.tipoConta = retorno.empresa.tipoConta === "" ? "semConfig" : 'TEMPORARIA'

        this.setState({ empresa: retorno.empresa })
        this.carregarMunicipios(retorno.empresa.estado?.id)

      })
      .catch(err => {
        console.error(err);

        //em caso de erro é impresso o erro no console.
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Erro ao buscar informações do usuário', { variant: 'error' })
        } else {
          err.response.json().then(elem => {
            console.log(elem);
            this.props.enqueueSnackbar('Você não esta logado', { variant: 'warning' })
          });
        }

      });
  }

  handleChange(event) {
    const { empresa } = this.state;

    Utils.setValue(empresa, event.target.name, event.target.value)

    this.setState({ empresa: empresa })
  }

  handleChecked(event) {
    const { empresa } = this.state;
    empresa[event.target.name] = event.target.checked;
    this.setState({ empresa: empresa });
  }

  handleCheckedMostraLoja(event) {
    const { empresa } = this.state;
    empresa[event.target.name] = event.target.value;
    this.setState({ empresa: empresa });
  }

  handleSubmit(event) {
    const { empresa, formatoLogo } = this.state;
    let service = new AuthService();

    empresa.formatoLogo = formatoLogo

    if (empresa.urlHook) {
      empresa.urlHook = empresa.urlHook.trim();
    }

    if (empresa.tipoConta === 'semConfig') {
      empresa.tipoConta = ""
    }

    empresa.dataBloqueio = this.converteData(empresa.dataBloqueio);

    service
      .post("/empresa", empresa)
      .then(retorno => {
        this.props.enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' })

        let profile = JSON.parse(localStorage.getItem("_profile"));
        profile.empresa = retorno;
        this.setState({ empresa: retorno });

        localStorage.setItem("_profile", JSON.stringify(profile))
      })
      .catch(err => {
        //em caso de erro é impresso o erro no console.
        if (err.response === undefined) {
          console.log(err);
          this.props.enqueueSnackbar('Erro ao salvar empresa', { variant: 'error' })
        } else {
          err.response.json().then(elem => {
            console.log(elem);
            this.props.enqueueSnackbar(elem.errorMessage, { variant: 'warning' })
          });
        }
      });

  }

  carregarPlanos() {
    let service = new AuthService();
    service
      .get("/plano/list")
      .then(retorno => {
        this.setState({ planos: retorno })
      })
      .catch(err => {
        console.error('Falha ao carregar planos', err)
      });
  }

  carregarEstados() {
    this.setState({ mensagem: "carregando..." })
    let service = new AuthService();
    service
      .get("/estado/list")
      .then(retorno => {
        this.setState({ estados: retorno, mensagem: "" })
      })
      .catch(err => {
        this.setState({ mensagem: "Falha ao carregar estados" })
      });
  }

  carregarMunicipios(idEstado) {
    this.setState({ mensagem: "carregando municípios..." })
    let service = new AuthService();
    service
      .get("/municipio/listByEstadoId/" + idEstado)
      .then(retorno => {
        this.setState({ municipios: retorno, mensagem: "" })
      })
      .catch(err => {
        this.setState({ mensagem: "Falha ao buscar municípios" })
      });
  }

  handleChangeEstado(event) {
    const estado = this.state.estados.find(e => e.id === event.target.value)

    const { empresa } = this.state;
    empresa.estado = estado

    this.setState({ empresa })

    if (estado) {
      this.carregarMunicipios(estado.id)
    }
  }

  handleChangeMunicipio(event) {
    const municipio = this.state.municipios.find(e => e.id === event.target.value)

    const { empresa } = this.state;
    empresa.municipio = municipio

    this.setState({ empresa })
  }

  uploadFileServer(file) {
    const { empresa } = this.state;
    const empresaId = empresa?.id ? '/' + empresa.id : '';
    const diretorio = 'qrpedir/logo' + empresaId;
    const URL = "https://img.imageboss.me/parseint/cdn/";
    let fileName = '';

    const config = { //config aws
      bucketName: 'parseint',
      region: 'sa-east-1',
      dirName: diretorio,
      accessKeyId: process.env.REACT_APP_AWS_API_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_API_SECRET_KEY,
    }

    const handleFileInput = (file) => {
      if (!!file) {
        const imageName = createUUID()
        const ext = file.name.split('.').pop();
        const newFileName = imageName + '.' + ext;
        fileName = newFileName;

        uploadFile(file)
      }
    }

    const uploadFile = async (file) => {
      if (!!file) {
        console.log("file", file)
        console.log("fileName", fileName)
        const ReactS3Client = new S3(config);
        ReactS3Client
          .uploadFile(file, fileName)
          .then(data => {
            console.log(data)
            onCompleteUpload(data)
          })
          .catch(err => console.error(err))
      }
    }

    const onCompleteUpload = response => {
      //var url = response.secure_url;
      empresa.urlLogo = URL + response.key
      this.setState({ empresa: empresa })
    }

    handleFileInput(file);
  }

  converteData(data) {

    if (!data) {
      return;
    }

    let date = "";
    let regexp = /\d\d\/\d\d\/\d\d\d\d\b/
    console.log(data);

    if (data) {

      if (typeof data == "object") {
        console.log("era objeto");
        data = data.toString();
      }

      if (data.match(regexp)) {
        let dataArray = data.split("/");
        let dataFormatada = dataArray[2] + "-" + dataArray[1] + "-" + dataArray[0] + "T00:00:00";
        date = new Date(dataFormatada);
      } else {
        data = new Date(data);

        let dia = data.getDate().toString();
        let mes = (data.getMonth() + 1).toString();
        let ano = data.getFullYear().toString();

        if (dia.length < 2) {
          dia = "0" + dia
        }
        if (mes.length < 2) {
          mes = "0" + mes
        }

        date = dia + "/" + mes + "/" + ano;
      }
      return date;
    }
  }

  handleChangeOnly = (atributo, valor) => {
    let { empresa } = this.state;
    let service = new AuthService();
    let profile = JSON.parse(localStorage.getItem("_profile"));

    let string = `{ "id": ${profile.empresa.id}, "${atributo}": ${valor} }`
    let json = JSON.parse(string)

    service
      .put(`/empresa/changeOnly`, json)
      .then(retorno => {
        profile.empresa = retorno;
        localStorage.setItem("_profile", JSON.stringify(profile))
        if (atributo === "dataBloqueio") {
          delete empresa.dataBloqueio;
          this.props.enqueueSnackbar('Data de bloqueio retirada com sucesso', { variant: 'success' });
        } else {
          empresa.numeroSequencial = 1;
          this.props.enqueueSnackbar('Sequencial resetado com sucesso', { variant: 'success' });
        }


        this.setState({ empresa });
      })
      .catch(err => {

      })

  }

  handleToggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  onChangeRadioLogo = (e) => {
    const { empresa } = this.state;
    empresa.formatoLogo = e.target.value
    this.setState({ empresa: empresa })
  }

  handleSaveLogo = () => {
    let { formatoLogo, id } = this.state.empresa;
    let service = new AuthService();

    let string = `{ "id": ${id}, "formatoLogo": "${formatoLogo}" }`
    let json = JSON.parse(string)

    service
      .put(`/empresa/changeOnly`, json)
      .then(resp => {
        this.setState({ empresa: resp })
        localStorage.setItem("_profile", JSON.stringify(resp))

      })

    this.handleSubmit(null)
    this.handleToggle()
  }

  /*
    * Utilizado para busca do cep automaticamente
    */
  handleSearchByCep = (event) => {

    const { empresa } = this.state;
    empresa.cep = event.target.value;

    if (Utils.onlyDigits(event.target.value).length > 7) {

      let cep = event.target.value

      fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then(response => {

          return response.json().then((result) => {
            if (result.erro) {
              this.props.enqueueSnackbar("CEP não encontrado", { variant: 'warning' });
              return;
            }

            empresa.endereco = result.logradouro
            empresa.bairro = result.bairro
            empresa.complemento = result.complemento

            this.setState(empresa)
          })

        }).catch(err => {
          console.error(err)
          this.props.enqueueSnackbar("Nada encontrado para o CEP inforado.", { variant: 'warning' });
        })

    }

    this.setState(empresa)
  }

  /*
  * Utilizado para busca do cep via botão de pesquisa
  */
  handleClickSearchByCep = () => {
    const { empresa } = this.state;

    const testCep = empresa.cep

    if (Utils.onlyDigits(testCep).length > 7) {

      fetch(`https://viacep.com.br/ws/${testCep}/json/`)
        .then(response => {

          return response.json().then((result) => {
            if (result.erro) {
              this.props.enqueueSnackbar("CEP não encontrado", { variant: 'warning' });
              return;
            }
            console.log("result", result)

            empresa.endereco = result.logradouro
            empresa.bairro = result.bairro
            empresa.complemento = result.complemento

            this.setState(empresa);
          })

        }).catch(err => {
          console.error(err)
          this.props.enqueueSnackbar("Nada encontrado para o CEP inforado.", { variant: 'warning' });
        })

    } else {
      this.props.enqueueSnackbar("O cep informado não corresponde a um endereço válido", { variant: 'error' });
    }
  }

  render() {
    const { classes } = this.props;
    const { empresa, planos, open } = this.state;
    const formatoLogo = empresa.formatoLogo
    let usuario = JSON.parse(localStorage.getItem("_profile"));

    return (

      <Grid container spacing={2} alignItems='center' justify='flex-end'>
        <Grid item xs={12} sm={12}>
          <Box flexDirection="row" display="flex" justifyContent="center">
            {empresa.urlLogo ? (
              <Avatar
                alt="Logo da empresa"
                src={empresa.urlLogo}
                variant={formatoLogo}
                onClick={this.handleToggle}
                className={classes.avatarLogo} />
            ) :
              <Avatar
                alt="Logo da empresa"
                className={classes.avatarLogo}
                onClick={this.handleToggle}
                variant={formatoLogo}>
                <AddAPhotoIcon
                  style={{ fontSize: '50px' }} />
              </Avatar>
            }

            <Dialog
              open={open}
              onClose={this.handleToggle}
              maxWidth="sm"
              fullWidth>

              <DialogTitle align='center'>
                Alterar foto de perfil
              </DialogTitle>

              <DialogContent>

                <Box display='flex' flexDirection='row'>
                  <Box flexGrow={1}>
                    {empresa.urlLogo ? (
                      <>
                        <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                          <Avatar variant={formatoLogo} alt='Logo da empresa' src={empresa.urlLogo} className={classes.alterarLogo}
                            onClick={event => document.getElementById("fileElem").click()} />
                        </Box>

                        <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                          <Link href='#' className={classes.removerLogo} onClick={event => { empresa.urlLogo = null; this.setState({ empresa }); return false }}>
                            Remover logo
                          </Link>
                        </Box>
                      </>
                    ) : null}

                    {empresa.urlLogo == null ? (
                      <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                        <Avatar variant={formatoLogo} alt='Logo da empresa' src={empresa.urlLogo} className={classes.alterarLogo}
                          onClick={event => document.getElementById("fileElem").click()} />
                      </Box>
                    ) : true}


                  </Box>

                  <Box display='flex' aling='center'>
                    <RadioGroup onChange={(event) => this.onChangeRadioLogo(event)} value={formatoLogo} >
                      <FormControlLabel value='circular' label={<AccountCircleIcon className={classes.avatarLogo} />} control={<Radio />} />
                      <FormControlLabel value='square' label={<AccountBoxIcon className={classes.avatarLogo} />} control={<Radio />} />
                    </RadioGroup>
                  </Box>

                </Box>
              </DialogContent>

              <DialogActions >
                <Button
                  onClick={this.handleSaveLogo}
                  color='primary'
                  variant="contained">
                  Salvar
                </Button>
              </DialogActions>

            </Dialog>
          </Box>

          <Box flexDirection="row" display="flex" justifyContent="center">
            <Box display="block">
              <input type="file" id="fileElem" accept="image/*" style={{ display: 'none' }} onChange={event => this.uploadFileServer(event.target.files[0])} />
            </Box>
          </Box>

        </Grid>

        <DividerP text='Dados da empresa' />

        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            className={classes.textField}
            variant="outlined"
            label={"Nome Fantasia"}
            name="nome"
            value={empresa.nome}
            onChange={this.handleChange}>
          </TextField>
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            className={classes.textField}
            fullWidth
            variant="outlined"
            label={"Razão Social"}
            name="razaoSocial"
            value={empresa.razaoSocial}
            onChange={event => this.handleChange(event)} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            className={classes.textField}
            variant="outlined"
            label={"CPF/CNPJ"}
            name="cpfCnpj"
            value={empresa.cpfCnpj}
            onChange={event => this.handleChange(event)}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            className={classes.textField}
            variant={ usuario.admin ? "outlined" : "filled"}
            label={"Empresa responsável pelo seu sistema"}
            name="representante"
            value={empresa.representante}
            InputProps={{
              readOnly: !usuario.admin,
            }}
            onChange={this.handleChange}>
          </TextField>
        </Grid>

        <DividerP text='Endereço' />

        <Grid item xs={6} sm={2} >
          <TextField
            select
            label="País"
            value={this.state.empresa.idPais || ''}
            name="idPais"
            onChange={event => this.handleChange(event)}
            fullWidth
            variant="outlined"
          >
            <MenuItem key={1058} value={1058}>BRASIL</MenuItem>
            <MenuItem key={3999} value={3999}>JAPÃO</MenuItem>
          </TextField>
        </Grid>

        <Grid item xs={6} sm={4} >
          <TextField
            select
            label="Estado"
            value={this.state.empresa.estado?.id || ''}
            name="estado"
            onChange={event => this.handleChangeEstado(event)}
            fullWidth
            variant="outlined"
          >
            {this.state.estados.map((estado) => (

              <MenuItem key={estado.id} value={estado.id}>
                {estado.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6} >
          <TextField
            select
            label="Município"
            value={this.state.empresa.municipio?.id || ''}
            name="municipio"
            onChange={event => this.handleChangeMunicipio(event)}
            fullWidth
            variant="outlined"
          >
            {this.state.municipios.map((municipio, index) => (
              <MenuItem key={index} value={municipio.id}>
                {municipio.nome}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6} sm={2}>
          <TextField
            fullWidth
            variant="outlined"
            label={"CEP"}
            name="cep"
            value={empresa.cep}
            inputProps={{ maxLength: 8 }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton onClick={this.handleClickSearchByCep} size="small"><FindReplaceIcon /></IconButton>
                </InputAdornment>
            }}
            onChange={event => this.handleSearchByCep(event)} />
        </Grid>

        <Grid item xs={12} sm={8} >
          <TextField
            fullWidth
            variant="outlined"
            label={"Endereço"}
            name="endereco"
            value={empresa.endereco}
            onChange={event => this.handleChange(event)}
          />
        </Grid>

        <Grid item xs={6} sm={2}>
          <TextField
            fullWidth
            variant="outlined"
            label={"Número"}
            name="numero"
            value={empresa.numero}
            onChange={event => this.handleChange(event)}
          />
        </Grid>

        <React.Fragment>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={"Bairro"}
              name="bairro"
              value={empresa.bairro}
              onChange={event => this.handleChange(event)}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              label={"Complemento"}
              name="complemento"
              value={empresa.complemento}
              onChange={event => this.handleChange(event)}
            />
          </Grid>
        </React.Fragment>

        <DividerP text='Social' />

        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            label="WhatsApp"
            name="foneWhatsapp"
            fullWidth
            value={empresa.foneWhatsapp}
            placeholder="Número do WhatsApp"
            onChange={event => this.handleChange(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <WhatsAppIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Instagram"
            name="urlInstagram"
            fullWidth
            value={empresa.urlInstagram}
            placeholder="URL da sua página no insta"
            onChange={event => this.handleChange(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <InstagramIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Facebook"
            name="urlFacebook"
            fullWidth
            value={empresa.urlFacebook}
            placeholder="URL da sua página no face"
            onChange={event => this.handleChange(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            className={classes.textField}
            variant="outlined"
            label="Pixel do Facebook"
            name="pixelFacebookId"
            fullWidth
            value={empresa.pixelFacebookId}
            placeholder="id do pixel do facebook"
            onChange={event => this.handleChange(event)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FacebookIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <DividerP text='Forma de atendimento' />


        <Grid item xs={12} >
          <TextField id="plano" label="Forma de atendimento" variant="outlined" name="plano.id"
            fullWidth value={(empresa.plano ? empresa.plano.id : '')}
            select disabled={!this.usuarioLogado().admin}
            onChange={this.handleChange} >

            <MenuItem value={'-1'}>
              <em>Escolha o formato de atendimento</em>
            </MenuItem>

            {planos.map(plano => (
              <MenuItem key={plano.id} value={plano.id}>
                {plano.descricao}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        {usuario.admin && (
          <React.Fragment>
            <Grid item xs={6} sm={3}>
              <TextField
                name="idExterno"
                value={empresa.idExterno ? empresa.idExterno : ""}
                variant="outlined"
                label="Id Externo"
                fullWidth
                onChange={this.handleChange}
              />
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                id="mostrarLoja"
                label="Mostrar loja no marketplace?"
                variant="outlined"
                name="mostrarLoja"
                fullWidth
                select
                value={(empresa.mostrarLoja || '')}
                onChange={event => this.handleCheckedMostraLoja(event)} >
                <MenuItem key={'SIM'} value={'SIM'}>
                  SIM
                </MenuItem>
                <MenuItem key={'NAO'} value={'NAO'}>
                  NÃO
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                id="moeda"
                label="Moeda"
                variant="outlined"
                name="moeda"
                fullWidth
                select
                value={(empresa.moeda || '')}
                onChange={event => this.handleCheckedMostraLoja(event)} >
                <MenuItem key={'IENE'} value={'IENE'}>
                  ￥ ( IENE )
                </MenuItem>
                <MenuItem key={'REAL'} value={'REAL'}>
                  R$ ( REAL )
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6} sm={3}>
              <TextField
                id="tipoConta"
                label="Tipo conta"
                variant="outlined"
                name="tipoConta"
                fullWidth
                select
                value={empresa.tipoConta}
                onChange={event => this.handleCheckedMostraLoja(event)} >
                <MenuItem key={"semConfig"} value={"semConfig"}>
                  Sem configuração
                </MenuItem>
                <MenuItem key={'TEMPORARIA'} value={'TEMPORARIA'}>
                  Temporaria
                </MenuItem>
              </TextField>
            </Grid>
            <Box display='flex' justifyContent='flex-start' width='100vw' pl={2}>
              <Grid item xs={6} sm={3}>
                <FormControlLabel
                  name="ativo"
                  control={
                    <Switch
                      checked={empresa.ativo}
                      color="primary"
                      onChange={event => this.handleChecked(event)}
                    />
                  }
                  label={"Empresa ativa"}
                />
              </Grid>
            </Box>


            <DividerP text='Configurações do Webhook' />

            <Grid item xs={12} sm={2}>
              <TextField
                id="statusHook"
                label="Status do webhook"
                variant="outlined"
                name="statusHook"
                fullWidth
                select
                value={(empresa.statusHook || '')}
                onChange={event => this.handleChange(event)} >
                <MenuItem key={"INATIVO"} value={"INATIVO"}>
                  Inativo
                </MenuItem>
                <MenuItem key={'ATIVO'} value={'ATIVO'}>
                  ATIVO
                </MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                id="urlHook"
                label="URL do webhook"
                variant="outlined"
                name="urlHook"
                fullWidth
                value={(empresa.urlHook || '')}
                onChange={event => this.handleChange(event)} >
              </TextField>
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id="id"
                label="Id loja Qrpedir"
                variant="outlined"
                name="id"
                fullWidth
                disabled
                value={empresa.id || ''}>
              </TextField>
            </Grid>

            {/**
            <Grid item xs={12} sm={2}>
              <TextField
                id="idExternoEmpresaHook"
                label="Id externo"
                variant="outlined"
                name="idExternoEmpresaHook"
                fullWidth
                value={(empresa.idExternoEmpresaHook || '')}
                onChange={event => this.handleChange(event)} >
              </TextField>
            </Grid>
             */}



          </React.Fragment>
        )}

        <Box display="flex" width='100vw' pr={1} justifyContent='flex-end'>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              onClick={e => this.handleSubmit(e)}>
              SALVAR
            </Button>
          </Grid>
        </Box>
      </Grid>
    );
  }
}

export default withSnackbar(withStyles(styles)(formEmpresa));

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { List, ListItem } from '@material-ui/core';

export default function ForList({ rows = 2, height = 80 }) {

  const _array = Array.from(Array(rows).keys())

  return (
    <div>
      {_array.map(elem =>
        <List key={elem}>
          <ListItem >
            <Skeleton variant="rect" width="100%" height={ height } />
          </ListItem>
        </List>
      )}

    </div>
  );
}
import React, { Component } from 'react'
import Template from '../Generico/Template'
import { Box, Paper, Grid, Typography, Divider, TableContainer, Table, TableBody, TableRow, TableCell, Button, IconButton, Dialog, DialogTitle, DialogContent, TablePagination } from '@material-ui/core'
import BarraTitulo from '../Generico/BarraTitulo'
import AuthService from '../../AuthService'
import { SkeletonBlock } from 'framework7-react'
import Infos from './components/Infos'
import utils from '../../utils'
import CommentIcon from '@material-ui/icons/Comment';

export class Avaliacao extends Component {
    constructor(props) {
        super(props)
        let width = window.screen.width;
        this.state = {
            avaliacoes: [],
            page: 0,
            rowsPerPage: width <= 560 ? 500 : 10,
            open: false,
            dialogContent: {}
        }
    }

    componentDidMount() {
        this.getAvaliacoes();
    }

    getAvaliacoes = () => {
        let service = new AuthService();

        service
            .get("/avaliacaoEmpresa/list")
            .then(resp => {
                this.setState({ avaliacoes: resp })
                console.log(this.state.avaliacoes);
            })
            .catch(err => {
                console.error(err)
            })
    }

    handleOpenObservacao(avaliacao) {
        let content = {
            title: avaliacao.pessoa ? avaliacao.pessoa.nome : avaliacao.pedido.nomeCliente,
            observacao: avaliacao.observacao
        }

        this.setState({ dialogContent: content, open: true });
    }

    handleClose = () => {
        this.setState({ open: false, dialogContent: {} })
    }

    handleChangePage = (event, newPage) => {

        this.setState({ page: newPage });
    }

    handleRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
        this.setState({ page: 0 })
    }

    render() {
        let { avaliacoes, rowsPerPage, page, open, dialogContent } = this.state;

        return (
            <React.Fragment>

                <Template documentTitle="Avaliações da empresa">

                    <Box mt={5} >
                        <BarraTitulo title="Avaliações da empresa" subTitle="Veja as avaliações dos seus clientes sobre a sua empresa" />
                        <Paper>
                            <Infos />
                            <Divider />
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {avaliacoes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(avaliacao => (
                                            <TableRow key={avaliacao.id} >
                                                <TableCell align="left">
                                                    <Typography variant="h6">
                                                        {avaliacao.pessoa? avaliacao.pessoa.nome : avaliacao.pedido.nomeCliente}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography style={{ fontSize: "115%", fontWeight: "bold", marginRight: "10px" }}>Nota:</Typography>
                                                        <Typography>{avaliacao.nota.toFixed(2)}</Typography>
                                                    </Box>
                                                </TableCell>

                                                <TableCell>
                                                    {utils.formatarDataHora(avaliacao.createdAt, avaliacao.empresa.timezone)}
                                                </TableCell>

                                                <TableCell align="right">
                                                    <Button onClick={() => this.handleOpenObservacao(avaliacao)} disabled={!avaliacao.observacao}>
                                                        <CommentIcon style={{ marginRight: "10px" }} /> Comentários
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 30]}
                                    component="div"
                                    count={avaliacoes.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={this.handleChangePage}
                                    onChangeRowsPerPage={this.handleRowsPerPage} />
                            </TableContainer>
                        </Paper>
                    </Box>

                </Template>

                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={open}
                    onClose={this.handleClose}>
                    <DialogTitle>
                        {dialogContent.title}
                        <Divider />
                    </DialogTitle>
                    <DialogContent style={{ padding: "20px" }}>
                        <Paper style={{ backgroundColor: "#E2E6E6", borderRadius: "5px solid Gray", width: "450px" }}>
                            <Typography style={{ padding: "15px" }}>{dialogContent.observacao}</Typography>
                        </Paper>
                    </DialogContent>
                </Dialog>

            </React.Fragment >
        )
    }
}

export default Avaliacao

import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Grid,
  TableContainer,
  Table,
  TextField,
  AccordionDetails,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Typography,
  ListItemText,
  FormControl,
  FormControlLabel
} from '@material-ui/core';
import React, { useState, useEffect } from 'react'

import CardInfo from '../../Dashboard/components/Card/CardInfo'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AuthService from '../../../AuthService'
import Utils from '../../../utils';

import { useSnackbar } from 'notistack';

export default function FecharConta() {
  const { enqueueSnackbar } = useSnackbar();

  const [numeroIdentificador, setNumeroIdentificador] = useState("")

  const [listaPedidos, setListaPedidos] = useState([])
  const [listaPedidoPorPessoa, setListaPedidoPorPessoa] = useState([])
  const [listaPedidosSelecionados, setListaPedidosSelecionados] = useState([])

  const [expanded, setExpanded] = useState(false);

  const [buscando, setBuscando] = useState(false)
  const [erroBusca, setErroBusca] = useState(null)

  useEffect(() => {
    let listaPedidoChecked = []

    listaPedidoPorPessoa.map(pessoa => {
      pessoa.listaPedidos.map(pedido => {
        if(pedido.checked) {
          listaPedidoChecked.push(pedido)
        }
      })
    })

    setListaPedidosSelecionados(listaPedidoChecked)
  }, [listaPedidoPorPessoa])

  const handleChange = (event) => {
    setNumeroIdentificador(event.target.value)
  }

  const handleBuscarPedido = () => {
    let service = new AuthService()

    setBuscando(true)

    service.get(`/pedido/buscarContasEmAberto?mesa=${numeroIdentificador}&statusIntegracao=PENDENTE&status=PENDENTE&max=200`)
      .then(listaPedidos => {

        handleValidaExistenciaPedido(listaPedidos)

        handleSeparaPorPessoa(listaPedidos)
        setListaPedidos(listaPedidos)

        setListaPedidosSelecionados([])
        setBuscando(false)
      })
      .catch(err => {
        console.error(err)
      })

  }

  const handleSeparaPorPessoa = (listaPedidos) => {
    let listaPessoa = []

    listaPedidos.map((pedido) => {
      let valor = listaPessoa.find(pessoa => pessoa.nome === pedido.nomeCliente)

      pedido.checked = false

      if (valor) {
        valor.listaPedidos.push(pedido)
      } else {
        let array = []
        array.push(pedido)
        listaPessoa.push({
          nome: pedido.nomeCliente,
          telefone: pedido.pessoa ? pedido.pessoa.telefone : "",
          checked: false,
          listaPedidos: array
        })
      }
    })

    setListaPedidoPorPessoa(listaPessoa)
  }

  const handleValidaExistenciaPedido = (listaPedidos) => {
    listaPedidos.length === 0 ? (
      setErroBusca(`Não existe contas em aberta nessa mesa`)
    ) : (
      setErroBusca(null)
    )
  }

  const handleCalculaTotal = (listaPedidos) => {
    let somaTotal = 0

    listaPedidos.map(pedido => {
      somaTotal += Utils.getTotalPedido(pedido)
    })

    return Utils.formatNumber(somaTotal)
  }

  const handleExpanded = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelecionaPedidoIndividual = (event, pedido) => {
    let check = event.target.checked
    
    pedido.checked = check;
    let copiaListaPedidoPorPessoa = Object.assign([], listaPedidoPorPessoa)

    setListaPedidoPorPessoa(copiaListaPedidoPorPessoa)
  }

  const handleSelecionaTodosOsPedidos = (event, pessoa) => {
    let check = event.target.checked

    let copiaListaPedidosPorPessoa = Object.assign([], listaPedidoPorPessoa)
    
 
    copiaListaPedidosPorPessoa.map(pessoaGroup => {
      if(pessoaGroup.nome === pessoa.nome){
        pessoaGroup.checked = check;

        pessoaGroup.listaPedidos.map(pedidoGroup => {
          pedidoGroup.checked = check;
        })
      }
     
    })

    setListaPedidoPorPessoa(copiaListaPedidosPorPessoa)
  }

  const handleSelecionaTodasAsPessoas = (event) => {
    let check = event.target.checked

    let copiaListaPedidosPorPessoa = Object.assign([], listaPedidoPorPessoa)
    copiaListaPedidosPorPessoa.map(pessoaGroup => {
      pessoaGroup.checked = check;
      pessoaGroup.listaPedidos.map(pedidoGroup => {
        pedidoGroup.checked = check;
      })
     
    })

    setListaPedidoPorPessoa(copiaListaPedidosPorPessoa)
    
  }

  const handleMarcarComoPago = (listaPedidos) => {
    let service = new AuthService();

    let pedidosBody = []

    listaPedidos.map(pedidoSelecionado => {
      pedidosBody.push({ id: pedidoSelecionado.id })
    })

    service
      .post(`/pedido/marcarComoPago`, pedidosBody)
      .then(() => {

        setListaPedidoPorPessoa([])
        setListaPedidosSelecionados([])
        handleBuscarPedido()

        enqueueSnackbar('Conta fechada com sucesso', { variant: 'success' })

      }).catch(err => {

        console.error(err)
      })
  }

  const keyDown = (event) => {
    if (event === "click" || event.key === 'Enter') {
      handleBuscarPedido()
    }
  }

  return (
    <div>
      <Box p={2}>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item xs={12} md={8}>
            <TextField
              value={numeroIdentificador}
              onChange={event => handleChange(event)}
              onKeyPress={event => keyDown(event)}
              fullWidth
              autoFocus
              size="small"
              error={erroBusca !== null}
              helperText={erroBusca}
              placeholder={`Caso queira mais de uma mesa, separe com vírgula. Ex: 60, 80`}
              variant='outlined'
              label={`Digite o número da mesa`}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            {buscando ? (
              <Button
                fullWidth
                size="large"
                variant="contained"
                disabled
              >Buscando...</Button>
            ) : <Button
              fullWidth
              size="large"
              color="primary"
              variant="contained"
              disabled={!numeroIdentificador}
              onClick={() => handleBuscarPedido()}

            >Buscar</Button>}
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              onClick={() => handleMarcarComoPago(listaPedidosSelecionados)}
              disabled={listaPedidosSelecionados.length === 0}
            >Fechar conta</Button>
          </Grid>

          {listaPedidosSelecionados.length > 0 ?
            <Grid item xs={12} md={2}>
              <CardInfo caption="Total selecionado" variant="h5" value={handleCalculaTotal(listaPedidosSelecionados)} valueColor="text.primary" />
            </Grid>
            : null}

          {listaPedidos.length > 0 ?
            <Grid item xs={12} md={2}>
              <CardInfo caption="Total" variant="h5" value={handleCalculaTotal(listaPedidos)} valueColor="primary.main" />
            </Grid>
            : null}

        </Grid>
      </Box>

      <Box p={2}>
        {listaPedidos.length > 0 ?
          <Box pl={4} pb={1}>
            <FormControl>
              <FormControlLabel control={
                <Checkbox
                  onChange={(event) => handleSelecionaTodasAsPessoas(event)}
                />}
                label="Selecionar todas as contas"
              />
            </FormControl>
          </Box>
          : null}

        {listaPedidoPorPessoa.map(pessoa => (
          <Accordion
            expanded={expanded === pessoa.nome}
            onChange={handleExpanded(pessoa.nome)}
            key={pessoa.nome}
          >
            <AccordionSummary
              aria-label="Expand"
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container
                direction="row"
                justify="space-between"
                alignItems="center">
                <Grid item xs={12} md={1}>
                  <Box pl={2}>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      onClick={(event) => event.stopPropagation()}
                      onFocus={(event) => event.stopPropagation()}
                      control={
                        <Checkbox
                          checked={pessoa.checked}
                          onChange={(event) => handleSelecionaTodosOsPedidos(event, pessoa)}
                        />
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Typography>{pessoa.nome.toUpperCase()}</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>{pessoa.telefone}</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography>{handleCalculaTotal(pessoa.listaPedidos)}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell>Pedido id</TableCell>
                      <TableCell></TableCell>
                      <TableCell>Data</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell align="right">Quantidade</TableCell>
                      <TableCell align="right">Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pessoa.listaPedidos.map(pedido => (
                      <React.Fragment key={pedido.id}>
                        {pedido.itens.map(item => (
                          <TableRow key={item.id}>
                            <TableCell>
                              <Checkbox
                                checked={pedido.checked}
                                onChange={(event) => handleSelecionaPedidoIndividual(event, pedido, pessoa)}
                              />
                            </TableCell>
                            <TableCell>{pedido.id}</TableCell>
                            <TableCell>
                              <ListItemText
                                primary={<Typography color="textPrimary">{pedido.identificador.numero}</Typography>}
                                secondary={<Typography color="textSecondary" variant="caption">{pedido.identificador.tipo}</Typography>} />
                            </TableCell>
                            <TableCell>{Utils.formatarDataHora(item.createdAt)}</TableCell>
                            <TableCell>
                              {item.produto.nome.toUpperCase()}
                            </TableCell>
                            <TableCell align="right">{item.quantidade}</TableCell>
                            <TableCell align="right">{Utils.formatNumber(item.valorTotal)}</TableCell>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>

    </div>
  )
}

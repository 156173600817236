import React, { Component } from 'react'
import TituloPrincipal from '../Generico/TituloPrincipal'
import Template from '../Generico/Template'
import {
    Paper, Box, Grid, Button, Typography, Divider, Switch, RadioGroup,
    FormControlLabel, Radio, FormControl, IconButton, Dialog,
    DialogContent, DialogActions, Chip, TablePagination, TableRow, TableContainer, Table, TableBody, TableCell, OutlinedInput, InputAdornment, TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails
} from '@material-ui/core'
import AuthService from '../../AuthService';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import frLocale from "date-fns/locale/pt-BR";
import format from "date-fns/format";
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import withStyles from "@material-ui/styles/withStyles";
import { withSnackbar } from "notistack";
import Utils from '../../utils';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class LocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date) {
        return format(date, "dd MMM yyyy", { locale: this.locale });
    }
}

const styles = theme => ({})

export class Empresas extends Component {
    constructor(props) {
        super(props);
        let width = window.screen.width;
        this.state = {
            empresas: [],
            pesquisa: "",
            listar: "todos",
            listarBloqueio: "todosDtBloqueio",
            listaridExterno: "todosIdExterno",
            open: false,
            editEmpresa: null,
            page: 0,
            rowsPerPage: width <= 560 ? 500 : 10
        }

        this.handlePesquisa = this.handlePesquisa.bind(this)
        this.handleEnterPressionar = this.handleEnterPressionar.bind(this)
    }

    componentDidMount() {
        this.buscarEmpresas();
    }

    buscarEmpresas = () => {

        let service = new AuthService();
        service
            .get('/empresa/list')
            .then(resp => {
                this.setState({ empresas: resp })
            })
            .catch(err => {
                console.log("erro", err);
            })
    }

    handleChangeDataBloqueio = (value) => {
        let { editEmpresa } = this.state;
       
        if (value === "Invalid Date") {
            return;
        }
        if (!editEmpresa.dataBloqueio) {
            editEmpresa.dataBloqueio = "";
        }

        editEmpresa.dataBloqueio = value

        this.setState({ editEmpresa })
    }

    handleAtivar(event) {
        let { editEmpresa } = this.state;

        if (event) {
            editEmpresa.ativo = event.target.checked
            this.setState({ editEmpresa })
        } else {
            return;
        }
    }

    handlePesquisa = (event) => {
        let service = new AuthService();
        let { pesquisa, listar, listarBloqueio, listaridExterno } = this.state;
        let ativo;

        if (listar === "ativos") {
            ativo = true;
        } else if (listar === "inativos") {
            ativo = false;
        } else {
            ativo = "null";
        }

        service
            .get(`/empresa/listBusca?q=${pesquisa}&ativo=${ativo}&dtBloqueio=${listarBloqueio}&idExterno=${listaridExterno}`)
            .then(resp => {
                this.setState({ empresas: resp })
                this.setState({ page: 0 })
            })
    }

    handleRadio = (event) => {
        let { listar } = this.state;

        listar = event.target.value;
        this.setState({ listar }, () => {
            this.handlePesquisa()
        });

    }

    handleRadioBloqueio = (event) => {
        let { listarBloqueio } = this.state;

        listarBloqueio = event.target.value;
        this.setState({ listarBloqueio }, () => {
            this.handlePesquisa()
        });
    }

    handleRadioidExterno = (event) => {
        let { listaridExterno } = this.state;

        listaridExterno = event.target.value;
        this.setState({ listaridExterno }, () => {
            this.handlePesquisa()
        });
    }

    handleChangePesquisa = (event) => {
        let { pesquisa } = this.state;

        pesquisa = event.target.value;

        this.setState({ pesquisa })
    }

    handleOpenConfig = () => {
        let { open } = this.state;
        this.setState({ open: !open })
    }

    handleEditEmpresa = (empresa) => {
        let { open, editEmpresa } = this.state;

        editEmpresa = empresa;
        this.setState({ editEmpresa })

        open = true;
        this.setState({ open })
    }

    handleClose = () => {
        this.setState({ editEmpresa: null })
        this.setState({ open: false })

    }

    handleSave = () => {
        let service = new AuthService();
        let { editEmpresa } = this.state;
        service
            .post("/empresa", editEmpresa)
            .then(resp => {
                this.setState({ editEmpresa: null })
                this.setState({ open: false })
            })
            .catch(err => {
                console.log("erro", err);

            })
    }

    handleEnterPressionar = (e) => {
        if (e.key === 'Enter') {
            this.handlePesquisa()
        }
    }

    handleChangePage = (event, newPage) => {

        this.setState({ page: newPage });
    }

    handleRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
        this.setState({ page: 0 })
    }

    verificaPendencias = () => {
        let service = new AuthService();
        let { empresas, editEmpresa } = this.state
        service
            .get(`/empresa/VerificaBoleto/${editEmpresa.id}`)
            .then(resp => {

                let index = empresas.findIndex(el => el.id === editEmpresa.id);
                empresas[index] = resp;

                this.setState({ empresas })

                this.handleClose();
            })
    }

    verificaPendenciasAll = () => {
        let service = new AuthService();

        service
            .get("/empresa/VerificaBoletoAll")
            .then(resp => {
                this.setState({ empresas: resp, listarBloqueio: "todosDtBloqueio", listar: "todos" })
            })
            .catch(err => {
                console.error(err)
            })
    }

    handleChange = (event) => {
        let { editEmpresa } = this.state;

        if (event.target.name === "idExterno") {
            editEmpresa.idExterno = event.target.value;
        }
        this.setState({ editEmpresa })
    }

    handleLiberarEmpresa = () => {
        let { empresas, editEmpresa } = this.state

        var service = new AuthService();

        service
            .get(`/empresa/liberarEmpresa/${editEmpresa.id}`)
            .then(resp => {

                let index = empresas.findIndex(el => el.id === editEmpresa.id);
                empresas[index] = resp;

                this.setState({ empresas })

                this.handleClose();

            })
    }

    render() {
        const { empresas, editEmpresa, rowsPerPage, page } = this.state;

        return (
            <Template documentTitle="Lista de empresas">
                <TituloPrincipal title="Lista de empresas" subTitle="Configure as opções das empresas" />

                <Paper>
                    <Box p={3}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <OutlinedInput
                                    placeholder="Nome da empresa"
                                    fullWidth
                                    name="pesquisa"
                                    variant="outlined"
                                    onKeyPress={e => this.handleEnterPressionar(e)}
                                    onChange={e => this.handleChangePesquisa(e)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton color="primary" variant="contained" onClick={this.handlePesquisa} >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Box display="flex" justifyContent="center">
                                    <Button variant="contained" color="secondary" onClick={this.verificaPendenciasAll}>
                                        <MoneyOffIcon /> Verificar boletos vencidos
                                    </Button>
                                </Box>
                            </Grid>

                            <ExpansionPanel style={{ marginTop: "10px", width: "100%" }}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography>Filtros</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Box display="flex" flexDirection="column">
                                        <Box>
                                            <RadioGroup
                                                aria-label="ativador" onChange={this.handleRadio} name="ativar" value={this.state.listar} row style={{ marginLeft: "20px" }}>
                                                <FormControlLabel value={"todos"} control={<Radio />} label="Todos" />
                                                <FormControlLabel value={"ativos"} control={<Radio />} label="Ativo" />
                                                <FormControlLabel value={"inativos"} control={<Radio />} label="Inativo" />
                                            </RadioGroup>
                                        </Box>

                                        <Box>
                                            <RadioGroup
                                                aria-label="dataBloqueio" onChange={this.handleRadioBloqueio} name="dataBloqueio" value={this.state.listarBloqueio} row style={{ marginLeft: "20px" }}>
                                                <FormControlLabel value={"todosDtBloqueio"} control={<Radio />} label="todos" />
                                                <FormControlLabel value={"comDtBloqueio"} control={<Radio />} label="Com data de bloqueio" />
                                                <FormControlLabel value={"semDtBloqueio"} control={<Radio />} label="Sem data de bloqueio" />
                                            </RadioGroup>
                                        </Box>

                                        <Box>
                                            <RadioGroup
                                                aria-label="dataBloqueio" onChange={this.handleRadioidExterno} name="idExterno" value={this.state.listaridExterno} row style={{ marginLeft: "20px" }}>
                                                <FormControlLabel value={"todosIdExterno"} control={<Radio />} label="todos" />
                                                <FormControlLabel value={"comIdExterno"} control={<Radio />} label="Com ID Externo" />
                                                <FormControlLabel value={"semIdExterno"} control={<Radio />} label="Sem ID Externo" />
                                            </RadioGroup>
                                        </Box>
                                    </Box>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>

                        </Grid>


                    </Box>

                    <Divider />

                    <Box p={3}>
                        <TableContainer>
                            <Table size="small">
                                <TableBody>
                                    {empresas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(empresa => (
                                        <TableRow key={empresa.id} >
                                            <TableCell align="left">
                                                <Typography variant="h6">
                                                    {empresa.nome}
                                                </Typography>

                                                <Typography variant="caption">
                                                    {empresa.razaoSocial}
                                                </Typography>

                                                <Typography style={{color: "lightGray" }}>
                                                    {`ID #${empresa.id} 
                                                    ${empresa.idExterno? ' | ID externo #' + empresa.idExterno:  ''}  
                                                    ${empresa.cpfCnpj? ' | CNPJ: ' + empresa.cpfCnpj: ''} 
                                                    | Cadastro: ${empresa.createdAt} 
                                                    ${empresa.tipoConta? '| Conta: ' + empresa.tipoConta: ''}`}
                                                </Typography>
                                            </TableCell>

                                            <TableCell>
                                                {Utils.formatarDataHora(empresa.dataBloqueio)}
                                            </TableCell>

                                            <TableCell align="right">
                                                {empresa.ativo ? null : (<Chip label="Inativo" />)}
                                                <Button
                                                    startIcon={<EditIcon />}
                                                    color="primary"
                                                    onClick={() => this.handleEditEmpresa(empresa)}
                                                    variant="text"
                                                    size="medium">
                                                    Editar
                                                </Button>
                                            </TableCell>
                                        </TableRow>

                                    ))}
                                </TableBody>

                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[10, 20, 30, 100]}
                                component="div"
                                count={empresas.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={this.handleChangePage}
                                onChangeRowsPerPage={this.handleRowsPerPage} />

                        </TableContainer>



                    </Box>
                </Paper>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleOpenConfig}>
                    <DialogContent>
                        <Grid container spacing={1} alignItems="center" justify="center">
                            <Grid item xs={6} sm={6}>
                                <MuiPickersUtilsProvider locale={frLocale} utils={LocalizedUtils}>
                                    <KeyboardDatePicker
                                        clearable
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        label="Data de bloqueio"
                                        required
                                        fullWidth
                                        value={editEmpresa ? (editEmpresa.dataBloqueio ? (editEmpresa.dataBloqueio) : null) : null}
                                        onChange={event => this.handleChangeDataBloqueio(event)}
                                    />
                                </MuiPickersUtilsProvider>

                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <Button
                                    variant="contained"
                                    onClick={this.verificaPendencias}
                                    color="primary"
                                    style={{ marginRight: "10px" }}
                                    startIcon={<MonetizationOnIcon />}>
                                    Verificar pendências
                                </Button>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <TextField
                                    name="idExterno"
                                    variant="outlined"
                                    fullWidth
                                    label="IdExterno"
                                    value={editEmpresa ? editEmpresa.idExterno : ""}
                                    onChange={event => this.handleChange(event)} />
                            </Grid>

                            <Grid item xs={6} sm={6}>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Switch
                                            defaultChecked={editEmpresa ? editEmpresa.ativo : null}
                                            name="ativarEmpresa"
                                            color="secondary"
                                            onChange={event => this.handleAtivar(event, editEmpresa)}
                                        />}
                                        label={editEmpresa ? editEmpresa.ativo ? "Empresa ativa" : "Empresa inativa" : null}
                                    />
                                </FormControl>
                            </Grid>


                        </Grid>
                    </DialogContent>

                    <DialogActions>

                        {editEmpresa ? editEmpresa.tipoConta == "TEMPORARIA" || editEmpresa.tipoConta == "TEMPORARIA_EXTENDED" ? (
                            <React.Fragment>
                                <Typography style={{ color: "red" }}>
                                    Periodo de Teste
                                </Typography>
                                <Button variant="outlined" onClick={() => { this.handleLiberarEmpresa() }}>
                                    Liberar Empresa
                                </Button>
                            </React.Fragment>
                        ) : null : null}

                        <Button
                            color="primary"
                            variant="text"
                            onClick={this.handleClose}
                            startIcon={<CancelIcon />}>
                            Cancelar
                            </Button>

                        <Button
                            aria-expanded={true}
                            variant="contained"
                            color="secondary"
                            onClick={this.handleSave}
                            startIcon={<SaveIcon />}>
                            Salvar
                            </Button>
                    </DialogActions>
                </Dialog>
            </Template >
        )
    }
}


export default withSnackbar(withStyles(styles)(Empresas));
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TextField,
  Typography,
  TableRow,
  Table,
  TableContainer
} from '@material-ui/core'
import React, { Component } from 'react'
import AuthService from '../../AuthService'
import Template from '../Generico/Template'
import TituloPrincipal from '../Generico/TituloPrincipal'
import VisibilityIcon from '@material-ui/icons/Visibility';
import SearchIcon from '@material-ui/icons/Search';
import utils from "../../utils"
import { CardGenerico } from '../Dashboard/components/CardQuick';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ForList from '../Skeleton/ForBoards';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export class Clientes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clientes: [],
      buscando: false,
      pessoaInfo: null,
      pessoaInfoPedidos: [],
      page: 0,
      rowsPerPage: 10,
      totalCountPedidos: 0,
      valorBusca: ""
    }
    this.input = React.createRef();
  }

  componentDidMount = () => {
    this.getClientes();
  }

  getClientes = (valor) => {

    if (!valor) {
      valor = null
    }
    const service = new AuthService();
    this.setState({ buscando: true })

    const { rowsPerPage, page } = this.state

    let url = null

    if (valor != null) {
      url = `/pessoa/clientesComPedidos?q=${valor}&first=${rowsPerPage * page}&max=${rowsPerPage}`;
    } else {
      url = `/pessoa/clientesComPedidos?first=${rowsPerPage * page}&max=${rowsPerPage}`;
    }

    service
      .request(url)
      .then(response => {

        let totalCountPedidos = parseInt(response.headers.get('totalCount'))

        this.setState({ totalCountPedidos: totalCountPedidos }, () => {
        })

        response.json()
          .then(
            retorno => {
              this.setState({ clientes: retorno, buscando: false })
            }
          )

      })
      .catch(err => {
        this.setState({ buscando: false })
        console.error(err);
      })
  }

  iconBusca = () => {
    const { buscando } = this.state;
    if (buscando) {
      return <CircularProgress color="primary" size={20} />
    } else {
      return <IconButton onClick={() => this.keyDown("click")}><SearchIcon color="primary" /></IconButton>
    }
  }

  keyDown = (a) => {
    if (a === "click" || a.key === 'Enter') {
      var valorPesquisa = this.input.current.children[1].children[0].value
      this.setState({ valorBusca: valorPesquisa })
      this.getClientes(valorPesquisa)
    }
  }

  handleOpenDialog = (pessoa) => {
    this.setState({ pessoaInfo: pessoa })
    this.getClientes(pessoa);
  }

  handleCloseDialog = () => {
    this.setState({ pessoaInfo: null })
  }

  getPedidos = (pessoa) => {
    let service = new AuthService();
    this.setState({ carregandoPedidos: true })
    service
      .get(`/pedido/listPedidosPorPessoa/${pessoa.id}`)
      .then(resp => {
        this.setState({ pessoaInfoPedidos: resp, carregandoPedidos: false })
      })
      .then(err => {
        console.error(err)
      })

  }

  calculaValorTotal = () => {
    let { pessoaInfoPedidos } = this.state;

    var valorTotal = 0;
    pessoaInfoPedidos.map(pedido => {
      valorTotal += pedido.valorTotal;
    })

    return valorTotal;
  }

  handleChangePage = (event, newPage) => {
    let valorBusca = this.state.valorBusca

    this.setState({ page: newPage }, () => {
      this.getClientes(valorBusca)
    })
  }

  handleRowsPerPage = (event) => {
    let { rowsPerPage } = this.state
    rowsPerPage = event.target.value

    let valorBusca = this.state.valorBusca

    this.setState({ rowsPerPage }, () => {
      this.getClientes(valorBusca)
    })
  }

  render() {
    var { clientes, pessoaInfo, buscando, page, rowsPerPage } = this.state;
    return (
      <Template>
        <TituloPrincipal title="Clientes" />

        <Paper>
          <Box >
            <Box p={2}>
            
            
            <Grid container>
              <Grid item xs={12} sm={4}>
                <TextField
                  ref={this.input}
                  variant="outlined"
                  fullWidth
                  style={{ minWidth: "200px" }}
                  label={"Nome ou Telefone"}
                  name="pesquisa" 
                  onKeyPress={(a) => this.keyDown(a)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {this.iconBusca()}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            </Box>

            <TableContainer>
            <Table>
              <TableHead >
                <TableRow>
                  <TableCell align='left'><strong>Nome</strong></TableCell>
                  <TableCell align='left'><strong>Telefone</strong></TableCell>
                  <TableCell align='center'><strong>Detalhes</strong></TableCell>
                </TableRow>
              </TableHead>


              {buscando ? (
                <TableBody>
                  <TableRow >
                    <TableCell colSpan={3}>
                      <ForList />
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : null}


              {clientes.map(cliente => (
                <TableBody key={cliente.id}>
                  <TableRow>
                    <TableCell component="th" scope="row" >
                      <Typography variant="h6" color="primary">{cliente.nome}</Typography>
                      <Typography style={{ opacity: "0.6", fontSize: "15px" }}>#{cliente.id}</Typography>
                    </TableCell>

                    <TableCell>
                      <Typography variant="body1" color="primary">{cliente.telefone}</Typography>
                    </TableCell>

                    <TableCell align='center'>
                      <IconButton onClick={() => this.handleOpenDialog(cliente)}>
                        <VisibilityIcon color="primary" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
            </TableContainer>
          </Box>
          <Box>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              labelRowsPerPage="Linhas por página"
              count={this.state.totalCountPedidos}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={e => this.handleRowsPerPage(e)} />
          </Box>
        </Paper>

        {
          pessoaInfo ? (
            <Dialog open={pessoaInfo != null} onClose={this.handleCloseDialog} maxWidth="md" fullWidth>
              <DialogTitle>
                <Typography align="center" variant="h4">
                  {pessoaInfo.nome}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container spacing={3} style={{ overflow: "hidden" }}>
                  <Grid item xs={12} sm={4}>
                    <CardGenerico
                      key='TOTAL_PEDIDOS'
                      color="success"
                      url={`/pedido/informacoes/TOTAL_PEDIDOS/${pessoaInfo.id}`}
                      icon={<ReceiptIcon />}
                      maskTypeValue="int"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CardGenerico
                      key='VALOR_PEDIDOS'
                      color="rose"
                      url={`/pedido/informacoes/VALOR_PEDIDOS/${pessoaInfo.id}`}
                      icon={<MonetizationOnIcon />}
                      maskTypeValue="double"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <CardGenerico
                      key='DATA_ULTIMO_PEDIDO'
                      color="warning"
                      url={`/pedido/informacoes/DATA_ULTIMO_PEDIDO/${pessoaInfo.id}`}
                      icon={<CalendarTodayIcon />}
                      maskTypeValue="data"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </Dialog>
          ) : null
        }

      </Template >
    )
  }
}

export default Clientes

import {
  Card,
  Box,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText
} from '@material-ui/core'
import React, { Component } from 'react'

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import Logo from "../../imagens/LogoVersao2Azul.svg";
import AuthService from "../../AuthService";

import Fundo from "../../imagens/fundo-qrpedir-min.png"

const styles = theme => ({})

class AlterarSenha extends Component {

  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmarSenha: "",
      confirmacao: false,
      errorMessages: {
        confirmarSenhaError: ""
      },
      token: "",
      nome: ""
    }
  }

  handleChangeSenha = (event) => {
    this.setState({ password: event.target.value })
  }
  handleChangeConfirmarSenha = (event) => {
    this.setState({ confirmarSenha: event.target.value })
  }

  handleClose = () => {
    this.setState({ confirmacao: false })
  }

  handleHookToken = () => {
    const { token } = this.props.match.params

    let service = new AuthService();

    service
      .get(`/usuario/getToken?token=${token}`)
      .then(retorno => {

        console.log("Token existente");
        this.setState({ token: true, nome: retorno.username })

      }).catch(err => {
        console.error(err);
        this.setState({ token: false })
      })
  }

  handleSalvarSenha = () => {
    const { password, confirmarSenha, errorMessages, nome } = this.state

    const { token } = this.props.match.params

    if (password === "" || confirmarSenha === "") {
      this.setState({ errorMessages: { confirmarSenhaError: "As senhas não podem ficar em branco" } })
      return
    }

    if (password !== confirmarSenha) {
      this.setState({ ...errorMessages, confirmarSenhaError: "As senhas são diferentes" })

      return
    }



    if (password !== "" && password === confirmarSenha) {
      const md5 = require('js-md5');

      var senha = md5(password);
      console.log(" senha: " + senha);

      this.setState({ erro: false })
    }

    let service = new AuthService();

    service
      .post(`/usuario/changePassword`, { password: senha, token: token, username: nome })
      .then(retorno => {

        console.log("Senha alterada com sucesso");
        this.props.enqueueSnackbar(`Senha alterada com sucesso`, { variant: 'success' })

        this.setState({ confirmacao: true })

      }).catch(err => {
        console.error(err)
        this.props.enqueueSnackbar(`Falha ao alterar a senha, tente novamente ou tente mais tarde`, { variant: 'error' })
      })
  }

  render() {
    const { confirmacao, errorMessages, token } = this.state

    const styles = {
      fundo: {
        height: "100vh",
        background: `url(${Fundo}) repeat`
      }
    }

    document.title = 'QR Pedir - Alterar senha'
    return (
      <div>

        {token === "" ? this.handleHookToken() : null}

        <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" style={{ height: "800px" }}>
            <img style={{ width: "30%" }} alt="logo" src={Logo} />

            <Card style={{ padding: "2vw", paddingBottom: '20px', width: "30vw", minWidth: "300px", maxWidth: "450px", maxHeight: "500px" }}>
              <Box display="flex" flexDirection="column" alignItems="center">

                <Typography variant="h5">
                  Alterar senha!
                </Typography>

                {token === true ? (
                  <>

                    <Box display='flex' flexDirection='colum' justifyContent='center' style={{marginTop: "20px"}}>
                      <TextField
                        label='Insira sua nova senha'
                        type='password'
                        variant='outlined'
                        style={{ width: '400px', marginBottom: '10px' }}
                        onChange={event => this.handleChangeSenha(event)}
                        inputProps={{ maxLength: 15 }}
                      />
                    </Box>


                    <Box>
                      <TextField
                        label='Confirme sua nova senha'
                        type='password'
                        error={errorMessages.confirmarSenhaError}
                        helperText={errorMessages.confirmarSenhaError}
                        variant='outlined'
                        style={{ width: '400px', marginTop: '10px' }}
                        onChange={event => this.handleChangeConfirmarSenha(event)}
                        inputProps={{ maxLength: 15 }}
                      />
                    </Box>

                    <Box>
                      <Button
                        color='primary'
                        variant='contained'
                        style={{ margin: '40px 0px' }}
                        onClick={this.handleSalvarSenha}
                      >Alterar</Button>
                    </Box>

                  </>
                ) : <>
                    <Dialog
                      open={true}
                      maxWidth='xs'
                      fullWidth
                      align='center'
                    >

                      <DialogTitle>Token inválido</DialogTitle>

                      <DialogContentText>
                        O token informado está incorreto ou já foi usado. Para gerar um novo token clique no botão abaixo.
                </DialogContentText>

                      <Box display='flex' justifyContent='center'>
                        <Button
                          color='primary'
                          variant='contained'
                          style={{ margin: '10px', width: '50%' }}
                          aling
                          onClick={() => { window.open('/RecuperarSenha', '_parent') }}>
                          Recuperar senha
                  </Button>
                      </Box>

                    </Dialog>
                  </>
                }
              </Box>
            </Card>

            <Dialog
              open={confirmacao}
              onClose={this.handleClose}
              maxWidth='xs'
              fullWidth
              align='center'
            >

              <DialogTitle>Senha alterada com sucesso</DialogTitle>

              <DialogContentText>
                Para entrar na sua conta, vá para a tela inicial.
              </DialogContentText>

              <Box display='flex' justifyContent='center'>
                <Button
                  color='primary'
                  variant='contained'
                  style={{ margin: '10px', width: '50%' }}
                  aling
                  onClick={() => { window.open('/login', '_parent') }}>
                  Tela inicial
              </Button>
              </Box>

            </Dialog>

          </Box>
        </Box>
      </div>
    )
  }
}

export default withSnackbar(withStyles(styles)(AlterarSenha));

export const SITUACAO_PEDIDO = {
    AGUARDANDO_CONFIRMACAO_PGTO: "AGUARDANDO PAGAMENTO",
    PENDENTE: "PENDENTE", 
    CONFIRMADO: "CONFIRMADO", 
    ENVIADO: "ENVIADO", 
    PRONTO_BUSCAR: "ENVIADO",
    ENTREGUE: "ENTREGUE", 
    CANCELADO: "CANCELADO"
}

export const SITUACAO_PEDIDO_COR = {
    AGUARDANDO_CONFIRMACAO_PGTO: "#7e57c2",
    PENDENTE: "#ffc107", 
    CONFIRMADO: "#4caf50", 
    ENVIADO: "#795548", 
    ENTREGUE: "#bdbdbd", 
    CANCELADO: "#ef5350"
}
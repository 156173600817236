import { Box, Button, Grid, TextField, Typography, Paper, MenuItem, InputAdornment, IconButton } from '@material-ui/core'
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import MaskedInput from 'react-text-mask';
import { Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React from 'react'
import Utils from '../../../utils';

const TextMaskCep = (props) => {
    const { inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
            placeholderChar={'\u2000'}
            placeholder="xxxxx-xxx"
        />
    );
}

export default function DadosPlano(props) {
    const [errorPlano, setErrorPlano] = React.useState({ endereco: null, numero: null, cep: null, bairro: null, estado: null, municipio: null });

    const [disabled, setDisabled] = React.useState(true)
    const sending = props.sending || false
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        props.carregarEstados();
    }, [])

    const validaStep = () => {
        var error = false;
        var erros = { endereco: null, numero: null, cep: null, bairro: null, estado: null, municipio: null };

        //if (props.isDelivery) {
        if (!props.endereco.endereco) {
            erros.endereco = "O endereço não pode estar em branco";
            error = true;
        }
        if (!props.endereco.numero) {
            erros.numero = "O numero não pode estar em branco";
            error = true;
        }

        if (!props.endereco.bairro) {
            erros.bairro = "O bairro não pode estar em branco";
            error = true;
        }

        if (!props.endereco.estado) {
            erros.estado = "O estado não pode estar em branco";
            error = true;
        }

        if (!props.endereco.municipio) {
            erros.municipio = "O municipio não pode estar em branco";
            error = true;
        }

        if (!props.endereco.cep) {
            erros.cep = "O cep não pode estar em branco";
            error = true;
        }
        if (Utils.onlyDigits(props.endereco.cep).length < 8) {
            erros.cep = "O cep deve ter 8 digitos. Ex.: (99999999)";
            error = true;
        }

        //}

        setErrorPlano(erros);

        if (error) {
            return;
        }

        props.handleCreateEmpresa();
    }

    const handleFormataEstado = (newValue) => {
        let estadoFormatado = { value: newValue.props.value, label: newValue.props.children }

        props.handleChangeEstado(estadoFormatado)

    }

    /*
    * Utilizado para busca do cep automaticamente
    */
    const handleSearchByCep = (event) => {

        props.handleChangeEndereco(event)

        setDisabled(true)

        if (Utils.onlyDigits(event.target.value).length > 7) {

            let cep = event.target.value

            fetch(`https://viacep.com.br/ws/${cep}/json/`)
                .then(response => {

                    return response.json().then((result) => {
                        if (result.erro) {
                            enqueueSnackbar("CEP não encontrado", { variant: 'warning' });
                            return;
                        }

                        let newEndereco = props.endereco

                        newEndereco.endereco = result.logradouro
                        newEndereco.bairro = result.bairro
                        newEndereco.complemento = result.complemento

                        if (result.logradouro === "" || result.bairro === "") {
                            setDisabled(false)
                            enqueueSnackbar("Este CEP não possui endereço associado, por favor informe-o.", { variant: 'warning' });
                        }

                        props.handleUpdateEndereco(newEndereco)
                    })

                }).catch(err => {
                    console.error(err)
                    enqueueSnackbar("Nada encontrado para o CEP informado.", { variant: 'warning' });
                })

        }
    }

    /*
    * Utilizado para busca do cep via botão de pesquisa
    */
    const handleClickSearchByCep = () => {
        const testCep = props.endereco.cep

        setDisabled(true)

        if (Utils.onlyDigits(testCep).length > 7) {

            fetch(`https://viacep.com.br/ws/${testCep}/json/`)
                .then(response => {

                    return response.json().then((result) => {
                        if (result.erro) {
                            enqueueSnackbar("CEP não encontrado", { variant: 'warning' });
                            return;
                        }

                        let newEndereco = props.endereco

                        newEndereco.endereco = result.logradouro
                        newEndereco.bairro = result.bairro
                        newEndereco.complemento = result.complemento

                        if (result.logradouro === "" || result.bairro === "") {
                            setDisabled(false)
                            enqueueSnackbar("Este CEP não possui endereço associado, por favor informe-o.", { variant: 'warning' });
                        }

                        props.handleUpdateEndereco(newEndereco)
                    })

                }).catch(err => {
                    console.error(err)
                    enqueueSnackbar("Nada encontrado para o CEP informado.", { variant: 'warning' });
                })

        } else {
            enqueueSnackbar("O cep informado não corresponde a um endereço válido", { variant: 'error' });
        }
    }

    return (
        <Paper>
            <Box px={4} py={2} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <Typography align="center" variant="h5" style={{ margin: "10px 0" }}>
                    Endereço do seu estabelecimento
                </Typography>
                <Grid container spacing={1}>

                    <Grid item xs={12}>
                        <TextField
                            select
                            fullWidth
                            style={{ margin: '10px 10px 0 0' }}
                            label="Estado"
                            error={errorPlano.estado != null}
                            helperText={errorPlano.estado}
                            defaultValue={props.estado || ""}
                            onChange={(event, newValue) => handleFormataEstado(newValue)}
                            variant="outlined"
                        >
                            {props.estados?.map(estado => (
                                <MenuItem key={estado.value} value={estado.value}>{estado.label}</MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    {/*                     <Grid item xs={12}>
                        <Autocomplete
                            options={props.estados}
                            getOptionLabel={(option) => option.label}
                            fullWidth
                            style={{ margin: '10px 10px 0 0' }}
                            value={props.estado}
                            renderInput={(params) =>
                                <TextField {...params} 
                                error={errorPlano.estado != null}
                                helperText={errorPlano.estado}
                                label="Estado" 
                                variant="outlined" />}
                            onChange={(event, newValue) => props.handleChangeEstado(newValue)}
                        />
                    </Grid> */}

                    {props.endereco.estado ? (
                        <Grid item xs={12}>
                            <Autocomplete
                                options={props.municipios}
                                getOptionLabel={(option) => option.label}
                                fullWidth
                                style={{ margin: '10px 10px 0 0' }}
                                value={props.municipio}
                                renderInput={(params) =>
                                    <TextField {...params}
                                        error={errorPlano.municipio != null}
                                        helperText={errorPlano.municipio}
                                        label="Municipio"
                                        variant="outlined" />}
                                onChange={(event, newValue) => props.handleChangeMunicipio(newValue)}
                            />
                        </Grid>
                    ) : null}

                    <Grid item xs={8}>
                        <TextField
                            label="Cep"
                            style={{ margin: '10px 10px 0 0' }}
                            value={props.endereco.cep}
                            error={errorPlano.cep != null}
                            helperText={errorPlano.cep}
                            name="cep"
                            variant="outlined"
                            fullWidth
                            onChange={event => handleSearchByCep(event)}
                            InputProps={{
                                inputComponent: TextMaskCep,
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleClickSearchByCep} size="small"><FindReplaceIcon /></IconButton>
                                    </InputAdornment>
                            }}
                        />

                    </Grid>

                    <Grid item xs={4}>
                        <TextField
                            label="Número"
                            error={errorPlano.numero != null}
                            helperText={errorPlano.numero}
                            style={{ margin: '10px 10px 0 0' }}
                            value={props.endereco.numero}
                            name="numero"
                            variant="outlined"
                            fullWidth
                            onChange={event => props.handleChangeEndereco(event)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Endereço"
                            disabled={disabled}
                            error={errorPlano.endereco != null}
                            helperText={errorPlano.endereco}
                            style={{ margin: '10px 10px 0 0' }}
                            value={props.endereco.endereco}
                            name="endereco"
                            variant="outlined"
                            fullWidth
                            onChange={event => props.handleChangeEndereco(event)} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Bairro"
                            disabled={disabled}
                            error={errorPlano.bairro != null}
                            helperText={errorPlano.bairro}
                            style={{ margin: '10px 10px 0 0' }}
                            value={props.endereco.bairro}
                            name="bairro"
                            variant="outlined"
                            fullWidth
                            onChange={event => props.handleChangeEndereco(event)} />
                    </Grid>
                </Grid>

                <Box style={{ marginTop: "20px", width: "100%" }} display="flex" justifyContent="space-between">
                    <Button
                        onClick={() => props.handleChangeStep("-")}
                        variant="text">
                        Retornar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={validaStep}
                        disabled={sending}>
                        {sending ? 'Finalizando...' : 'Finalizar'}
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

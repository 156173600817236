import { Box, Button, TextField, Typography, Card, InputAdornment, Avatar, Link, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import React, { Component } from 'react'
import Logo from "../../../imagens/logo-azul.svg";
import Fundo from "../../../imagens/fundo-qrpedir-min.png"
import AuthService from '../../../AuthService';
import { hotjar } from 'react-hotjar';

export class ConfigNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            unhashedPassword: "",
            uuidIdentificador: "",
            open: false,
            usuarioAdmin: null,
            errosConfig: { unhashedPassword: null, uuidIndentificador: null }
        }
    }

    componentWillMount() {
        this.handleVerificaUsuarioAdm()
    }

    componentDidMount = () => {
        var service = new AuthService();
        service.logout();

        const { token } = this.props.match.params

        service
            .get(`/usuario/getToken?token=${token}`)
            .then(resp => {
                this.setState({ user: resp })
            })
            .catch(err => {
                console.error(err);
            })
    }

    handleChange = (event, type) => {
        let { uuidIdentificador, unhashedPassword } = this.state;

        if (type == "senha") {
            unhashedPassword = event.target.value;
            this.setState({ unhashedPassword })
        } else if (type == "identificador") {
            uuidIdentificador = event.target.value.replace(/[^\w\s]/gi, "")

            if (uuidIdentificador.match(/\ /)) {
                uuidIdentificador = uuidIdentificador.replace(/\ /, '-');
            }

            //uuidIdentificador = uuidIdentificador;

            this.setState({ uuidIdentificador })
        }

    }

    uploadFileServer(file) {
        const cloudName = 'parseint';
        const unsignedUploadPreset = 'upload-qrpedir';

        var url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`;
        var xhr = new XMLHttpRequest();
        var fd = new FormData();
        xhr.open('POST', url, true);
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');


        let progressStatus = "0%"

        // Update progress (can be used to show progress indicator)
        xhr.upload.addEventListener("progress", function (e) {
            var progress = Math.round((e.loaded * 100.0) / e.total);
            progressStatus = progress + "%";
            console.log(`fileuploadprogress data.loaded: ${e.loaded}, data.total: ${e.total}`);
        });

        var { user } = this.state;

        const onCompleteUpload = response => {
            var url = response.secure_url;
            user.empresa.urlLogo = url
            this.setState({ user })
            console.log(user);
        }

        xhr.onreadystatechange = function (e) {
            if (xhr.readyState == 4 && xhr.status == 200) {
                // File uploaded successfully
                var response = JSON.parse(xhr.responseText);

                onCompleteUpload(response)

                //var img = new Image(); // HTML5 Constructor
                //img.src = url;
                //img.alt = response.public_id;
                //document.getElementById('logo-container').appendChild(img);
            }
        };
        fd.append('upload_preset', unsignedUploadPreset);
        fd.append('tags', 'browser_upload'); // Optional - add tag for image admin in Cloudinary
        fd.append('file', file);
        xhr.send(fd);
    }

    handleVerificaUsuarioAdm = () => {
        let profile = localStorage.getItem("_profile")

        if (profile) {
            this.setState({ usuarioAdmin: JSON.parse(profile) })
        }
    }

    validaIdentificador(erros, error) {
        var { user, unhashedPassword, uuidIdentificador, usuarioAdmin } = this.state;

        const userIsAdm = usuarioAdmin?.admin ? true : false
        const md5 = require('js-md5');
        let service = new AuthService();
        service
            .get(`/identificador/validaUrlDelivery/${uuidIdentificador}`)
            .then(resp => {
                if (resp.value == "IDENTIFICADOR_EXISTENTE") {
                    erros.uuidIndentificador = "Endereço digital ja está sendo utilizado";
                    error = true;
                    this.setState({ errosConfig: erros })
                    if (error) {
                        return;
                    }
                } else {
                    this.setState({ errosConfig: erros })
                    if (error) {
                        return;
                    }
                    user.password = md5(unhashedPassword)

                    service
                        .post(`/usuario/changePassword`, user)
                        .then(resp => {
                            var token = resp.token;
                            console.log(resp.token);

                            service
                                .post(`/identificador/changeUuidForNewUser/${user.empresa.id}/${uuidIdentificador}`)
                                .then(response => {

                                })
                                .catch(error => {
                                    console.error(error);
                                })

                            var hoje = new Date();

                            if (!userIsAdm) {
                                user.empresa.dataBloqueio = new Date(hoje.setDate(hoje.getDate() + 15));
                            }
                            user.empresa.configuracaoAbertura = "SEMPRE_ABERTO";
                            user.empresa.ativo = true;

                            var options = {
                                method: 'POST',
                                body: JSON.stringify(user.empresa),
                                headers: {
                                    "Authorization": "Bearer " + token,
                                    "Accept": "application/json",
                                    "Content-Type": "application/json; charset=utf-8"
                                }
                            }

                            console.log(options);

                            service
                                .myFetch(`/empresa`, options)
                                .then(empresa => {

                                    this.setState({ open: true })

                                }).catch(err => {
                                    console.error(err)
                                })

                            this.setState({ user: resp })
                        }).catch(err => {
                            console.error(err);
                        })
                }


            })
    }

    handleSave = () => {
        var { user, unhashedPassword, uuidIdentificador } = this.state;


        var service = new AuthService();
        var error = false;
        var erros = { unhashedPassword: null, uuidIndentificador: null };

        if (!unhashedPassword) {
            erros.unhashedPassword = "É necessario escolher uma senha";
            error = true;
        }
        if (!uuidIdentificador) {
            erros.uuidIndentificador = "É necessario escolher um endereco digital para seu cardapio";
            error = true;
        } else if (uuidIdentificador) {

        }

        this.validaIdentificador(erros, error);
    }

    logar = () => {
        let service = new AuthService();
        var { user } = this.state;
        service
            .login(user.username, user.password)
            .then(res => {
                hotjar.initialize(2227724, 6);
                this.props.history.replace("/Cardapio");
            })
            .catch(err => {
                console.error(err);
            });
    }

    render() {
        const { user, unhashedPassword, uuidIdentificador, errosConfig } = this.state;
        var empresa = user ? user.empresa : null;
        const styles = {
            fundo: {
                height: "100vh",
                background: `url(${Fundo}) repeat`
            }
        }

        return (
            <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" style={{ height: "800px" }}>
                    <Box display="flex" justifyContent="center" p={4} mt={6}>
                        <img style={{ width: "15vw", minWidth: "200px" }} alt="logo" src={Logo} />
                    </Box>
                    <Box flexDirection="row" display="flex" justifyContent="center">
                        <Box display="block">
                            <input type="file" id="fileElem" accept="image/*" style={{ display: 'none' }} onChange={event => this.uploadFileServer(event.target.files[0])} />
                        </Box>
                    </Box>
                    <Card style={{ padding: "2vw", width: "30vw", minWidth: "300px", maxWidth: "450px", maxHeight: "500px" }}>
                        <Box style={{ height: '100%' }} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                            {user ? (
                                <React.Fragment>
                                    <Typography align="center" variant="h5" style={{ margin: "10px 0" }}>
                                        Cadastro de empresa
                                    </Typography>
                                    <TextField
                                        label="Nova Senha"
                                        type="password"
                                        error={errosConfig.unhashedPassword != null}
                                        helperText={errosConfig.unhashedPassword}
                                        style={{ margin: '10px' }}
                                        name="unhashedPassword"
                                        value={unhashedPassword}
                                        onChange={e => this.handleChange(e, "senha")}
                                        variant="outlined"
                                        fullWidth />

                                    {empresa ? (
                                        <React.Fragment>
                                            <TextField
                                                variant="outlined"
                                                label="Link do cardápio"
                                                error={errosConfig.uuidIndentificador != null}
                                                helperText={errosConfig.uuidIndentificador}
                                                placeholder="EX.: Nome-Empresa"
                                                name="uuidIdentificador"
                                                value={uuidIdentificador}
                                                onChange={e => this.handleChange(e, "identificador")}
                                                fullWidth
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="start">.qrpedir.com</InputAdornment>,
                                                }}
                                            />
                                            {empresa.urlLogo ? (
                                                <>
                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                                                        <Avatar variant={'circle'} alt='Logo da empresa' src={empresa.urlLogo} style={{ width: '120px', height: '120px', marginTop: "20px" }} />
                                                    </Box>

                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                                                        <Link href='#' styles={{ fontSize: '18px' }} onClick={event => document.getElementById("fileElem").click()}>
                                                            Alterar logo
                                                        </Link>
                                                    </Box>

                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                                                        <Link href='#' styles={{ fontSize: '18px' }} onClick={event => { empresa.urlLogo = null; this.setState({ empresa }); return false }}>
                                                            Remover logo
                                                        </Link>
                                                    </Box>
                                                </>
                                            ) : null}

                                            {empresa.urlLogo == null ? (
                                                <>
                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                                                        <Avatar variant={"circle"} alt='Logo da empresa' src={empresa.urlLogo} style={{ width: "120px", height: '120px', marginTop: "20px" }} />
                                                    </Box>

                                                    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
                                                        <Link href='#' styles={{ fontSize: '18px' }} onClick={event => document.getElementById("fileElem").click()}>
                                                            Alterar logo
                                                        </Link>
                                                    </Box>
                                                </>
                                            ) : true}
                                        </React.Fragment>
                                    ) : null}
                                    <Box display="flex" justifyContent="flex-end" style={{ marginTop: "20px", width: "100%" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={this.handleSave}>
                                            Finalizar
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Box display="flex" justifyContent="center" flexDirection="column">
                                        <Typography style={{ fontSize: "150%" }} align="center">
                                            Ops, parece que o token foi expirado, ou é invalido.
                                        </Typography>
                                        <Button onClick={() => window.open("https://qrpedir.com/suporte", "_blank")} style={{ marginTop: "40px" }}>
                                            Entrar em contato com nossa equipe
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}
                            <Dialog open={this.state.open}>
                                <DialogTitle>
                                    <Typography>
                                        Seu cadastro foi realizado com sucesso.
                                    </Typography>
                                </DialogTitle>
                                <DialogContent>
                                    <Box display='flex' justifyContent="center">
                                        <Button variant="contained" color="primary" onClick={this.logar}>
                                            Clique aqui para continuar
                                        </Button>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </Card>
                </Box>
            </Box>
        )
    }
}

export default ConfigNewUser

import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/styles/withStyles";
import AuthService from "../../../AuthService";
import { withSnackbar } from "notistack";
import DeleteIcon from "@material-ui/icons/Delete";

import {
  Button,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  TextField,
  DialogTitle,
} from "@material-ui/core";

const styles = theme => ({

});

class CadastroCardapio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      grupoObject: "",
      grupo: {
        tipoGrupoProduto: {},
        ativo: true,
        pausado: true
      },
      optionsTipoGrupo: [],
      idTipoGrupo: null,
      erros: { nomeGrupo: null }
    };
    this.listUpdate = props.listUpdate
    this.handleChange = this.handleChange.bind(this);
    this.handleUpdateGrupo = this.props.handleUpdateGrupo;
    this.handleListGrupos = this.props.handleListGrupos;
  }

  createNewGrupo() {

    let grupo = {
      key: Math.floor(Math.random() * 1000),
      tipoGrupoProduto: {},
      ativo: true,
      pausado: true
    };
    let groupObject = {
      grupoProduto: grupo,
      produtos: []
    }
    return groupObject;
  }

  async componentDidMount() {
    this.handleList();
  }

  update(group, event) {
    event.stopPropagation();

    let { grupoObject, grupo, idTipoGrupo } = this.state;

    grupoObject = group;
    grupo = group.grupoProduto;
    idTipoGrupo = group.grupoProduto.tipoGrupoProduto.id;

    console.log(group);

    this.setState({ grupoObject })
    this.setState({ grupo });
    this.setState({ idTipoGrupo });

    console.log(this.state.grupoObject);
    console.log(this.state.grupo);
    console.log(this.state.idTipoGrupo);

    this.handleToggle();
  }

  handleList() {
    let service = new AuthService();
    service
      .get("/tipoGrupoProduto/list")
      .then(retorno => {
        this.setState({ optionsTipoGrupo: retorno });
      })
      .catch(err => {
        console.error(err);
      });
  }

  handleDelete(grupo) {

    let service = new AuthService();
    service
      .delete(`/grupoProduto/inativar/${grupo.id}`)
      .then(retorno => {
        console.log(retorno.id);

        let grupos = this.props.grupos;
        console.log(retorno);

        let index = grupos.findIndex(el => el.grupoProduto.id === retorno.id)

        grupos[index].grupoProduto = retorno;

        this.handleUpdateGrupo(grupos)

        this.props.enqueueSnackbar("Grupo inativado com sucesso", {
          variant: "info"
        });
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar("Falha ao tentar deletar o grupo", {
            variant: "error"
          });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar(
              "Falha ao deletar grupo " + elem.errorMessage,
              { variant: "error" }
            );
          });
        }
      });
    this.handleToggle();
  }

  //responsável por salvar os dados do produto, setando no
  //state os campos dentro do array
  handleChange(event) {
    const tempGrupo = this.state.grupo;
    tempGrupo[event.target.name] = event.target.value;
    this.setState({ grupo: tempGrupo });
  }

  handleChangeTipoGrupoProduto(event) {
    const tempGrupo = this.state.grupo;
    let tipoGrupoProduto = { id: event.target.value };
    if (event.target.value === "") {
      tipoGrupoProduto = null;
      this.setState({ idTipoGrupo: null });
    } else {
      this.setState({ idTipoGrupo: tipoGrupoProduto.id });
    }
    tempGrupo.tipoGrupoProduto = tipoGrupoProduto;
    this.setState({ grupo: tempGrupo });
    console.log(this.state);
  }

  //handle submit salva os dados do state mandando para a API
  handleSubmit(event) {

    const { grupo, erros } = this.state;
    if (grupo.descricao === null || grupo.descricao === "") {
      this.props.enqueueSnackbar("Preencha os campos requeridos", {
        variant: "warning"
      });
      erros.nomeGrupo = "o nome do grupo é obigatório!";
      this.setState({ erros })
      return;
    }

    if (!grupo.tipoGrupoProduto.id) {
      grupo.tipoGrupoProduto.id = 23
    }

    let service = new AuthService();
    service
      .post("/grupoProduto", grupo)
      .then(retorno => {

        if (erros.nomeGrupo) {
          erros.nomeGrupo = null;
          this.setState({ erros })
        }

        this.props.enqueueSnackbar("Grupo salvo com sucesso ", {
          variant: "success"
        });

        this.handleListGrupos();

        this.handleToggle();
      })
      .catch(err => {
        this.handleToggle();
        if (err.response === undefined) {
          this.props.enqueueSnackbar("Falha ao tentar salvar grupo", {
            variant: "error"
          });
        } else {
          err.response.json().then(elem => {
            console.log(elem);
            this.props.enqueueSnackbar(
              "Falha ao salvar grupo " + elem.errorMessage,
              { variant: "error" }
            );
          });
        }
      });
  }

  novoGrupoProduto = (event) => {
    this.update(this.createNewGrupo(), event);
    this.handleToggle();
  };

  //responsável por fazer a abertura da tela
  handleToggle = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const { open, grupo, optionsTipoGrupo, idTipoGrupo, erros } = this.state;

    return (
      <Fragment>

        <Dialog
          open={open}
          onClose={this.handleToggle}>

          <DialogTitle>
            Cadastro de grupos
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <TextField
                  variant="outlined"
                  label={"Nome do grupo"}
                  name="descricao"
                  required
                  error={erros.nomeGrupo}
                  helperText={erros.nomeGrupo}
                  fullWidth
                  value={grupo.descricao}
                  onChange={event => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={"Ordem"}
                  name="ordem"
                  value={grupo.ordem || 0}
                  onChange={event => this.handleChange(event)}
                />
              </Grid>
              <Grid item xs={12} sm={12} >
                <TextField
                  select
                  label="Tipo do grupo"
                  fullWidth
                  variant="outlined"
                  value={idTipoGrupo || 23}
                  onChange={event => this.handleChangeTipoGrupoProduto(event)}
                  inputProps={{
                    name: "tipoGrupoProduto",
                    id: "tipo-do-grupo"
                  }}
                >
                  {optionsTipoGrupo.map(tipo => (
                    <MenuItem key={tipo.id} value={tipo.id}>
                      <ListItem style={{ padding: "0" }} >
                        <ListItemIcon>
                          <img alt='icone-grupo' style={{ maxWidth: "32px" }} src={`https://app.qrpedir.com/assets/images/iconsLinesColor/${tipo.imagem}`}></img>
                        </ListItemIcon>
                        <ListItemText primary={tipo.descricao} />
                      </ListItem>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              disabled={!grupo.id}
              variant="text"
              startIcon={<DeleteIcon />}
              color="default"
              onClick={() => this.handleDelete(grupo)}>
              EXCLUIR
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={e => this.handleSubmit(e)}>
              SALVAR
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withSnackbar(withStyles(styles)(CadastroCardapio));

import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/styles/withStyles";
import AuthService from "../../../AuthService";
import { withSnackbar } from "notistack";
import Utils from '../../../utils';
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import CompCadastroImagem from "./compCadastroImagem"
import CloseIcon from '@material-ui/icons/Close';

import {
  Button,
  Box,
  Dialog,
  MenuItem,
  DialogActions,
  DialogContent,
  ListItem,
  ListItemText,
  ExpansionPanelSummary,
  Typography,
  FormControlLabel,
  Switch,
  List,
  Grid,
  TextField,
  ExpansionPanel,
  ExpansionPanelDetails,
  Divider,
  DialogTitle,
  withWidth
} from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';


const styles = theme => ({
  dialog: {
    // minHeight: '65vh',
    // maxHeight: '65vh',
    minHeight: '60vh',
    maxHeight: '95vh',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// const novoProduto = {
//   id: null,
//   descricao: '',
//   fichaTecnica: '',
//   localImpressao: '',
//   nome: '',
//   pausado: false,
//   preco: 0.0,
//   grupoProduto: { id: 0 },
//   complementos: [],
//   ordem: 100,
//   ativo: true,
//   advertencia: "",
//   codigoExterno: ""
// }

class CadastroProduto extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produto: props.produto,
      complemento: null,
      compTamanho: null,
      grupos: [],
      locaisNotificacoes: [],
      grupoAnterior: null,
      hideButton: false,
      erros: { nomeProduto: null, localImpressao: null, descricaoComplemento: null, nomeItemComplemento: null, tamanho: null }
    };

    // this.produto = props.produto === null ? novoProduto : props.produto
    this.handleChange = this.handleChange.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleAddChange = this.handleAddChange.bind(this);
    this.handleChangeComplementoCheck = this.handleChangeComplementoCheck.bind(this);
    this.handleChangeComplementoItem = this.handleChangeComplementoItem.bind(this);
    this.handleListGroup = this.handleListGroup.bind(this);
    this.handleDeletarComplemento = this.handleDeletarComplemento.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.buscarLocaisNotificacao = this.buscarLocaisNotificacao.bind(this);
    this.handleAddTamanho = this.handleAddTamanho.bind(this);
    this.novoComplemento = this.novoComplemento.bind(this)
  }

  componentDidMount() {
    this.handleListGroup()
    this.buscarLocaisNotificacao()

    this.setState({ produto: this.props.produto })
    this.setState({ grupoAnterior: this.props.produto.grupoProduto })
  }

  //
  //Modificadores de State
  //

  //responsável por salvar os dados do produto, setando no 
  //state os campos dentro do array de produto
  handleChange(event, value) {
    const tempProduto = this.state.produto

    Utils.setValue(tempProduto, event.target.name, value || event.target.value)

    // if (!tempProduto["grupoProduto.id"]) {
    //   tempProduto["grupoProduto.id"] = this.props.grupo;
    // }

    this.setState({ produto: tempProduto })
  }

  //o handleChecked seta o state de chekbox dentro do produto
  handleChecked(event) {
    const tempProduto = this.state.produto
    tempProduto[event.target.name] = event.target.checked;
    this.setState({ produto: tempProduto })
  }

  //responsável por setar as informaçõs do complemento dentro 
  //do state de produtos
  handleAddChange(event) {
    const { complemento } = this.state;
    complemento[event.target.name] = (event.target.value)
    this.setState({ complemento: complemento })
  }

  //o HandleChangeComplementoCheck recebe o state do complemento 
  handleChangeComplementoCheck(event) {
    const { complemento } = this.state;
    complemento[event.target.name] = (event.target.checked)
    this.setState({ complemento: complemento })
  }

  //recebe os dados do state referentes ao complemento
  handleChangeComplementoItem(event, index, value) {
    const { complemento } = this.state;
    complemento.complementoItens[index][event.target.name] = value || (event.target.value)

    this.setState({ complemento: complemento })
  }

  handleChangeComplementoTamanho(event, value) {
    let { tamanhoItemEdicao } = this.state;

    tamanhoItemEdicao[event.target.name] = value || (event.target.value)
    this.setState({ tamanhoItemEdicao })
  }

  //
  //funcionalidade de tela
  //

  //lista o produto para edição a partir do id proveniente da listagem 
  handleListProdutos(produto) {
    let service = new AuthService();
    service
      .get(`/produto/${produto}`)
      .then(retorno => {
        this.setState({ produto: retorno });
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao listar grupo', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao listar grupo' + elem.errorMessage, { variant: 'error' });
          });
        }
      });
  }

  //O handle List Grup é responsável por listar
  //os grupos ultilizados dentro do box de grupos para cadastro de produtos
  handleListGroup() {
    let service = new AuthService();
    service
      .get("/grupoProduto/list")
      .then(retorno => {
        this.setState({ grupos: retorno });
        console.log(this.state.grupos);

      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao listar grupo', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao listar grupo' + elem.errorMessage, { variant: 'error' });
          });
        }
      });
  }

  buscarLocaisNotificacao() {
    let service = new AuthService();
    service
      .get("/localNotificacao/list")
      .then(retorno => {
        this.setState({ locaisNotificacoes: retorno });
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao buscar locais de notificação', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao buscar locais de notificação' + elem.errorMessage, { variant: 'error' });
          });
        }
      });
  }

  //
  // metodos do produto
  //

  // O handleSubinit verifica se alguns campos principais do cadastro de produto estao preechidos
  //apos isso envia para a API o componente de produto do state
  handleSubmit(event) {
    const { produto, grupoAnterior, erros } = this.state;

    var erro = false;
    if (produto.nome === "") {
      erros.nomeProduto = "O nome do produto é obrigatório!";
      this.props.enqueueSnackbar('Preencha os campos requeridos (Campo: Nome)', { variant: 'warning' })
      erro = true;
    } else {
      erros.nomeProduto = null
    }
    if (produto.localImpressao === "") {
      erros.localImpressao = "O Local de impressão é obrigatório!";
      this.props.enqueueSnackbar('Preencha os campos requeridos (Campo: Local de Impressão)', { variant: 'warning' })
      erro = true;
    } else {
      erros.localImpressao = null
    }

    if (erro) {
      this.setState({ erros: erros });
      console.log(erros);
      return;
    }

    if (produto.grupoProduto.id === 0) {
      produto.grupoProduto.id = this.props.grupo;
    }

    let value = produto.preco;
    let valueFormat = Utils.replaceValor(value)
    produto.preco = valueFormat;

    let service = new AuthService();
    service
      .post("/produto", produto)
      .then(retorno => {
        this.props.enqueueSnackbar('Produto salvo com sucesso', { variant: 'success' })

        this.props.handleChangeProduto(retorno, "SALVAR", grupoAnterior);

        //this.props.handleOpenCadProd();
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao tentar salvar o produto', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao salvar o produto ' + elem.errorMessage, { variant: 'error' });
          });
        }
      })
  }

  //metodo de delete do produto 
  handleDelete(produto) {
    if (!window.confirm('Deseja realmente excluir o produto?'))
      return;

    let service = new AuthService();
    service
      .delete(`/produto/${produto.id}`)
      .then(retorno => {

        produto.ativo = false;
        this.setState({ produto: produto })

        this.props.handleChangeProduto(retorno, "EXCLUIR");

        this.props.handleOpenCadProd();

        this.props.enqueueSnackbar('Produto inativado com sucesso', { variant: 'success' })

      }).catch(err => {
        this.props.enqueueSnackbar('Falha ao excluir o produto ' + err.errorMessage, { variant: 'error' });
      })
  }


  //metodo para salvar complemento, valida caso o campo de descrição estiver vazio, 
  //salva o complemento com o array complento, caso tenha itens do complemento ele salva junto
  handleSubmitComplemento(event) {
    const { complemento, grupoAnterior, produto, erros } = this.state;
    var error = false;

    complemento.complementoItens.map(item => {

      let value = item.valor;
      let valueFormat = Utils.replaceValor(value)
      item.valor = valueFormat;

      if (item.descricao === "") {
        erros.descricaoComplemento = "O nome do item do complemento é obrigatório!";
        this.props.enqueueSnackbar('Descrição do item não informada.', { variant: 'warning' })
        error = true;
      }
      return null
    });

    if (complemento.descricao === "") {
      erros.nomeItemComplemento = "A descrição do complemento é obrigatória!"
      this.props.enqueueSnackbar('Preencha os campos requeridos (Campo: Descrição) ', { variant: 'warning' })
      error = true
    }

    if (error) {
      this.setState({ erros: erros })
      return;
    }

    complemento.produto = { id: produto.id };
    let service = new AuthService();

    service
      .post("/complemento", complemento)
      .then(retorno => {
        this.props.enqueueSnackbar('Complemento salvo com sucesso', { variant: 'success' })
        let indexComplemento = produto.complementos.findIndex(el => el.id === retorno.id)

        if (indexComplemento > -1) {
          produto.complementos.splice(indexComplemento, 1)
        }

        produto.complementos.push(retorno)

        this.props.handleChangeProduto(produto, "SALVAR", grupoAnterior);
        this.handleSubmit();
        this.setState({ produto })
        this.setState({ complemento: null })
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao tentar salvar o complemento', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao salvar o complemento ' + elem.errorMessage, { variant: 'error' });
          });
        }
      })
  }

  //metodo responsável por deletar o complemento do produto
  handleDeletarComplemento(event) {
    if (!window.confirm('Deseja realmente excluir o complemento?'))
      return;

    const { complemento } = this.state;
    const { produto } = this.state;
    if (complemento.id !== null) {
      let service = new AuthService();
      service
        .delete(`/complemento/${complemento.id}`)
        .then(retorno => {
          this.props.enqueueSnackbar('Complemento excluído com sucesso', { variant: 'success' })

          let index = produto.complementos.findIndex(e => {
            return e.id === complemento.id
          })

          produto.complementos.splice(index, 1)

          this.handleSubmit();
          this.setState({ produto })
          this.setState({ complemento })
          this.novoComplemento()

        })
        .catch(err => {
          if (err.response === undefined) {
            this.props.enqueueSnackbar('Falha ao tentar excluir o complemento', { variant: 'error' });
          } else {
            err.response.json().then(elem => {
              this.props.enqueueSnackbar('Falha ao excluir o complemento ' + elem.errorMessage, { variant: 'error' });
            });
          }
        })
    }
  }

  //seta no state um complemento vazio, após salvar ou alterar
  novoComplemento() {
    let complemento = {
      id: null,
      descricao: "",
      max: 1,
      min: 0,
      obrigatorio: false,
      ordem: 1,
      complementoItens: [{ descricao: "", valor: "0.00" }]
    }

    this.setState({ complemento })
  }

  novoComplementoTamanho() {
    return {
      id: null,
      descricao: "TAMANHO",
      max: 1,
      min: 0,
      obrigatorio: true,
      ordem: 1,
      tipoComplemento: 'TAMANHO',
      complementoItens: []
    }
  }

  //responsavel pela ativação de adicionar um novo complemento ao state, no caso vazio, 
  //somente para que seja possivel aparecer em tela seus campos
  handleAddComplementoItem(complemento) {
    if (complemento.complementoItens === undefined)
      complemento.complementoItens = []

    complemento.complementoItens.push({ descricao: "", valor: "0.00" })
    this.setState({ complemento: complemento })
  }

  //responsável editar um complemento jogando suas informações nos campos para editar
  handleEditarComplemento(event, complemento) {
    event.stopPropagation();
    this.setState({ complemento: complemento })
  }

  //metodo para deletar item do complemento, nao deleta o complemento mas sim o complemento dele, 
  //recebe a posição dele detro do array, caso tenha id, ele manda para a API a solicitação de exclusão
  //caso esteja apenas dentro do state ele exclue sua posição dentro do array.
  handleRemoveComplementoItem(complementoItem) {
    const { complemento } = this.state;
    let temp = complemento.complementoItens
    let pos = temp.findIndex(r => r === complementoItem)

    if (complementoItem.id != null) {
      let service = new AuthService();
      service
        .delete(`/complementoItem/${complementoItem.id}`)
        .then(retorno => {
          temp.splice(pos, 1);
          this.props.enqueueSnackbar('Item do complemento excluido com sucesso', { variant: 'success' })
          this.setState({ complemento: complemento })
        }).catch(err => {
          this.props.enqueueSnackbar('Erro ao tentar excluir item do complemento', { variant: 'error' })
        })
    } else {
      temp.splice(pos, 1);
      this.setState({ complemento: complemento })
      this.props.enqueueSnackbar('Item do complemento excluido com sucesso', { variant: 'success' })
      this.handleSubmit();
    }
  }

  handleAddTamanhoItem() {
    let tamanhoItemEdicao = {
      descricao: "",
      valor: 0.00,
      codigoExterno: null,
      ordem: 0
    }

    this.setState({ tamanhoItemEdicao })
  }

  handleAddTamanho() {
    let { tamanhoItemEdicao } = this.state;
    const { produto, erros } = this.state;

    let complementoTamanho = produto.complementos.find(function (elem) {
      return elem.tipoComplemento === 'TAMANHO'
    })

    if (tamanhoItemEdicao.descricao === "") {
      this.props.enqueueSnackbar('Preencha os campos requeridos (Campo: Tamanho) ', { variant: 'warning' })
      erros.tamanho = "O tamanho é obrigatório!";
      this.setState({ erros: erros })
      return;
    }

    if (!complementoTamanho) {
      complementoTamanho = this.novoComplementoTamanho()
    } else {
      let itens = complementoTamanho.complementoItens.filter(function (elem) {
        // eslint-disable-next-line eqeqeq
        return elem.id != tamanhoItemEdicao.id
      })

      complementoTamanho.complementoItens = itens
    }

    complementoTamanho.complementoItens.push(tamanhoItemEdicao)

    complementoTamanho.produto = { id: produto.id };

    let service = new AuthService();
    service
      .post("/complemento", complementoTamanho)
      .then(retorno => {
        this.props.enqueueSnackbar('Complemento salvo com sucesso', { variant: 'success' })

        let indexComplemento = produto.complementos.findIndex(el => el.id === retorno.id)

        if (indexComplemento === -1)
          produto.complementos.push(retorno)

        produto.complementos.forEach(complemento => {
          if (complemento.id === retorno.id) {
            produto.complementos[indexComplemento] = retorno;
          }
        })
        this.handleSubmit();
        this.setState({ produto })
        this.setState({ tamanhoItemEdicao: null })
      })
      .catch(err => {
        if (err.response === undefined) {
          this.props.enqueueSnackbar('Falha ao tentar salvar o complemento', { variant: 'error' });
        } else {
          err.response.json().then(elem => {
            this.props.enqueueSnackbar('Falha ao salvar o complemento ' + elem.errorMessage, { variant: 'error' });
          });
        }
      })

  }

  handleRemoveTamanhoItem(complementoItem, complemento) {

    let temp = complemento.complementoItens
    let pos = temp.findIndex(r => r === complementoItem)
    let produto = this.state.produto

    if (complementoItem.id != null) {
      let service = new AuthService();
      service
        .delete(`/complementoItem/${complementoItem.id}`)
        .then(retorno => {
          temp.splice(pos, 1);
          this.props.enqueueSnackbar('Item do complemento excluido com sucesso', { variant: 'success' })

          this.setState({ produto })
        }).catch(err => {
          this.props.enqueueSnackbar('Erro ao tentar excluir item do complemento', { variant: 'error' })
        })
    } else {
      temp.splice(pos, 1);
      this.props.enqueueSnackbar('Item do complemento excluido com sucesso', { variant: 'success' })
      this.handleSubmit();
      this.setState({ produto })
    }
  }

  handleEditarTamanho(tamanhoItem) {
    this.setState({ tamanhoItemEdicao: tamanhoItem })
  }

  hideButton(event) {
    let { hideButton } = this.state;

    let botao = event.target.textContent;

    if (botao === "Detalhes") {
      hideButton = false;
      this.setState({ hideButton });
    } else {
      hideButton = true;
      this.setState({ hideButton });
    }

  }

  validaComplemento = () => {
    let { complemento } = this.state;
    if (parseInt(complemento.min) > parseInt(complemento.max)) {
      return {
        bool: true,
        text: "A quantidade máxima deve ser maior que a minima."
      };
    } else if (complemento.obrigatorio && parseInt(complemento.max) <= 0) {
      return {
        bool: true,
        text: "O complemento não pode ser obrigatorio se o máximo for 0."
      };
    }
    return false;
  }

  handleClickStopAndPause = (event, item, complemento) => {
    event.stopPropagation();
    let service = new AuthService();
    let produto = this.props.produto;

    let string = `{ "id": ${item.id}, "produtoId": ${produto.id} }`
    let json = JSON.parse(string)

    service
      .put("/complementoItem/play-or-pause", json)
      .then(resp => {

        let compItemIndex = complemento.complementoItens.findIndex(el => el.id === item.id);
        let compIndex = produto.complementos.findIndex(el => el.id === complemento.id);

        produto.complementos[compIndex].complementoItens[compItemIndex] = resp;

        this.props.handleChangeProduto(produto, "SALVAR");

      })

  }

  render() {
    const { classes } = this.props;
    const { produto, grupos, complemento, locaisNotificacoes, tamanhoItemEdicao, hideButton, erros } = this.state;

    const isMobile = this.props.width === 'sm' || this.props.width === 'xs'
    const optionsCurrency = Utils.optionsCurrency()

    return (
      <Fragment>
        {produto ? (
          <div>

            <Dialog
              fullScreen={isMobile}
              open={this.props.open}
              onClose={this.props.handleOpenCadProd}
              classes={{ paper: classes.dialog }} maxWidth="md" fullWidth>
              <DialogTitle  >
                Cadastro de produtos
                <IconButton aria-label="close" className={classes.closeButton} onClick={this.props.handleOpenCadProd}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>

              <DialogContent>
                <Tabs>
                  <Box pb={3}>
                    <TabList>
                      <Tab>
                        <Button onClick={(event) => this.hideButton(event)} >
                          Detalhes
                        </Button>
                      </Tab>
                      {!produto.id ?
                        null : (
                          <>
                            <Tab>
                              <Button onClick={(event) => { this.hideButton(event) }} >
                                Complementos
                              </Button>
                            </Tab>
                            <Tab>
                              <Button onClick={(event) => { this.hideButton(event) }}>
                                Tamanhos
                              </Button>
                            </Tab>
                            <Tab>
                              <Button onClick={(event) => { this.hideButton(event) }}>
                                Imagens
                              </Button>
                            </Tab>
                          </>
                        )}
                    </TabList>
                  </Box>

                  <TabPanel>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <TextField
                          variant="outlined"
                          // step="0.01"
                          label={"Nome"}
                          name="nome"
                          required
                          inputProps={{ maxLength: 60 }}
                          fullWidth
                          error={erros.nomeProduto}
                          helperText={erros.nomeProduto}
                          placeholder="Máximo de 35 caracteres"
                          value={produto.nome}
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          variant="outlined"
                          select
                          required
                          fullWidth
                          label={"Categoria"}
                          name="grupoProduto.id"
                          value={produto.grupoProduto.id}
                          onChange={event => this.handleChange(event)}
                        >
                          {grupos.map(grupo => (
                            <MenuItem key={grupo.id} value={grupo.id} >{grupo.descricao}</MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <CurrencyTextField
                          label="Preço"
                          variant="outlined"
                          fullWidth
                          value={produto.preco}
                          currencySymbol={optionsCurrency.currencySymbol}
                          decimalPlaces={optionsCurrency.decimalPlaces}
                          outputFormat="string"
                          required
                          name="preco"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          onChange={(event, value) => this.handleChange(event, value)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <TextField
                          variant="outlined"
                          select
                          fullWidth
                          required
                          label={"Local Impressão"}
                          name="localImpressao"
                          error={erros.localImpressao}
                          value={produto.localImpressao}
                          helperText={locaisNotificacoes.length === 0 ? 'carregando....' : erros.localImpressao}
                          onChange={event => this.handleChange(event)}
                        >
                          {locaisNotificacoes.length === 0 ? (
                            <span>carregando...</span>
                          ) : (
                            locaisNotificacoes.map(local => (
                              local.chave !== "DELIVERY" ? (
                                <MenuItem key={local.id} value={local.chave}>{local.descricao}</MenuItem>
                              ) : null
                            ))
                          )}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} sm={2}>
                        <FormControlLabel
                          name="promocaoAtiva"
                          fullwidth='true'
                          control={
                            <Switch
                              checked={produto.promocaoAtiva}
                              color="primary"
                              name="promocaoAtiva"
                              onChange={event => this.handleChecked(event)}
                            />
                          }
                          label={"Promoção"}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>

                        <TextField
                          select
                          name="localVenda"
                          fullWidth
                          label="Mostrar"
                          onChange={event => this.handleChange(event)}
                          value={produto.localVenda}
                          variant="outlined">

                          <MenuItem value="TODOS">
                            Sempre visível
                          </MenuItem>

                          <MenuItem label="Delivery" value={"DELIVERY"}>
                            Somente no delivery
                          </MenuItem>

                          <MenuItem value="INTERNO">
                            Somente vendas internas
                          </MenuItem>
                        </TextField>

                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label={"Ordem"}
                          name="ordem"
                          type="number"
                          defaultValue={produto.ordem}
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={2}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label={"Id Exterior"}
                          name="codigoExterno"
                          value={produto.codigoExterno}
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          label={"Descrição"}
                          value={produto.descricao}
                          name="descricao"
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          label="Ficha técnica e ingredientes"
                          multiline
                          fullWidth
                          variant="outlined"
                          minRows="4"
                          name="fichaTecnica"
                          value={produto.fichaTecnica}
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          label={"Advertências"}
                          name="advertencia"
                          value={produto.advertencia}
                          placeholder="Ex. Contém lactose"
                          helperText="Ex: Contém lactose, Bebida alcoólica. Venda somente para maiores de 18 anos"
                          onChange={event => this.handleChange(event)}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel>
                    {complemento ? (
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={5}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label="Descrição do complemento"
                            error={erros.descricaoComplemento}
                            helperText={erros.descricaoComplemento}
                            placeholder="Sabores tradicionais"
                            name="descricao"
                            required
                            value={complemento.descricao}
                            onChange={event => this.handleAddChange(event)} />
                        </Grid>

                        <Grid item xs={6} sm={1}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            label={"Ordem"}
                            name="ordem"
                            value={complemento.ordem}
                            onChange={event => this.handleAddChange(event)}
                          />
                        </Grid>

                        <Grid item xs={6} sm={2}>
                          <TextField
                            select
                            name="tipoComplemento"
                            label="Tipo"
                            fullWidth
                            onChange={event => this.handleAddChange(event)}
                            value={complemento.tipoComplemento}
                            variant="outlined">
                            <MenuItem value="">
                              Nenhum
                            </MenuItem>
                            <MenuItem value={"COMPLEMENTO"}>
                              Complemento
                            </MenuItem>
                            <MenuItem value="PIZZA_FRACIONADA">
                              Sabores de pizzas (valor proporcional)
                            </MenuItem>
                            <MenuItem value="PIZZA_MAIOR_VALOR">
                              Sabores de pizzas (maior valor)
                            </MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item xs={3} sm={1}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            label={"Qtd. min."}
                            name="min"
                            type="number"
                            value={parseInt(complemento.min)}
                            error={this.validaComplemento()}
                            onChange={event => this.handleAddChange(event)}
                          />
                        </Grid>
                        <Grid item xs={3} sm={1}>
                          <TextField
                            variant="outlined"
                            label={"Qtd. máx."}
                            fullWidth
                            name="max"
                            type="number"
                            value={parseInt(complemento.max)}
                            error={this.validaComplemento()}
                            onChange={event => this.handleAddChange(event)}
                          />
                        </Grid>

                        <Grid item xs={6} sm={1}>
                          <FormControlLabel
                            name="ativo"
                            labelPlacement="top"
                            control={
                              <Switch
                                checked={complemento.obrigatorio}
                                color="primary"
                                name="obrigatorio"
                                onChange={event => this.handleChangeComplementoCheck(event)}
                              />
                            }
                            label={"Obrigatório"}
                          />
                        </Grid>

                        {complemento.complementoItens != null ? complemento.complementoItens.map((complementoItem, index) => (
                          <Box paddingBottom={1} key={complementoItem.id}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} sm={3}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  required
                                  size="small"
                                  label="Descrição do item"
                                  name="descricao"
                                  error={complementoItem.descricao === "" ? erros.nomeItemComplemento ? true : false : false}
                                  helperText={complementoItem.descricao === "" ? erros.nomeItemComplemento : null}
                                  placeholder="Ex: MARGHERITA"
                                  defaultValue={complementoItem.descricao}
                                  onBlur={(event) => this.handleChangeComplementoItem(event, index)}
                                />
                              </Grid>

                              <Grid item xs={10} sm={3}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  label="Descrição adicional"
                                  name="descricaoComplementar"
                                  placeholder="Ex: Molho Pomodoro e queijo mussarela"
                                  defaultValue={complementoItem.descricaoComplementar}
                                  onBlur={(event) => this.handleChangeComplementoItem(event, index)}
                                />
                              </Grid>

                              <Grid item xs={3} sm={1}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  label="Ordem"
                                  name="ordem"
                                  defaultValue={complementoItem.ordem}
                                  onBlur={(event) => this.handleChangeComplementoItem(event, index)}
                                />
                              </Grid>

                              <Grid item xs={4} sm={2}>
                                <TextField
                                  variant="outlined"
                                  fullWidth
                                  size="small"
                                  label="ID Ext."
                                  name="codigoExterno"
                                  defaultValue={complementoItem.codigoExterno}
                                  onBlur={(event) => this.handleChangeComplementoItem(event, index)}
                                />
                              </Grid>

                              <Grid item xs={4} sm={2}>
                                <CurrencyTextField
                                  label="Valor"
                                  variant="outlined"
                                  defaultValue={complementoItem.valor}
                                  currencySymbol={optionsCurrency.currencySymbol}
                                  decimalPlaces={optionsCurrency.decimalPlaces}
                                  outputFormat="string"
                                  name="valor"
                                  size="small"
                                  fullWidth
                                  decimalCharacter=","
                                  digitGroupSeparator=""
                                  onBlur={(event, value) => this.handleChangeComplementoItem(event, index, value)}
                                />
                              </Grid>

                              <Grid item xs={1} sm={1} container justifyContent="center">
                                <Button
                                  onClick={(event) => this.handleRemoveComplementoItem(complementoItem, index)}>
                                  <DeleteIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        )) : ""}

                        <Grid item xs={12} sm={3} justifyContent="flex-start" >
                          <Button
                            variant="contained"
                            onClick={() => this.handleAddComplementoItem(complemento)}
                            startIcon={<AddIcon />}
                            color="primary"
                            size="small">
                            Adicionar item
                          </Button>
                        </Grid>

                        <Grid item xs={12} sm={9} container justifyContent="flex-end" alignItems="center">
                          <Typography color="error">
                            {this.validaComplemento().text}
                          </Typography>

                          <Button
                            onClick={(e) => this.handleDeletarComplemento(e)}
                            startIcon={<DeleteIcon />}>
                            EXCLUIR
                          </Button>

                          <Button
                            // eslint-disable-next-line eqeqeq
                            disabled={complemento.complementoItens == undefined ||
                              complemento.complementoItens.length === 0 ||
                              complemento.min > complemento.max ||
                              complemento.max <= 0}
                            variant="contained"
                            color="secondary"
                            onClick={e => this.handleSubmitComplemento(e)}>
                            SALVAR COMPLEMENTO
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container justifyContent="center">
                        <Button variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={this.novoComplemento}>
                          Adicionar Complemento
                        </Button>
                      </Grid>
                    )}

                    {produto.complementos.map(complementos => (
                      complementos.tipoComplemento !== "TAMANHO" ? (
                        <Box paddingTop={1} key={complementos.id}>
                          <ExpansionPanel square={true}>
                            <ExpansionPanelSummary
                              expandIcon={<ExpandMoreIcon />}>

                              <ListItem dense>
                                <ListItemText dense='true'
                                  primary={
                                    <Typography color="textPrimary" variant="button">
                                      {complementos.descricao}
                                    </Typography>
                                  }
                                  secondary={
                                    <Typography color="textSecondary" variant="body1">
                                      Qtd. min. {complementos.min} | Qtd. Máx. {complementos.max}
                                      {complementos.obrigatorio ? ' (obrigatório)' : ' (opcional)'}
                                    </Typography>
                                  } />

                                <Button
                                  startIcon={<EditIcon />}
                                  onClick={(event) => this.handleEditarComplemento(event, complementos)}>
                                  Editar
                                </Button>
                              </ListItem>
                            </ExpansionPanelSummary>

                            <ExpansionPanelDetails>
                              <List dense={true} style={{ width: "100%" }}>
                                {complementos.complementoItens.map(complementoItem => (

                                  <React.Fragment key={complementoItem.id}>
                                    <Grid container direction="row">
                                      <Grid item xs={8} sm={8}>
                                        <ListItemText
                                          primary={complementoItem.descricao}
                                          secondary={Utils.formatNumber(complementoItem.valor)} />
                                      </Grid>

                                      <Grid item xs={4} sm={4}>
                                        <Button
                                          startIcon={complementoItem.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                                          color={complementoItem.pausado ? "secondary" : "primary"}
                                          onClick={(event) => { this.handleClickStopAndPause(event, complementoItem, complementos) }}>
                                          {complementoItem.pausado ? (
                                            'Iniciar'
                                          ) : (
                                            'Pausar'
                                          )
                                          }
                                        </Button>
                                      </Grid>
                                    </Grid>

                                    <Divider />

                                  </React.Fragment>
                                ))}
                              </List>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        </Box>
                      ) : null
                    ))}
                  </TabPanel>

                  <TabPanel>
                    <Box>
                      {tamanhoItemEdicao ?
                        <Grid container spacing={1} alignItems="center" alignContent="flex-end">
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              required
                              label="Tamanho"
                              error={erros.tamanho}
                              helperText={erros.tamanho}
                              name="descricao"
                              defaultValue={tamanhoItemEdicao.descricao}
                              onChange={(event) => this.handleChangeComplementoTamanho(event)} />
                          </Grid>

                          <Grid item xs={6} sm={2}>
                            <CurrencyTextField
                              label="Valor"
                              fullWidth
                              variant="outlined"
                              value={tamanhoItemEdicao.valor}
                              currencySymbol={optionsCurrency.currencySymbol}
                              decimalPlaces={optionsCurrency.decimalPlaces}
                              outputFormat="string"
                              name="valor"
                              decimalCharacter=","
                              digitGroupSeparator=""
                              onChange={(event, value) => this.handleChangeComplementoTamanho(event, value)} />
                          </Grid>

                          <Grid item xs={6} sm={1} >
                            <TextField
                              variant="outlined"
                              fullWidth
                              label={"Ordem"}
                              name="ordem"
                              defaultValue={tamanhoItemEdicao.ordem}
                              onChange={(event) => this.handleChangeComplementoTamanho(event)}
                            />
                          </Grid>

                          <Grid item xs={4} sm={1}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              label="Id Ext."
                              name="codigoExterno"
                              defaultValue={tamanhoItemEdicao.codigoExterno}
                              onChange={(event) => this.handleChangeComplementoTamanho(event)}
                            />
                          </Grid>

                          <Grid item xs={4} sm={1}>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={this.handleAddTamanho}>
                              Salvar
                            </Button>
                          </Grid>

                          <Grid item xs={4} sm={1}>
                            <Button
                              color="default"
                              onClick={event => this.setState({ tamanhoItemEdicao: null })}>
                              <CancelIcon />
                            </Button >
                          </Grid>
                        </Grid>
                        : ""}
                    </Box>

                    {tamanhoItemEdicao ? null : (

                      <Grid container justifyContent="center">
                        <Button variant="contained"
                          color="primary"
                          startIcon={<AddIcon />}
                          onClick={() => this.handleAddTamanhoItem()} >
                          Adicionar tamanho
                        </Button>
                      </Grid>
                    )}

                    {produto.complementos.map(complementos => (
                      complementos.tipoComplemento === "TAMANHO" ? (
                        <React.Fragment key={complementos.id}>
                          {complementos.complementoItens.map((complementoItem, index) => (
                            <Box>
                              <Grid container direction="row" alignItems="center" justifyContent="center">
                                <Grid item xs={4} sm={6}>
                                  <Typography>
                                    {complementoItem.descricao}
                                  </Typography>
                                </Grid>

                                <Grid item xs={4} sm={2}>
                                  <Typography>
                                    {Utils.formatNumber(complementoItem.valor)}
                                  </Typography>
                                </Grid>

                                <Grid item xs={1} sm={2}>
                                  <Button
                                    startIcon={complementoItem.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                                    color={complementoItem.pausado ? "secondary" : "primary"}
                                    onClick={(event) => { this.handleClickStopAndPause(event, complementoItem, complementos) }}>
                                    {complementoItem.pausado ? (
                                      'Iniciar'
                                    ) : (
                                      'Pausar'
                                    )
                                    }
                                  </Button>
                                </Grid>

                                <Grid item xs={1} sm={1}>
                                  <Button onClick={(event) => this.handleRemoveTamanhoItem(complementoItem, complementos)}>
                                    <DeleteIcon />
                                  </Button>
                                </Grid>

                                <Grid item xs={2} sm={1}>
                                  <Button
                                    startIcon={<EditIcon />}
                                    onClick={(event) => this.handleEditarTamanho(complementoItem)}>
                                    Editar
                                  </Button>
                                </Grid>
                              </Grid>

                              <Divider />
                            </Box>
                          ))}
                        </React.Fragment>
                      ) : null))}
                  </TabPanel>

                  <TabPanel>
                    <CompCadastroImagem produto={produto} handleChangeProduto={this.props.handleChangeProduto} />
                  </TabPanel>
                </Tabs>
              </DialogContent>
              {hideButton ? null :
                <DialogActions >
                  {
                    produto.id === null ?
                      <strong style={{ marginRight: "10px", color: "red", marginBottom: "2%" }}>
                        Para adicionar complementos ou tamanhos ao produto, salve ele e clique para edita-lo novamente.
                      </strong>
                      :

                      <Button
                        variant="text"
                        color="default"
                        onClick={() => this.handleDelete(produto)}
                        startIcon={<DeleteIcon />}>
                        Excluir
                      </Button>
                  }

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={e => this.handleSubmit(e)}>
                    SALVAR
                  </Button>

                </DialogActions>
              }
            </Dialog>
          </div>)
          : null
        }
      </Fragment>
    );
  }
}



export default withWidth()(withSnackbar(withStyles(styles)(CadastroProduto)));
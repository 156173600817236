import React, { Component } from "react";

import AuthService from "../../AuthService";
import Logo from "../../imagens/logo-azul.svg";

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import Fundo from "../../imagens/fundo-qrpedir-min.png"

import {
  Button,
  Card,
  Grid,
  TextField,
  Box
} from "@material-ui/core";
import { NavLink } from "react-router-dom";

const styles = theme => ({
  card: {
    margin: "100px auto",
    maxWidth: "600px"
  },
  textField: {
    margin: theme.spacing(1)
  },
  buttonPrincipal: {
    background: "#253257",
    color: "white",
    borderRadius: 50,
    height: 48,
    width: "40%",
    marginTop: "30px",
    "&:hover": {
      background: "#E9E9E9",
      color: "#253257"
    }
  },
  buttonSecundario: {
    color: "#253257",
    marginBottom: "60px",
    "&:hover": {
      color: "#253257",
      background: "transparent"
    }
  },
  img: {
    width: "30%",
    marginTop: "10%"
  },
  buttonRecuperarSenha: {
    color: "#253257"
  }
});

class SignIn extends Component {
  constructor() {
    super();
    this.state = {
      acessando: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.Auth = new AuthService();
  }

  handleLogin = (msg, options) => {
    this.key = this.props.enqueueSnackbar(msg, options);
  };

  render() {

    const styles = {
      fundo: {
        height: "100vh",
        background: `url(${Fundo}) repeat`
      }
    }

    document.title = 'QR Pedir - Login'
    const { classes } = this.props;
    const { acessando } = this.state
    return (
      <React.Fragment>
        <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex" justifyContent="center" p={4} mt={6}>
            <img style={{ width: "15vw", minWidth: "200px" }} alt="logo" src={Logo} />
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" style={{ height: "800px" }}>
            <Card style={{ padding: "2vw", paddingBottom: '0px', width: "30vw", minWidth: "300px", maxWidth: "450px", maxHeight: "500px" }}>

              <Box p={3}>

                <form>
                  <Grid>
                    <Grid container justify="center">

                      <TextField
                        label="E-mail"
                        variant="outlined"
                        name="username"
                        fullWidth
                        onChange={this.handleChange}
                        className={classes.textField}
                      />
                      <TextField
                        label="Senha"
                        type="password"
                        variant="outlined"
                        name="password"
                        fullWidth
                        onChange={this.handleChange}
                        className={classes.textField}
                      />
                      <Button
                        variant="contained" color="primary"
                        value="submit"
                        type="submit"
                        size="large"
                        disabled={acessando}
                        onClick={this.handleFormSubmit}
                      >
                        {acessando ? 'ENTRANDO...' : 'ENTRAR'}
                      </Button>
                    </Grid>
                    <Grid container justify="center">
                      <Button className={classes.buttonSecundario} href={"/NovaEmpresaUser"}>
                        Cadastrar-se{" "}
                      </Button>
                    </Grid>
                    <Grid container justify="center" >
                      <NavLink className={classes.buttonRecuperarSenha} to='/RecuperarSenha'>
                        Recuperar senha
                      </NavLink>
                    </Grid>
                  </Grid>
                </form>

              </Box>

            </Card>
          </Box>
        </Box>
      </React.Fragment>
    );
  }

  //handleChange método que define os valores de entrada para o estado do componente.
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  // para evitar que se permaneça na tela de login caso ja esteja logado na aplicação
  componentWillMount() {
    if (this.Auth.loggedIn()) this.props.history.replace("/");
  }

  //chamando o método de login que criamos no serviço de autenticação.
  //Se logarmos com sucesso, seremos redirecionados para a página inicial,
  //que protegeremos com nosso componente de ordem superior posteriormente.
  handleFormSubmit(e) {
    e.preventDefault();
    const md5 = require('js-md5');
    this.setState({ acessando: true })

    this.Auth.login(this.state.username, md5(this.state.password))
      .then(res => {
        console.log("res", res)
        let ativo = true;
        let admin = false;

        if (res.empresa !== null && res.empresa !== undefined) { //verifica se a empresa está ativa
          ativo = res.empresa.ativo;
        }
        if (res.admin !== null && res.admin !== undefined) { //verifica se o usuário é admin
          admin = res.admin;
        }

        if (res.id != null) {

          if (!ativo && !admin) {//se a empresa não estiver ativa e o usuário não for admin desloga o usuário e redireciona para tela de inativo
            this.Auth.logout()
            this.props.history.replace("/Inativo");
          } else { //se o usuário for admin ainda poderá acessar a empresa
            this.setState({ acessando: false })
            this.handleLogin("Login realizado com sucesso", { variant: "success" });
            this.props.history.replace("/Cardapio");
          }

        } else if (res.text === "BLOCK_EMPRESA") {

          this.props.history.replace("/Block");

          console.log("Entra no block")

        } else if (res.text === "BLOCK_EMPRESA_TEMPORARIA") {
          this.props.history.push({
            pathname: '/TempoExpirado',
            state: { tipo: res.tipo }
          })
        }
      })
      .catch(err => {
        this.setState({ acessando: false })
        if (err.response === undefined) {
          this.handleLogin("Falha ao se comunicar com servidor", {
            variant: "error"
          });
        } else {
          err.response.json().then(elem => {
            console.log(elem);
            this.handleLogin(elem.errorMessage, { variant: "error" });
            //alert(elem.errorMessage);
          });
        }
      });
  }


}

export default withSnackbar(withStyles(styles)(SignIn));

import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Paper, Tab, Tabs } from '@material-ui/core';
import Template from '../../Generico/Template';
import TituloPrincipal from '../../Generico/TituloPrincipal';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import moment from 'moment';
import AuthService from '../../../AuthService';
import { useSnackbar } from 'notistack';
import { dateFormat } from '../../../utils/DateUtils';
import utils from '../../../utils';
import ReportSaleCustomer from './Report';

export default function CustomerSalesByType() {
    const [selectedTab, setSelectedTab] = useState(0)
    const [filter, setFilter] = useState({ dataInicial: `${moment().subtract(2, 'days').format('yyyy-MM-DD')} 00:00:00`, dataFinal: `${moment().add(1, 'days').format('yyyy-MM-DD')} 00:00:00` })
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const [orderList, setOrderList] = useState([])

    const handleChangeTab = (event, newValue) => {
        setSelectedTab(newValue);
    };

    function handleChangeDate(date, name) {
        const dateFormatted = `${dateFormat(date, { pattern: "yyyy-MM-DD" })} 00:00:00`

        setFilter({ ...filter, [name]: dateFormatted })
    }

    const organizarPedidosPorPessoa = (orderList) => {
        if (orderList && orderList.length <= 0) {
            enqueueSnackbar('Nenhum pedido encontrado no período informado', { variant: 'warning' })
            return
        }


        let pedidosDelivery = []
        let pedidosQrcode = []

        orderList.map((order) => {

            let hash = ''

            if (order.pessoa) {
                hash = order.pessoa.nome + utils.onlyDigits(order.pessoa.telefone)
            } else {
                hash = order.nomeCliente + utils.onlyDigits(order.telefone)
            }

            hash = hash.replaceAll(' ', '').toLowerCase()

            if (order.tipoPedido === 'DELIVERY') {

                
                let customer = pedidosDelivery.find(e => e.hash === hash)

                if (!customer) {
                    customer = {}
                    customer.quantityTakeWay = 0.0
                    customer.quantityDelivery = 0.0
                    customer.dataUltimoPedido = order.createdAt;

                    if (order.pessoa) {
                        customer.name = order.pessoa.nome
                        customer.phone = order.pessoa.telefone
                        customer.hash = hash
                        customer.orderList = []
                    }
                }

                customer.orderList.push(order)

                if(moment(order.createdAt).isAfter(moment(customer.dataUltimoPedido))){
                    customer.dataUltimoPedido = order.createdAt
                }

                customer.value = utils.getTotalPedido(order) + (customer.value || 0)
                customer.quantity = 1 + (customer.quantity || 0)

                if (order.tipoDelivery === 'RETIRAR_LOCAL') {
                    customer.quantityTakeWay ++  
                } else {
                    customer.quantityDelivery ++
                }



                let index = pedidosDelivery.findIndex(e => e.hash === hash)

                if (index < 0) {
                    pedidosDelivery.push(customer)
                } else {
                    pedidosDelivery[index] = customer
                }
            } else {

                let customer = pedidosQrcode.find(e => e.hash === hash)

                if (!customer) {
                    customer = {}

                    customer.name = order.nomeCliente
                    customer.phone = order.telefone
                    customer.hash = hash
                    customer.orderList = []
                    customer.value = 0.0
                    customer.quantity = 0.0
                    customer.quantityTakeWay = 0.0
                    customer.quantityDelivery = 0.0

                }

                customer.orderList.push(order)

                customer.value += utils.getTotalPedido(order)
                customer.quantity++

                let index = pedidosQrcode.findIndex(e => e.hash === hash)

                if (index < 0) {
                    pedidosQrcode.push(customer)
                } else {
                    pedidosQrcode[index] = customer
                }
            }
        })

        let pessoaPedidos = {}
        pessoaPedidos.pedidosDelivery = pedidosDelivery
        pessoaPedidos.pedidosQrcode = pedidosQrcode

        setOrderList(pessoaPedidos)
    }


    const onClickBuscar = () => {
        setOrderList([])
        const now = new Date(filter.dataInicial); // Data de hoje
        const past = new Date(filter.dataFinal); // Outra data no passado
        const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
        const days = Math.ceil(diff / (1000 * 60 * 60 * 24)); // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo).
        
        if (days > 30) {
            enqueueSnackbar("A diferença máxima entre as datas deve ser de 30 dias", { variant: 'warning' })
            return;
        }

        setLoading(true)

        let service = new AuthService();

        let query = Object.keys(filter).map(k => {
            if (filter[k] != null && filter[k] !== '' && filter[k] !== 'null')
                return encodeURIComponent(k) + '=' + encodeURIComponent(filter[k])
        }).join('&');

        service.get(`/pedido/find?${query}&&max=5000`).then(respPedidos => {

            organizarPedidosPorPessoa(respPedidos)

        }).catch(err => {

            console.error(err)
            enqueueSnackbar("Falha ao listar os pedidos.", { variant: "error" });

        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Template>
            <TituloPrincipal title="Vendas por cliente" subTitle="As vendas serão classificadas pela origem" />

            <Paper>
                <Box p={2}>
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                        spacing={2}>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>

                            <Grid item xs={12} md={3}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    variant="inline"
                                    inputVariant="outlined"
                                    margin="dense"
                                    id="dataInicial"
                                    name="dataInicial"
                                    size='small'
                                    label="Período inicial"
                                    value={filter.dataInicial}
                                    onChange={(date) => handleChangeDate(date, 'dataInicial')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período inicial',
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    fullWidth
                                    format="dd/MM/yyyy"
                                    margin="dense"
                                    size='small'
                                    id="dataFinal"
                                    name="dataFinal"
                                    label="Período final"
                                    value={filter.dataFinal}
                                    onChange={(date) => handleChangeDate(date, 'dataFinal')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar período final',
                                    }}
                                />
                            </Grid>

                        </MuiPickersUtilsProvider>

                        <Grid item xs={6} md={2}>
                            <Button fullWidth disabled={loading} variant='contained' color='primary' onClick={onClickBuscar}>
                                {loading ? 'Buscando...' : 'Buscar'}
                            </Button>
                        </Grid>
                    </Grid>


                    <Tabs
                        value={selectedTab}
                        onChange={handleChangeTab}
                        variant="standard"
                        indicatorColor="secondary"
                        textColor="secondary"
                    >
                        <Tab label="Delivery" value={0} />
                        <Tab label="QrCode" value={1} />
                    </Tabs>

                    {selectedTab === 0 ?
                        <ReportSaleCustomer orderList={orderList.pedidosDelivery} filename={`delivery_${filter.dataInicial}_${filter.dataFinal}.csv`} />
                        :
                        <ReportSaleCustomer orderList={orderList.pedidosQrcode} filename={`qrcode_${filter.dataInicial}_${filter.dataFinal}.csv`} />
                    }
                </Box>
            </Paper>
        </Template >
    );
}
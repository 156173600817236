import React, { useState, useEffect } from 'react'
import BarraTitulo from '../Generico/BarraTitulo'
import utils, { formatTextToFirstLetterUpperCase } from '../../utils';
import AuthService from '../../AuthService';
import ForList from './../Skeleton/ForList'

import { useSnackbar } from 'notistack';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Divider,
  MenuItem,
  TablePagination,
  Switch,
  FormControlLabel
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

import Utils from '../../utils'

export default function ConfiguracaoEntregaBairro() {
  const { enqueueSnackbar } = useSnackbar();

  const [bairroEntrega, setBairroEntrega] = useState({ bairro: "", valor: 0.0, municipio: null, ativo: true })
  const [municipios, setMunicipios] = useState([])
  const [listaBairros, setListaBairros] = useState([])

  const [paginations, setPaginations] = useState({ page: 0, rowsPerPage: 10, totalCountPedidos: 0 })
  const [totalCountPedidos, setTotalCountPedidos] = useState(0)

  const [openNovoBairro, setOpenNovoBairro] = useState(false)
  const [skeleton, setSkeleton] = useState(false)

  const [erroCadastroBairro, setErroCadastroBairro] = useState({ bairro: null, municipio: null })

  const [pesquisaBairro, setPesquisaBairro] = useState("")

  const [bairrosAtivos, setBairrosAtivos] = useState(true)

  const novaTaxa = () => {
    let taxa = {
      bairro: "",
      valor: 0.0,
      municipio: null,
      ativo: true
    }

    setBairroEntrega(taxa)
  }

  useEffect(() => {
    handleListarBairros()
    handleGetMunicipios()
  }, [])

  useEffect(() => {
    handleListarBairros(bairrosAtivos)
  }, [paginations, bairrosAtivos])

  //Cria um novo bairro
  const handleCriarNovoBairro = () => {
    setOpenNovoBairro(!openNovoBairro)
    handleGetMunicipios()

    novaTaxa()
  }

  //Cria o objeto do bairro passando o nome e o valor
  const handleChange = (event, value) => {
    const taxaEntrega = Object.assign({}, bairroEntrega)

    utils.setValue(taxaEntrega, event.target.name, value || event.target.value)
    setBairroEntrega(taxaEntrega)
  }

  //Salva o id do municipio no objeto bairroEntrega
  const handleChangeMunicipio = (event) => {
    let municipioId = event.target.value
    setBairroEntrega({ ...bairroEntrega, municipio: { id: municipioId } })
  }

  //Lista os municípios atendidos
  const handleGetMunicipios = () => {
    let service = new AuthService();
    service
      .get('/permissaoMunicipio/listOrCreate?tipo=station')
      .then(resp => {
        let municipiosResp = []

        resp.map(permissaoMunicipio => {
          municipiosResp.push(permissaoMunicipio.municipio)
        })
        setMunicipios(municipiosResp)
      })
      .catch(err => {
        console.error(err);
      })
  }

  //Salva o bairro no banco de dados
  const handleSalvar = () => {

    let copiaBairroEntrega = Object.assign({}, bairroEntrega)
    copiaBairroEntrega.valor = Utils.replaceValor(bairroEntrega.valor)
    copiaBairroEntrega.bairro = formatTextToFirstLetterUpperCase(copiaBairroEntrega.bairro)

    let service = new AuthService();
    service
      .post("/bairroEntrega", copiaBairroEntrega)
      .then(resp => {

        let pos = listaBairros.map(function (t) { return t.id; }).indexOf(bairroEntrega.id);

        if (pos >= 0) {
          listaBairros.splice(pos, 1)
        }

        let bairroEntregaResp = []

        listaBairros.map(bairros => (
          bairroEntregaResp.push(bairros)
        ))

        bairroEntregaResp.push(resp)
        setListaBairros(bairroEntregaResp)
        setOpenNovoBairro(!openNovoBairro)
      })
      .catch(err => {
        console.error(err);
      })
  }

  //Lista os bairros ao carregar a página
  const handleListarBairros = (isActive) => {
    let copiaPaginations = Object.assign({}, paginations)
    setSkeleton(true)

    let service = new AuthService();
    service
      .request(`/bairroEntrega/list?ativo=${isActive}&first=${copiaPaginations.rowsPerPage * copiaPaginations.page}&max=${copiaPaginations.rowsPerPage}`)
      .then(resp => {
        let totalCountPedidos = resp.headers.get('totalCount')
        setTotalCountPedidos(parseInt(totalCountPedidos))

        resp.json()
          .then(retorno => {
            setListaBairros(retorno)
            setSkeleton(false)
          })
          .catch(err => {
            console.error(err);
          })

      })
      .catch(err => {
        console.error(err);
      })
  }

  const handleChangePage = (event, newPage) => {
    setPaginations({ ...paginations, page: newPage });
  };

  const handleChangeRowsPerPage = (event) => {
    setPaginations({ page: 0, rowsPerPage: parseInt(event.target.value) });
  };

  //Edita o valor do bairro
  const handleEdit = (taxa) => {
    setOpenNovoBairro(!openNovoBairro)

    let bairro = JSON.parse(JSON.stringify(taxa))

    setBairroEntrega(bairro)
  }

  //Verifica se o bairro já foi cadastrado
  const handleValidarBairro = () => {
    let salvar = true

    listaBairros.map((taxa) => {

      if (taxa.bairro === bairroEntrega.bairro &&
        taxa.municipio?.id === bairroEntrega.municipio?.id &&
        taxa.id !== bairroEntrega.id) {
        enqueueSnackbar('Bairro já cadastrado', { variant: 'error' })
        return salvar = false
      }
    })

    if (salvar) {
      handleSalvar()
    }
  }

  const handleValidarCampos = () => {

    var erros = { bairro: null, municipio: null };
    var erro = false

    if (!bairroEntrega.bairro) {
      erros.bairro = "O campo bairro deve ser preenchido"
      erro = true
    }

    if (!bairroEntrega.municipio) {
      erros.municipio = "Você deve selecionar um município"
      erro = true
    }

    setErroCadastroBairro(erros)

    if (!erro) {
      handleValidarBairro()
    }
  }

  const handleChecked = (event) => {
    let valorAtivo = event.target.checked;
    setBairroEntrega({ ...bairroEntrega, ativo: valorAtivo })
  }

  const handleChangePesquisaBairro = (event) => {
    setPesquisaBairro(event.target.value)
  }

  const handlePesquisarBairro = () => {
    setSkeleton(true)

    let service = new AuthService();
    service
      .get(`/bairroEntrega/list?q=${pesquisaBairro}&ativo=${bairrosAtivos}`)
      .then(resp => {
        setListaBairros(resp)
        setSkeleton(false)
      })
      .catch(err => {
        console.error(err);
      })
  }

  const handleChangeAtivos = () => {
    setBairrosAtivos(!bairrosAtivos);
    setPaginations({ ...paginations, page: 0 })
  }

  const keyDown = (event) => {
    if (event === "click" || event.key === 'Enter') {
      handlePesquisarBairro()
    }
  }


  return (
    <div>
      <BarraTitulo
        title="Taxas por bairros"
        subTitle="Configure as taxa de entrega conforme o bairro" />

      <Box pb={2}>

        <Grid container spacing={2} direction="row" alignItems="center">

          <Grid item xs={12} sm={2}>
            <Button color="primary"
              variant="contained"
              startIcon={<AddIcon />}
              size="medium"
              fullWidth
              onClick={handleCriarNovoBairro}>
              Novo bairro
            </Button>
          </Grid>

          <Grid item xs={12} sm={4}>
            <FormControlLabel
              control={<Switch checked={bairrosAtivos} onChange={handleChangeAtivos} />}
              label={bairrosAtivos ? "Bairros ativos" : "Bairros inativos"}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              variant="outlined"
              onChange={(event) => { handleChangePesquisaBairro(event) }}
              label="Digite o nome do bairro"
              value={pesquisaBairro}
              size="small"
              fullWidth
              onKeyPress={event => keyDown(event)}
            />
          </Grid>

          <Grid item xs={12} sm={2}>
            <Button color="primary"
              variant="contained"
              size="medium"
              onClick={() => handlePesquisarBairro()}
              fullWidth
            >
              Pesquisar
            </Button>
          </Grid>
        </Grid>

      </Box>

      <Divider />

      <Box p={3}>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Bairro</TableCell>
                <TableCell>Valor da taxa</TableCell>
                <TableCell>Município</TableCell>
                <TableCell>Situação</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>

            {skeleton ? (
              <TableBody>
                <TableRow >
                  <TableCell colSpan={12}>
                    <ForList rows={paginations.rowsPerPage} height={50} />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : null}

            <TableBody>
              {listaBairros.map((taxa) => {
                return (
                  <TableRow key={taxa.id}>
                    <TableCell>
                      {taxa.bairro}
                    </TableCell>

                    <TableCell>
                      {utils.formatNumber(taxa.valor)}
                    </TableCell>

                    <TableCell>
                      {taxa.municipio?.nome}
                    </TableCell>

                    <TableCell>
                      {taxa.ativo ? "Ativo" : "Inativo"}
                    </TableCell>

                    <TableCell scope="medium" align="right" xs={2}>
                      <Button
                        onClick={event => { handleEdit(taxa) }}
                        startIcon={<EditIcon />}>
                        Editar
                      </Button>

                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={totalCountPedidos}
            rowsPerPage={paginations.rowsPerPage}
            page={paginations.page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage} />
        </TableContainer>
      </Box>

      <Dialog
        open={openNovoBairro}
        onClose={handleCriarNovoBairro}
        fullWidth
      >
        <DialogTitle>Criar novo bairro</DialogTitle>

        <DialogContent>
          <Box p={2}>
            <Grid container spacing={2}>

              <Grid item xs={12} md={6}>
                <TextField
                  variant="outlined"
                  name="bairro"
                  error={erroCadastroBairro.bairro !== null}
                  helperText={erroCadastroBairro.bairro}
                  defaultValue={bairroEntrega.bairro}
                  onChange={(event) => { handleChange(event) }}
                  label="Bairro"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6} >
                <CurrencyTextField
                  variant="outlined"
                  name="valor"
                  currencySymbol="R$"
                  outputFormat="string"
                  defaultValue={bairroEntrega.valor}
                  onChange={(event) => { handleChange(event) }}
                  label="Taxa de entrega"
                  fullWidth
                  decimalCharacter=","
                  digitGroupSeparator=""
                />
              </Grid>

              <Grid item xs={12} md={12} >
                <TextField
                  id="selecionarMunicipio"
                  select
                  label="Municípios"
                  error={erroCadastroBairro.municipio !== null}
                  helperText={erroCadastroBairro.municipio}
                  value={bairroEntrega.municipio?.id ? bairroEntrega.municipio.id : ''}
                  name="municipio"
                  onChange={handleChangeMunicipio}
                  fullWidth
                  variant="outlined"
                >
                  {municipios?.map(municipio => (
                    <MenuItem key={municipio.id} value={municipio.id}>{municipio.nome}</MenuItem>
                  ))}
                </TextField>
              </Grid>

            </Grid>
          </Box>

          <Box style={{ marginLeft: '20px' }}>
            <FormControlLabel
              name="ativo"
              control={
                <Switch
                  checked={bairroEntrega.ativo}
                  color="primary"
                  onChange={event => handleChecked(event)}
                />
              }
              label={"Bairro ativo"}
            />
          </Box>

          <Box p={2} display="flex" justifyContent="flex-end">
            <Button
              color="primary"
              variant="text"
              onClick={handleCriarNovoBairro}
              startIcon={<CancelIcon />}
            >Cancelar</Button>
            <Button
              aria-expanded={true}
              variant="contained"
              onClick={handleValidarCampos}
              color="secondary"
              startIcon={<SaveIcon />}
            >Salvar</Button>
          </Box>

        </DialogContent>
      </Dialog>

    </div>
  )
}

import React, { useEffect, useState } from "react";
import AuthService from "../../../AuthService";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  Divider,
  Grid,
  Button,
  Typography,
  TextField,
  Card,
  Container,
  MenuItem,
  Link,
  InputAdornment
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import MaskedInput from "react-text-mask";

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: "110px",
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
  },
  subtitleForm: {
    fontSize: "95%",
    textAlign: "center",
    fontFamily: "Bebas Neue",
    textTransform: "uppercase"
  },
  divider: {
    marginTop: "18px"
  },
  card: {
    padding: "1% 10% 1% 10%",
  }
}));

const TextMaskCnpj = (props) => {
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
          }}
          mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          placeholderChar={'\u2000'}
          placeholder="xx.xxx.xxx/xxxx-xx"
      />
  );
}

const TextMaskPhone = (props) => {
  const { inputRef, ...other } = props;
  return (
      <MaskedInput
          {...other}
          ref={(ref) => {
              inputRef(ref ? ref.inputElement : null);
          }}
          mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          placeholderChar={'\u2000'}
          placeholder="(xx) x xxxx-xxxx"
      />
  );
}

export default function CompCadastroEmpresa()  {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [usuario, setUsuario] = useState({
    nome: "",
    password: "",
    passwordSemMD5: "",
    username: "",
    email: "",
    telefone: "",
    empresa: {}
  });
  const [empresa, setEmpresa] = useState({
    nome: "",
    razaoSocial: null,
    cpfCnpj: "",
    representante: "Parseint",
    moeda: "REAL",
    tipo: "outro",
    tipoConta: "",
    idPais: 1058
  });
  const [ativarDelivery, setAtivarDelivery] = useState(false);
  const [urlDelivery, setUrlDelivery] = useState("");
  const [errosCadastro, setErrosCadastro] = useState({
    nomeFantasia: null,
    nomeUsuario: null,
    username: null,
    email: null,
    senha: null,
    urlDelivery: null,
    telefone: null,
    cnpj: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsuario(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeEmpresa = (e) => {
    const { name, value } = e.target;
    setEmpresa(prevState => ({ ...prevState, [name]: value }));
  };

  const handleChangeUrlDelivery = (newUrlDelivery) =>{
    let uuidIdentificador = newUrlDelivery.replace(/[^\w\s]/gi, "")

    if (uuidIdentificador.match(/\ /)) {
        uuidIdentificador = uuidIdentificador.replace(/\ /, '-');
    }

    setUrlDelivery(uuidIdentificador)
  }

  const handleValidaCadastro = () => {
    var error = false;
    var erros = { nomeFantasia: null, nomeUsuario: null, username: null, email: null, senha: null, urlDelivery: null, telefone: null, cnpj: null };

    if (!!empresa) {
      if (!!!empresa.nome) {
        erros.nomeFantasia = "O nome da empresa não pode ser vazio!";
        error = true;
      }
    } else {
      erros.nomeFantasia = "O nome da empresa não pode ser vazio!";
      error = true;
    }

    if(!!!empresa.cpfCnpj){
      erros.cnpj = "O CNPJ da empresa não pode ser vazio!"
      error = true;
    }

    if (!!!usuario.nome) {
      erros.nomeUsuario = "O nome do usuario não pode ser vazio!";
      error = true;
    }
    if (!!!usuario.passwordSemMD5) {
      erros.senha = "A senha não pode ser vazia!";
      error = true;
    }
    if (!!!usuario.username) {
      erros.username = "O username não pode ser vazio!";
      error = true;
    }
    if(!!!usuario.email){
      erros.email = "O email não pode ser vazio!";
      error = true;
    }

    if (ativarDelivery){
      if (!!!urlDelivery) {
        erros.urlDelivery = "A Url do delivery não pode ser vazia!";
        error = true;
      } else {
        let service = new AuthService();
        service
            .get(`/identificador/validaUrlDelivery/${urlDelivery}`)
            .then(resp => {
                if (resp.value == "IDENTIFICADOR_EXISTENTE") {
                    erros.urlDelivery = "Endereço digital ja está sendo utilizado";
                    error = true;
                    this.setState({ errosConfig: erros })
                    if (error) {
                        return;
                    }
                } 
            })
      }
    }
    setErrosCadastro(erros)

    if (error) {
      return;
    } else {
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    let service = new AuthService();
    const md5 = require('js-md5');
    usuario.password = md5(usuario.passwordSemMD5);
    usuario.createOptions = {
      ativarDelivery,
      urlDelivery
    };
    usuario.empresa = empresa;

    console.log("usuario",usuario)
    service
      .post("/usuario/create", usuario)
      .then(user => {

        service
        .post(`/usuario/changePassword`, usuario)
        .then(resp => {

          let token = resp.token;
          console.log(resp.token);

          service
          .post(`/identificador/changeUuidForNewUser/${user.empresa.id}/${urlDelivery}`)
          .then(response => {

          })
          .catch(error => {
              console.error(error);
          })

          user.empresa.configuracaoAbertura = "SEMPRE_ABERTO";
          user.empresa.ativo = true;

          var options = {
              method: 'POST',
              body: JSON.stringify(user.empresa),
              headers: {
                  "Authorization": "Bearer " + token,
                  "Accept": "application/json",
                  "Content-Type": "application/json; charset=utf-8"
              }
          }

          console.log(options);

          service
              .myFetch(`/empresa`, options)
              .then(empresa => {

                  this.setState({ open: true })

              }).catch(err => {
                  console.error(err)
              })
        }).catch(err => {
          console.error(err);
      })        

      setUsuario({
        nome: "",
        password: "",
        passwordSemMD5: "",
        username: "",
        email: "",
        telefone: "",
        empresa: {}
      })

      setEmpresa({
        nome: "",
        razaoSocial: null,
        cpfCnpj: "",
        representante: "Parseint",
        moeda: "REAL",
        tipo: "",
        tipoConta: "",
        idPais: 1058
      })

      setUrlDelivery("")
      setAtivarDelivery(false)
      enqueueSnackbar('Empresa criada com sucesso', { variant: 'success' })
      
    }).catch(err => {
      if (err.response === undefined) {
        enqueueSnackbar('Falha ao salvar empresa.', { variant: 'error' })
      } else {
        err.response.json().then(elem => {
          console.log(elem);
          enqueueSnackbar('Falha ao salvar: ' + elem.errorMessage, { variant: 'warning' })
        });
      }

    })
  }

  useEffect(() => {
    let authService = new AuthService();
    const loggerUser = authService.getProfile();

    if (!loggerUser.admin) {
      window.location.replace("/Cardapio");
    }
  }, []);

  return (
    <Container className={classes.container} >
      <Card className={classes.card} variant="outlined" >

        <Grid className={classes.subtitleForm}>
          <h1> Cadastro de empresa </h1>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={4} >
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={4} >
            <Typography
              className={classes.subtitleForm}
              color="textSecondary">
              Dados da empresa
              </Typography>
          </Grid>

          <Grid item xs={4} >
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label={"Nome Fantasia"}
              name="nome"
              error={errosCadastro.nomeFantasia != null}
              helperText={errosCadastro.nomeFantasia}
              value={empresa.nome}
              fullWidth
              onChange={event => handleChangeEmpresa(event)}>
            </TextField>
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label={"Razão Social"}
              name="razaoSocial"
              value={empresa.razaoSocial}
              fullWidth
              onChange={event => handleChangeEmpresa(event)} />
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label="CNPJ (obrigatório)"
              name="cpfCnpj"
              error={errosCadastro.cnpj != null}
              helperText={errosCadastro.cnpj}
              value={empresa.cpfCnpj}
              inputProps={{ maxLength: 30 }}
              fullWidth
              onChange={event => handleChangeEmpresa(event)}
              InputProps={{
                inputComponent: TextMaskCnpj
              }} />
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label={"Representante"}
              name="razaoSocial"
              value={empresa.representante}
              fullWidth
              onChange={event => handleChangeEmpresa(event)} />
          </Grid>

          <Grid item xs={6} >
            <TextField
                select
                fullWidth
                style={{ margin: '10px 10px 0 0' }}
                label="Tipo"
                name="tipo"
                defaultValue={empresa.tipo || ""}
                onChange={event => handleChangeEmpresa(event)}
                variant="outlined"
            >
              <MenuItem value={"aLaCarte"}>À la carte</MenuItem>
              <MenuItem value={"pizza"}>Pizzas</MenuItem>
              <MenuItem value={"lanche"}>Lanches</MenuItem>
              <MenuItem value={"outro"}>Outros</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={6} >
          </Grid>

          <Grid item xs={4} >
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={4} >
            <Typography
              className={classes.subtitleForm}
              color="textSecondary">
              Usuario
            </Typography>
          </Grid>

          <Grid item xs={4} >
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              fullWidth
              label={"Nome do Usuario"}
              error={errosCadastro.nomeUsuario != null}
              helperText={errosCadastro.nomeUsuario}
              name="nome"
              value={usuario.nome}
              onChange={event => handleChange(event)}>
            </TextField>
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label={"Username"}
              fullWidth
              error={errosCadastro.username != null}
              helperText={errosCadastro.username || 'Nome que o usuario vai usar para logar'}
              name="username"
              value={usuario.username}
              onChange={event => handleChange(event)}>
            </TextField>
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              variant="outlined"
              label={"E-mail"}
              fullWidth
              error={errosCadastro.email != null}
              helperText={errosCadastro.email || 'E-mail do usuario'}
              name="email"
              value={usuario.email}
              onChange={event => handleChange(event)}>
            </TextField>
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              label="Senha"
              type="password"
              variant="outlined"
              error={errosCadastro.senha != null}
              helperText={errosCadastro.senha}
              name="passwordSemMD5"
              value={usuario.passwordSemMD5}
              fullWidth
              onChange={event => handleChange(event)}>
            </TextField>
          </Grid>

          <Grid item xs={6} >
            <TextField
              className={classes.textField}
              label="Telefone"
              type="tel"
              variant="outlined"
              error={errosCadastro.telefone != null}
              helperText={errosCadastro.telefone}
              name="telefone"
              value={usuario.telefone}
              fullWidth
              onChange={event => handleChange(event)}
              InputProps={{
                inputComponent: TextMaskPhone
              }}>
            </TextField>
          </Grid>

          <Grid item xs={12} >
            <Divider className={classes.divider} />
          </Grid>

          <Grid item xs={3} >
            <FormControlLabel
              control={
                <Switch
                  checked={ativarDelivery}
                  name="ativarDelivery"
                  color="primary"
                  onChange={event => { setAtivarDelivery(event.target.checked )}}
                />
              }
              label="Ativar delivery"
            />
          </Grid>

          <Grid item xs={9} >
            <TextField
              disabled={!ativarDelivery}
              className={classes.textField}
              label="URL da loja delivery"
              type="text"
              variant="outlined"
              error={errosCadastro.urlDelivery != null}
              helperText={errosCadastro.urlDelivery ? errosCadastro.urlDelivery + `   ||   Copie a URL do seu delivery: https://app.qrpedir.com/checkout/${urlDelivery}` : ativarDelivery ? `Copie a URL do seu delivery: https://app.qrpedir.com/checkout/${urlDelivery}` : ""}
              name="urlDelivery"
              value={urlDelivery}
              fullWidth
              onChange={event => { handleChangeUrlDelivery(event.target.value) }}
              InputProps={{
                endAdornment: <InputAdornment position="start">.qrpedir.com</InputAdornment>,
              }}>
            </TextField>
          </Grid>

          <Grid item xs={12} />
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        > 
          <Grid item xs={6} >
              <Link style={{ cursor: "pointer" }} onClick={() => { window.open('/login', '_parent') }}
              >voltar a página inicial</Link>
          </Grid>
          <Button variant="contained"
            color="primary"
            size="large"
            onClick={handleValidaCadastro}>Cadastrar nova empresa</Button>
        </Grid>

      </Card>
    </Container>
  );
}
import { Typography, Slider, Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, Paper, Radio, RadioGroup, FormGroup, Checkbox, Accordion, AccordionSummary, AccordionDetails, Button, TableContainer } from '@material-ui/core';
import React, { useEffect, useState } from "react";
import AuthService from '../../AuthService';
import BarraTitulo from "../Generico/BarraTitulo";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import { add, format, isSameDay } from 'date-fns';

const MARKS_HORAS_ANTECEDENCIA = [
    {
        value: 0,
        label: '0h',
    },
    {
        value: 12,
        label: '12h',
    },
    {
        value: 24,
        label: '24h',
    },
    {
        value: 36,
        label: '36h',
    },
    {
        value: 48,
        label: '48h',
    },
    {
        value: 72,
        label: '72h',
    },
];

const MARKS_MINUTOS_ANTECEDENCIA = [
    {
        value: 0,
        label: '0min',
    },
    {
        value: 15,
        label: '15min',
    },
    {
        value: 30,
        label: '30min',
    },
    {
        value: 45,
        label: '45min',
    },
];

const MARK_DIAS_AGENDAMENTO = [
    {
        value: 0,
        label: '0d',
    },
    {
        value: 1,
        label: '1d',
    },
    {
        value: 5,
        label: '5d',
    },
    {
        value: 10,
        label: '10d'
    },
    {
        value: 15,
        label: '15d',
    },
    {
        value: 20,
        label: '20d',
    },
    {
        value: 30,
        label: '30d',
    },
]

const INTERVALO_HORARIO_AGENDAMENTO = [
    {
        value: 15,
        label: '15min',
    },
    {
        value: 30,
        label: '30min',
    },
    {
        value: 45,
        label: '45min',
    },
    {
        value: 60,
        label: '1h',
    },
]

const CLEAN_DAYS = [1, 2, 3, 4, 5, 6, 7].map(item => ({
    "dayOfWeek": item,
    "times": [],
}))

const DIAS_SEMANA = ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"];

function generateInterval(minutes) {
    const arr = []
    const originalDate = new Date(0, 0, 0, 0, 0)

    let actualDate = originalDate

    while (isSameDay(originalDate, actualDate)) {
        arr.push(format(actualDate, 'HH:mm'))

        actualDate = add(actualDate, { minutes })
    }

    return arr
}

export default function ConfiguracaoAgendamento() {
    const { enqueueSnackbar } = useSnackbar()
    const [configuracao, setConfiguracao] = useState({
        situacao: "",
        antecedenciaHoras: "",
        antecedenciaMinutos: 30,
        intervaloHorarioAgendamento: 30
    })
    const [intervaloHorarioList, setIntervaloHorarioList] = useState(generateInterval(30))
    const [configuracoesDias, setConfiguracoesDias] = useState(null);

    useEffect(() => {

        let service = new AuthService();
        service.get("/configurarAgendamento/configuracaoAtual")
            .then(configuracaoResp => {
                console.log(configuracaoResp)

                var days = JSON.parse(configuracaoResp.jsonConfiguracao);
                console.log(days)
                setConfiguracao(configuracaoResp);
                setIntervaloHorarioList(generateInterval(configuracaoResp.intervaloHorarioAgendamento))
                setConfiguracoesDias(days);
            }).catch(err => {
                console.error(err)
            })

    }, [])

    const handleChange = (event) => {
        console.log(event.target.value)
        setConfiguracao({ ...configuracao, [event.target.name]: event.target.value })
    }

    const handleChangeSlider = (name, value) => {
        console.log(name, value)

        if (name === 'intervaloHorarioAgendamento') {
            setConfiguracoesDias([...CLEAN_DAYS])
            setIntervaloHorarioList(generateInterval(value))
        }

        setConfiguracao({ ...configuracao, [name]: value })
    }

    const handleChangeHorarios = (event, indexDiaSemana) => {
        var configuracaoCopy = JSON.parse(JSON.stringify(configuracoesDias));
        if (configuracaoCopy[indexDiaSemana].times.includes(event.target.name)) {
            let index = configuracaoCopy[indexDiaSemana].times.indexOf(event.target.name);
            configuracaoCopy[indexDiaSemana].times.splice(index, 1);
        } else {
            configuracaoCopy[indexDiaSemana].times.push(event.target.name);
        }
        setConfiguracoesDias(configuracaoCopy);
    }

    const handleSaveConfig = () => {
        configuracoesDias.map(config => {
            config.times.sort();
        })

        var jsonDias = JSON.stringify(configuracoesDias);

        configuracao.jsonConfiguracao = jsonDias;

        var service = new AuthService();

        service
            .post("/configurarAgendamento", configuracao)
            .then(resp => {
                enqueueSnackbar("configurações salvas com sucesso", { variant: "success" })
            }).catch(err => {
                enqueueSnackbar("Esso ao salvar configurações", { variant: "error" })
                console.error(err);
            })

    }

    const verificaDiaSemana = (dia) => {
        return " " + DIAS_SEMANA[dia - 1];
    }

    return (
        <div>
            <BarraTitulo
                title="Configuração de agendamento"
                subTitle="Trabalha com agendamento? Configure tudo aqui" />

            <Paper>
                <Box p={3}>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Como sua loja trabalha</FormLabel>

                        <RadioGroup row
                            name="situacao"
                            value={configuracao.situacao || ""}
                            onChange={(e) => handleChange(e)}>
                            <FormControlLabel value="N" control={<Radio color="primary" />} label="Pedidos sem agendamento" />
                            <FormControlLabel value="NA" control={<Radio color="primary" />} label="Pedidos com e sem agendamento" />
                            <FormControlLabel value="A" control={<Radio color="primary" />} label="Somente pedidos agendados" />
                        </RadioGroup>
                    </FormControl>


                </Box>

                <Divider />

                <Box p={3}>

                    <Grid container spacing={3} style={{ justifyContent: 'space-around' }}>
                        <Grid item xs={12} sm={5}>

                            <Typography id="discrete-slider" gutterBottom>
                                Antecedência mínima (em horas)
                            </Typography>

                            <Slider
                                value={configuracao.antecedenciaHoras | ""}
                                name="antecedenciaHoras"
                                aria-labelledby="discrete-slider"
                                step={1}
                                min={0}
                                max={72}
                                marks={MARKS_HORAS_ANTECEDENCIA}
                                valueLabelDisplay="auto"
                                onChange={(event, value) => handleChangeSlider('antecedenciaHoras', value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={5}>

                            <Typography id="antecedencia-minutos-slider" gutterBottom>
                                Antecedência mínima (em minutos)
                            </Typography>

                            <Slider
                                key={`slider-${configuracao.antecedenciaMinutos}`}
                                defaultValue={configuracao.antecedenciaMinutos}
                                name="antecedenciaMinutos"
                                aria-labelledby="antecedencia-minutos-slider"
                                step={15}
                                min={0}
                                max={45}
                                marks={MARKS_MINUTOS_ANTECEDENCIA}
                                valueLabelDisplay="auto"
                                onChange={(event, value) => handleChangeSlider('antecedenciaMinutos', value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <Typography id="discrete-slider" gutterBottom>
                                Máximo de dias para agendamento (em dias)
                            </Typography>

                            <Slider
                                value={configuracao.numeroMaximoDiasAgendamento | ""}
                                name="numeroMaximoDiasAgendamento"
                                aria-labelledby="discrete-slider"
                                step={1}
                                min={0}
                                max={30}
                                marks={MARK_DIAS_AGENDAMENTO}
                                valueLabelDisplay="auto"
                                onChange={(event, value) => handleChangeSlider('numeroMaximoDiasAgendamento', value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>

                            <Typography id="intervalo-horario-slider" gutterBottom>
                                Intervalo Horário de Agendamento
                            </Typography>

                            <Slider
                                key={`slider-${configuracao.intervaloHorarioAgendamento}`}
                                defaultValue={configuracao.intervaloHorarioAgendamento}
                                name="intervaloHorarioAgendamento"
                                aria-labelledby="intervalo-horario-slider"
                                step={15}
                                min={15}
                                max={60}
                                marks={INTERVALO_HORARIO_AGENDAMENTO}
                                valueLabelDisplay="auto"
                                onChange={(event, value) => handleChangeSlider('intervaloHorarioAgendamento', value)}
                            />

                        </Grid>
                    </Grid>
                </Box>


                <Divider />

                <Box p={1}>
                    {configuracoesDias ? configuracoesDias.map((config, indexDiaSemana) => (
                        <Accordion key={indexDiaSemana} TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                {verificaDiaSemana(config.dayOfWeek)}
                            </AccordionSummary>

                            <FormControl component="fieldset">
                                <FormGroup>
                                    <AccordionDetails>
                                        <TableContainer>
                                            {intervaloHorarioList.map((horario, index) => (
                                                <FormControlLabel
                                                    key={index}
                                                    control={<Checkbox checked={config.times.includes(horario)} onChange={(e) => handleChangeHorarios(e, indexDiaSemana)} name={horario} />}
                                                    label={horario}
                                                />
                                            ))}
                                        </TableContainer>
                                    </AccordionDetails>
                                </FormGroup>
                            </FormControl>
                        </Accordion>
                    ))
                        : null}
                </Box>

                <Box p={1} display="flex" justifyContent="flex-end">
                    <Button onClick={handleSaveConfig} color="secondary" variant="contained">
                        Salvar
                    </Button>
                </Box>
            </Paper>

        </div >
    )
}
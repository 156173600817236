import React from "react";
import Template from "../Generico/Template"
import { withSnackbar } from "notistack";
import ConfigurarTempoEntrega from './configurarTempoEntrega'
import ConfigurarEndereco from './ConfigurarEndereco'
import TituloPrincipal from "../Generico/TituloPrincipal"
import { Box } from "@material-ui/core";
import CupomDesconto from "./CupomDesconto";
import PermissaoMunicipio from "./permissaoMunicipio";
import ConfiguracaoValorMinimo from "./ConfiguracaoValorMinimo";
import MensagemDelivery from "./MensagemDelivery";
import ConfiguracaoAgendamento from "./configuracaoAgendamento";
import ConfiguracaoFormatoEntrega from "./ConfiguracaoFormatoEntrega";

function Delivery() {

    return (
        <Template documentTitle="Configurações do delivery">
            <TituloPrincipal title="Configurações do delivery" subTitle="Configure as opções do seu delivery" />

            <Box mt={5} >
                <ConfigurarEndereco />
            </Box>
           
            <Box mt={5} >
                <MensagemDelivery />
            </Box>

            <Box mt={5}  >
                <ConfigurarTempoEntrega />
            </Box>
            
            <Box mt={5}>
                <ConfiguracaoValorMinimo/>
            </Box>

            <Box mt={10} >
                <ConfiguracaoFormatoEntrega />
            </Box>

            <Box mt={10} >
                <CupomDesconto />
            </Box>

            <Box mt={10}>
                <PermissaoMunicipio />
            </Box>

            <Box mt={10}>
                <ConfiguracaoAgendamento />
            </Box>
            
        </Template>
    );
}


export default withSnackbar(Delivery);

import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Cadastro from "./pages/Cadastro";
import Login from "./pages/Login";
import Cardapio from "./pages/Cardapio";
import Pedidos from "./pages/Pedidos";
import Identificadores from "./pages/Identificadores";
import Usuario from "./pages/Usuario";
import Chamado from "./pages/chamado";
import TelaBloqueado from "./pages/bloqueio/TelaBloqueado";
import TelaInativo  from "./pages/bloqueio/TelaInativo";
import FormaPagamento from "./pages/formaPagamento";
import Delivery from "./pages/delivery";
import CupomDesconto from "./pages/delivery/CupomDesconto";
import HorarioAbertura from "./pages/HorarioAbertura";
import RouteWithLayout from "./pages/Generico/RouteWithLayout"
import MainLayout from "./pages/Generico/MainLayout"
import Configuracoes from "./pages/configuracoes"
import Empresas from "./pages/empresas";
import Dashboard from "./pages/Dashboard";
import Avaliacao from "./pages/Avaliacao";
import Clientes from "./pages/clientes";
import AlterarSenha from "./pages/RecuperarSenha/alterarSenha";
import RecuperarSenha from "./pages/RecuperarSenha";
import CadastroEmpresaCliente from "./pages/CadastroEmpresaCliente";
import { ConfigNewUser } from "./pages/CadastroEmpresaCliente/configNewUser";
import TempoExpiradoTeste from "./pages/bloqueio/TempoExpiradoTeste";
import ExtraTime from "./pages/CadastroEmpresaCliente/extraTime";
import CustomerSalesByType from "./pages/clientes/Sales";
import compCadastroEmpresa from "./pages/cadastroEmpresa/components/compCadastroEmpresa";


const TOKEN = "id_token";


export const isAuthenticated = () => (
  localStorage.getItem(TOKEN) ? (
    true
  ) : (
      false
    )
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated() ? (
      <RouteWithLayout
        layout={MainLayout}
        {...props}
        component={Component}
      />
    ) : (
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
      )
  )} />
)

export const isAdmin = () => (
  JSON.parse(localStorage.getItem("_profile")).admin ? (
    true
  ) : (
      false
    )
);

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    isAdmin() ? (
      <RouteWithLayout
        layout={MainLayout}
        {...props}
        component={Component}
      />
    ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
  )} />
)


const Routes = () => (
  <BrowserRouter>
    <Switch>

      <Route exact path="/login" component={Login} />
      <Route exact path="/RecuperarSenha" component={RecuperarSenha} />
      <Route exact path="/AlterarSenha/:token" component={AlterarSenha} />
      <Route exact path="/Block" component={TelaBloqueado} />
      <Route exact path="/Inativo" component={TelaInativo} />
      <Route exact path="/NovaEmpresa" component={CadastroEmpresaCliente} />
      <Route exact path="/NovaEmpresaUser" component={CadastroEmpresaCliente} />
      <Route exact path="/ConfigNewUser/:token" component={ConfigNewUser} />
      <Route exact path="/TempoExpirado" component={TempoExpiradoTeste} />
      <Route exact path="/TempoExtra" component={ExtraTime} />

      <PrivateRoute exact path="/" component={Pedidos} />
      <PrivateRoute exact path="/Dashboard" component={Dashboard} />
      <PrivateRoute exact path="/Cardapio" component={Cardapio} />
      <PrivateRoute exact path="/Cadastro" component={Cadastro} />
      <PrivateRoute exact path="/chamados" component={Chamado} />
      <PrivateRoute exact path="/Pedidos" component={Pedidos} />
      <PrivateRoute exact path="/Identificadores" component={Identificadores} />
      <PrivateRoute exact path="/Usuario/:id" component={Usuario} />
      <PrivateRoute exact path="/Usuario" component={Usuario} />
      <PrivateRoute exact path="/FormaPagamento" component={FormaPagamento} />
      <PrivateRoute exact path="/Delivery" component={Delivery} />
      <PrivateRoute exact path="/CupomDesconto" component={CupomDesconto} />
      <PrivateRoute exact path="/HorarioAbertura" component={HorarioAbertura} />
      <PrivateRoute exact path="/Configuracoes" component={Configuracoes} />
      <PrivateRoute exact path="/Avaliacoes" component={Avaliacao} />
      <PrivateRoute exact path="/Clientes" component={Clientes} />
      <PrivateRoute exact path="/CustomerSalesByType" component={CustomerSalesByType}/>

      <AdminRoute exact path="/Empresas" component={Empresas} />
      {/* <AdminRoute exact path="/cadastroEmpresa" component={CadastroEmpresa} /> */}
      <AdminRoute exact path="/Dashboard" component={Dashboard} />


    </Switch>
  </BrowserRouter>
);

export default Routes;

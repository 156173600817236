import { Box, Button, Container, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { Component } from 'react'
import Logo from "../../imagens/logo-azul.svg";
import Fundo from "../../imagens/fundo-qrpedir-min.png"
import DadosPessoa from './Components/DadosPessoa';
import DadosEmpresa from './Components/DadosEmpresa';
import DadosTipoRestaurante from './Components/DadosTipoRestaurante';
import DadosPlano from './Components/DadosPlano';
import AuthService from '../../AuthService';
import { withSnackbar } from "notistack";
import * as Pixel from '../../utils/FacebookPixelUtil';


export class CadastroEmpresaCliente extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sending: false,
            usuario: {
                nome: "",
                email: "",
                telefone: ""
            },
            empresa: {
                nome: "",
                cpfCnpj: "",
                moeda: 'REAL',
                tipoConta: "TEMPORARIA",
                dataBloqueio : new Date((new Date()).setDate((new Date()).getDate() + 15))
            },
            tipoEmpresa: "outro",
            endereco: {
                endereco: "",
                numero: "",
                cep: "",
                bairro: "",
                municipio: null,
                estado: null,
                idPais: 1058,
            },
            estados: [],
            municipios: [],
            estado: null,
            municipio: null,
            step: 1,
            open: false,
            usuarioAdmin: null
        }
    }

    componentWillMount() {
        // let service = new AuthService();
        // if (service.loggedIn()) this.props.history.replace("/");
        this.handleVerificaUsuarioAdm()
        Pixel.init();
        Pixel.trackPageView();
    }

    handleVerificaUsuarioAdm = () => {
        let profile = localStorage.getItem("_profile")

        if (profile) {
            this.setState({ usuarioAdmin: JSON.parse(profile) })
        }
    }

    handleChangePessoa = (event) => {
        let { usuario } = this.state;
        usuario[event.target.name] = event.target.value;
        this.setState({ usuario })
    }

    handleChangeEmpresa = (event) => {
        let { empresa } = this.state;
        empresa[event.target.name] = event.target.value;
        this.setState({ empresa })
    }

    handleChangeTipoEmpresa = (event) => {
        this.setState({ tipoEmpresa: event.target.value })
    }

    handleChangeEndereco = (event) => {
        let { endereco } = this.state;
        endereco[event.target.name] = event.target.value;
        this.setState({ endereco })
    }

    handleUpdateEndereco = (newEndereco) => {
        let { endereco } = this.state
        endereco = newEndereco
        this.setState({ endereco })
    }

    handleChangeStep = (direction) => {
        var { step } = this.state;
        if (direction === "+") {
            step += 1;
        } else {
            step -= 1;
        }
        this.setState({ step });
    }

    carregarEstados = () => {

        let service = new AuthService();
        service
            .get("/estado/list")
            .then(retorno => {
                let options = retorno.map(elem => ({ "value": elem.id, "label": elem.nome }));
                options.push({ "value": null, "label": 'selecione um estado' })

                this.setState({ estados: options });
            })
            .catch(err => {
                console.error('Falha ao carregar estados', err)
            });

    }

    carregarMunicipios = (idEstado) => {

        let service = new AuthService();
        service
            .get(`/municipio/listByEstadoId/${idEstado}`)
            .then(retorno => {
                let options = retorno.map(elem => ({ "value": elem.id, "label": elem.nome }));
                options.push({ "value": null, "label": 'selecione um estado' })

                this.setState({ municipios: options });
            })
            .catch(err => {
                console.error('Falha ao carregar municipios', err)
            });

    }

    handleChangeEstado = (selectOption) => {
        var { endereco } = this.state;

        if (selectOption) {
            this.setState({ estado: selectOption })
            endereco.estado = { id: selectOption.value }
            this.setState({ endereco });
            this.setState({ municipio: null })
            this.carregarMunicipios(selectOption.value);
        } else {
            this.setState({ estado: null })
            endereco.estado = null
            this.setState({ endereco });
            this.setState({ municipios: null });
            this.setState({ municipio: null })
        }

    }

    handleChangeMunicipio = (selectOption) => {
        var { endereco } = this.state;

        if (selectOption) {
            this.setState({ municipio: selectOption })
            endereco.municipio = { id: selectOption.value };
            this.setState({ endereco })
        } else {
            this.setState({ municipio: null })
            endereco.municipio = null;
            this.setState({ endereco })
        }
    }

    handleCreateEmpresa = () => {
        const { usuario, empresa, endereco, tipoEmpresa, usuarioAdmin } = this.state;
        const md5 = require('js-md5');

        const userIsAdm = usuarioAdmin?.admin ? true : false

        if (userIsAdm) {
            empresa.tipoConta = "";
        }

        for (var [key, value] of Object.entries(endereco)) {
            empresa[key] = value
        }

        if (userIsAdm) {
            empresa.tipoConta = "";
        }

        usuario.empresa = empresa;
        usuario.createOptions = { tipoEmpresa: tipoEmpresa, ativarDelivery: true }
        usuario.username = usuario.email;
        usuario.password = md5("123");

        this.setState({ sending: true })

        let service = new AuthService();
        service
            .post(`/usuario/create?t=${!userIsAdm}`, usuario)
            .then(resp => {
                this.setState({ sending: false })

                console.log("resp", resp)
                if (userIsAdm) {
                    this.props.history.replace(`/ConfigNewUser/${resp.token}`)
                }

                this.setState({ open: true })

                let pixelObj = {
                    name: usuario.nome,
                    empresa_name: usuario.empresa.nome
                }

                Pixel.trackCustom('Cadastro', pixelObj)

            }).catch(err => {
                this.setState({ sending: false })
                console.error(err)
                err.response.json().then(elem => {
                    this.props.enqueueSnackbar('Infelizmente não conseguimos criar sua empresa. Entre em contato com nosso suporte. Detalhe: ' + elem.errorMessage, { variant: 'error' })
                });
            })

    }

    render() {

        const styles = {
            fundo: {
                height: "100vh",
                background: `url(${Fundo}) repeat`
            }
        }

        var { usuario, step, empresa, tipoEmpresa, endereco, estados, municipios, sending } = this.state;

        return (
            <Box style={styles.fundo} pt={4} >
                <Container maxWidth="sm">
                    <Box display="flex" justifyContent="center" p={4} mt={4}>
                        <img style={{ width: "15vw", minWidth: "200px" }} alt="logo-qrpedir" src={Logo} />
                    </Box>

                    {step === 1 ? (
                        <DadosPessoa
                            pessoa={usuario}
                            handleChangePessoa={this.handleChangePessoa}
                            handleChangeStep={this.handleChangeStep} />
                    ) : step === 2 ? (
                        <DadosEmpresa
                            empresa={empresa}
                            handleChangeEmpresa={this.handleChangeEmpresa}
                            handleChangeStep={this.handleChangeStep} />
                    ) : step === 3 ? (
                        <DadosTipoRestaurante
                            tipoEmpresa={tipoEmpresa}
                            handleChangeTipoEmpresa={this.handleChangeTipoEmpresa}
                            handleChangeStep={this.handleChangeStep} />
                    ) : step === 4 ? (
                        <DadosPlano
                            endereco={endereco}
                            estados={estados}
                            municipios={municipios}
                            handleUpdateEndereco={this.handleUpdateEndereco}
                            handleChangeEndereco={this.handleChangeEndereco}
                            carregarEstados={this.carregarEstados}
                            handleCreateEmpresa={this.handleCreateEmpresa}
                            handleChangeStep={this.handleChangeStep}
                            handleChangeEstado={this.handleChangeEstado}
                            handleChangeMunicipio={this.handleChangeMunicipio}
                            estado={this.state.estado}
                            municipio={this.state.municipio}
                            sending={sending} />
                    ) : null}
                    <Dialog open={this.state.open}>
                        <DialogTitle>
                            <Typography align="center" variant="h5">
                                Enviamos um e-mail para finalizar seu cadastro.
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" justifyContent="center">
                                <Button variant="contained" color="primary" onClick={() => window.location.href = process.env.REACT_APP_STATION_URL}>
                                    Voltar a pagina inicial
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </Container>
            </Box>
        )
    }
}

export default withSnackbar(CadastroEmpresaCliente)

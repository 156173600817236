import React, {useEffect} from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "./styles.css";
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import Logo from "../../imagens/logo-qrpedir.svg";
import Cardapio from "../../imagens/cardapio.svg";
import Pedidos from "../../imagens/pedidos.svg";
import QrCode from "../../imagens/qrCode.svg";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import Chip from '@material-ui/core/Chip';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import AuthService from "../../AuthService";
import { NavLink } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from '@material-ui/core/Tooltip';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import StatusLoja from './StatusLoja'
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemIcon,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TuneIcon from '@material-ui/icons/Tune';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SentimentVerySatisfiedOutlinedIcon from '@material-ui/icons/SentimentVerySatisfiedOutlined';
import moment from "moment";
import CloseIcon from '@material-ui/icons/Close';
import DialogTutorial from "../DialogTutorial";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SmartphoneIcon from '@material-ui/icons/Smartphone';

const drawerWidth = 270;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarTemporaria: {
    marginTop: window.innerWidth > 600 ? "40px" : "70px",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarTeste: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36,
    color: "#ffffff",
  },
  menuList: {
    marginRight: 36,
    fontSize: "20px"
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: "10px",
      background: "rgba(10,23,55,0.2)",
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)"
    }
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "#F2C26A",
  },
  drawerAppOpen: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: "white",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1
    },
    '&::-webkit-scrollbar': {
      width: '0.2em'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: "10px",
      background: "rgba(10,23,55,0.2)",
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.5)"
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  div: {
    flexGrow: 1,
    textAlign: "center"
  },
  img: {
    width: "100px",
  },
  root: {
    background: "#F2C26A"
  },
  ListSettings: {
    marginBottom: "3%",
    color: "#253257",
    fontSize: "150%",
    fontFamily: "Bebas Neue"
  },
  List: {
    background: "#F0CA84",
    marginBottom: "3%",
    color: "#253257",
    fontSize: "150%",
    fontFamily: "Bebas Neue"
  },
  ListPrincipal: {
    marginTop: "2%"
  },
  ListButton: {
    marginTop: "20px",
    marginBottom: " 10px",
    "&:hover": {
      background: "transparent"
    }
  },
  Avatar: {
    marginTop: 20,
    background: theme.palette.background.paper,
    color: "#253257",
    "&:hover": {
      opacity: "0.5"
    }
  },
  Avatar2: {
    marginTop: 20,
    width: "150px",
    height: "150px",
    fontSize: "3em"
  },
  a: {
    fontSize: "10px"
  },
  btnBranco: {
    color: "#ffffff !important",
  }
}));

export default function MenuPrincipal() {
  const auth = new AuthService();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openCollapse, setOpenCollapse] = React.useState(false);
  const [openCollapseAdmin, setOpenCollapseAdmin] = React.useState(false);
  const [openDialogBlock, setOpenDialogBlock] = React.useState(false);
  const [empresa, setEmpresa] = React.useState(auth.getProfile().empresa);
  const [openTutorial, setOpenTutorial] = React.useState(false);
  const [identificadorDelivery, setIdentificadorDelivery] = React.useState(null);
  const [openTesteApp, setOpenTesteApp] = React.useState(false);



  const handleOpenBlockDialog = () => {
    setOpenDialogBlock(!openDialogBlock);
  }

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleSair = () => {
    console.log('Realizando logout...')
    auth.logout()
    window.location = "/login"
  }

  function handleOpenSettings() {
    setOpenCollapse(!openCollapse);
  }

  const getEmpresa = () => {
    auth
      .get(`/empresa/${auth.getProfile().empresa.id}`)
      .then(resp => {
        setEmpresa(resp);
        var profile = auth.getProfile();
        profile.empresa = resp;
        auth.setProfile(profile);
      })
  }

  const getDifferenceBlockDate = () => {
    if (empresa.dataBloqueio) {
      var momentDate1 = moment(new Date(empresa.dataBloqueio));
      var momentDate2 = moment(new Date());
      const duration = moment.duration(momentDate1.diff(momentDate2));
      return Math.ceil(duration.asDays());
    }
  }

  function handleOpenSettingsAdmin() {
    setOpenCollapseAdmin(!openCollapseAdmin);
  }

  function handleClickLinkPlano() {
    if (empresa) {
      window.open("https://app.galaxpay.com.br/landing1868454118/planoqrpedir", '_blank');
    }
  }

  function getIdentificadorDelivery() {
    let service = new AuthService();

   


    service
      .get("/identificador/getFirstDelivery")
      .then(resp => {
        if (resp.id) {
          setIdentificadorDelivery(resp.uuid);
          const empresa = resp.empresa;
          if (empresa?.tipoConta === "TEMPORARIA" || empresa?.tipoConta === "TEMPORARIA_EXTENDED") {
            const testeAppOpenStorage = window.localStorage.getItem("_TESTE_APP_") === 'false' ? false : true
            setOpenTesteApp(testeAppOpenStorage);
          }
        }
      })
  }

  function getMensagemBoqueio() {
    if (getDifferenceBlockDate() >= 2) {
      return (<Typography variant="body1" align="center" style={{ position: "relative", left: window.innerWidth > 600 ? "54px" : "0", fontFamily: "Bebas Neue", fontSize: "20px" }}>
        <strong>{getDifferenceBlockDate()}</strong> dias restantes</Typography>)
    } else if (getDifferenceBlockDate() == 1) {
      return (<Typography variant="body1" align="center" style={{ position: "relative", left: window.innerWidth > 600 ? "54px" : "0", fontFamily: "Bebas Neue", fontSize: "20px" }}>
        Último dia de teste grátis!</Typography>)
    } else if (getDifferenceBlockDate() < 1) {
      return (
        <Box display="block">
          <Typography variant="body1" align="center" 
          style={{  fontFamily: "Bebas Neue" }}>
          Seu período de avaliação terminou 😟
          <Button
            onClick={handleClickLinkPlano}
            variant="outlined"
            size="small"  >
              Assinar agora
            </Button>
          </Typography>
         
        </Box>
        )
    }
  }

  function handleEnviarWhatsApp() {
    const fone = "5546991390992"

    var mensagem = `Olá, sou da empresa ${empresa?.nome}, estou experimentando o Qrpedir e gostaria de tirar uma dúvida!`
    window.open(`https://wa.me/${fone}?text=${mensagem}`, '_blank')
  }

  useEffect(() => {
    getEmpresa();
    setOpenTutorial(window.localStorage.getItem("_OPEN_TUTORIAL_") === 'false' ? false : true);

    getIdentificadorDelivery();
    
  }, [])

  function toogleOpenTutorial(){
    setOpenTutorial(!openTutorial)
    window.localStorage.setItem("_OPEN_TUTORIAL_", !openTutorial)
  }
 

  function toogleTesteApp(){
    setOpenTesteApp(!openTesteApp)
    window.localStorage.setItem("_TESTE_APP_", !openTesteApp)
  }

  function handleCriarNovaEmpresa() {
    window.location = "/NovaEmpresa"
  }

  let nomeUsuario = JSON.parse(localStorage.getItem("_profile")).nome

  const drawerLinks = (
    <div>
      {empresa ?
        empresa.tipoConta == "TEMPORARIA" || empresa.tipoConta == "TEMPORARIA_EXTENDED" ? (
          <DialogTutorial open={openTutorial} close={toogleOpenTutorial} />
        ) : null : null}
      <Box style={{ marginTop: empresa.tipoConta == "TEMPORARIA" || empresa.tipoConta == "TEMPORARIA_EXTENDED" ? (window.innerWidth > 600 ? "30px" : "60px") : 0 }}>
        <NavLink to="/Cadastro">
          <Grid container justifyContent="center" >
            <Avatar
              variant={empresa.formatoLogo}
              alt={empresa.nome}
              src={empresa.urlLogo}
              className={clsx(classes.Avatar, { [classes.Avatar2]: open })}
            >
              <strong style={{ fontFamily: "Bebas Neue", fontWeight: "600" }}>
                {empresa.nome.substr(0, 2)}
              </strong>
            </Avatar>
          </Grid>

          <ListItem
            className={clsx(classes.menuList, { [classes.hide]: !open })}  >
            <Grid container>
              <strong style={{ fontFamily: "Bebas Neue", fontSize: "24px", whiteSpace: "normal", textAlign: "center", width: "100%" }}> {auth.getProfile() === null ? 'NOME RESTAURANTE' : empresa.nome} </strong>
            </Grid>
          </ListItem>
        </NavLink>
      </Box>

      <List className={classes.ListPrincipal}>

        <NavLink to="/Dashboard">
          <Tooltip title={
            <Typography color="inherit">Dashboard</Typography>
          }
            arrow placement="right">
            <ListItem button className={classes.List}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                  <AssessmentOutlinedIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              Dashboard
          </ListItem>

          </Tooltip>
        </NavLink>

        <NavLink to="/Cardapio">
          <Tooltip title={
            <Typography color="inherit">Cardápio</Typography>
          }
            arrow placement="right">
            <ListItem button className={classes.List}>
              <ListItemAvatar>
                <Avatar src={Cardapio} color="primary" />
              </ListItemAvatar>
            &nbsp;&nbsp;Cardápio
          </ListItem>

          </Tooltip>
        </NavLink>

        <NavLink to="/Pedidos">
          <Tooltip title={
            <Typography color="inherit">Pedidos</Typography>
          }
            arrow placement="right">
            <ListItem button className={classes.List}>
              <ListItemAvatar>
                <Avatar src={Pedidos} color="primary" />
              </ListItemAvatar>
            &nbsp;&nbsp;Pedidos
          </ListItem>
          </Tooltip>
        </NavLink>

        <NavLink to="/Avaliacoes">
          <Tooltip title={
            <Typography color="inherit">Avaliações</Typography>
          }
            arrow placement="right">
            <ListItem button className={classes.List}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }} ><StarBorderIcon style={{ height: "40px" }} /></Avatar>
              </ListItemAvatar>
            &nbsp;&nbsp;Avaliações
          </ListItem>

          </Tooltip>
        </NavLink>

        <NavLink to="/Clientes">
          <Tooltip title={
            <Typography color="inherit">Clientes</Typography>
          }
            arrow placement="right">
            <ListItem button className={classes.List}>
              <ListItemAvatar>
                <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }} ><SentimentVerySatisfiedOutlinedIcon style={{ height: "40px" }} /></Avatar>
              </ListItemAvatar>
            &nbsp;&nbsp;Clientes
          </ListItem>

          </Tooltip>
        </NavLink>

        <Tooltip title={
          <Typography color="inherit">Configurações</Typography>
        }
          arrow placement="right">
          <ListItem button className={classes.List} onClick={handleOpenSettings}>
            <ListItemIcon>
              <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                <SettingsOutlinedIcon color="primary" />
              </Avatar>
            </ListItemIcon>
          &nbsp;&nbsp;Configurações

          {openCollapse ? <ExpandLessIcon style={{ marginLeft: "35px" }} /> : <ExpandMoreIcon style={{ marginLeft: "35px" }} />}
          </ListItem>
        </Tooltip>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>

            <NavLink to="/Configuracoes">
              <Tooltip title={
                <Typography color="inherit">Configurações gerais</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                      <TuneIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
              &nbsp;&nbsp;Gerais
            </ListItem>
              </Tooltip>
            </NavLink>

            <NavLink to="/Delivery">
              <Tooltip title={
                <Typography color="inherit">Configurações do Delivery</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                      <MotorcycleIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                &nbsp;&nbsp;Delivery
              </ListItem>

              </Tooltip>
            </NavLink>

            <NavLink to="/Identificadores">
              <Tooltip title={
                <Typography color="inherit">Mesas e QRCodes</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar src={QrCode} />
                  </ListItemAvatar>
                  &nbsp;&nbsp;Mesas e QRCodes
                </ListItem>
              </Tooltip>
            </NavLink>

            <NavLink to="/FormaPagamento">
              <Tooltip title={
                <Typography color="inherit">Formas de Pagamento</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                      <PaymentOutlinedIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  &nbsp;&nbsp;Formas de Pagamento
                </ListItem>
              </Tooltip>
            </NavLink>

            <NavLink to="/HorarioAbertura">
              <Tooltip title={
                <Typography color="inherit">Horário de abertura</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                      <AccessTimeIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
                  &nbsp;&nbsp;Horário de abertura
                </ListItem>
              </Tooltip>
            </NavLink>

            <NavLink to="/chamados">
              <Tooltip title={
                <Typography color="inherit">Chamados</Typography>
              }
                arrow placement="right">
                <ListItem button className={classes.ListSettings}>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                      <NotificationsNoneOutlinedIcon color="primary" />
                    </Avatar>
                  </ListItemAvatar>
            &nbsp;&nbsp;Chamados
            </ListItem>
              </Tooltip>
            </NavLink>

          </List>
        </Collapse>

        {auth.isAdmin() ? (
          <React.Fragment>
            <Tooltip title={
              <Typography color="inherit">Configurações suporte</Typography>
            }
              arrow placement="right">
              <ListItem button className={classes.List} onClick={handleOpenSettingsAdmin}>
                <ListItemIcon>
                  <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                    <SupervisorAccountOutlinedIcon color="primary" />
                  </Avatar>
                </ListItemIcon>
          &nbsp;&nbsp;Suporte

          {openCollapseAdmin ? <ExpandLessIcon style={{ marginLeft: "82px" }} /> : <ExpandMoreIcon style={{ marginLeft: "82px" }} />}
              </ListItem>
            </Tooltip>
            <Collapse in={openCollapseAdmin} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>

                <Tooltip title={
                  <Typography color="inherit">Cadastro de Empresas</Typography>
                }
                  arrow placement="right">
                  <ListItem onClick={handleCriarNovaEmpresa} button className={classes.ListSettings}>
                    <ListItemAvatar>
                      <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                        <AddCircleOutlineOutlinedIcon color="primary" />
                      </Avatar>
                    </ListItemAvatar>
                    &nbsp;&nbsp;Cadastro de empresas
                  </ListItem>
                </Tooltip>

                <NavLink to="/Empresas">
                  <Tooltip title={
                    <Typography color="inherit">Configurações das Empresas</Typography>
                  }
                    arrow placement="right">
                    <ListItem button className={classes.ListSettings}>
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                          <BusinessOutlinedIcon color="primary" />
                        </Avatar>
                      </ListItemAvatar>
                      &nbsp;&nbsp;Empresas
                    </ListItem>
                  </Tooltip>
                </NavLink>

                <NavLink to="/Usuario">
                  <Tooltip title={
                    <Typography color="inherit">Mudar empresa logada</Typography>
                  }
                    arrow placement="right">
                    <ListItem button className={classes.ListSettings}>
                      <ListItemAvatar>
                        <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                          <SwapHorizIcon color="primary" />
                        </Avatar>
                      </ListItemAvatar>
                      &nbsp;&nbsp;Mudar empresa
                    </ListItem>
                  </Tooltip>
                </NavLink>

              </List>
            </Collapse>
          </React.Fragment>
        ) : null}

        {auth.loggedIn() ?
          (
            <Tooltip title={
              <Typography color="inherit">Sair</Typography>
            }
              arrow placement="right">
              <ListItem button className={classes.List} onClick={handleSair}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                    <ExitToAppIcon color="primary" /></Avatar>
                </ListItemAvatar>
                &nbsp;&nbsp;SAIR
              </ListItem>
            </Tooltip>
          )
          :
          (
            <NavLink to="/Login" className={classes.btnBranco}>
              <ListItem button className={classes.List}>
                <ListItemAvatar>
                  <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}>
                    <PowerSettingsNewOutlinedIcon color="primary" />
                  </Avatar>
                </ListItemAvatar>
                &nbsp;&nbsp;Acessar
            </ListItem>
            </NavLink>
          )
        }

        <Tooltip title={
          <Typography color="inherit">Ajuda</Typography>
        }
          arrow placement="right">
          <ListItem button className={classes.List} onClick={() => {
            window.open(
              'https://qrpedir.com/suporte',
              '_blank'
            )
          }}>
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: "#F2C26A", color: "#253257" }}><HelpOutlineOutlinedIcon color="primary" /></Avatar>
            </ListItemAvatar>
                &nbsp;&nbsp;Ajuda
              </ListItem>
        </Tooltip>
      </List>
    </div>
  );


  return (
    <div className={classes.root}>
      <CssBaseline />

      {empresa ?
        empresa.tipoConta == "TEMPORARIA" || empresa.tipoConta == "TEMPORARIA_EXTENDED" ? (
          <AppBar
            className={clsx(classes.appBarTeste, {
              [classes.appBarShift]: open,
            })}
            position="fixed"
            color="secondary">

            <Box display="flex" alignItems="center" justifyContent="space-between" style={{ height: window.innerWidth > 600 ? "40px" : "70px" }}>
              <Typography style={{ fontFamily: "Bebas Neue", fontSize: "20px", margin: "15px" }}>
                Periodo de teste
          </Typography>

              {getMensagemBoqueio()}

              <Box>
                <Button
                  onClick={ toogleOpenTutorial }
                  color="primary"
                  size="small"  >
                  Como funciona?
                </Button>
                <Button
                  onClick={handleClickLinkPlano}
                  className={"pendenteAnimationGreen"}
                  variant="contained"
                  size="small" 
                  style={{ fontFamily: "Bebas Neue", fontSize: "18px", padding: "0 10px",  backgroundColor: "#66BB6A", margin: "0 10px" }}>
                  Assinar
                </Button>

                <IconButton style={{ marginRight: "15px" }} onClick={() => handleEnviarWhatsApp()}>
                  <WhatsAppIcon color="primary" />
                </IconButton>
              </Box>
            </Box>

          </AppBar>
        ) : null : null}
      <AppBar
        position="fixed"
        className={clsx(empresa?.tipoConta == "TEMPORARIA" || empresa.tipoConta == "TEMPORARIA_EXTENDED" ? classes.appBarTemporaria : classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap style={{ fontFamily: "Bebas Neue", fontWeight: "500" }}>
            Station
          </Typography>
          {auth.isAdmin() && !!empresa?.representante && 
            <Box pl={1}>
              <Chip label={empresa?.representante} color="secondary" style={{ fontFamily: "Bebas Neue", fontWeight: "500", fontSize: "20px" }} />
            </Box>}
          <div className={classes.div}>
            <Hidden smDown implementation="css">
              <img className={classes.img} src={Logo} alt="Logo qrpedir" />
            </Hidden>
          </div>

          {/* {getDifferenceBlockDate() < 5 ? (
            <Tooltip title={
              <Typography color="inherit">O sistema será bloqueado em breve!</Typography>
            }
              arrow placement="bottom">
              <IconButton onClick={() => { handleOpenBlockDialog() }}>
                <Avatar style={{ backgroundColor: "#FF4848", color: "#253257" }} className={"pendenteAnimationSecondary"}><ErrorOutlineIcon color="primary" /></Avatar>
              </IconButton>
            </Tooltip>
          ) : null} */}

          {auth.loggedIn() ?
            (
              <React.Fragment>

                {identificadorDelivery ? (
                  <Hidden smDown implementation="css">
                  <Tooltip disableFocusListener title="Mostrar app">
                  <IconButton 
                    onClick={toogleTesteApp}
                    color="inherit">
                    <SmartphoneIcon  />
                  </IconButton>
                  </Tooltip>
                  </Hidden>
                ) : null}

                <StatusLoja />

                 
                <Tooltip disableFocusListener title={nomeUsuario}>
                <IconButton color="inherit" >
                    <AccountCircle />  
                </IconButton>
                </Tooltip>
                 

              </React.Fragment>
            ) : (
              <NavLink to="/Login" className={classes.btnBranco}>
                <span>Acessar</span>
              </NavLink>
            )
          }
        </Toolbar>
      </AppBar>

      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={open}
          onClose={handleDrawerToggle}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerPaper]: true,
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerLinks}
        </Drawer>
      </Hidden>


      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open} >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          {drawerLinks}

        </Drawer>
      </Hidden>

      {identificadorDelivery ? (
        <Hidden smDown implementation="css">
        <Drawer
          open={openTesteApp}
          variant="persistent"
          classes={{
            paper: classes.drawerAppOpen,
          }}
          anchor="right"
        >
          <Box display="flex" 
            justifyContent="center" 
            alignItems="center"
            pr={3} pt={16}>

            <IconButton 
              color="inherit"
              onClick={toogleTesteApp}
              size="small" >
              <ChevronRightIcon />
            </IconButton>

            
            <Box>
              <Box p={1} fontSize="h5.fontSize" align="center" display="block"> Confere o seu app 👇</Box>
              <Box boxShadow={2}>
                <iframe 
                  frameBorder="0"
                  style={{  minWidth: "20vw", height: "75vh" }} 
                  src={(process.env.NODE_ENV === 'production' ? "https://" : "http://") + identificadorDelivery + (process.env.NODE_ENV === 'production' ? ".qrpedir.com" : ".localhost:3001")} 
                  title="Preview app qrpedir" sanddbox="true">
                </iframe>
              </Box>
                
            </Box>
          </Box>
        </Drawer>
        </Hidden>
      ) : null}

      <Dialog maxWidth="xs" fullWidth open={openDialogBlock}>
        <DialogTitle>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={handleOpenBlockDialog}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box p={2} mb={3}>
            {getMensagemBoqueio()}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => window.open("https://qrpedir.com/suporte", "_blank")}>
            Falar com um consultor
          </Button>
          <Button variant="contained" color="primary" onClick={handleClickLinkPlano}>
            Ativar Agora
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}

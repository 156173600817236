import { Box, Button, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@material-ui/core'
import React from 'react'
import Prato from '../../../imagens/prato.png'
import Outro from '../../../imagens/outros.png'
import Pizza from '../../../imagens/pizza.png'
import Lanche from '../../../imagens/lanche.png'

export default function DadosTipoRestaurante(props) {

    return (
        <Paper>
            <Box p={2} display="flex" flexDirection="column" justifyContent="space-between" alignItems="center">
                <Typography align="center" variant="h5" style={{ margin: "10px 0" }}>
                    Ainda sobre sua empresa
                </Typography>

                <Typography align="center" variant="body1">
                  Qual tipo de estabelecimento mais se adequa?
                </Typography>

                <RadioGroup value={props.tipoEmpresa} onChange={props.handleChangeTipoEmpresa} style={{ display: "flex", flexDirection: "column" }}>

                    <Box display="flex">
                        <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "115px", minWidth: "100px", margin: "10px" }}>
                            <img style={{ width: "70px" }} alt="prato" src={Prato} />
                            <FormControlLabel labelPlacement="top" value="aLaCarte" control={<Radio />} label={<Typography variant="body2">à la carte</Typography>} />
                        </Paper>

                        <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "115px", minWidth: "100px", margin: "10px" }}>
                            <img style={{ width: "70px" }} alt="pizza" src={Pizza} />
                            <FormControlLabel labelPlacement="top" value="pizza" control={<Radio />} label={<Typography variant="body2">Pizzas</Typography>} />
                        </Paper>
                    </Box>

                    <Box display="flex">
                        <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "115px", minWidth: "100px", margin: "10px" }}>
                            <img style={{ width: "70px" }} alt="lanche" src={Lanche} />
                            <FormControlLabel labelPlacement="top" value="lanche" control={<Radio />} label={<Typography variant="body2">Lanches</Typography>} />
                        </Paper>

                        <Paper style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", padding: "20px", height: "115px", minWidth: "100px", margin: "10px" }}>
                            <img style={{ width: "70px" }} alt="outros" src={Outro} />
                            <FormControlLabel labelPlacement="top" value="outro" control={<Radio />} label={<Typography variant="body2">outro</Typography>} />
                        </Paper>
                    </Box>

                </RadioGroup>


                <Box display="flex" justifyContent="space-between" style={{ marginTop: "20px", width: "100%" }}>
                    <Button
                        onClick={() => props.handleChangeStep("-")}
                        variant="text">
                        Retornar
                    </Button>

                    <Button
                        onClick={() => props.handleChangeStep("+")}
                        variant="contained"
                        color="primary">
                        Próximo
                    </Button>
                </Box>
            </Box>
        </Paper>
    )
}

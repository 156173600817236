import { Box, Button, Container, Paper, TextField, Typography } from '@material-ui/core'
import React from 'react'
import Logo from "../../../imagens/logo-azul.svg";
import Fundo from "../../../imagens/fundo-qrpedir-min.png"
import AuthService from '../../../AuthService';
import { useSnackbar } from 'notistack';

export default function ExtraTime(props) {
    const { enqueueSnackbar } = useSnackbar();

    const [senha, setSenha] = React.useState(null);
    const [login, setLogin] = React.useState(null);
    const [acessando, setAcessando] = React.useState(null);

    const handleFormSubmit = (e) => {

        e.preventDefault();
        const md5 = require('js-md5');
        setAcessando(true)
        const service = new AuthService();

        var credentials = {
            username: login,
            password: md5(senha)
        };

        service
            .post("/empresa/liberaExtraTime", credentials)
            .then(res => {

                var response = service.verificaBloqueio(res.empresa.dataBloqueio, res);

                if (response.id) {
                    console.log('Retornou do server token' + res.token)
                    service.setProfile(res)
                    service.setToken(res.token) // Configurando o token em localStorage
                }

                if (response.id != null) {

                    setAcessando(false)
                    props.history.replace("/Cardapio");

                } else if (res.text === "BLOCK_EMPRESA") {

                    props.history.replace("/Block");

                } else if (res.text === "BLOCK_EMPRESA_TEMPORARIA") {
                    props.history.push({
                        pathname: '/TempoExpirado',
                        state: { tipo: res.empresa.tipoConta }
                    })
                }
            })
            .catch(err => {
                setAcessando(false)
                if (err.response === undefined) {
                    console.error(err)
                } else {
                    err.response.json().then(elem => {
                        console.error(elem.errorMessage);
                        //alert(elem.errorMessage);
                        enqueueSnackbar(elem.errorMessage, { variant: 'error' });
                    });
                }
            });

    }

    const styles = {
        fundo: {
            height: "100vh",
            background: `url(${Fundo}) repeat`
        }
    }
    return (
        <Box style={styles.fundo} pt={4} >
            <Container maxWidth="sm">
                <Box display="flex" justifyContent="center" p={4} mt={4}>
                    <img style={{ width: "15vw", minWidth: "200px" }} alt="logo" src={Logo} />
                </Box>

                <Paper>
                    <Box p={3} display="flex" alignItems="center" flexDirection="column">
                        <Typography align="center" variant="h5" style={{ margin: "10px 0" }}>
                            Para resgatar mais tempo de teste, faça o login!
                        </Typography>

                        <TextField
                            style={{ maxWidth: "386px", margin: "10px" }}
                            label="E-mail"
                            variant="outlined"
                            name="username"
                            fullWidth
                            onChange={e => setLogin(e.target.value)}
                        />
                        <TextField
                            style={{ maxWidth: "386px", margin: "10px" }}
                            label="Senha"
                            type="password"
                            variant="outlined"
                            name="password"
                            fullWidth
                            onChange={e => setSenha(e.target.value)}
                        />
                        <Button
                            style={{ margin: "10px 0" }}
                            variant="contained" color="primary"
                            value="submit"
                            type="submit"
                            size="large"
                            disabled={acessando}
                            onClick={handleFormSubmit}
                        >
                            {acessando? "Entrando": "Entrar"}
                      </Button>
                    </Box>
                </Paper>

            </Container>
        </Box>
    )
}
import React from "react";
import { Button, TextField, Box, Paper, InputAdornment } from "@material-ui/core";
import BarraTitulo from "../Generico/BarraTitulo"
import AuthService from "../../AuthService";
import Grid from '@material-ui/core/Grid';
import { useSnackbar } from 'notistack';

export default function ConfigurarTempoEntrega() {
    const service = new AuthService()
    const { enqueueSnackbar } = useSnackbar();
    const [empresa, setEmpresa] = React.useState(null);
    const [values, setValues] = React.useState({
        tempoMinPreparo: '',
        tempoMaxPreparo: '',
        tempoRetirada: ''
      });

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
    };


    const handleSalvar = () => {
        let empresaToChange = {
            id: empresa.id, 
            tempoMinPreparo: values.tempoMinPreparo, 
            tempoMaxPreparo: values.tempoMaxPreparo, 
            tempoRetirada: values.tempoRetirada
        }
        service.put('/empresa/changeOnly', empresaToChange)
        .then(empresaRetorno => {
            setEmpresa(empresaRetorno)
            setValues({  
                tempoMinPreparo: empresaRetorno.tempoMinPreparo, 
                tempoMaxPreparo: empresaRetorno.tempoMaxPreparo, 
                tempoRetirada: empresaRetorno.tempoRetirada  });
            service.atualizarEmpresaProfile(empresaRetorno)
            enqueueSnackbar("Tempo de preparo atualizado com sucesso", { variant: 'success' })
        }).catch(err =>{
            console.error(err)
            enqueueSnackbar("Falha ao salvar tempo de preparo", { variant: 'error' })
        })

    };
    React.useEffect(() => {
        service.get('/login/empresaLogada')
        .then(empresa => {
            setEmpresa(empresa)
            setValues({ tempoMinPreparo: empresa.tempoMinPreparo, 
                        tempoMaxPreparo: empresa.tempoMaxPreparo, 
                        tempoRetirada: empresa.tempoRetirada  });
        }).catch(err =>{
            console.error(err)
        })

        let hash = document.location.hash
        if (hash){
            let element = document.getElementById( hash.replace("#", "") );
            if (element){
                element.scrollIntoView({block: "start", behavior: "smooth"})
            }
        }
        

    }, []);

  return (
    <React.Fragment>
        <BarraTitulo   
            title="Tempo de preparo"/>

            <Paper id="mostrarTempoEntrega">  
                <Box p={3} display='flex' flexDirection='row' alignItems='center' justifyContent='center'>

                    <Grid container spacing={2} direction="row" alignItems="center">

                        <Grid item xs={12} md={10}>
                            <Grid container spacing={2} direction="row" alignItems="center" >
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        name="tempoRetirada"
                                        onChange={handleChange}
                                        value={values.tempoRetirada}
                                        fullWidth
                                        label="Tempo para retirada no local"
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mínutos</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Tempo mínimo"
                                        name="tempoMinPreparo"
                                        onChange={handleChange}
                                        value={values.tempoMinPreparo}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mínutos</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        variant="outlined"
                                        label="Tempo máximo"
                                        name="tempoMaxPreparo"
                                        value={values.tempoMaxPreparo}
                                        onChange={handleChange}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">mínutos</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={12} md={2}>
                            <Button fullWidth
                                color="secondary"
                                onClick={handleSalvar}
                                variant="contained">Salvar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Paper>
    </React.Fragment>
  );
}
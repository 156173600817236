import React, { useState, useEffect } from 'react'
import {
  RadioGroup,
  Radio,
  FormControlLabel,
  Box,
  Checkbox,
  Paper,
  FormControl,
  FormLabel,
  FormGroup,
  Button,
  Grid
} from '@material-ui/core'
import BarraTitulo from "../Generico/BarraTitulo"
import ConfiguracaoEntregaBairro from './configuracaoEntregaBairro'
import ConfiguracaoTaxa from './configuracaoTaxa'
import AuthService from "../../AuthService";

import { useSnackbar } from 'notistack';

export default function ConfiguracaoFormatoEntrega() {
  const { enqueueSnackbar } = useSnackbar();

  const [empresa, setEmpresa] = useState(null)
  const [tipoEntrega, setTipoEntrega] = useState(null)
  const [formaRetirada, setFormaRetirada] = useState({})

  useEffect(() => {
    let service = new AuthService
    service.get('/login/empresaLogada')
      .then(empresa => {
        setEmpresa(empresa)
        setTipoEntrega(empresa.configuracaoEntrega)
        
        let form = JSON.parse(empresa.formaRetirada)
      
        let formaTemp = {formaRetiradaDelivery: '', formaRetiradaLocal: '' }

        form.map((e) => {
          if (e === 'D') {
            formaTemp = { ...formaTemp, formaRetiradaDelivery: 'D' }
          } else if (e === 'L') {
            formaTemp = { ...formaTemp, formaRetiradaLocal: 'L' }
          } else if (e === 'C'){
            formaTemp = { ...formaTemp, formaConsumirLocal: 'C' }
          }
        })
 
        setFormaRetirada(formaTemp)
      }).catch(err => {
        console.error(err)
      })
  }, [])

  const handleChange = (event) => {
    setTipoEntrega(event.target.value)

    let valorTipoEntrega = event.target.value
    let copiaEmpresa = Object.assign({}, empresa)

    copiaEmpresa.configuracaoEntrega = valorTipoEntrega

    setEmpresa(copiaEmpresa)
  }

  const handleSaveEmpresa = (empresa) => {
    console.log('handlesave', empresa)
    let service = new AuthService
    service
      .post('/empresa', empresa)
      .then(resp => {
        let profile = service.getProfile()
        profile.empresa = resp
        localStorage.setItem("_profile", JSON.stringify(profile))
        enqueueSnackbar('Configuração salva com sucesso.', { variant: 'success' })
      })
      .catch(err => {
        enqueueSnackbar('Erro ao salvar configuração.', { variant: 'error' })
        console.error(err)
      })
  }

  const salvar = () => {
    let configuracaoFormaRetirada = [formaRetirada.formaRetiradaLocal ? 'L' : '', formaRetirada.formaRetiradaDelivery ? 'D' : '', formaRetirada.formaConsumirLocal ? 'C' : '']

    let copiaEmpresa = Object.assign({}, empresa)
    copiaEmpresa.formaRetirada = JSON.stringify(configuracaoFormaRetirada)
    handleSaveEmpresa(copiaEmpresa)
  }

  const alterarConfiguracao = (event, formaRetiradaSelecionada) => {
    let name = event.target.name
    let value = event.target.checked ? formaRetiradaSelecionada : ''
    
    setFormaRetirada({ ...formaRetirada, [name]: value })
  }

  return (
    <div>
      <BarraTitulo
        title="Taxas de entrega"
        subTitle="Configure as taxas de entrega conforme sua preferência" />
      <Paper>
        <Box p={2}>
          <FormLabel component="legend">Como sua loja trabalha com as entrega?</FormLabel>
          <FormGroup row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <FormControl>
                <RadioGroup row>
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={handleChange}
                        checked={tipoEntrega === 'QUILOMETRAGEM'}
                        value="QUILOMETRAGEM"
                      />}
                    label="Entrega por quilometragem" />
                  <FormControlLabel
                    control={
                      <Radio
                        onChange={handleChange}
                        checked={tipoEntrega === 'BAIRRO'}
                        value="BAIRRO"
                      />}
                    label="Entrega por bairro" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box display="inline">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formaRetirada.formaRetiradaLocal === 'L'}
                    onChange={(event) => alterarConfiguracao(event, 'L')}
                    name="formaRetiradaLocal"
                    color="secondary"
                  />
                }
                label="Permite retirar no local"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formaRetirada.formaRetiradaDelivery === 'D'}
                    onChange={(event) => alterarConfiguracao(event, 'D')}
                    name="formaRetiradaDelivery"
                    color="secondary"
                  />
                }
                label="Permite entregar no delivery"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formaRetirada.formaConsumirLocal === 'C'}
                    onChange={(event) => alterarConfiguracao(event, 'C')}
                    name="formaConsumirLocal"
                    color="secondary"
                  />
                }
                label="Permite consumir no local"
              />
            </Box>

            <Grid item xs={12} sm={2}>
              <Button
                fullWidth
                variant='contained'
                color="secondary"
                onClick={() => salvar()}>
                salvar
              </Button>
            </Grid>
          </FormGroup>
        </Box>
      </Paper>
      <Paper style={{ marginTop: '1rem' }}>
        <Box p={2}>
          {tipoEntrega === 'QUILOMETRAGEM' ?
            (
              <ConfiguracaoTaxa />
            ) : (
              <ConfiguracaoEntregaBairro />
            )}
        </Box>
      </Paper>
    </div>
  )
}
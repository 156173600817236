import { Grid, Divider, Typography } from '@material-ui/core';
import React from 'react';

function DividerP({text}) {
    return (
        <Grid container direction='row' alignItems='center' style={{paddingTop: '10px'}}> 
          <Grid item xs={4} sm={4}>
            <Divider variant="middle" />
          </Grid>
        
          <Grid item xs={4} sm={4}>
            <Typography
              align='center'
              color="textSecondary">
                {text}
              </Typography>
          </Grid>
          
          <Grid item xs={4} sm={4}>
            <Divider variant="middle" />
          </Grid>
        </Grid>
      )
  }



export default DividerP;
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { Button, Paper } from "@material-ui/core";
import CompPedidos from "./components/compPedidos";
import CompPedidosMesa from "./components/compPedidosMesa"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import TemplateBase from '../Generico/TemplateBase';
import FecharConta from "./components/compFecharConta";

const styles = theme => ({
  card: {
    width: "140%",
    marginTop: "100px"
  },
  Button: {
    fontSize: "100%",
    color: "#253257",
    "&:hover": {
      color: "#F2C26A",
      background: "transparent"
    }
  },
  title: {
    marginTop: "5%",
    fontSize: "200%",
    color: "#253257"
  },
  subTitle: {
    marginBottom: "30px"
  },
  content: {
    paddingBottom: "20px"
  },
  appBarSpacer: theme.mixins.toolbar
});

class Pedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pedidosPendentes: []
    };
  }

  componentDidMount() {
    //this.buscaPedidosPendentes();
  }

  handlePrintOut = (pedido) => {
    if (!window.confirm('Deseja permitir reimpressão do pedido ?'))
      return;
    let service = new AuthService();
    let pedidosBody = [{ id: pedido.id }]
    service
      .post(`/pedido/liberarParaNovaImpressao`, pedidosBody)
      .then(retorno => {

        console.log(retorno)
        let pedidoRet = retorno[0]
        pedido.statusImpressao = pedidoRet.statusImpressao

        //atualiza state
        let { pedidos } = this.state
        this.setState({ pedidos })

        this.props.enqueueSnackbar("Pedido liberado para re-impressão", {
          variant: "Success"
        });

      }).catch(err => {
        console.error(err)
        this.props.enqueueSnackbar(`erro: ${err}`, {
          variant: "Error"
        });
      })

  }

  handleMarcarComoImpresso(pedido) {
    if (!window.confirm('Deseja marcar como impresso este pedido ?'))
      return;
    let service = new AuthService();
    let pedidosBody = [{ id: pedido.id, itens: pedido.itens.map(elem => { return { id: elem.id } }) }]
    service
      .post(`/pedido/marcarComoImpresso`, pedidosBody)
      .then(retorno => {

        console.log(retorno)
        let pedidoRet = retorno[0]
        pedido.statusImpressao = pedidoRet.statusImpressao

        if (pedidoRet.statusImpressao === "PENDENTE") {
          this.props.enqueueSnackbar("falha ao imprimir itens.", {
            variant: "error"
          });
        }
        //atualiza state
        let { pedidos } = this.state
        this.setState({ pedidos })

      }).catch(err => {
        console.error(err)
      })

  }

  handleCancel(pedido) {
    if (!window.confirm('Deseja cancelar o pedido?'))
      return;
    let motivo = (window.prompt('Motivo do cancelamento'))
    let service = new AuthService();
    service
      .delete(`/pedido/cancelar/${pedido.id}/${motivo}`)
      .then(retorno => {
        //atualiza state
        let { pedidos } = this.state

        var idx = pedidos.findIndex(pedidoLista => pedidoLista.id === retorno.id);
        if (idx < 0) {
          pedidos.push(retorno)
        } else {
          pedidos.splice(idx, 1, retorno)
        }
        //pedido.ativo = retorno.ativo
        //pedido.motivoCancelamento = retorno.motivoCancelamento
        this.setState({ pedidos })

        //this.handleListPedido()
      }).catch(err => {
        console.error(err)
      })
  }

  render() {
    document.title = 'QR Pedir - Pedidos'
    const { classes } = this.props;
    return (

      <TemplateBase title="PEDIDOS" subTitle="Veja aqui os pedidos feitos em seu restaurante. Você também pode alterar o status de qualquer pedido">

        <Paper>
          <Tabs>
            <TabList>
              <Tab>
                <Button className={classes.Button}>
                  Lista
                  </Button>
              </Tab>
              <Tab>
                <Button className={classes.Button}>
                  Contas abertas
                  </Button>
              </Tab>
            </TabList>
            <TabPanel>
              <CompPedidos
                handlePrintOut={this.handlePrintOut}
                handleMarcarComoImpresso={this.handleMarcarComoImpresso}
                handleCancel={this.handleCancel} />
            </TabPanel>
            <TabPanel>
              {/* <CompPedidosMesa /> */}
              <FecharConta />
            </TabPanel>
          </Tabs>

        </Paper>


      </TemplateBase>
    );
  }
}

export default withSnackbar(withStyles(styles)(Pedidos));

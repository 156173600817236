import React, { Component } from 'react'
import { Typography, Divider, List, ListItem, ListItemText, Button, Chip, Box, Accordion, AccordionSummary, AccordionDetails, AccordionActions, Hidden } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import utils from '../../../utils';
import CompCadastroProduto from "./compCadastroProduto";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AuthService from '../../../AuthService';
import { withSnackbar } from 'notistack';

export class CompListaGrupo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            openCadProd: false,
            produtoEdit: null,
        }
        this.handlePauseGrupo = this.props.handlePauseGrupo;
        this.handlePauseProduto = this.props.handlePauseProduto;
        this.handleEditarGrupo = this.props.handleEditarGrupo;
        this.newProduct = this.newProduct.bind(this)
    }

    handleChange = (panel) => (event, isExpanded) => {
        let { expanded } = this.state;
        expanded = isExpanded ? panel : false
        this.setState({ expanded });
    };

    setProdutoEdit = (produto) => {
        this.setState({ produtoEdit: produto })
    }

    handleOpenCadProd = () => {
        console.log(this.state.produtoEdit);

        let { openCadProd } = this.state;
        this.setState({ openCadProd: !openCadProd });
    }

    handleChangeProduto = (produto, mudanca, grupoAntigo) => {
        let { grupos, produtosBuscados } = this.props;

        let index = grupos.findIndex(el => el.grupoProduto.id === produto.grupoProduto.id)
        let indexProd = grupos[index].produtos.findIndex(el => el.id === produto.id);

        if (mudanca === "SALVAR") {
            console.log("grupoAntigo", grupoAntigo);
            console.log("produto", produto);

            if (produtosBuscados) {
                let indexBuscado = produtosBuscados.findIndex(el => el.id === produto.id);
                produtosBuscados[indexBuscado] = produto;
                this.props.saveProdutosBuscados(produtosBuscados);
            }

            //deleta produto do grupo antigo, caso tenha mudado de grupo
            if (grupoAntigo && grupoAntigo.id !== produto.grupoProduto.id) {
                let indexGpAntigo = grupos.findIndex(el => el.grupoProduto.id === grupoAntigo.id);
                console.log(grupos[indexGpAntigo]);
                let indexProdGpAntigo = grupos[indexGpAntigo].produtos.findIndex(el => el.id === produto.id);
                grupos[indexGpAntigo].produtos.splice(indexProdGpAntigo, 1);
            }

            if (indexProd > -1) {
                grupos[index].produtos[indexProd] = produto;
            } else {
                grupos[index].produtos.push(produto);
            }
        } else if (mudanca === "EXCLUIR") {
            if (produtosBuscados) {
                let indexBuscado = produtosBuscados.findIndex(el => el.id === produto.id);
                produtosBuscados.splice(indexBuscado, 1);
                this.props.saveProdutosBuscados(produtosBuscados);
            }
            if (indexProd > -1) {
                grupos[index].produtos.splice(indexProd, 1);

            }
        }

        let idxGrupoPromo = grupos.findIndex(el => el.grupoProduto.id === -1)
        let idxProdPromo = grupos[idxGrupoPromo].produtos.findIndex(prod => prod.id === produto.id)

        if (mudanca === 'SALVAR') {
            if (idxProdPromo >= 0) {
                if (produto.promocaoAtiva) {
                    grupos[idxGrupoPromo].produtos[idxProdPromo] = produto
                } else {
                    grupos[idxGrupoPromo].produtos.splice(idxProdPromo, 1)
                }
            } else {
                if (produto.promocaoAtiva) {
                    grupos[idxGrupoPromo].produtos.push(produto)
                }
            }
        } else if (mudanca === 'EXCLUIR') {
            if (idxProdPromo >= 0) {
                grupos[idxGrupoPromo].produtos.splice(idxProdPromo, 1)
            }
        }

        this.props.handleUpdateGrupo(grupos);
    }

    newProduct = (grupo) => {
        let produto = {
            descricao: "",
            nome: "",
            fichaTecnica: "",
            localImpressao: 'CAIXA',
            pausado: false,
            localVenda: "TODOS",
            preco: 0.0,
            grupoProduto: { id: grupo.id },
            complementos: [],
            ativo: true,
            advertencia: "",
            codigoExterno: "",
        }

        this.setState({ produtoEdit: produto })

        console.log(this.state.produtoEdit)
    }

    handleAtivarGrupo = (grupo, event) => {
        event.stopPropagation();

        let { grupos } = this.props;

        let service = new AuthService();

        let string = `{ "id": ${grupo.id}, "ativo": true }`
        let json = JSON.parse(string)

        service
            .put("/grupoProduto/changeOnly", json)
            .then(resp => {

                let index = grupos.findIndex(el => el.grupoProduto.id === resp.id)

                grupos[index].grupoProduto = resp;

                this.props.handleUpdateGrupo(grupos);

                this.props.enqueueSnackbar('Grupo ativado com sucesso', { variant: 'success' })
            })
            .catch(err => {
                this.props.enqueueSnackbar('Erro ao ativar grupo', { variant: 'error' })

            })

    }

    render() {
        let { expanded, produtoEdit, openCadProd } = this.state;
        let { grupos } = this.props;
        let isAdmin = new AuthService().isAdmin()

        return (
            <div>
                {openCadProd ? (
                    <CompCadastroProduto key={produtoEdit ? produtoEdit.id : null}
                        open={openCadProd}
                        produto={produtoEdit}
                        handleChangeProduto={this.handleChangeProduto}
                        handleOpenCadProd={this.handleOpenCadProd} />
                ) : null}

                {grupos.map(grupo => (
                    <React.Fragment key={grupo.grupoProduto.id}>
                        {(grupo.produtos.length === 0 && grupo.grupoProduto.id === -1) ? (null) : (
                            (grupo.grupoProduto.ativo === this.props.ativos) ? (
                                <Box key={grupo.id} m={1}>
                                    <Accordion expanded={expanded === grupo.grupoProduto.id} onChange={this.handleChange(grupo.grupoProduto.id)} >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={grupo.grupoProduto.id + "-content"}>

                                            <Box display="flex" style={{ width: '100%' }}>

                                                <Box flexGrow={1}  >
                                                    <Typography
                                                        variant="h6"
                                                        color="primary">
                                                        {grupo.grupoProduto.descricao ? grupo.grupoProduto.descricao.toUpperCase() : ""}
                                                    </Typography>
                                                </Box>
                                                <Box  >
                                                    {grupo.grupoProduto.id !== -1 ? (

                                                        grupo.grupoProduto.ativo ? (
                                                            <React.Fragment>
                                                                <Button
                                                                    startIcon={grupo.grupoProduto.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                                                                    color={grupo.grupoProduto.pausado ? "secondary" : "primary"}
                                                                    onClick={(event) => { this.handlePauseGrupo(event, grupo) }}>
                                                                    <Hidden smDown>{grupo.grupoProduto.pausado ? 'Iniciar vendas' : 'Pausar vendas'}</Hidden>

                                                                </Button>

                                                                <Button
                                                                    startIcon={<EditIcon />}
                                                                    onClick={(event) => this.handleEditarGrupo(grupo, event)} >
                                                                    <Hidden smDown>Editar</Hidden>
                                                                </Button>
                                                            </React.Fragment>
                                                        ) : (
                                                            <Button
                                                                startIcon={<CheckCircleOutlineIcon />}
                                                                onClick={(event) => this.handleAtivarGrupo(grupo.grupoProduto, event)}>
                                                                <Hidden smDown>Ativar</Hidden>
                                                            </Button>
                                                        )
                                                    ) : null}

                                                </Box>

                                            </Box>



                                        </AccordionSummary>


                                        <AccordionDetails>
                                            <List style={{ width: "100%" }}>
                                                {grupo.produtos.map(produto => (
                                                    <React.Fragment key={produto.id}>
                                                        <ListItem >
                                                            <ListItemText
                                                                primary={
                                                                    <React.Fragment>
                                                                        <Typography color={"primary"} component="span"
                                                                            style={{ fontWeight: "400", fontFamily: "Bebas Neue", textTransform: "capitalize", fontSize: "135%" }}>
                                                                            {produto.nome}
                                                                            {produto.promocaoAtiva ?
                                                                                <Chip color="primary" size="small" label="promo" icon={<StarIcon />} style={{ marginLeft: "15px" }} />
                                                                                : null}
                                                                        </Typography>
                                                                    </React.Fragment>
                                                                } />

                                                            <Typography>
                                                                {utils.formatNumber(produto.preco)}
                                                            </Typography>

                                                            <Button disabled={!grupo.grupoProduto.ativo} edge="end" color={produto.pausado ? "secondary" : "primary"} onClick={(event) => this.handlePauseProduto(event, produto, grupo)}>
                                                                {produto.pausado ? (
                                                                    <PlayCircleOutlineIcon />
                                                                ) : (
                                                                    <PauseCircleOutlineIcon />
                                                                )}
                                                            </Button>

                                                            <Button title="Editar produto" disabled={!grupo.grupoProduto.ativo} onClick={() => { this.handleOpenCadProd(); this.setState({ produtoEdit: JSON.parse(JSON.stringify(produto)) }) }}>
                                                                <EditIcon style={{ width: "20px" }} />
                                                            </Button>

                                                            {/* GRUPO PROMOCAO TEM ID = -1 */}
                                                            {grupo.grupoProduto.id !== -1 ? (
                                                                <Button title="Criar uma cópia deste produto" onClick={() => this.props.duplicarProduto(produto, grupo)}>
                                                                    <FileCopyOutlinedIcon style={{ width: "20px" }} />
                                                                </Button>
                                                            ) : null}

                                                        </ListItem>

                                                        <Divider />


                                                    </React.Fragment>
                                                ))}
                                            </List>


                                        </AccordionDetails>
                                        <AccordionActions>
                                            {grupo.grupoProduto.id === -1 ? (null) : (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={!grupo.grupoProduto.ativo}
                                                    onClick={() => { this.newProduct(grupo.grupoProduto); this.handleOpenCadProd() }}>
                                                    Novo Produto
                                                </Button>)}
                                        </AccordionActions>
                                    </Accordion>
                                </Box>
                            ) : null
                        )}
                    </React.Fragment>

                ))}
            </div>
        )
    }
}

export default withSnackbar(CompListaGrupo)

import decode from 'jwt-decode';
export default class AuthService {
    // Inicializando variáveis ​​importantes
    constructor(domain) {
        this.domain = domain || process.env.REACT_APP_API_URL // Dominio do servidor da API
        this.fetch = this.fetch.bind(this) // React binding stuff
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)

        this.download = this.download.bind(this)
        this.get = this.get.bind(this)
        this.post = this.post.bind(this)
        this.put = this.put.bind(this)
        this.delete = this.delete.bind(this)
        this.atualizarEmpresaProfile = this.atualizarEmpresaProfile.bind(this)
    }

    login(username, password) {
        //primeira coisa, remove token caso exista
        this.logout()

        // Obter um token do servidor da API usando a API de busca
        return this.fetch(`${this.domain}/login`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
            })
        }).then(res => {

            var response = this.verificaBloqueio(res.empresa.dataBloqueio, res);

            if (response.id) {
                console.log('Retornou do server token' + res.token)
                this.setProfile(res)
                this.setToken(res.token) // Configurando o token em localStorage
            }

            // if (this.verificaBloqueio(res.empresa.dataBloqueio, res)) {
            //     res = null;
            // } else {
            //     console.log('Retornou do server token' + res.token)
            //     this.setProfile(res)
            //     this.setToken(res.token) // Configurando o token em localStorage
            // }

            return Promise.resolve(response);
        })
    }

    verificaBloqueio(dataBloqueio, usuario) {
        if (dataBloqueio) {
            let hoje = new Date();
            dataBloqueio = new Date(dataBloqueio);
            console.log(usuario);

            if (!usuario.admin) {
                if (hoje.getTime() > dataBloqueio.getTime() && (usuario.empresa.tipoConta == "TEMPORARIA" || usuario.empresa.tipoConta == "TEMPORARIA_EXTENDED")) {
                    return { text: "BLOCK_EMPRESA_TEMPORARIA", tipo: usuario.empresa.tipoConta }
                } else if (hoje.getTime() > dataBloqueio.getTime()) {
                    return { text: "BLOCK_EMPRESA" };
                } else {
                    return usuario;
                }
            } else {
                return usuario;
            }
        } else {
            return usuario;
        }

    }

    isAdmin() {
        const usuario = this.getProfile();
        if (usuario.admin) {
            return true;
        } else {
            return false
        }
    }

    loggedIn() {
        // Verifica se há um token salvo e ainda é válido
        const token = this.getToken() // Obtendo token do localstorage
        return !!token && !this.isTokenExpired(token)
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) { //Verificando se o token expirou.
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        // Salva o token do usuário em localStorage
        console.log('salvando token' + idToken)
        localStorage.setItem('id_token', idToken)
    }

    getToken() {
        // Recupera o token do usuário do localStorage
        return localStorage.getItem('id_token')
    }

    logout() {
        // Limpar token do usuário e dados de perfil do localStorage
        console.log("sair");
        localStorage.removeItem('_profile');
        localStorage.removeItem('id_token');
    }

    getProfile() {
        // Usando o pacote jwt-decode npm para decodificar o token
        let profileString = localStorage.getItem("_profile")
        return JSON.parse(profileString)
        //return decode(this.getToken());
    }

    setProfile(profile) {
        localStorage.setItem("_profile", JSON.stringify(profile))
    }

    atualizarEmpresaProfile(empresa) {
        let profile = this.getProfile()
        profile.empresa = empresa;
        localStorage.setItem("_profile", JSON.stringify(profile))
    }


    fetch(url, options) {
        const token = this.getToken()

        if (!options) {
            options = {}
        }
        options.headers = {
            //"Authorization": "Bearer " + (token != null ? token : ''),
            "Accept": "application/json",
            "Content-Type": "application/json; charset=utf-8"
        }
        //SO ADICIONA O HEADER SE HOUVER TOKEN
        if (token) {
            options.headers["Authorization"] = "Bearer " + token
        }

        return fetch(url, options)
            .then(this._checkStatus)
            .then(response => response.json())
    }

    download(url, options) {
        const token = this.getToken()

        if (!options) {
            options = {}
        }
        options.headers = {
            "Authorization": "Bearer " + (token != null ? token : ''),
            "Content-Type": "application/octet-stream"
        }
        return fetch(this.domain + url, options)
            .then(this._checkStatus)
            .then(response => response.blob())
    }

    post(url, obj) {
        return fetch(this.domain + url, {
            method: 'POST',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    get(url) {
        console.log(this.domain + url)
        return fetch(this.domain + url, {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    getNoToken(url) {
        return fetch(this.domain + url, {
            method: 'GET',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    get(url, obj) {
        return fetch(this.domain + url, {
            method: 'GET',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }


    request(url, options) {
        options = {
            ...options,
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        }
        return fetch(this.domain + url, options)
            .then(this._checkStatus)

    }

    myFetch(url, options) {
        return fetch(this.domain + url, options)
            .then(this._checkStatus)
    }

    put(url, obj) {
        return fetch(this.domain + url, {
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    delete(url, obj) {
        return fetch(this.domain + url, {
            method: 'DELETE',
            body: JSON.stringify(obj),
            headers: {
                "Authorization": "Bearer " + this.getToken(),
                "Accept": "application/json",
                "Content-Type": "application/json; charset=utf-8"
            }
        })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    _checkStatus(response) {
        // gera um erro caso o status da resposta não seja um sucesso
        if (response.status >= 200 && response.status < 300) { // O status de sucesso situa-se entre 200 e 300
            return response
        } else {
            console.log('Falha ao logar')
            var error = { message: response.statusText, response }
            //error.response = response
            throw error
        }
    }
}
import React, { Component } from 'react'
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core';

import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";

import Logo from "../../imagens/logo-azul.svg";
import AuthService from "../../AuthService";

import Fundo from "../../imagens/fundo-qrpedir-min.png"

const styles = theme => ({})

class RecuperarSenha extends Component {

  constructor(props) {
    super(props);
    this.state = {
      confirmado: false,
      verificado: false,
      email: "",
      token: "",
      nome: ""
    }
  }

  handleClose = () => {
    this.setState({ confirmado: false })
  }

  handleChange = (event) => {
    this.setState({ email: event.target.value })
  }

  verificarEmail = () => {
    const { email } = this.state

    let service = new AuthService();

    console.log(email);

    service
      .get(`/usuario/getByEmail?email=${email}`)
      .then(retorno => {

        this.setState({ token: retorno.token, nome: retorno.nome })

        this.setState({ verificado: true, confirmado: true })

        console.log("Sucesso");

        this.sendEmail()

      }).catch(err => {
        console.error(err)
        this.props.enqueueSnackbar(`Seu e-mail está incorreto`, { variant: 'error' })
      })

  }

  sendEmail = () => {

    const { email, token, nome } = this.state

    let service = new AuthService();

    service
      .put(`/usuario/sendEmail?email=${email}&subject=${'Recuperação de senha'}&templateHtmlName=${'template-email-recuperar-senha'}&token=${token}&nome=${nome}`)
      .then(retorno => {
        console.log("Enviado com sucesso");
      }).catch(err => {
        console.error(err);
      })

  }

  render() {

    const styles = {
      fundo: {
        height: "100vh",
        background: `url(${Fundo}) repeat`
      }
    }

    document.title = 'QR Pedir - Recuperar senha'
    const { email, confirmado } = this.state;

    return (

      <Box style={styles.fundo} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box display="flex" justifyContent="center" p={4} mt={6}>
          <img style={{ width: "15vw", minWidth: "200px" }} alt="logo" src={Logo} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" style={{ height: "800px" }}>

          <Card style={{ padding: "2vw", paddingBottom: '20px', width: "30vw", minWidth: "300px", maxWidth: "450px", maxHeight: "500px" }}>

            <Typography variant='h5' align="center">
              Recuperação de senha
            </Typography>

            <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
              <TextField
                variant='outlined'
                label='E-mail'
                id='email'
                name='email'
                placeholder='meu_email@exemplo.com'
                fullWidth
                onChange={event => this.handleChange(event)}
              />
              <Button
                color='primary'
                variant='contained'
                style={{ width: '100px', margin: '25px 0px 30px 0px' }}
                onClick={this.verificarEmail}
              >Enviar</Button>

              <Link style={{ cursor: "pointer" }} onClick={() => { window.open('/login', '_parent') }}
              >Voltar a Página Inicial</Link>

              <Box style={{ margin: '10px 0px 0px 0px' }}>
                <Link style={{ cursor: "pointer" }} onClick={() => { window.open('https://qrpedir.com/suporte', '_blank') }}
                >Entre em contato</Link>
              </Box>
            </Box>
          </Card>

          <Dialog
            open={confirmado}
            onClose={this.handleClose}
            maxWidth='xs'
            fullWidth
            align='center'
          >
            <DialogTitle>Confirmação</DialogTitle>

            <DialogContentText>
              Uma mensagem de confirmação foi enviado para {email}, confira seu e-mail para continuar.
          </DialogContentText>

            <Box display='flex' flexDirection='row' justifyContent='center'>
              <Button
                color='primary'
                variant='contained'
                style={{ margin: '10px 0px 20px 10px' }}
                onClick={this.handleClose}
              >Confirmar</Button>
            </Box>
          </Dialog>

        </Box>
      </Box>
    )
  }
}

export default withSnackbar(withStyles(styles)(RecuperarSenha));

import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import AuthService from "../AuthService";


const authservice = new AuthService()

const profile = authservice.getProfile()
const moeda = (profile && profile.empresa.moeda ? profile.empresa.moeda : "REAL")

export default {
    /**
     * 
     * @param {
     * "currency" para formato monetário
     * "percent" para formato percentual} style 
     * @returns Valor formatado
     */
    optionsCurrency(style = 'currency') {
        let options = {
            currencySymbol: style === 'currency' ? 'R$' : '%',
            decimalPlaces: 2,
        }

        //Japao
        if (moeda === 'IENE') {
            options = {
                currencySymbol: style === 'currency' ? '￥' : '%',
                decimalPlaces: 0,
            }
        }

        return options
    },

    /**
     * 
     * @param {Valor a ser formatado} value 
     * @param {"decimal" para formato de número simples, 
     * "currency" para formato monetário
     * "percent" para formato percentual; 
     * o padrão é "decimal"} style 
     * @returns Valor formatado
     */
    formatNumber(value, style = 'currency') {
        // "￥123,357"=  {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(123356.65)}
        // "R$ 123.356,65" = {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(123356.65)}

        let param = {
            locales: 'pt-BR',
            options: {
                style,
                currency: 'BRL',
                useGrouping: true,
                maximumFractionDigits: 2,
            }
        }

        //Japao
        if (moeda === 'IENE') {
            param = {
                locales: 'ja-JP',
                options: {
                    style,
                    currency: 'JPY',
                    useGrouping: true,
                    maximumFractionDigits: 0,
                }
            }
        }

        if (value !== null && value !== undefined) {
            return new Intl.NumberFormat(param.locales, param.options).format(value)
        } else {
            return value
        }
    },
    setValue(objDest, name, value) {
        if (name.indexOf('.') >= 0) {
            let objectName = name.split('.')[0]
            let objectAtt = name.split('.')[1]
            objDest[objectName] = JSON.parse(`{ "${objectAtt}": ${value} }`)
        } else {
            objDest[name] = value
        }
    },

    getTotalItensPedido(pedido) {
        let total = 0;
        pedido.itens.map(elem => {
            total += elem.valorTotal;
            if (elem.subItens) {
                elem.subItens.map(subItem => {
                    total += subItem.valorTotal
                })
            }
        })

        return total;
    },

    getTotalPedido(pedido) {
        let total = this.getTotalItensPedido(pedido)

        if (pedido.tipoDelivery === "ENTREGAR" && pedido.valorTaxaEntrega) {
            total += pedido.valorTaxaEntrega
        }

        if (pedido.valorDesconto > 0) {
            total = total - pedido.valorDesconto
        }

        return total;
    },

    replaceValor(value) {
        let strValor = value ? value.toString() : '';
        strValor = strValor.replace(',', '.');

        return strValor;
    },

    formataMonetario(value) {
        if (!value && 0 !== value)
            return '0.00'
        let val = parseFloat(value)
        return val.toFixed(2).replace('.', ',');
    },

    formatarDataHora(data, timezone = "-03:00") {
        if (!data)
            return ''
        try {
            let date = utcToZonedTime(data, timezone);

            return format(date, 'dd/MM/yy HH:mm')
        } catch (e) {
            console.log('falha ao converter data ', data)
            return ''
        }
    },

    formatarData(data, timezone = "-03:00") {
        if (!data)
            return ''
        try {
            let date = utcToZonedTime(data, timezone);

            return format(date, 'dd/MM/yyyy')
        } catch (e) {
            console.log('falha ao converter data ', data)
            return ''
        }
    },

    labelTipoIdentificador(tipo) {
        if (tipo === 'MESA') return 'Mesa'
        if (tipo === 'COMANDA') return 'Comanda'
        if (tipo === 'DELIVERY') return 'Delivery'
        if (tipo === 'SEQUENCIAL') return 'Sequencial'

        return 'Outros'
    },

    onlyDigits(numbers) {
        if (!numbers) {
            return "";
        }

        let onlyNumbers = numbers.replace(/\D/g, "")

        return onlyNumbers
    },

    isDev() {
        if (process.env.NODE_ENV === "development") {
            return true
        } else {
            return false
        }
    }
}

export const createUUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & (0x3 | 0x8))).toString(16);
    });
    return uuid;
}

export const converterURLImagemProduto = (imagem) => {

    /*const baseURL = "https://jura-balde.s3-sa-east-1.amazonaws.com/"
    const convertedURL = "https://img.imageboss.me/awsjurandi/cdn"

    const urlConverted = convertedURL + urlImagem.substring(baseURL.length - 1, urlImagem.length)*/

    const urlConverted = imagem.url + imagem.publicId

    return urlConverted
}

/*
* Função que formata uma string, deixando todas as primeiras letras das palavras maiúsculas
*   converte tudo para minúsculo e depois alterna as primeiras letras para maiúsculo
*
* @param {[String]} text             String com a frase a ser processada
* @return {[String]}                 Frase processada ou "" caso venha nulo, undefined ou ""
*/
export const formatTextToFirstLetterUpperCase = (text) => {
    if (text === null || text === undefined || text === "") {
        return ""
    }

    let words = text.toLowerCase();
    let finalFrase = words.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    return finalFrase;
}
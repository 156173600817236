import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/styles/withStyles";
import Utils from '../../../utils';
import Detalhes from './compDetalhesPedido';
import PrintIcon from '@material-ui/icons/Print';
import AuthService from "../../../AuthService";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DateFnsUtils from "@date-io/date-fns";
import Chip from '@material-ui/core/Chip';
import "../styles.css";
import { withSnackbar, useSnackbar } from "notistack";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fade from '@material-ui/core/Fade';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ForList from "../../Skeleton/ForList"
import frLocale from "date-fns/locale/pt-BR";
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import { SITUACAO_PEDIDO, SITUACAO_PEDIDO_COR } from "../../../utils/PedidoUtil"
import CloseIcon from '@material-ui/icons/Close';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MessageIcon from '@material-ui/icons/Message';
import Mesa from "../../../imagens/iconeMesa.svg";
import Sequencial from "../../../imagens/iconeSequencial.svg";
import Comanda from "../../../imagens/iconeComanda.svg";
import DeliveryIcon from "../../../imagens/iconeDelivery.svg";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import BrowserNotification, { spawnNotification } from '../../../utils/BrowserNotification';

import {
  Typography,
  ListItemText,
  Menu,
  MenuItem,
  Grid,
  Box,
  TextField,
  Button,
  Tooltip
} from "@material-ui/core";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { format } from "date-fns";

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return format(date, "yyyy-MM-dd", { locale: this.locale });
  }
}

const pesquisaEmBranco = {
  ativo: 'null',
  status: 'PENDENTE',
  dataPedido: null,
  dataInicial: null,
  dataFinal: null,
  nomeCliente: '',
  statusIntegracao: "null",
  statusImpressao: 'null',
  situacao: 'null',
  tipo: 'null',
  agendado: "null"
}

class Pedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
      pedidos: [],
      pedidoSelecionado: null,
      pesquisa: pesquisaEmBranco,
      buscaAvancadaOpen: false,
      buscando: false,
      rowsPerPage: 10,
      page: 0,
      openInfo: false,
      totalCountPedidos: 0,
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.buscarPedidosUtilizandoPesquisa = this.buscarPedidosUtilizandoPesquisa.bind(this)
    this.handleChangeBuscaAvancadaOpen = this.handleChangeBuscaAvancadaOpen.bind(this)

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)

    this.handleMarcarComoImpresso = this.props.handleMarcarComoImpresso;
    this.handlePrintOut = this.props.handlePrintOut;
    this.handleCancel = this.props.handleCancel;
    this.openPedido = this.openPedido.bind(this)
    this.verificaSeExistemPedidosPendentesNaoImpressos = this.verificaSeExistemPedidosPendentesNaoImpressos.bind(this)
  }

  componentDidMount() {
    this.buscarPedidosUtilizandoPesquisa();
    this.intervalBuscaPedidosPendentes = setInterval(this.verificaSeExistemPedidosPendentesNaoImpressos, 10 * 1000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalBuscaPedidosPendentes);
  }



  verificaSeExistemPedidosPendentesNaoImpressos() {

    let service = new AuthService();
    service
      .get(`/pedido/count?statusImpressao=PENDENTE&situacao=PENDENTE&ativo=true`)
      .then(countResult => {

        const count = countResult.count

        if (count && count > 0) {

          if (count > 0) {
            const action = key => (
              <Fragment>
                <Button onClick={() => {
                  this.setState({ pesquisa: pesquisaEmBranco, page: 0 }, () => {
                    this.buscarPedidosUtilizandoPesquisa()
                  }
                  )
                }}>
                  Atualizar
                  </Button>
              </Fragment>
            );

            this.props.enqueueSnackbar(`Existem pedidos pendentes (${count})`, {
              variant: 'warning',
              preventDuplicate: true,
              action
            })

            spawnNotification("Existem pedidos pendentes", `Existe ${count} pedidos pendentes. Clique para ver`, process.env.PUBLIC_URL + "/images/logoQrpedir.png", (event) => {
              let notification = event.currentTarget
              if (notification){
                notification.close()
              }
              window.focus();
            })

          }

        }

      }).catch(err => {
        this.props.enqueueSnackbar('Falha ao verificar se existem pedidos pendentes', { variant: 'error' });
        console.error(err)
      })
  }

  handleChange(event) {
    const { pesquisa } = this.state;
    pesquisa[event.target.name] = (event.target.value)
    this.setState({ pesquisa: pesquisa })
  }

  handleDateChange(data, name) {
    const { pesquisa } = this.state;

    if (data) {

      if (data === "Invalid Date") {
        console.log("1");
        return;
      }

      if (!pesquisa[name]) {
        console.log("2");
        pesquisa[name] = "";
      }
      console.log("3");

      pesquisa[name] = data;

    } else {
      console.log("4");

    }

    this.setState({ pesquisa: pesquisa })
    console.log(data);
  }

  openPedido(pedido) {
    this.setState({ pedidoSelecionado: pedido })
    console.log(this.state.pedidoSelecionado, pedido)
    this.handleOpenInfo()
  }

  handleClose(pedido) {
    if (!window.confirm('Deseja fechar o pedido ?'))
      return;
    let service = new AuthService();
    let pedidosBody = [{ id: pedido.id }]
    service
      .post(`/pedido/marcarComoPago`, pedidosBody)
      .then(retorno => {
        this.props.enqueueSnackbar('Pedido fechado com sucesso', { variant: 'success' })
        pedido.status = retorno[0].status
        //atualiza state
        let { pedidos } = this.state
        this.setState({ pedidos })
      }).catch(err => {
        this.props.enqueueSnackbar(`Falha ao fechar pedido: ${err}`, { variant: 'error' })
        console.error(err)
      })
  }

  handleReopen = (pedido) => {
    if (!window.confirm('Deseja reabrir o pedido ?'))
      return;
    let service = new AuthService();
    let pedidosBody = [{ id: pedido.id }]
    service
      .post(`/pedido/marcarComoNaoPago`, pedidosBody)
      .then(retorno => {
        this.props.enqueueSnackbar('Pedido reaberto com sucesso', { variant: 'success' })
        pedido.status = retorno[0].status
        //atualiza state
        let { pedidos } = this.state
        this.setState({ pedidos })
      }).catch(err => {
        this.props.enqueueSnackbar(`Falha ao reabrir pedido: ${err}`, { variant: 'error' })
        console.error(err)
      })
  }

  buscarPedidosUtilizandoPesquisa() {
    const { pesquisa } = this.state
    this.handleSearch(pesquisa)
  }

  handleClickBuscarPedido = () => {
    this.setState({ page: 0 }, () => {
      this.buscarPedidosUtilizandoPesquisa()
    })
  }

  handleSearch(pesquisa) {
    let { rowsPerPage, page } = this.state;

    var esc = encodeURIComponent;
    
    if (pesquisa.dataPedido) {

      if (typeof pesquisa.dataPedido !== 'object') pesquisa.dataPedido = new Date(pesquisa.dataPedido);

      let data = (pesquisa.dataPedido.getFullYear() + "-" + (pesquisa.dataPedido.getMonth() + 1) + "-" + pesquisa.dataPedido.getDate());
      pesquisa.dataPedido = data;
    }

    if (pesquisa.dataInicial && pesquisa.dataFinal) {

      if (typeof pesquisa.dataInicial !== 'object') pesquisa.dataInicial = new Date(pesquisa.dataInicial);

      if (typeof pesquisa.dataFinal !== 'object') pesquisa.dataFinal = new Date(pesquisa.dataFinal);
      
      let dataInicial = (pesquisa.dataInicial.getFullYear() + "-" + (pesquisa.dataInicial.getMonth() + 1) + "-" + pesquisa.dataInicial.getDate());
      pesquisa.dataInicial = dataInicial;
    
      let dataFinal = (pesquisa.dataFinal.getFullYear() + "-" + (pesquisa.dataFinal.getMonth() + 1) + "-" + pesquisa.dataFinal.getDate());
      pesquisa.dataFinal = dataFinal;
    }

    pesquisa = {
      ...pesquisa
    }

    var query = Object.keys(pesquisa)
      .map(k => {
        if (pesquisa[k] != null && pesquisa[k] !== '' && pesquisa[k] !== 'null')
          return esc(k) + '=' + esc(pesquisa[k])
      })
      .join('&');


    this.setState({ buscando: true })
    let service = new AuthService();

    service
      .request(`/pedido/find?${query}&first=${rowsPerPage * page}&max=${rowsPerPage}`)
      .then(response => {

        let totalCountPedidos = response.headers.get('totalCount')

        this.setState({ totalCountPedidos: parseInt(totalCountPedidos) })

        response.json()
          .then(
            retorno => {
              this.setState({ pedidos: retorno, buscando: false });
            }
          )

      }).catch(err => {
        console.error(err)
        this.setState({ buscando: false })
      })
  }

  handleChangeBuscaAvancadaOpen = () => {
    this.setState({
      buscaAvancadaOpen: !this.state.buscaAvancadaOpen
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => {
      this.buscarPedidosUtilizandoPesquisa()
    })
  };

  handleChangeRowsPerPage = (event) => {
    let { rowsPerPage } = this.state
    rowsPerPage = event.target.value
    this.setState({ rowsPerPage }, () => {
      this.buscarPedidosUtilizandoPesquisa()
    })

  };

  handleOpenInfo = () => {
    let { openInfo } = this.state;

    this.setState({ openInfo: !openInfo })
  }

  render(prps) {
    const { pedidos, pesquisa, buscaAvancadaOpen, buscando } = this.state;
    const { page } = this.state;
    const { rowsPerPage } = this.state;

    const columns = [
      {
        id: 'situacao',
        label: 'Situação',
        width: 10,
        minWidth: 10,
      },
      {
        id: 'tipo',
        label: '',
        width: 10,
        minWidth: 10
      },
      {
        id: 'statusIntegracao',
        label: '',
        width: 30,
        minWidth: 30,
      },
      {
        id: 'identificadorPrimario',
        label: '',
        width: 30,
        minWidth: 30,
      },
      {
        id: 'identificadorSecundario',
        label: '',
        width: 30,
        minWidth: 30,
      },
      {
        id: 'nomeCliente',
        label: 'Nome',
        width: 200,
        minWidth: 20,
      },
      {
        id: 'dataAgendamento',
        label: 'Agendado',
        width: 5,
        minWidth: 5,
      },
      {
        id: 'Data',
        label: 'Data',
        align: 'center',
        width: 30,
        minWidth: 30,
      },
      {
        id: 'valor',
        label: 'Valor',
        align: "right",
        width: 30,
        minWidth: 30,
      },
      {
        id: 'opcao',
        label: 'Opções',
        align: 'center',
        width: 80,
        minWidth: 30,
      },
    ];

    return (
      <div>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={2}>
              <TextField
                fullWidth
                variant="outlined"
                label={"Mesa/Comanda"}
                name="mesa"
                value={pesquisa.mesa}
                onChange={event => this.handleChange(event)}
              />
            </Grid>

            <Grid item xs={6} md={4}>
              <TextField
                variant="outlined"
                fullWidth
                label={"Nome"}
                name="nomeCliente"
                value={pesquisa.nomeCliente}
                onChange={event => this.handleChange(event)}
              />
            </Grid>

            <Grid item xs={6} md={2}>
              <TextField
                variant="outlined"
                select
                label={"Pagamento"}
                name="status"
                fullWidth
                value={pesquisa.status}
                onChange={event => this.handleChange(event)}>
                <MenuItem key={"null"} value={"null"} >Ambos</MenuItem>
                <MenuItem key={"PAGO"} value={"PAGO"} >Conta fechada</MenuItem>
                <MenuItem key={"PENDENTE"} value={"PENDENTE"} >Conta aberta</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={6} md={2} align="center">
              <FormControlLabel

                control={<Switch checked={buscaAvancadaOpen} onChange={this.handleChangeBuscaAvancadaOpen} />}
                label="opções">
              </FormControlLabel>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button color="primary"
                fullWidth
                variant="contained"
                size="large"
                disabled={this.state.buscando}
                style={{ fontFamily: "Bebas Neue", fontSize: "20px" }}
                onClick={this.handleClickBuscarPedido}>
                {this.state.buscando ? 'Buscando...' : 'Buscar'}
              </Button>
            </Grid>

            <Fade in={buscaAvancadaOpen} style={{ margin: "0px" }}>
              <Grid container spacing={2} style={{ display: `${buscaAvancadaOpen ? '' : 'none'}` }}>
                <Grid item xs={12} md={2}>
                  <MuiPickersUtilsProvider locale={frLocale} utils={LocalizedUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format='dd/MM/yyyy'
                      inputVariant="outlined"
                      label="Data inicial"
                      fullWidth
                      value={pesquisa ? (pesquisa.dataInicial ? (pesquisa.dataInicial) : null) : null}
                      onChange={(date) => this.handleDateChange(date, 'dataInicial')} />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} md={2}>
                  <MuiPickersUtilsProvider locale={frLocale} utils={LocalizedUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format='dd/MM/yyyy'
                      inputVariant="outlined"
                      label="Data final"
                      fullWidth
                      value={pesquisa ? (pesquisa.dataFinal ? (pesquisa.dataFinal) : null) : null}
                      onChange={(date) => this.handleDateChange(date, 'dataFinal')} />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    select
                    name="statusIntegracao"
                    id="statusIntegracao"
                    value={pesquisa.statusIntegracao}
                    fullWidth
                    onChange={event => this.handleChange(event)}>
                    <MenuItem value={"null"} >Integrados e não integrados</MenuItem>
                    <MenuItem value={'INTEGRADO'} >Integrados</MenuItem>
                    <MenuItem value={'PENDENTE'} >Não integrados</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    variant="outlined"
                    select
                    name="statusImpressao"
                    id="statusImpressao"
                    value={pesquisa.statusImpressao}
                    fullWidth
                    onChange={event => this.handleChange(event)}>
                    <MenuItem value={"null"} >Impressos e não impressos</MenuItem>
                    <MenuItem value={'IMPRESSO'} >Impressos</MenuItem>
                    <MenuItem value={'PENDENTE'} >Não impressos</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    select
                    name="situacao"
                    id="situacao"
                    value={pesquisa.situacao}
                    fullWidth
                    onChange={event => this.handleChange(event)}>
                    <MenuItem key={"null"} value={"null"} >Situações</MenuItem>
                    {Object.keys(SITUACAO_PEDIDO).map(SITUACAO => (
                      <MenuItem key={SITUACAO} value={SITUACAO} >{SITUACAO_PEDIDO[SITUACAO]}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    select
                    name="tipo"
                    id="tipo"
                    value={pesquisa.tipo}
                    fullWidth
                    onChange={event => this.handleChange(event)}>
                    <MenuItem value={"null"} >Todos os tipos</MenuItem>
                    <MenuItem value={'DELIVERY'} >Delivery</MenuItem>
                    <MenuItem value={'MESA'} >Mesa</MenuItem>
                    <MenuItem value={'COMANDA'} >Comanda</MenuItem>
                    <MenuItem value={'SEQUENCIAL'} >Sequencial</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    variant="outlined"
                    select
                    name="agendado"
                    id="agendado"
                    value={pesquisa.agendado}
                    fullWidth
                    onChange={event => this.handleChange(event)}>
                    <MenuItem value={"null"} >Agendados e Não agendados</MenuItem>
                    <MenuItem value={'AGENDADO'} >Agendados</MenuItem>
                    <MenuItem value={'N_AGENDADO'} >Não agendados</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Fade>

            <TableContainer>
              <Table >
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {buscando ? (
                    <TableRow>
                      <TableCell colSpan={12}>
                        <ForList rows={rowsPerPage} height={50} />
                      </TableCell>
                    </TableRow>) : (

                      pedidos.map((pedido) => (
                        <TableRow key={pedido.id} style={{ opacity: (pedido.situacao === "CANCELADO" ? "0.2" : "1") }} >
                          <TableCell>
                            {pedido.situacao ? (
                              <MenuSituacao handleCancel={this.handleCancel} pedido={pedido} />
                            ) : null}
                          </TableCell>
                          <TableCell>
                            <Avatar
                              src={pedido.identificador.tipo === 'MESA' ? Mesa :
                                pedido.identificador.tipo === 'COMANDA' ? Comanda :
                                  pedido.identificador.tipo === 'SEQUENCIAL' ? Sequencial :
                                    pedido.identificador.tipo === 'DELIVERY' ? DeliveryIcon :
                                      null} />
                          </TableCell>
                          <TableCell>
                            <Grid container spacing={1} direction="column" justify="flex-start" alignItems="flex-start">

                              <Grid item>
                                <Tooltip title={pedido.statusImpressao === "IMPRESSO" ? "Liberar nova impressão" : "Marcar como impresso"} placement="top">
                                  <Chip
                                    onClick={pedido.statusImpressao === "IMPRESSO" ? () => this.handlePrintOut(pedido) : () => this.handleMarcarComoImpresso(pedido)}
                                    clickable
                                    label={pedido.statusImpressao === "IMPRESSO" ? " Impresso " : " pendente "}
                                    icon={<PrintIcon fontSize="small" />}
                                    size="small"
                                    color={pedido.statusImpressao === "IMPRESSO" ? "default" : "primary"}>
                                  </Chip>
                                </Tooltip>

                              </Grid>

                              <Grid item>
                                <Chip
                                  label={pedido.statusIntegracao === "INTEGRADO" ? "Integrado" : "Integração"}
                                  icon={pedido.statusIntegracao === "INTEGRADO" ? <DoneAllIcon fontSize="small" /> : <HourglassEmptyIcon fontSize="small" />}
                                  title={pedido.statusIntegracao === "INTEGRADO" ? "Pedido integrado" : "Aguardando integração"}
                                  size="small"
                                  color={pedido.statusIntegracao === "INTEGRADO" ? "default" : "primary"}>
                                </Chip>
                              </Grid>

                            </Grid>
                          </TableCell>

                          <TableCell>
                            <ListItemText
                              primary={<Typography color="textPrimary">{pedido.identificador.numero}</Typography>}
                              secondary={pedido.identificador.tipo} />
                          </TableCell>

                          <TableCell>
                            {pedido.identificadorSecundario ? (
                              <ListItemText
                                primary={<Typography color="textPrimary">{pedido.identificadorSecundario}</Typography>}
                                secondary={
                                  ((pedido.identificador.tipo === "DELIVERY") || (pedido.identificador.tipo === "SEQUENCIAL") ? "Sequencial" : "")} />
                            ) : ""}
                          </TableCell>

                          <TableCell>
                            <Box display="flex" flexDirection="row"   >
                              <Box p={1} >
                                <Typography color="textPrimary" >
                                  {pedido.nomeCliente}
                                </Typography>

                                <Typography color="textSecondary" variant='caption' >
                                  {pedido.pessoa?.telefone }
                                </Typography>
                              </Box>

                              {pedido.tokenMessage &&
                                <Box p={1}>
                                  <Tooltip title="Push ativado">
                                    <MessageIcon fontSize="small" />
                                  </Tooltip>
                                </Box>
                              }

                            </Box>


                            <BrowserNotification />

                          </TableCell>

                          <TableCell align='center'>
                            <Typography align="center" color="textPrimary">
                              {pedido.horarioAgendamento && <AccessTimeIcon color="secondary" />}
                            </Typography>
                            
                            <Typography color="textSecondary" variant='caption'>{Utils.formatarDataHora(pedido.horarioAgendamento, pedido.empresa.timezone)}</Typography>
                          </TableCell>

                          <TableCell>
                            <Typography color="textPrimary">{Utils.formatarDataHora(pedido.dataPedido, pedido.empresa.timezone)}</Typography>
                          </TableCell>

                          <TableCell>
                            <Typography align="right" color="textPrimary"> 
                              {Utils.formatNumber(Utils.getTotalPedido(pedido))}
                            </Typography>
                          </TableCell>

                          <TableCell align="right">
                            <Box display="flex">
                              <Tooltip title="VER PEDIDO" placement="top">
                                <IconButton onClick={() => this.openPedido(pedido)}>
                                  <VisibilityIcon color="primary" />
                                </IconButton>
                              </Tooltip>

                              {pedido.status === "PAGO" ?

                                <Tooltip title="REABRIR PEDIDO" placement="top">
                                  <IconButton onClick={() => this.handleReopen(pedido)}>
                                    <CompareArrowsIcon color='primary' />
                                  </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="FECHAR PEDIDO" placement="top">
                                  <IconButton onClick={() => this.handleClose(pedido)}>
                                    <AccountBalanceWalletIcon color="primary" />
                                  </IconButton>
                                </Tooltip>
                              }
                              <Tooltip title="CANCELAR PEDIDO" placement="top">

                                <IconButton onClick={() => this.handleCancel(pedido)}>
                                  <CloseIcon color="error" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>



                      ))
                    )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={this.state.totalCountPedidos}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={e => this.handleChangeRowsPerPage(e)} />
            </TableContainer>
          </Grid>
        </Box>

        {this.state.openInfo ? <Detalhes pedido={this.state.pedidoSelecionado} open={this.state.openInfo} handleOpenInfo={this.handleOpenInfo} /> : null}
      </div >
    );
  }
}
export default withSnackbar(Pedidos);

function MenuDetalhe(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Button style={{ borderTopLeftRadius: "0px", borderBottomLeftRadius: "0" }} variant="contained" color="secondary" onClick={handleClick}>
        <MoreHorizIcon fontSize="small" />
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.itens ? (props.itens.map((item, i) => (
          (item.ativo ? (

            <MenuItem key={i}
              onClick={() => {
                handleClose();
                item.call()
              }
              }>
              {item.nome}
            </MenuItem>

          ) : null)

        )
        )) : null}

      </Menu>
    </React.Fragment>
  )

}


function MenuSituacao(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    console.log(event);

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSituacao = (pedido, situacao) => {

    if(situacao === "CANCELADO") {
      props.handleCancel(pedido)
      pedido.situacao = "CANCELADO"

      handleClose();
      return
    }

    const service = new AuthService();
    service
      .post(`/pedido/situacao/${pedido.id}/${situacao}`)
      .then(ped => {
        pedido.situacao = ped.situacao;
        enqueueSnackbar(`Situação alterada para ${ped.situacao} com sucesso`, { variant: 'success' });
        handleClose();
      }).catch(err => {
        if (err.response) {
          err.response.json().then(elem => {
            enqueueSnackbar(elem.errorMessage, { variant: 'error' });
          });
        }

      })
  }

  return (
    <React.Fragment>
      <Tooltip title={SITUACAO_PEDIDO[props.pedido.situacao]} placement="bottom">
        <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR[props.pedido.situacao] }} className={props.pedido.situacao === 'PENDENTE' ? "pendenteAnimation" : ""}>
          <Button
            style={{ backgroundColor: "transparent", color: "white", fontSize: "20px" }}
            onClick={(e) => handleClick(e)}>
            {props.pedido.tipoDelivery === "RETIRAR_LOCAL" && props.pedido.situacao === "ENVIADO" ? (
              "PRONTO_RETIRAR".substring(0, 2)) : SITUACAO_PEDIDO[props.pedido.situacao].substring(0, 2)
            }
          </Button>
        </Avatar>
      </Tooltip>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeSituacao(props.pedido, "PENDENTE")}>
          <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR["PENDENTE"] }}>
            {SITUACAO_PEDIDO["PENDENTE"].substring(0, 2)}
          </Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            MARCAR COMO PENDENTE
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChangeSituacao(props.pedido, "CONFIRMADO")}>
          <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR["CONFIRMADO"] }}>
            {SITUACAO_PEDIDO["CONFIRMADO"].substring(0, 2)}
          </Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            MARCAR COMO CONFIRMADO
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChangeSituacao(props.pedido, "ENVIADO")}>
          <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR["ENVIADO"] }}>
            {props.pedido.tipoDelivery === "RETIRAR_LOCAL" ? "PRONTO_RETIRAR".substring(0, 2) : SITUACAO_PEDIDO["ENVIADO"].substring(0, 2)}
          </Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            {props.pedido.tipoDelivery === "RETIRAR_LOCAL" ? (
              "MARCAR COMO PRONTO PARA BUSCAR"
            ) :
              "MARCAR COMO ENVIADO"
            }
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChangeSituacao(props.pedido, "ENTREGUE")}>
          <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR["ENTREGUE"] }}>
            {SITUACAO_PEDIDO["ENTREGUE"].substring(0, 2)}
          </Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            MARCAR COMO ENTREGUE
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleChangeSituacao(props.pedido, "CANCELADO")}>
          <Avatar style={{ backgroundColor: SITUACAO_PEDIDO_COR["CANCELADO"] }}>
            {SITUACAO_PEDIDO["CANCELADO"].substring(0, 2)}
          </Avatar>
          <Typography style={{ marginLeft: "10px" }}>
            MARCAR COMO CANCELADO
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}


import { Dialog, DialogTitle, Typography, DialogContent, TextField, Box, IconButton, Divider, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab';
import React from 'react'
import CloseIcon from '@material-ui/icons/Close';

export default function DialogTutorial({ open, close }) {
    const [selectedOption, setSelectedOption] = React.useState({ label: "CADASTRO DE PRODUTOS", url: `https://www.youtube.com/embed/E54kfunzysc` });

    var options = [
        { label: "CONFIGURAR HORARIOS DE ATENDIMENTO", url: `https://www.youtube.com/embed/LzPerFDPkOM` },
        { label: "CONFIGURAR O LINK DA LOJA", url: `https://www.youtube.com/embed/v0wqL8BU_YE` },
        { label: "CRIAR CUPONS DE DESCONTO", url: `https://www.youtube.com/embed/umnsLNQHmUA` },
        { label: "ALTERAR CORES DO CARDÁPIO", url: `https://www.youtube.com/embed/ukThGvHWqnU` },
        { label: "CADASTRAR GRUPO DE PRODUTOS", url: `https://www.youtube.com/embed/r_EDltu3Ubk` },
        { label: "CADASTRAR PRODUTOS", url: `https://www.youtube.com/embed/6EP0XMi_f7I` },
        { label: "CADASTRAR PRODUTOS COM COMPLEMENTOS", url: `https://www.youtube.com/embed/Kzq58JXxmQ0` },
        { label: "CADASTRAR PRODUTOS COM TAMANHOS", url: `https://www.youtube.com/embed/NS_7q_zslIM` },
        { label: "COLOCAR IMAGENS NOS PRODUTOS", url: `https://www.youtube.com/embed/fli9OLoKg40` },
        { label: "MONITOR DE IMPRESSAO", url: `https://www.youtube.com/embed/uvZPwauoDWo` },
        { label: "GERENCIAMENTO DE PEDIDOS", url: `https://www.youtube.com/embed/SIohrFZtaLc` },
        { label: "CONFIGURAR TAXA DE ENTREGA", url: `https://www.youtube.com/embed/KKrENJ8Cs8E` },
        { label: "CONFIGURAR REDES SOCIAIS", url: `https://www.youtube.com/embed/tyYFVCgO-WU` },
        { label: "TEMPO DE PREPARO E VALOR MINIMO", url: `https://www.youtube.com/embed/F2Km6x73M6o` }
    ];

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={() => close()}>

            <DialogTitle>
                <Box display="flex" justifyContent="space-between">
                    <Typography color="primary" align="center" variant='h6'>
                        Veja como faz
                    </Typography>

                    {/* <Box display="flex" justifyContent="flex-end"> */}
                    <IconButton size="medium" onClick={() => close()}>
                        <CloseIcon />
                    </IconButton>
                    {/* </Box> */}
                </Box>
            </DialogTitle>

            <DialogContent >
                <Grid container spacing={2} justify='center' direction='column'>
                    <Grid item xs>
                        <Autocomplete

                            disableClearable
                            options={options}
                            getOptionLabel={(option) => option.label}
                            fullWidth
                            value={selectedOption}
                            renderInput={(params) => <TextField {...params} label="Assunto" variant="outlined" />}
                            onChange={(e, value) => setSelectedOption(value)}
                        />
                    </Grid>

                    <Grid item xs>

                        <iframe 
                            title='Veja video' 
                            src={selectedOption.url} 
                            width='100%' 
                            height='400'
                            frameborder="0" 
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            allowfullscreen />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

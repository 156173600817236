import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card } from '@material-ui/core';

export default function SkelectonCard() {
    return (
        <Card elevation={0.1}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
        </Card>

    );
}
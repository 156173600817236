import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSnackbar } from 'notistack';
import { Button, Grid, Box } from "@material-ui/core";
import AuthService from "../../../AuthService";
import ImagemCard from "./ImagemCard"
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import CloseIcon from '@material-ui/icons/Close';
import { useDropzone } from 'react-dropzone'
import S3 from 'react-aws-s3';
//import { ImageBoss } from 'imageboss-js';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { PulseLoader } from "react-spinners";
import { createUUID } from "../../../utils/index"


/*  http://res.cloudinary.com/parseint/image/upload/v1590428538/qrpedir/produtos/o5eluugpthlt2dezfugl.jpg  ---  padrao atual

    Amazon 
    https://jura-balde.s3-sa-east-1.amazonaws.com/teste/1.jpg 

    bucket - region - folder(if exists) - file name

    Imageboss

    https://img.imageboss.me/awsjurandi/cover/500x300/teste/1.jpg

    url imageboss - Your Source - operation in image - folder(if exists) - file name (the same as amazon)
*/


function CompCadastroImagem(props) {

    const { enqueueSnackbar } = useSnackbar();

    const produtoRef = useRef(props.produto);

    const { handleChangeProduto } = props
    const [imagens, setImagens] = useState(produtoRef.current.imagens || []);
    const [showWidget, setShowWidget] = useState(false);

    let service = new AuthService();
    let profile = service.getProfile()

    const empresaId = profile?.empresa?.id ? '/' + profile.empresa.id : '';

    const diretorio = 'qrpedir/produtos' + empresaId
    const URL = 'https://img.imageboss.me/parseint/cdn/'
    const URL_Thumb = 'https://img.imageboss.me/parseint/cover:inside/60x90/'
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState(null);

    const config = { //config aws
        bucketName: 'parseint',
        region: 'sa-east-1',
        dirName: diretorio,
        accessKeyId: process.env.REACT_APP_AWS_API_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_API_SECRET_KEY,
    }

    const configToDelete = { //config aws
        bucketName: 'parseint',
        region: 'sa-east-1',
        accessKeyId: process.env.REACT_APP_AWS_API_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_API_SECRET_KEY,
    }

    const handleFileInput = (file) => {
        if (!!file) {
            const imageName = createUUID()
            const ext = file.name.split('.').pop();
            const newFileName = imageName + '.' + ext;
            setFileName(newFileName);
            setSelectedFile(file);
        }
    }

    const uploadFile = async (file) => {
        if (!!file) {
            const ReactS3Client = new S3(config);
            ReactS3Client
                .uploadFile(file, fileName)
                .then(data => {
                    closeUploadWidget()
                    const newFile = Object.assign([], selectedFile)
                    newFile.url = data.location
                    setSelectedFile(newFile)
                    salvarImagem(data)
                })
                .catch(err => console.error(err))
        }
    }

    const removeFile = async (file) => {
        const ReactS3Client = new S3(configToDelete);
        //Nome do arquivo
        ReactS3Client
            .deleteFile(file.publicId)
            .then(data => {
                console.log(data)
            }).catch(err => console.error(err))
    }

    useEffect(() => {
        console.log("CRIADO COMPONENTE - PRODUTO ", produtoRef.current)
    }, []);

    useEffect(() => {
        console.log("State imagens alterado ", imagens)
        const produto = produtoRef.current
        produto.imagens = imagens
        handleChangeProduto(produto, "SALVAR")
    }, [imagens]);

    const salvarImagem = (data) => {
        // eslint-disable-next-line
        let { key, location, bucket } = data

        const service = new AuthService()

        const produto = produtoRef.current

        let imagem = {
            produto: { id: produto.id },
            url: URL,
            thumbnailUrl: URL_Thumb + key,
            publicId: key,
            ordem: produto.imagens.length + 1,
            ativo: true
        }

        service.post("/imagemProduto", imagem)
            .then(imagemProdutoSalva => {
                let imagensUpdate = [...produto.imagens, imagemProdutoSalva]
                setImagens(imagensUpdate)
                closeUploadWidget()

            }).catch(err => {
                closeUploadWidget()
                console.error(err);
                enqueueSnackbar('Erro ao salvar imagem (api)', { variant: 'error' })
            })
    }



    const openUploadWidget = () => {
        setShowWidget(true)
    };

    const closeUploadWidget = () => {
        setShowWidget(false)
    }

    const handleAlterarOrdem = (imagem) => {
        const service = new AuthService()
        service.put("/imagemProduto", imagem)
            .then(resp => {

                console.log("Resp", resp)
                let imagensUpdate = imagens.filter(e => e.id !== resp.id)

                imagensUpdate.push(resp)
                imagensUpdate.sort((a, b) => a.ordem - b.ordem);

                setImagens(imagensUpdate)

            }).catch(err => {
                enqueueSnackbar('Erro ao mudar ordem da imagem', { variant: 'error' })
            })

    }

    const handleDeleteImage = (imagem) => {
        const service = new AuthService()

        service.delete(`/imagemProduto/${imagem.id}`)
            .then(result => {
                let imagensUpdate = [...imagens]
                let index = imagensUpdate.findIndex(e => e.id === imagem.id)

                imagensUpdate.splice(index, 1)
                removeFile(imagem)
                setImagens(imagensUpdate)
            }).catch(err => {
                console.error(err);
                enqueueSnackbar('Erro ao excluir imagem (api)', { variant: 'error' })
            })
    }

    return (
        <div>

            {showWidget ? (
                <Box display="block" textAlign="right">
                    <Button
                        onClick={closeUploadWidget}
                        endIcon={<CloseIcon />}
                        variant="outlined">Cancelar upload</Button>
                </Box>
            ) : null}

            {showWidget && (
                <MyDropzone uploadFile={uploadFile} handleFileInput={handleFileInput} />
            )}
            {imagens.length > 0 ? (

                <Grid container spacing={3}>
                    {imagens.map(imagem => (
                        <Grid item xs={12} sm={4} key={imagem.id}>
                            <ImagemCard imagem={imagem}
                                handleDelete={handleDeleteImage}
                                handleOrdemAdicionar={() => { imagem.ordem += 1; return handleAlterarOrdem(imagem) }}
                                handleOrdemSubtrair={() => { imagem.ordem -= 1; return handleAlterarOrdem(imagem) }} />
                        </Grid>
                    ))}
                </Grid>
            ) : (
                "Nenhuma imagem adicionada"
            )}

            <Box pt={2}>
                <Button disabled={imagens.length > 2}
                    onClick={openUploadWidget}
                    color="primary"
                    variant="contained"
                    startIcon={<ImageSearchIcon />}>
                    Adicionar imagem  {imagens.length > 2 ? ' (limite de 3 imagens atingido)' : ''}
                </Button>
            </Box>

        </div>
    )
}

function MyDropzone({ uploadFile, handleFileInput }) {
    const [files, setFiles] = useState([]);
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        handleFileInput(acceptedFiles[0])
        uploadFile(acceptedFiles[0])
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));

        if (fileRejections.length > 0) {
            let error = fileRejections[0]?.errors[0]?.code

            switch (error) {
                case "too-many-files":
                    error = "Erro: Limite máximo de arquivos excedido, envie um arquivo de cada vez"
                    break;
                case "file-too-large":
                    error = "Erro: O tamanho do arquivo é muito grande, limite máximo " + maxFileSize / 1000000 + " Mb."
                    break;
                case "file-invalid-type":
                    error = "Erro: Tipo de arquivo inválido, selecione uma imagem"
                    break;
                default:
                    error = ''

            }

            enqueueSnackbar("Impossível adicionar este arquivo como imagem. " + error, { variant: 'error' })
        }
    }, [])

    const maxFileSize = 5 * 1000 * 1000; //5 Mb in bytes - syze in MB * Kbyte * Byte
    const {
        getRootProps,
        getInputProps,
        isDragActive
    } = useDropzone({
        maxFiles: 1, maxSize: maxFileSize, accept: { 'image/*': [] },
        onDrop
    })

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);




    return (

        <div {...getRootProps()}>
            <Box mt={2} mb={4} display='flex' justifyContent="center" sx={{ p: 4, border: '4px dashed grey' }}>
                <input {...getInputProps()} />

                {files?.length === 0 ? (
                    <>
                        <PermMediaIcon style={{ marginRight: 8 }} />
                        {isDragActive ?
                            'Solte a imagem aqui ... ' :
                            'Arraste e solte alguma imagem aqui, ou clique para seleciona-la'
                        }
                    </>
                ) : (
                    <PulseLoader color="#283454" size={15} speedMultiplier={0.42} loading />
                )}

            </Box>
        </div >
    )
}


export default CompCadastroImagem;
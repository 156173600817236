import React, { useEffect, useState } from 'react'
import { Button, Grid, TextField } from '@material-ui/core';
import { MenuItem } from "@material-ui/core";
import CustomDateFilter from '../../../../components/CustomDateFilter';
import moment from 'moment';

export default function DashboardFilter({setPeriodo}) {
    const [filtro, setFiltro] = useState(
        {
            periodo: {
                dataInicial: `${moment().subtract(6, 'hours').format('yyyy-MM-DD')} 06:00:00`,
                dataFinal: `${moment().subtract(6, 'hours').add(1, 'days').format('yyyy-MM-DD')} 06:00:00`
            },
            /*TODOS, delivery, mesa, sequencial */
            tipoPedido: 'TODOS',

            /*TODOS, DELIVERY, TAKEOUT */
            tipoEntrega: 'TODOS'
        })

    function handleChangeTipoPedido(event) {

        let name = event.target.name
        let value = event.target.value

        setFiltro({ ...filtro, [name]: value })
    }

    const handleChangePeriodo = (startDate, endDate) => {
        let periodo = {}
        periodo.dataInicial = startDate
        periodo.dataFinal = endDate

        setFiltro({ ...filtro, periodo })
    };

    const onClickBuscar = () => {
        setPeriodo(filtro)
    }

    return (
        <Grid container spacing={2} alignItems='center'>
            <Grid item xs={6} md={4}>
                <CustomDateFilter onChange={handleChangePeriodo} />
            </Grid>

            <Grid item xs={6} md={3}>
                <TextField
                    size='small'
                    variant="outlined"
                    select
                    label={"Tipo do pedido"}
                    name="tipoPedido"
                    fullWidth
                    defaultValue='TODOS'
                    onChange={(event) => handleChangeTipoPedido(event)}>
                    <MenuItem key={"TODOS"} value={'TODOS'} >TODOS</MenuItem>
                    <MenuItem key={"DELIVERY"} value={'DELIVERY'} >DELIVERY</MenuItem>
                    <MenuItem key={"MESA"} value={'MESA'} >MESA</MenuItem>
                    <MenuItem key={"COMANDA"} value={'COMANDA'} >COMANDA</MenuItem>
                    <MenuItem key={"SEQUENCIAL"} value={'SEQUENCIAL'} >SEQUENCIAL</MenuItem>
                </TextField>
            </Grid>

            {/* <Grid item xs={6} md={3}>
                <TextField
                    variant="outlined"
                    select
                    label={"Tipo da entrega"}
                    name="tipoEntrega"
                    defaultValue='TODOS'
                    fullWidth
                    onChange={(event) => handleChangeTipoPedido(event)}>
                    <MenuItem key={"TODOS"} value={'TODOS'} >TODOS</MenuItem>
                    <MenuItem key={"DELIVERY"} value={'DELIVERY'} >DELIVERY</MenuItem>
                    <MenuItem key={"RETIRADA"} value={'RETIRADA'} >RETIRADA</MenuItem>
                </TextField>
            </Grid> */}

            <Grid item xs={6} md={2}>
                <Button 
                    onClick={onClickBuscar}
                    fullWidth 
                    variant='contained' 
                    color='primary'>Buscar</Button>
            </Grid>
        </Grid>

    )
}

import React, { Component } from 'react'
import Template from '../Generico/Template';
import BarraTitulo from '../Generico/BarraTitulo';
import { Paper, Grid, Typography, Button, withStyles, Tabs, Tab, Box, FormControlLabel, FormControl, RadioGroup, Radio, AppBar } from '@material-ui/core';
import AuthService from '../../AuthService';
import { withSnackbar } from 'notistack';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import ConfigurarTimezone from "./configurarTimezone"

class HorarioAbertura extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            horarios: [],
            indexTipo: "DELIVERY"
        }
    }

    componentDidMount() {
        this.getHorarios();

    }

    handleSubmit = (horario) => {
        let { horarios } = this.state;
        horario = this.dateToString(horario);

        let service = new AuthService();

        service
            .post("/horarioAbertura", horario)
            .then(resp => {

                let index = horarios.findIndex(el => el.id === resp.id);
                horarios[index] = resp;

                horarios = this.stringToDate(horarios);

                this.setState({ horarios })

                this.props.enqueueSnackbar('Horarios salvos com sucesso', { variant: 'success' })
            })
            .catch(err => {
                this.props.enqueueSnackbar('Não foi possivel salvar os horarios', { variant: 'error' })
            })
    }

    handleChange = (date, name, horario) => {
        let { horarios } = this.state;

        let index = horarios.findIndex(el => el.id === horario.id);
        horarios[index][name] = date

        this.setState({ horarios });
    }

    getHorarios = () => {
        let service = new AuthService();

        service
            .get("/horarioAbertura/list")
            .then(resp => {
                console.log(">>>>>", resp)
                resp = this.stringToDate(resp);
                this.setState({ horarios: resp })
            })
    }



    dateToString = (horario) => {

        // formata de data para string de time - (00:00:00)
        horario.periodo01Inicio = horario.periodo01Inicio.toLocaleTimeString('it-IT');
        horario.periodo01Fim = horario.periodo01Fim.toLocaleTimeString('it-IT');
        horario.periodo02Inicio = horario.periodo02Inicio.toLocaleTimeString('it-IT');
        horario.periodo02Fim = horario.periodo02Fim.toLocaleTimeString('it-IT');

        return horario;
    }

    stringToDate = (horas) => {
        console.log(horas);
        horas.map(hora => {
            if (typeof hora.periodo01Inicio === "string") {
                let arrayH1Inicio = hora.periodo01Inicio.split(":");
                let arrayH1Fim = hora.periodo01Fim.split(":");

                let arrayH2Inicio = hora.periodo02Inicio.split(":");
                let arrayH2Fim = hora.periodo02Fim.split(":");

                let h1Inicio = new Date();
                let h1Fim = new Date();

                let h2Inicio = new Date();
                let h2Fim = new Date();

                h1Inicio.setHours(arrayH1Inicio[0]);
                h2Inicio.setHours(arrayH2Inicio[0]);

                h1Fim.setHours(arrayH1Fim[0]);
                h2Fim.setHours(arrayH2Fim[0]);

                h1Inicio.setMinutes(arrayH1Inicio[1]);
                h2Inicio.setMinutes(arrayH2Inicio[1]);

                h1Fim.setMinutes(arrayH1Fim[1]);
                h2Fim.setMinutes(arrayH2Fim[1]);

                hora.periodo01Inicio = h1Inicio;
                hora.periodo02Inicio = h2Inicio;

                hora.periodo01Fim = h1Fim;
                hora.periodo02Fim = h2Fim;
            }
            return null;
        })

        return horas;
    }

    handleChangeRadio = (event, horario) => {
        let { horarios } = this.state;

        let index = horarios.findIndex(el => el.id === horario.id);

        if (event.target.value === horarios[index].status.toString()) {
            horarios[index].status = 0;
        } else {
            horarios[index].status = event.target.value;
        }

        this.setState({ horarios })
    };

    handleChangeTab = (_, activeIndex) => {
        this.setState({ activeIndex })
    }

    handleChangeTabTipo = (_, indexTipo) => {
        this.setState({ indexTipo })
    }

    verificaDiaSemana = (indexDiaEscolhido) => {
        
        let { horarios, indexTipo } = this.state;
        let diasNome = ["Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado", "Domingo"];

        //soma mais um ao indexDiaEscolhido para pegar o index pelo dia da semana
        //indexDiaEscolhido = [0-6]
        //diasSemana = [1-7]
        let index = horarios.findIndex(el => el.diaSemana === (indexDiaEscolhido + 1) && el.tipo === indexTipo);
        let horario = horarios[index];
      
        return (<TabContainer title={diasNome[indexDiaEscolhido]}
            config={horario} handleChange={this.handleChange} handleSubmit={this.handleSubmit} handleChangeRadio={this.handleChangeRadio} />)
    }

    render() {
        const { activeIndex, indexTipo } = this.state;
        
        return (
            <Template documentTitle="Horário de abertura" >

                <BarraTitulo title="Horário de abertura"
                    subTitle="Configure os horários de abertura e fechamento" />

                <Box pb={3} mb={2}>
                    <ConfigurarTimezone/>
                </Box>

                <div>
                <Paper>
                    <AppBar position="static">
                        <Tabs value={indexTipo} onChange={this.handleChangeTabTipo} aria-label="simple tabs example">
                            <Tab value={"DELIVERY"} label="Delivery" {...a11yProps("DELIVERY")} />
                            <Tab value={"COMANDA"} label="Comanda / Mesa" {...a11yProps("COMANDA")} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={indexTipo} index={"DELIVERY"}>
                            <Box style={{ display: "flex" }}>
                                <VerticalTabs
                                    value={activeIndex}
                                    onChange={this.handleChangeTab}
                                >
                                    <MyTab label='Segunda-feira' />
                                    <MyTab label='Terça-feira' />
                                    <MyTab label='Quarta-feira' />
                                    <MyTab label='Quinta-feira' />
                                    <MyTab label='Sexta-feira' />
                                    <MyTab label='Sábado' />
                                    <MyTab label='Domingo' />
                                </VerticalTabs>

                                {/* index 0 = dia 1 da semana = domingo
                                index 6 = dia 7 da semana = sabado
                                (deixar assim devido ao java calendar)
                                */}

                                {this.verificaDiaSemana(activeIndex)}

                            </Box>
                    </TabPanel>
                    <TabPanel value={indexTipo} index={"COMANDA"}>
                            <Box style={{ display: "flex" }}>
                                <VerticalTabs
                                    value={activeIndex}
                                    onChange={this.handleChangeTab}
                                >
                                    <MyTab label='Segunda-feira' />
                                    <MyTab label='Terça-feira' />
                                    <MyTab label='Quarta-feira' />
                                    <MyTab label='Quinta-feira' />
                                    <MyTab label='Sexta-feira' />
                                    <MyTab label='Sábado' />
                                    <MyTab label='Domingo' />
                                </VerticalTabs>

                                {/* index 0 = dia 1 da semana = domingo
                                index 6 = dia 7 da semana = sabado
                                (deixar assim devido ao java calendar)
                                */}

                                {this.verificaDiaSemana(activeIndex)}

                            </Box>
                    </TabPanel>
                    </Paper>    
                </div>

            </Template>
        )
    }
}

const VerticalTabs = withStyles(theme => ({
    flexContainer: {
        flexDirection: 'column'
    },
    indicator: {
        display: 'none',
    },
    root: {
        flexGrow: "1"
    }
}))(Tabs)

const MyTab = withStyles(theme => ({
    root: {
        borderRight: "1px solid #d1d1d1",
    },
    selected: {
        color: '#253257',
        borderRight: "0",
        borderBottom: "1px solid #d1d1d1",
        borderTop: "1px solid #d1d1d1"
    }
}))(Tab);

function TabContainer(props) {
    let config = props.config;

    const validaHorariosUnicos = (periodoInicio, periodoFim) => {

        if (periodoFim < periodoInicio) {
            console.log("ERRO");

            return {
                error: true,
            }
        }
        else {
            console.log("N ERRO");
            return {
                error: false,
            }
        }
    }

    const validaHorarios = (config) => {

        if (config.periodo01Inicio > config.periodo01Fim && config.status !== 1) {
            errorText = "O horario final não pode ser menor que o inicial";
            return true
        } else if (config.periodo02Inicio > config.periodo02Fim && config.status !== 2) {
            errorText = "O horario final não pode ser menor que o inicial";
            return true
        } else if (config.periodo02Inicio < config.periodo01Fim && config.status === 0) {
            errorText = "Os horarios não devem convergir";
            return true
        } else return false



    }

    var errorText = "";

    return (
        <div style={{ flexGrow: "5" }}>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={12}>
                    <Typography style={{ margin: "15px 0", fontSize: "200%" }} align="center">
                        {props.title}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{ padding: "0 30px", display: "flex", flexDirection: "column", borderRight: "1px solid lightGray" }}>
                        <Typography align="center">
                            Horário 1
                        </Typography>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                ampm={false}
                                label="Horário inicial"
                                variant="outlined"
                                name="periodo01Inicio"
                                disabled={config ? config.status.toString() === "1" || config.status.toString() === "3" ?true : false : false}
                                value={config ? config.periodo01Inicio : new Date()}
                                onChange={(e) => props.handleChange(e, "periodo01Inicio", config)}

                            />

                            <TimePicker
                                ampm={false}
                                style={{ marginTop: "10px" }}
                                label="Horário final"
                                variant="outlined"
                                name="periodo01Fim"
                                disabled={config ? config.status.toString() === "1" || config.status.toString() === "3" ?true : false : false}
                                value={config ? config.periodo01Fim : new Date()}
                                error={config ? validaHorariosUnicos(config.periodo01Inicio, config.periodo01Fim).error : false}
                                onChange={(e) => props.handleChange(e, "periodo01Fim", config)}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box style={{ padding: "0 30px", display: "flex", flexDirection: "column" }}>
                        <Typography align="center">
                            Horário 2
                        </Typography>

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                                ampm={false}
                                label="Horário inicial"
                                variant="outlined"
                                name="periodo02Inicio"
                                disabled={config ? config.status.toString() === "2" || config.status.toString() === "3" ?true : false : false}
                                value={config ? config.periodo02Inicio : new Date()}
                                onChange={(e, event) => props.handleChange(e, "periodo02Inicio", config)}
                            />
                            <TimePicker
                                ampm={false}
                                style={{ marginTop: "10px" }}
                                label="Horário final"
                                variant="outlined"
                                name="periodo02Fim"
                                disabled={config ? config.status.toString() === "2" || config.status.toString() === "3" ?true : false : false}
                                value={config ? config.periodo02Fim : ""}
                                error={config ? validaHorariosUnicos(config.periodo02Inicio, config.periodo02Fim).error : false}
                                onChange={(e) => props.handleChange(e, "periodo02Fim", config)}
                            />
                        </MuiPickersUtilsProvider>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Box style={{ padding: "0 30px", borderLeft: "1px solid lightGray" }}>
                        <FormControl component="fieldset" >
                            <RadioGroup aria-label="opções" name="opções">
                                {/*Fica 0,2,1 e 3 pois antigamente era utilizado para ignorar o horário, 1 ignorava o horário 1 e 2 ignorava o horário 2*/}
                                <FormControlLabel value="0" control={<Radio checked={config ? config.status.toString() === "0" : false} onClick={(e) => props.handleChangeRadio(e, config)} />} label="Aberto nos 2 horários" />
                                <FormControlLabel value="2" control={<Radio checked={config ? config.status.toString() === "2" : false} onClick={(e) => props.handleChangeRadio(e, config)} />} label="Aberto apenas no horário 1" />
                                <FormControlLabel value="1" control={<Radio checked={config ? config.status.toString() === "1" : false} onClick={(e) => props.handleChangeRadio(e, config)} />} label="Aberto apenas no horário 2" />
                                <FormControlLabel value="3" control={<Radio checked={config ? config.status.toString() === "3" : false} onClick={(e) => props.handleChangeRadio(e, config)} />} label="Fechado o dia todo" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    <Box style={{ marginTop: "40px", display: "flex", justifyContent: "center" }}>
                        <Button variant="contained" color="primary" size="large" disabled={config ? validaHorarios(config) : true} onClick={() => props.handleSubmit(config)}>
                            Salvar
                        </Button>
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "center" }}>
                        <Typography style={{ color: "red", margin: "0 auto" }}>
                            {errorText}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
                {children}
            </Box>
        )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default withSnackbar(HorarioAbertura)

import React, { Component } from "react";
import CompListaGrupo from "./components/CompListaGrupo";
import CadastroGrupo from "./components/compCadastroGrupo";
import {
  Typography,
  Grid,
  Button,
  Divider,
  Switch,
  FormControlLabel,
  Box,
  Paper,
  Dialog,
  TextField,
  IconButton,
  CircularProgress,
  DialogTitle,
  InputAdornment,
  DialogContent,
  ListItem,
  List,
  ListItemText,
  Chip,
  FormControl,
  RadioGroup,
  Radio,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import AuthService from "../../AuthService";
import { withSnackbar } from "notistack";
import Template from "../Generico/Template";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BarraTitulo from "../Generico/BarraTitulo";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import { withRouter } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import utils from "../../utils";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import Utils from "../../utils";
import { TabPanel } from "react-tabs";


class Cardapio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grupos: [],
      ativos: true,
      identificadores: [],
      openBusca: false,
      produtosBuscados: null,
      subProdutosBuscados: false,
      escolhaBusca: "PRODUTO"
    };

    this.cadastroGrupoRef = React.createRef();
    this.listGrupoRef = React.createRef();
    this.input = React.createRef();
    this.baixarQRCode = this.baixarQRCode.bind(this);
    this.onCopy = this.onCopy.bind(this);
  }

  componentDidMount = () => {
    this.handleListIdentificadores();
    this.handleListGrupos();
  }

  handleListIdentificadores = () => {
    let { identificadores } = this.state;
    let service = new AuthService();

    service
      .get(`/identificador/tipo/DELIVERY`)
      .then(resp => {

        identificadores = resp;
        this.setState({ identificadores });

      })
      .catch(err => {
        this.props.enqueueSnackbar('Erro ao buscar loja online', { variant: 'error' })

      })
  }

  handleListGrupos = () => {
    let { grupos } = this.state;
    let service = new AuthService();

    service
      .get("/grupoProduto/listTodosComProdutos")
      .then(resp => {
        grupos = resp;
        this.setState({ grupos });
        this.handleListProdutosPromocao()
      })
      .catch(err => {
        this.props.enqueueSnackbar('Erro ao buscar grupos', { variant: 'error' })
      })
  }

  handleListProdutosPromocao = () => {
    let { grupos } = this.state;
    let service = new AuthService();
    let grupoProduto = { id: -1, ordem: -1, descricao: 'Em promoção', ativo: true, pausado: false, tipoGrupoProduto: { id: 1 } }

    let grupoPojo = { grupoProduto, produtos: [] }

    service
      .get("/produto/listPromocao")
      .then(resp => {
        grupoPojo.produtos = resp

        grupos.push(grupoPojo)

        grupos.sort((grupoA, grupoB) => grupoA.grupoProduto.ordem - grupoB.grupoProduto.ordem)
        this.setState({ grupos })
      })
      .catch(err => {
        this.props.enqueueSnackbar('Erro ao buscar grupos' + err, { variant: 'error' })
      })
  }

  handlePauseGrupo = (event, grupo) => {
    let { grupos } = this.state;
    event.stopPropagation();

    let string = `{ "id": ${grupo.grupoProduto.id}, "pausado": ${!grupo.grupoProduto.pausado} }`
    let json = JSON.parse(string)

    let service = new AuthService();
    service
      .put(`/grupoProduto/changeOnly`, json)
      .then(retorno => {

        grupo.grupoProduto = retorno;

        this.setState({ grupos });

        this.props.enqueueSnackbar('Grupo pausado/ativado com sucesso!', { variant: 'success' })

      }).catch(err => {
        console.error("Falha ao pausar/iniciar grupo", err)
      })
    return false;
  }

  handleChangeAtivos = () => {
    let { ativos } = this.state;
    this.setState({ ativos: !ativos });
  }

  handlePauseProduto = (event, produto, grupo = null) => {
    let { grupos, produtosBuscados } = this.state;
    event.stopPropagation();

    if (!grupo) {
      grupos.map(grupoPojo => {
        grupoPojo.produtos.map(prod => {
          if (prod.id === produto.id) {
            grupo = grupoPojo
          }
        })
      })
    }

    let string = `{ "id": ${produto.id}, "pausado": ${!produto.pausado} }`
    let json = JSON.parse(string)

    let service = new AuthService();
    service
      .put(`/produto/changeOnly`, json)
      .then(retorno => {

        if (produtosBuscados) {
          let indexBuscado = produtosBuscados.findIndex(el => el.id === produto.id);
          produtosBuscados[indexBuscado] = retorno;
          this.setState({ produtosBuscados })
        }

        let index = grupo.produtos.findIndex(el => el.id === produto.id);
        console.log("index " + index);

        grupo.produtos[index] = retorno;

        this.setState({ grupos });

        this.props.enqueueSnackbar('Produto pausado/ativado com sucesso!', { variant: 'success' })

      }).catch(err => {
        console.error("Falha ao pausar/iniciar produto", err)
      })
    return false;
  }

  handleNewGroup = (event) => {
    this.cadastroGrupoRef.current.novoGrupoProduto(event);
  }

  handleEditarGrupo = (grupo, event) => {
    this.cadastroGrupoRef.current.update(grupo, event)
  }

  handleUpdateGrupo = (groups) => {
    console.log(groups);

    let { grupos } = this.state;
    grupos = groups;
    this.setState({ grupos })
  }

  onCopy = () => {
    this.props.enqueueSnackbar('Copiado', { variant: 'success' })
  };

  baixarQRCode = (identificador) => {
    let service = new AuthService();

    let urlTOQrcode = "http://" + identificador + ".qrpedir.com"

    service
      .download("/identificador/gerarQrcode?url=" + encodeURIComponent(urlTOQrcode))
      .then(retorno => {

        var url = window.URL.createObjectURL(retorno);
        var a = document.createElement('a');
        a.href = url;
        a.download = "qrcode.png";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();

      }).catch(err => {
        console.error(err)
      })
  }

  duplicarProduto = (produto, grupo = null) => {

    if (!window.confirm("Deseja realmente criar uma cópia deste produto?"))
      return;
    let { grupos, produtosBuscados } = this.state;
    console.log(grupos);

    if (!grupo) {
      grupos.map(grupoPojo => {
        grupoPojo.produtos.map(prod => {
          if (prod.id === produto.id) {
            grupo = grupoPojo
          }
        })
      })
    }

    let service = new AuthService();
    service
      .post("/produto/duplicar", produto)
      .then(prod => {
        if (produtosBuscados) {
          produtosBuscados.map(product => {
            if (product.id === produto.id) {
              produtosBuscados.push(prod)
              this.setState({ produtosBuscados })
            }
          })
        }

        grupo.produtos.push(prod);
        let index = grupos.findIndex(el => el.grupoProduto.id === grupo.grupoProduto.id);
        grupos[index] = grupo;
        this.setState({ grupos })
      })
  }

  handleOpenBusca = () => {
    let { openBusca } = this.state;
    this.setState({ openBusca: !openBusca }, () => {
      if (!openBusca) {
        this.setState({ produtosBuscados: null })
      }
    });
  }

  keyDown = (a) => {
    if (a === "click" || a.key === 'Enter') {
      var valorPesquisa = this.input.current.children[1].children[0].value;
      if (this.state.escolhaBusca === "SUBITEM") {
        this.getSubItens(valorPesquisa)
      } else {
        this.getProdutos(valorPesquisa)
      }
    }
  }

  getProdutos = (valorBusca) => {
    let service = new AuthService();

    service
      .get(`/produto/list?q=${valorBusca}&ativo=true`)
      .then(resp => {
        this.setState({ produtosBuscados: resp })
        this.setState({ subProdutosBuscados: false })
        console.log(resp.length);
      })
      .catch(err => {
        console.error(err);
      })

  }

  verificaBuscaInexistente = () => {
    if (this.state.produtosBuscados === null) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography>
            Clique em buscar para pesquisar
          </Typography>
        </Box>
      )
    } else {
      return (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography color="error">
            Não encontramos nenhum produto
          </Typography>
        </Box>
      )
    }
  }

  saveProdutosBuscados = (pedidos) => {
    this.setState({ produtosBuscados: pedidos })
  }

  getSubItens = (valorBusca) => {
    let service = new AuthService();

    service
      .get(`/produto/buscar?q=${valorBusca}`)
      .then(resp => {
        this.setState({ produtosBuscados: resp })
        this.setState({ subProdutosBuscados: true })

        resp.map((produto) => {

          produto.complementos = produto.complementos.filter((ele, ind) => ind === produto.complementos.findIndex(elem => elem.jobid === ele.jobid && elem.id === ele.id)) //remove os grupos duplicados do código

          produto.complementos.map((complemento) => {

            complemento.complementoItens = complemento.complementoItens.filter(item => (item.descricao.toUpperCase().includes(valorBusca.toUpperCase()))); //remove os itens marcados como desatualizados

          })
        })

        this.setState({ produtosBuscados: resp })
        console.log(resp.length);
      })
      .catch(err => {
        console.error(err);
      })
  }

  handleChangeEscolhaTipoBusca = (event) => {

    this.setState({ escolhaBusca: event.target.value })

  }


  render() {
    let { identificadores, ativos, openBusca, produtosBuscados, escolhaBusca, subProdutosBuscados } = this.state;
    document.title = 'QR Pedir - Cardapio'
    let isAdmin = new AuthService().isAdmin();
    return (
      <React.Fragment>

        <Template documentTitle="Cadastro do cardápio">
          {identificadores.length > 0 ? (


            <Box mt={5} >
              <BarraTitulo title="Link do cardápio" subTitle="Compartilhe e divulgue o link em suas redes sociais. Facebook, WhatsApp, Site, Instagram entre outros" />
              <Paper>
                <Box p={3}>

                  {identificadores.map(identificador => (

                    <Grid container key={identificador.id}>

                      <Grid item xs={12} md={8} >
                        <Box p={1}  >
                          <Typography color="primary" variant="h6" >
                            <a href={"http://" + identificador.uuid + ".qrpedir.com"} target="_blank" >{identificador.uuid}.qrpedir.com</a>
                          </Typography>
                        </Box>
                      </Grid>


                      <Grid item xs={12} md={4}>

                        <Box display="flex" justifyContent="flex-end" >
                          <Box p={1}  >
                            <CopyToClipboard
                              text={identificador.uuid + '.qrpedir.com'}
                              onCopy={this.onCopy}>
                              <Button startIcon={<LinkIcon />} color="primary">Copiar</Button>
                            </CopyToClipboard>
                          </Box>

                          <Box p={1}>
                            <Button
                              onClick={() => this.baixarQRCode(identificador.uuid)}
                              startIcon={<BorderClearIcon />}>
                              QRCode
                            </Button>
                          </Box>

                          <Box p={1}>
                            <Button
                              onClick={() => this.props.history.push("/Delivery")}
                              startIcon={<EditIcon />}>
                              Editar
                            </Button>
                          </Box>


                        </Box>

                      </Grid>

                    </Grid>
                  ))}
                </Box>
              </Paper>
            </Box>
          ) : null}

          <Box mt={10}>
            <BarraTitulo title="CARDÁPIO" subTitle="Inserir itens, complementos e opcionais, definir disponibilidade e alterar preços." />
            <Paper>

              <Box p={3} display="flex" justifyContent="space-between">

                <Box>
                  <Button startIcon={<AddIcon />} variant="contained" color="primary" onClick={(e) => this.handleNewGroup(e)}>
                    Novo Grupo
                  </Button>

                  <Box p={2} display="inline">
                    <FormControlLabel
                      control={<Switch checked={ativos} onChange={this.handleChangeAtivos} />}
                      label={ativos ? "grupos ativos" : "grupos inativos"}
                    />
                  </Box>
                </Box>

                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={this.handleOpenBusca}
                    endIcon={<SearchIcon />}>
                    Buscar
                  </Button>
                </Box>
              </Box>

              <Divider />
              <Box  >
                <Grid container justifyContent="flex-end">
                  <CadastroGrupo ref={this.cadastroGrupoRef}
                    grupos={this.state.grupos}
                    handleUpdateGrupo={this.handleUpdateGrupo}
                    handleListGrupos={this.handleListGrupos}
                    listUpdate={() => this.handleListGrupos} />
                </Grid>
                <CompListaGrupo
                  ref={this.listGrupoRef}
                  produtosBuscados={produtosBuscados}
                  saveProdutosBuscados={this.saveProdutosBuscados}
                  handlePauseGrupo={this.handlePauseGrupo}
                  duplicarProduto={this.duplicarProduto}
                  ativos={this.state.ativos}
                  grupos={this.state.grupos}
                  handleUpdateGrupo={this.handleUpdateGrupo}
                  handlePauseProduto={this.handlePauseProduto}
                  handleEditarGrupo={this.handleEditarGrupo} />
              </Box>

            </Paper>
          </Box>
        </Template>
        <Dialog maxWidth="md" fullWidth open={openBusca} onClose={this.handleOpenBusca}>
          <DialogTitle>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <TextField
                ref={this.input}
                variant="outlined"
                fullWidth
                style={{ minWidth: "200px" }}
                label={"Nome ou código"}
                name="pesquisa"
                onKeyPress={(a) => this.keyDown(a)}
              />
              <Box pl={2}>
                <Button variant="contained" color="primary" onClick={() => this.keyDown("click")}> Buscar </Button>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>

            <FormControl>
              <RadioGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      onChange={this.handleChangeEscolhaTipoBusca}
                      checked={escolhaBusca === 'PRODUTO'}
                      value="PRODUTO"
                    />}
                  label="Buscar produtos" />
              </RadioGroup>
            </FormControl>
            <FormControlLabel
              control={
                <Radio
                  onChange={this.handleChangeEscolhaTipoBusca}
                  checked={escolhaBusca === "SUBITEM"}
                  value="SUBITEM"
                />}
              label="Buscar subitens" />

            {produtosBuscados ?
              produtosBuscados.length > 0 ?
                produtosBuscados.map(produto => (
                  <React.Fragment key={produto.id}>
                    <ExpansionPanel square="true">


                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <ListItem>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                <Typography color="textSecondary" variant='caption' >
                                  {produto.grupoProduto.ativo ? produto.grupoProduto.descricao.toUpperCase() :
                                    <Typography color="error" variant='caption'>{produto.grupoProduto.descricao.toUpperCase()} (inativo)</Typography>}
                                </Typography>
                                <Typography color={"primary"} style={{ fontWeight: "400", fontFamily: "Bebas Neue", textTransform: "capitalize", fontSize: "135%" }}>
                                  {produto.nome}
                                  {produto.promocaoAtiva ?
                                    <Chip color="primary" size="small" label="promo" icon={<StarIcon />} style={{ marginLeft: "15px" }} />
                                    : null}
                                </Typography>
                              </React.Fragment>
                            } />

                          <Typography>
                            {utils.formatNumber(produto.preco)}
                          </Typography>

                          <Button disabled={!produto.grupoProduto.ativo} edge="end" color={produto.pausado ? "secondary" : "primary"} onClick={(event) => this.handlePauseProduto(event, produto)}>
                            {produto.pausado ? (
                              <PlayCircleOutlineIcon />
                            ) : (
                              <PauseCircleOutlineIcon />
                            )}
                          </Button>

                          <Button title="Editar produto" disabled={!produto.grupoProduto.ativo} onClick={() => { this.listGrupoRef.current.handleOpenCadProd(); this.listGrupoRef.current.setProdutoEdit(JSON.parse(JSON.stringify(produto))) }}>
                            <EditIcon style={{ width: "20px" }} />
                          </Button>

                          {/* GRUPO PROMOCAO TEM ID = -1 */}
                          {produto.grupoProduto.id !== -1 && isAdmin ? (
                            <Button title="Criar uma cópia deste produto" onClick={() => this.duplicarProduto(produto)}>
                              <FileCopyOutlinedIcon style={{ width: "20px" }} />
                            </Button>
                          ) : null}
                        </ListItem>
                      </ExpansionPanelSummary>


                      {subProdutosBuscados && (
                        <ExpansionPanelDetails>
                          <ShowComplements
                            produto={produto}
                            produtosBuscados={produtosBuscados}
                            saveProdutosBuscados={this.saveProdutosBuscados}/>

                        </ExpansionPanelDetails>
                      )}



                      <Divider />

                    </ExpansionPanel>
                  </React.Fragment>
                )) : this.verificaBuscaInexistente() : this.verificaBuscaInexistente()}
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}


function ShowComplements({ produto, saveProdutosBuscados, produtosBuscados }) {

  const handleClickStopAndPause = (event, item, complemento) => {
    event.stopPropagation();
    let service = new AuthService();

    let string = `{ "id": ${item.id}, "produtoId": ${produto.id} }`
    let json = JSON.parse(string)

    service
      .put("/complementoItem/play-or-pause", json)
      .then(resp => {

        let compItemIndex = complemento.complementoItens.findIndex(el => el.id === item.id);
        let compIndex = produto.complementos.findIndex(el => el.id === complemento.id);

        produto.complementos[compIndex].complementoItens[compItemIndex] = resp;

        saveProdutosBuscados(produtosBuscados);
      })
  }

  return (
    <Box>
      {produto.complementos.map(complementos => (
        complementos.tipoComplemento !== "TAMANHO" ? (
          <React.Fragment>
            <Box paddingTop={1} key={complementos.id}>
              <ExpansionPanel square="true">
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon />}>

                  <ListItem dense>
                    <ListItemText dense
                      primary={
                        <Typography color="textPrimary" variant="button">
                          {complementos.descricao}
                        </Typography>
                      }
                      secondary={
                        <Typography color="textSecondary" variant="body1">
                          Qtd. min. {complementos.min} | Qtd. Máx. {complementos.max}
                          {complementos.obrigatorio ? ' (obrigatório)' : ' (opcional)'}
                        </Typography>
                      } />

                  </ListItem>
                </ExpansionPanelSummary>

                <ExpansionPanelDetails>
                  <List dense={true} style={{ width: "100%" }}>
                    {complementos.complementoItens.map(complementoItem => (

                      <React.Fragment key={complementoItem.id}>
                        <Grid container direction="row">
                          <Grid item xs={8} sm={8}>
                            <ListItemText
                              primary={complementoItem.descricao}
                              secondary={Utils.formatNumber(complementoItem.valor)} />
                          </Grid>

                          <Grid item xs={4} sm={4}>
                            <Button
                              startIcon={complementoItem.pausado ? <PlayCircleOutlineIcon /> : <PauseCircleOutlineIcon />}
                              color={complementoItem.pausado ? "secondary" : "primary"}
                              onClick={(event) => { handleClickStopAndPause(event, complementoItem, complementos) }}>
                              {complementoItem.pausado ? (
                                'Iniciar'
                              ) : (
                                'Pausar'
                              )
                              }
                            </Button>
                          </Grid>
                        </Grid>

                        <Divider />

                      </React.Fragment>
                    ))}
                  </List>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Box>
          </React.Fragment>
        ) : null
      ))}
    </Box>
  )
}

export default withRouter(withSnackbar(Cardapio));